import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { MemorizedFormBuilderDataUpdate } from './dynamicFormBuilder';

import { addAttributeDetail, AttributeUpdate, getAttributeDetail } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "../../../components/Common/Alert";

const FormLayoutsBasic = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL
  const [formData, setFormData] = useState({
    "id": editId,
    "content": "",
    "status": ""
  });
  const { attributeDetail } = useSelector((state) => ({
    attributeDetail: state.attributes.attributeDetail,
  }));

  useEffect(() => {
    if (editId) {
      dispatch(getAttributeDetail(editId));
    }
  }, [editId]);

  const handleSubmit = (data) => {
    let postBody = {};
    postBody.content = data;

    if (postBody.content) {
      if (Number(formData.id) > 0) {
        dispatch(AttributeUpdate({ ...postBody, id: formData.id }));
      } else {
        dispatch(addAttributeDetail(postBody));
      }
      navigate("/admin/questionnaire-attributes");

    }
  }


  var newAttributes = {
    className: {
      label: 'Attribute Custom name',
      style: 'border: 1px solid red',
      type: 'text',
      label: 'Show in Group Desc.?'
    },
    class_name: {
      label: 'Class',
      value: 'col-md-4',
    },
    is_any_dependent_yes: {
      label: 'Any dependent yes?',
      value: false,
      type: 'checkbox',
    },
    dependent_ids_yes: {
      label: 'Dependent ids',
      value: '',
      type: 'textarea',
    },
  };

  var newUserEventAttr = {
    onadd: function (fld) {
      var $patternField = $('.fld-dependent_ids_yes', fld);
      $patternField.parent().parent();
      $('.fld-is_any_dependent_yes', fld)
        .change(function (e) {
          $patternField.parent().parent().toggle(e.currentTarget.checked);
        });
    }
  }

  var userAttrs = {};
  var userEventAttrs = {};
  const fields = [
    'text',
    'hidden',
    'number',
    'date',
    'radio-group',
    'checkbox-group',
    'select',
    'textarea',
    'file',
  ];
  fields.forEach(function (item, index) {
    if (item === 'select') {
      userAttrs[item] = {
        ...newAttributes, select_field: {
          label: 'Select field',
          options: {
            'default': 'Default',
            'countryus': 'Country US',
            'country': 'Country',
            'state': 'State',
            'dependent': 'Dependent'
          }
        }
      }
    }
    else if (item === 'text') {
      userAttrs[item] = {
        ...newAttributes, select_field_type: {
          label: 'Select field',
          options: {
            'default': 'Default',
            'label': 'Label',
            'MaskPhone': 'MaskPhone',
            'MaskSSN': 'MaskSSN'
          }
        }
      }
    }
    else if (item === 'textarea') {
      userAttrs[item] = {
        ...newAttributes, select_field_type: {
          label: 'Select field type',
          options: {
            'default': 'Default',
            'richEditor': 'RichEditor',
          }
        }
      }
    }
    else {
      userAttrs[item] = newAttributes
    }
    ;
  });

  fields.forEach(function (item, index) {
    userEventAttrs[item] = newUserEventAttr;
  });

  console.log('userAttrs', userAttrs.text);

  var options = {
    showActionButtons: false, // defaults: `true`

    disabledAttrs: ["access", 'className'],
    disableFields: ['autocomplete', 'starRating', 'autocomplete', 'header', 'paragraph', 'button'],
    controlPosition: 'right',
    controlOrder: [
      'text',
      'hidden',
      'number',
      'date',
      'radio-group',
      'checkbox-group',
      'select',
      'textarea',
      'file',
    ],
    typeUserAttrs: userAttrs,
    typeUserEvents: userEventAttrs,
    onSave: function (formData) {
      console.log('formData', formData);

    }
  };

  let title = editId ? 'Update Questionnaire Attribute' : 'Add Questionnaire Attribute';
  const BreadcrumbData = [
    { name: 'Questionnaire Attributes', url: '/admin/questionnaire-attributes' },
    { name: title, url: '/admin/add-questionnaire-attributes' }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

          <Row>
            <Col xs={12}>
              <Card>

                <CardBody className="p-4">
                  {/* import TextualInputs */}
                  <div className="mt-4 mt-lg-0">


                    <MemorizedFormBuilderDataUpdate
                      getChildFormData={handleSubmit}
                      attributeDetailData={Number(formData.id) > 0 ? attributeDetail?.content : ''}
                      attForm_id={attributeDetail?.form_id}
                      attrid={attributeDetail?.id}
                      options={options}
                    />



                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};
export default FormLayoutsBasic
