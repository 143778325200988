import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import DatatableContainer from "../../../../components/Common/DatatableContainer";
import {
  Link, useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import {
  CommonCell, Date, CaseTypeName, LinkCell
} from "./listCol";


export const Case = () => {
  document.title = "Cases | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openTab, setopenTab] = useState("Open");

  const { employercontactDetail } = useSelector((state) => ({
    employercontactDetail: state.EmployerContacts.employercontactDetail.personCases || [],
  }));

  const onViewData = (data) => {
    navigate(`/employer/view-case?caseId=${data.case_id}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Case",
        accessor: "case_number",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CaseTypeName {...cellProps} />;
        },
      },
      {
        Header: "Case Type",
        accessor: "case_type",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Questionnaire Type",
        accessor: "qnnr_type",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Processing Status",
        accessor: "processing_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Case Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => onViewData(cellProps.row.original)}>View</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );


  return (
    <React.Fragment>
      <div>
        {/* Render Breadcrumbs */}
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <DatatableContainer
                  columns={columns}
                  data={employercontactDetail}
                  isGlobalFilter={true}
                  //isInvoiceFilter={true}
                  //defaultAttorneyFilter={'Open'}
                  // isInvoiceFilterChange={onReloadData}
                  // isAddInvoiceList={true}
                  customPageSize={10}
                //handleInvoiceClick={handleUserClicks}
                />
              </CardBody>

            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
