import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_INVOICES, GET_INVOICE_DETAIL, ADD_INVOICE_DETAIL, SEND_INVOICE, UPDATE_INVOICE_PDF, RECORD_PAYMENT, DELETE_INVOICE_DETAIL, UPDATE_INVOICE_DETAIL, GET_INVOICE_SETTINGS, GET_QUICKBOOK_CALLBACK, UPDATE_INVOICE_SETTINGS, CONNECT_QUICKBOOKS, UPLOAD_INVOICE_ATTACHMENT, GET_INVOICE_TIMESHEET_DETAIL, GET_QUICKBOOK_REFRESH,GET_QUICKBOOK_STATUS, GET_QUICKBOOK_SYNC_INVOICE, GET_CREATE_INVOICE_SETTINGS } from "./actionTypes"
import {
  getInvoicesSuccess,
  getInvoicesFail,
  getInvoiceDetailSuccess,
  getInvoiceDetailFail,
  addInvoicedetailSuccess,
  addInvoicedetailFail,
  sendInvoiceSuccess,
  sendInvoiceFail,
  updateInvoicePdfSuccess,
  updateInvoicePdfFail,
  recordPaymentSuccess,
  recordPaymentFail,
  invoiceDeleteSuccess,
  invoiceDeleteFail,
  invoiceUpdateSuccess,
  invoiceUpdateFail,
  getInvoiceSettingsSuccess,
  getInvoiceSettingsFail,
  getCreateInvoiceSettingsSuccess,
  getCreateInvoiceSettingsFail,
  updateInvoiceSettingsSuccess,
  updateInvoiceSettingsFail,
  connectQuickbooksSuccess,
  connectQuickbooksFail,
  uploadInvoiceAttachmentSuccess,
  uploadInvoiceAttachmentFail,
  getInvoiceTimesheetDetailsSuccess,
  getInvoiceTimesheetDetailsFail,
  getQuickbookCallbackSuccess,
  getQuickbookCallbackFail,
  getQuickbookRefreshSuccess,
  getQuickbookRefreshFail,
  getQuickbookSyncInvoiceSuccess,
  getQuickbookSyncInvoiceFail,
  getQuickbookStatusSuccess,
  getQuickbookStatusFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getInvoices, getInvoiceDetail, connectQuickBookApi, getInvoiceSettingsApiHelper, getCreateInvoiceSettingsApiHelper, getQuickbookCallbackApiHelper, getQuickbookSyncInvoiceApiHelper, getQuickbookRefreshApiHelper,getQuickbookStatusApiHelper, updateInvoiceSettingsApiHelper, uploadInvoiceAttachmentApiHelper, addInvoice, sendInvoice, updateInvoicePdf, recordPayment, getInvoiceTimesheetDetails, deleteInvoice, updateInvoice } from "../../helpers/apibackend_helper"
import { setAlertMessage } from "../common/actions"
const invoiceList = [
  {
    id: 1,
    invoiceId: "#MN0215",
    founder: "Marion Burton",
    founderAt: "Skote",
    invoiceID: "14251",
    company: "Skote Dashboard UI",
    invoicePrice: "1455",
    Amount: "$26.30",
    status: "Paid",
    date: "10 Oct, 2020",
    color: "success",
    orderId: "12345",
    billingAddress: "John Smith, 1234 Main, Apt. 4B, Springfield ST 54321",
    shippingAddress: "Kenny Rigdon, 1234 Main, Apt. 4B, Springfield ST 54321",
    card: "Visa ending **** 4242",
    email: "jsmith@email.com",
    orderDate: "October 16, 2019",
    orderSummary: {}
  },
]

function* fetchInvoices({ payload: data }) {
  try {
    const response = yield call(getInvoices, data)
    yield put(getInvoicesSuccess(response.data))
  } catch (error) {
    yield put(getInvoicesFail(error))
  }
}

function* fetchInvoiceDetail({ invoiceId }) {
  try {
    const response = yield call(getInvoiceDetail, invoiceId)
    yield put(getInvoiceDetailSuccess(response.data))
  } catch (error) {
    yield put(getInvoiceDetailFail(error))
  }
}

function* addInvoiceDetail({ payload: invoicedata }) {
  try {
    const response = yield call(addInvoice, invoicedata)
    yield put(addInvoicedetailSuccess(response.data))
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(addInvoicedetailFail(error))
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* fetchSendInvoice({ payload: invoicedata }) {
  try {
    const response = yield call(sendInvoice, invoicedata)
    yield put(sendInvoiceSuccess(response.data))
  } catch (error) {
    yield put(sendInvoiceFail(error))
  }
}

function* fetchUpdateInvoicePdf({ payload: invoicedata }) {
  try {
    const response = yield call(updateInvoicePdf, invoicedata)
    yield put(updateInvoicePdfSuccess(response.data))
  } catch (error) {
    yield put(updateInvoicePdfFail(error))
  }
}

function* fetchRecordPayment({ payload: invoicedata }) {
  try {
    const response = yield call(recordPayment, invoicedata)
    yield put(recordPaymentSuccess(response.data))
  } catch (error) {
    yield put(recordPaymentFail(error))
  }
}

function* fetchQuickbookCallback({ payload: quickbookpayload }) {
  try {
    const response = yield call(getQuickbookCallbackApiHelper, quickbookpayload)
    yield put(getQuickbookCallbackSuccess(response.data))
  } catch (error) {
    yield put(getQuickbookCallbackFail(error))
  }
}

function* fetchQuickbookSyncInvoice({ payload: quickbookpayload }) {
  try {
    const response = yield call(getQuickbookSyncInvoiceApiHelper, quickbookpayload)
    yield put(getQuickbookSyncInvoiceSuccess(response.data))
  } catch (error) {
    yield put(getQuickbookSyncInvoiceFail(error))
  }
}

function* fetchQuickbookRefresh({ payload: quickbookpayload }) {
  try {
    const response = yield call(getQuickbookRefreshApiHelper, quickbookpayload)
    yield put(getQuickbookRefreshSuccess(response.data))
  } catch (error) {
    yield put(getQuickbookRefreshFail(error))
  }
}
function* fetchQuickbookStatus({ payload: quickbookpayload }) {
  try {
    const response = yield call(getQuickbookStatusApiHelper, quickbookpayload)
    yield put(getQuickbookStatusSuccess(response.data))
  } catch (error) {
    yield put(getQuickbookStatusFail(error))
  }
}

function* fetchInvoiceSettings({ payload: invoicedata }) {
  try {
    const response = yield call(getInvoiceSettingsApiHelper, invoicedata)
    yield put(getInvoiceSettingsSuccess(response.data))
  } catch (error) {
    yield put(getInvoiceSettingsFail(error))
  }
}

function* fetchCreateInvoiceSettings({ payload: invoicedata }) {
  try {
    const response = yield call(getCreateInvoiceSettingsApiHelper, invoicedata)
    yield put(getCreateInvoiceSettingsSuccess(response.data.invoice_settings))
  } catch (error) {
    yield put(getCreateInvoiceSettingsFail(error))
  }
}

function* fetchUpdateInvoiceSettings({ payload: invoicedata }) {
  try {
    const response = yield call(updateInvoiceSettingsApiHelper, invoicedata)
    yield put(updateInvoiceSettingsSuccess(response.data))
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(updateInvoiceSettingsFail(error))
  }
}

function* fetchQuickbookURL({ payload: invoicedata }) {
  try {
    const response = yield call(connectQuickBookApi, invoicedata)
    yield put(connectQuickbooksSuccess(response.data))
  } catch (error) {
    yield put(connectQuickbooksFail(error))
  }
}

function* uploadInvoiceAttachments({ payload: invoicedata }) {
  try {
    const response = yield call(uploadInvoiceAttachmentApiHelper, invoicedata)
    yield put(uploadInvoiceAttachmentSuccess(response.data))
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(uploadInvoiceAttachmentFail(error))
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* onDeleteInvoice({ payload: data }) {
  try {
    const response = yield call(deleteInvoice, data);
    yield put(invoiceDeleteSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(invoiceDeleteFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* onUpdateinvoice({ payload: data }) {
  try {
    const response = yield call(updateInvoice, data);
    yield put(invoiceUpdateSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(invoiceUpdateFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* fetchInvoiceTimesheetDetails({ payload: invoicedata }) {
  try {
    const response = yield call(getInvoiceTimesheetDetails, invoicedata)
    yield put(getInvoiceTimesheetDetailsSuccess(response.data))
  } catch (error) {
    yield put(getInvoiceTimesheetDetailsFail(error))
  }
}

function* invoiceSaga() {
  yield takeEvery(GET_INVOICES, fetchInvoices)
  yield takeEvery(GET_INVOICE_DETAIL, fetchInvoiceDetail)
  yield takeEvery(ADD_INVOICE_DETAIL, addInvoiceDetail)
  yield takeEvery(SEND_INVOICE, fetchSendInvoice)
  yield takeEvery(UPDATE_INVOICE_PDF, fetchUpdateInvoicePdf)
  yield takeEvery(RECORD_PAYMENT, fetchRecordPayment)
  yield takeEvery(GET_INVOICE_SETTINGS, fetchInvoiceSettings)
  yield takeEvery(GET_CREATE_INVOICE_SETTINGS, fetchCreateInvoiceSettings)
  yield takeEvery(GET_QUICKBOOK_CALLBACK, fetchQuickbookCallback)
  yield takeEvery(GET_QUICKBOOK_SYNC_INVOICE, fetchQuickbookSyncInvoice)
  yield takeEvery(GET_QUICKBOOK_REFRESH, fetchQuickbookRefresh)
  yield takeEvery(GET_QUICKBOOK_STATUS, fetchQuickbookStatus)
  yield takeEvery(UPDATE_INVOICE_SETTINGS, fetchUpdateInvoiceSettings)
  yield takeEvery(CONNECT_QUICKBOOKS, fetchQuickbookURL)
  yield takeEvery(UPLOAD_INVOICE_ATTACHMENT, uploadInvoiceAttachments)
  yield takeEvery(DELETE_INVOICE_DETAIL, onDeleteInvoice)
  yield takeEvery(UPDATE_INVOICE_DETAIL, onUpdateinvoice)
  yield takeEvery(GET_INVOICE_TIMESHEET_DETAIL, fetchInvoiceTimesheetDetails)
}

export default invoiceSaga
