import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown
} from "reactstrap";
import DatatableContainer from "../../../../components/Common/DatatableContainer";

import {changePreloader, addDocumentUpload, bundleCasedocuments, getAttorneyCaseDetail, getCaseDocuments, requestDocument, revokeAttorneyFormReview, sendReminderDocument, shareUploadedDoc } from "../../../../store/actions";

import {
  Assigned,
  BillingName,
  Date, InvoiceId,
  SharedInfo
} from "./formCol";

import {
  Link
} from "react-router-dom";

//redux
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import * as Yup from "yup";

const Documents = () => {
  //meta title
  document.title = "Documents | Immidock ";

  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getCaseDocuments({
  //     case_id: caseId
  //   }));
  // }, [dispatch]);

  const queryParams = new URLSearchParams(location.search);
  const caseId = queryParams.get("caseId"); // Read the 'id' query parameter from the URL

  const [activeTab, setactiveTab] = useState("All");

  const { caseDocuments, bundlecasedocs } = useSelector((state) => ({
    caseDocuments: state.AttorneyCases.caseDocuments || [],
    bundlecasedocs: state.AttorneyCases.bundlecasedocs || "",
  }));

  const { caseDetail } = useSelector((state) => ({
    caseDetail: state.AttorneyCases.caseDetail || [],
  }));
  useEffect(() => {
    dispatch(getAttorneyCaseDetail({
      case_id: caseId
    }));
  }, [caseId]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [order, setOrder] = useState(null);

  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);

  const { invoices } = useSelector((state) => ({
    invoices: state.Invoices.invoices,
  }));


  const [showReviewModel, setReviewShowModel] = useState(false);
  const [caseDocsId, setCaseDocsId] = useState();
  const [caseViewDocuments, setCaseViewDocuments] = useState([]);
  const shareFormforReview = (data) => {
    setReviewShowModel(!showReviewModel);
    setCaseDocsId(data.case_docs_id);
  }
  const viewDocuments = (data) => {
    setShowViewDocuments(!showViewDocments);
    setCaseViewDocuments(data);
  }
  const [caseDocumentIds, setCaseDocumentIds] = useState([]);
  const handleChecked = (e, index) => {
    let prev = caseDocumentIds;
    let itemIndex = prev.indexOf(index);
    if (itemIndex !== -1) {
      prev.splice(itemIndex, 1);
    } else {
      prev.push(index);
    }
    setCaseDocumentIds([...prev]);
  };

  const sendReminderDoc = (data) => {
    dispatch(sendReminderDocument({ case_id: caseId, case_docs_id: data.case_docs_id }));
  }

  const shareDocvalidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      case_id: caseId,
      share_user: '',
      case_docs_id: caseDocsId,
    },
    validationSchema: Yup.object({
      share_user: Yup.string().required("Please select User"),
    }),
    onSubmit: (values) => {
      dispatch(shareUploadedDoc(values));
      dispatch(getCaseDocuments({
        case_id: caseId
      }));
      setReviewShowModel(false);
      shareDocvalidation.resetForm();
    }
  });
  var userType = window.localStorage.getItem("user_type")
  let moreactions = [];
  if (userType === "attorney") {
    moreactions = [
      { label: "Add Document", action: "adddocument" },
      { label: "Request Document", action: "requestdocument" },
      { label: "Create Bundle", action: "createbundle" },
    ];
  }
  else {
    moreactions = [
      { label: "Add Document", action: "adddocument" }
    ];
  }


  const document_details = [
    {
      id: 1,
      invoiceId: "G-28 (I129)",
      date: "10/15/2023",
      founder: "--",
      founderAt: "--",
      invoiceID: "14251",
      company: "--",
      invoicePrice: "1455",
      Amount: "--",
      status: "Pending",
      qnnrprocess: "50",
      isprinted: true,
    },
  ];

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setOrder(data);
  };

  const handleDeleteInvoice = () => {
    if (order.id) {
      dispatch(invoiceDelete(order.id));
      setDeleteModal(false);
    }
  };

  const onUpdateData = (data) => {
    setOrder({
      id: data.id,
      Amount: data.Amount,
      color: data.color,
      date: moment(data.date).format("YYYY-MM-DD"),
      founder: data.founder,
      invoiceId: data.invoiceId,
      status: data.status,
    });

    setIsEdit(true);

    toggle();
  };
  const [showSharedModel, setShowSharedModel] = useState(false);
  const [sharedContacts, setSharedContacts] = useState([]);
  const [docInfo, setDocumentInfo] = useState('');
  const clickSharedInfo = (cellInfo) => {
    setShowSharedModel(!showSharedModel)
    let { is_shared_to_petitioner, is_shared_to_employee, is_shared_to_attroney } = cellInfo.cell.row.original;
    let sharedDataShow = [];
    if (is_shared_to_petitioner === 1 && userType === "attorney") {
      sharedDataShow = [...sharedDataShow, { contact: caseDetail?.petitioner_company, type: 'Petitioner' }]
    }
    if (is_shared_to_attroney === 1 && userType === "employer") {
      sharedDataShow = [...sharedDataShow, { contact: caseDetail?.attorney_company, type: 'Attorney' }]
    }
    if (is_shared_to_employee == 1) {
      sharedDataShow = [...sharedDataShow, { contact: caseDetail?.employee_name, type: 'Employee' }]
    }

    setSharedContacts(sharedDataShow);
    setDocumentInfo(cellInfo);

  };
  const handleRevokeButtonClicks = (type) => {
    let { case_docs_id, case_id } = docInfo.cell.row.original;
    const reqBody = {
      case_docs_id: case_docs_id,
      case_id,
      user_type: type,
      formType: 'documents'
    }
    dispatch(revokeAttorneyFormReview(reqBody));
    setShowSharedModel(false)
    dispatch(getCaseDocuments({
      case_id: caseId
    }));
  }
  const columns = [
    {
      Header: "#",
      Cell: (cell) => {
        return <input
          checked={caseDocumentIds.includes(cell.row.original.case_docs_id)}
          onChange={(e) => handleChecked(e, cell.row.original.case_docs_id)}
          //  onChange={toggleHandler(cell.row.original.case_form_id)} 
          type="checkbox" name="case_document_ids" value={cell.row.original.case_docs_id} disabled={cell.row.original.doc_status == "Active" ? false : true} />;
      },
    },
    {
      Header: "Name",
      accessor: "doc_name",
      filterable: true,
      disableFilters: true,
      Cell: (cellProps) => {
        return <InvoiceId {...cellProps} />;
      },
    },
    {
      Header: "Document Type",
      accessor: "doc_type",
      filterable: true,
      disableFilters: true,
      Cell: (cellProps) => {
        return <BillingName {...cellProps} />;
      },
    },

    {
      Header: "Assigned To",
      accessor: "assigned_to",
      filterable: true,
      disableFilters: true,
      Cell: (cellProps) => {
        return <Assigned {...cellProps} />;
      },
    },
    {
      Header: "Uploaded By",
      accessor: "uploaded_by",
      filterable: true,
      disableFilters: true,
      Cell: (cellProps) => {
        return <BillingName {...cellProps} />;
      },
    },
    {
      Header: "Shared Info",
      accessor: "shared_doc_count",
      filterable: true,
      disableFilters: true,
      Cell: (cellProps) => {
        return (
          <SharedInfo
            {...cellProps}
            clickSharedInfo={() => clickSharedInfo(cellProps)}
          />
        );
      },
    },

    // {
    //   Header: "Is Printed",
    //   accessor: "is_printed",
    //   filterable: true,
    //   disableFilters: true,
    //   Cell: (cellProps) => {
    //     return <IsPrinted {...cellProps} />;
    //   },
    // },
    {
      Header: "Status",
      accessor: "doc_status",
      filterable: true,
      disableFilters: true,
      Cell: (invoice) => {
        return (
          <div className={(invoice.row.original.doc_status == "Active" ? "badge-soft-success" : "badge-soft-warning") + " badge font-size-12"}>
            {invoice.row.original.doc_status == "Active" ? "Uploaded" : invoice.row.original.doc_status}
          </div>
        );
      },
    },

    {
      Header: "Uploaded",
      accessor: "upload_date",
      filterable: true,
      disableFilters: true,
      Cell: (cellProps) => {
        return <Date {...cellProps} />;
      },
    },

    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn btn-link text-muted py-1 font-size-16 shadow-none"
              tag="a"
            >
              <i className="bx bx-dots-horizontal-rounded"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {(cellProps.row.original.doc_status == "Pending" ? (
                <>
                  <DropdownItem onClick={() => sendReminderDoc(cellProps.row.original)}>Reminder Document</DropdownItem>
                </>
              ) :
                <>
                  {/* <DropdownItem
                    onClick={() => onUpdateData(cellProps.row.original)}
                  >
                    Edit
                  </DropdownItem> */}
                  {cellProps.row.original?.doc_media && cellProps.row.original.doc_media.length > 0 && (
                    <DropdownItem onClick={() => viewDocuments(cellProps.row.original)}>View Documents
                    </DropdownItem>
                  )}
                  {
                    (userType === "attorney") && (
                      <><DropdownItem onClick={() => shareFormforReview(cellProps.row.original)}>Share Document</DropdownItem></>
                    )
                  }
                  
                  {/* {cellProps.row.original.attachment && (
                    <Link to={cellProps.row.original.attachment} target="_blank" download>
                      <DropdownItem>Download
                      </DropdownItem>
                    </Link>
                  )} */}
                  {/* <DropdownItem onClick={() => onDeleteData(cellProps.row.original)}>Delete</DropdownItem>
                  <DropdownItem
                    onClick={() => onDeleteData(cellProps.row.original)}
                  >
                    Delete
                  </DropdownItem>  */}
                </>)}
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];
  const [apiSuccess, setApiSuccess] = useState(false);
  const [showAddDocModel, setShowAddDocModel] = useState(false);
  const [showViewDocments, setShowViewDocuments] = useState(false);
  const [showRequestDocModel, setShowRequestDocModel] = useState(false);
  const [moreActions, setMoreActions] = useState('');

  const handleActionSubmit = (e) => {
    // validation.handleSubmit();
    e.preventDefault();
    setMoreActions(e.target.value);
    if (e.target.value === 'adddocument') {
      setShowAddDocModel(!showAddDocModel);
    } else
      if (e.target.value === 'requestdocument') {
        setShowRequestDocModel(!showRequestDocModel)
      } else if (e.target.value === 'createbundle') {
        if (caseDocumentIds.length > 0) {
          dispatch(bundleCasedocuments({
            case_id: caseId,
            case_document_ids: caseDocumentIds.toString(),
          }));
          dispatch(changePreloader(true));
          setApiSuccess(true);
          setMoreActions("");
          setCaseDocumentIds([]);
        } else {
          alert('Please select uploaded documents');
        }
      }
  };

  useEffect(() => {
    if (bundlecasedocs.length > 0 && apiSuccess) {
      setMoreActions('');
      setApiSuccess(false);
      dispatch(changePreloader(false));
      window.open(bundlecasedocs, '_blank', 'noreferrer');
    }
  }, [bundlecasedocs]);

  const MAX_FILE_SIZE = 2097152;
  const MAX_ALLOWED_MESSAGE = "Max allowed size is 2MB";

  // Form validation 
  const addDocvalidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      add_document: '',
      share_user: '',
      document_name: '',
      document_category: '',
    },
    validationSchema: Yup.object({
      add_document: Yup.mixed()
        .required('Please select document')
        .test("is-valid-size", MAX_ALLOWED_MESSAGE,
          value => value && value.size <= MAX_FILE_SIZE),
      share_user: Yup.string().required("Please select contact"),
      document_name: Yup.string().required("Please Enter document name"),
      document_category: Yup.string().required("Please select document category"),
    }),
    onSubmit: (values) => {
      dispatch(addDocumentUpload({ ...values, case_id: caseId }));
      setShowAddDocModel(false);
      setTimeout(() => {
        dispatch(getCaseDocuments({
          case_id: caseId
        }));
      }, 2000);
      setMoreActions('');
      addDocvalidation.resetForm();

      console.log('document submitted')
    }
  });

  const requestDocvalidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      assigned_to: '',
      document_name: '',
      document_category: '',
    },
    validationSchema: Yup.object({
      assigned_to: Yup.string().required("Please select contact"),
      document_name: Yup.string().required("Please Enter document name"),
      document_category: Yup.string().required("Please select document category"),
    }),
    onSubmit: (values) => {
      dispatch(requestDocument({ ...values, case_id: caseId }));
      setShowRequestDocModel(false);
      setTimeout(() => {
        dispatch(getCaseDocuments({
          case_id: caseId
        }));
      }, 2000);
      setMoreActions('');
      requestDocvalidation.resetForm();
    }
  });
  const reloadData = (event) => {
    var tabInfo = event.target.value;
    setactiveTab(tabInfo);
    dispatch(getCaseDocuments({
      case_id: caseId,
      category: tabInfo
    }));
  }



  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Modal isOpen={showReviewModel}>
            <ModalHeader toggle={() => { shareDocvalidation.resetForm(); setReviewShowModel(false) }} tag="h4">
              Share the Document
            </ModalHeader>
            <ModalBody>
              <p className="text-muted font-size-16">Share the document with Benificiery and Petitioner</p>

              <Form
                //onSubmit={handleSubmitReview}
                onSubmit={(e) => {
                  e.preventDefault();
                  shareDocvalidation.handleSubmit();
                  return false;
                }}
              >
                <Row form>
                  <Col className="col-12 mb-3">
                    <Label className="form-label">
                      Choose User Type
                    </Label>
                    <Input name="share_user" type="select"
                      onChange={shareDocvalidation.handleChange}
                      onBlur={shareDocvalidation.handleBlur}
                      value={shareDocvalidation.values.share_user || ""}
                      invalid={
                        shareDocvalidation.touched.share_user &&
                          shareDocvalidation.errors.share_user
                          ? true
                          : false
                      }>
                      <option value="">Select user</option>
                      <option value="Petitioner">Petitioner</option>
                      <option value="Employee">Beneficiary</option>
                      <option value="Both">Both</option>
                    </Input>
                    {shareDocvalidation.touched.share_user &&
                      shareDocvalidation.errors.share_user ? (
                      <FormFeedback type="invalid">
                        {shareDocvalidation.errors.share_user}
                      </FormFeedback>
                    ) : null}
                  </Col>

                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="ms-1 btn btn-primary save-event"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
          <Modal isOpen={showSharedModel}>
            <ModalHeader toggle={() => setShowSharedModel(false)} tag="h4">
              Shared Information
            </ModalHeader>
            <ModalBody>
              <Table bordered hover>
                <thead className="table-light table-nowrap">
                  <tr role="row">
                    <th>Contact</th>
                    <th>User Type</th> <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {sharedContacts.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.contact}</td>
                        <td>{item?.type}</td>
                        <td>
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2 btn btn-danger "
                            onClick={() => handleRevokeButtonClicks(item?.type)}
                          >
                            Revoke
                          </Button>
                        </td>
                      </tr>
                    )
                  })}

                </tbody>
              </Table>
            </ModalBody>
          </Modal>
          <Modal isOpen={showRequestDocModel}>
            <ModalHeader toggle={() => { setMoreActions(''); requestDocvalidation.resetForm(); setShowRequestDocModel(false) }} tag="h4">
              Request Document
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  requestDocvalidation.handleSubmit();
                  return false;
                }}
              >
                <Row form>
                  <Col className="col-12 mb-3">
                    <Label className="form-label">Enter Document Name *</Label>
                    <Input
                      name="document_name"
                      type="text"
                      placeholder="Eg : Passport Copy"
                      onChange={requestDocvalidation.handleChange}
                      onBlur={requestDocvalidation.handleBlur}
                      value={requestDocvalidation.values.document_name || ""}
                      invalid={
                        requestDocvalidation.touched.document_name &&
                          requestDocvalidation.errors.document_name
                          ? true
                          : false
                      }
                    />
                    {requestDocvalidation.touched.document_name &&
                      requestDocvalidation.errors.document_name ? (
                      <FormFeedback type="invalid">
                        {requestDocvalidation.errors.document_name}
                      </FormFeedback>
                    ) : null}
                  </Col>

                  <Col className="col-12 mb-3">
                    <Label className="form-label">
                      Who Should Upload This Document *
                    </Label>
                    <Input name="assigned_to" type="select"
                      onChange={requestDocvalidation.handleChange}
                      onBlur={requestDocvalidation.handleBlur}
                      value={requestDocvalidation.values.assigned_to || ""}
                      invalid={
                        requestDocvalidation.touched.assigned_to &&
                          requestDocvalidation.errors.assigned_to
                          ? true
                          : false
                      }>
                      <option value="">Select</option>
                      <option value="Petitioner">Petitioner</option>
                      <option value="Employee">Benificiery </option>
                    </Input>
                    {requestDocvalidation.touched.assigned_to &&
                      requestDocvalidation.errors.assigned_to ? (
                      <FormFeedback type="invalid">
                        {requestDocvalidation.errors.assigned_to}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col className="col-12 mb-3">
                    <Label className="form-label">Select Category *</Label>
                    <Input
                      type="select"
                      name="document_category"
                      title="Select from list"
                      onChange={requestDocvalidation.handleChange}
                      onBlur={requestDocvalidation.handleBlur}
                      value={requestDocvalidation.values.document_category || ""}
                      invalid={
                        requestDocvalidation.touched.document_category &&
                          requestDocvalidation.errors.document_category
                          ? true
                          : false
                      }
                    >
                      <option value="">Select</option>
                      <option value="Immigration">Immigration</option>
                      <option value="Job">Job</option>
                      <option value="Education">Education</option>
                      <option value="Contracts">Contracts</option>
                      <option value="Dependents">Dependents</option>
                      <option value="Other">Other</option>
                    </Input>
                    {requestDocvalidation.touched.document_category &&
                      requestDocvalidation.errors.document_category ? (
                      <FormFeedback type="invalid">
                        {requestDocvalidation.errors.document_category}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-primary save-event"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
          <Modal isOpen={showAddDocModel}>
            <ModalHeader toggle={() => { setMoreActions(''); addDocvalidation.resetForm(); setShowAddDocModel(false) }} tag="h4">
              Add Document
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  addDocvalidation.handleSubmit();
                  return false;
                }}
              >
                <Row form>
                  <Col className="col-12 mb-3">
                    <div className="file-select">
                      <Label className="form-label">
                        Upload Document *
                      </Label>
                      <Input
                        type="file"
                        name="add_document"
                        id="chooseFile"
                        accept=".png, .jpg, .jpeg, .pdf,.txt"
                        onChange={(e) => addDocvalidation.setFieldValue('add_document', e.target.files[0])}
                        onBlur={addDocvalidation.handleBlur}
                        invalid={
                          addDocvalidation.touched.add_document &&
                            addDocvalidation.errors.add_document
                            ? true
                            : false}
                      />
                      {addDocvalidation.touched.add_document &&
                        addDocvalidation.errors.add_document ? (
                        <FormFeedback type="invalid">
                          {addDocvalidation.errors.add_document}
                        </FormFeedback>
                      ) : null}

                      <FormFeedback className="text-muted">
                        Note: {MAX_ALLOWED_MESSAGE}
                      </FormFeedback>
                    </div>
                  </Col>
                  <Col className="col-12 mb-3">
                    <Label className="form-label">
                      Select  Contact To Share Document *
                    </Label>
                    <Input name="share_user" type="select"
                      onChange={addDocvalidation.handleChange}
                      onBlur={addDocvalidation.handleBlur}
                      value={addDocvalidation.values.share_user || ""}
                      invalid={
                        addDocvalidation.touched.share_user &&
                          addDocvalidation.errors.share_user
                          ? true
                          : false}>
                      <option value="">Select user</option>
                      {userType === "employer" && (
                        <option value="Attorney">Attorney</option>
                      )}
                      {userType === "attorney" && (
                        <option value="Petitioner">Petitioner</option>
                      )}
                      <option value="Employee">Beneficiary</option>
                      <option value="Both">Both</option>
                    </Input>
                    {addDocvalidation.touched.share_user &&
                      addDocvalidation.errors.share_user ? (
                      <FormFeedback type="invalid">
                        {addDocvalidation.errors.share_user}
                      </FormFeedback>
                    ) : null}
                  </Col>

                  <Col className="col-12 mb-3">
                    <Label className="form-label">Enter Document Name *</Label>
                    <Input
                      name="document_name"
                      type="text"
                      placeholder="Eg : Passport Copy"
                      onChange={addDocvalidation.handleChange}
                      onBlur={addDocvalidation.handleBlur}
                      value={addDocvalidation.values.document_name || ""}
                      invalid={
                        addDocvalidation.touched.document_name &&
                          addDocvalidation.errors.document_name
                          ? true
                          : false
                      }
                    />
                    {addDocvalidation.touched.document_name &&
                      addDocvalidation.errors.document_name ? (
                      <FormFeedback type="invalid">
                        {addDocvalidation.errors.document_name}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col className="col-12 mb-3">
                    <Label className="form-label">Category *</Label>
                    <Input
                      type="select"
                      name="document_category"
                      title="Select from list"
                      onChange={addDocvalidation.handleChange}
                      onBlur={addDocvalidation.handleBlur}
                      value={addDocvalidation.values.document_category || ""}
                      invalid={
                        addDocvalidation.touched.document_category &&
                          addDocvalidation.errors.document_category
                          ? true
                          : false
                      }
                    >
                      <option value="">Select category</option>
                      <option value="Immigration">Immigration</option>
                      <option value="Job">Job</option>
                      <option value="Education">Education</option>
                      <option value="Contracts">Contracts</option>
                      <option value="Dependents">Dependents</option>
                      <option value="Other">Other</option>
                    </Input>
                    {addDocvalidation.touched.document_category &&
                      addDocvalidation.errors.document_category ? (
                      <FormFeedback type="invalid">
                        {addDocvalidation.errors.document_category}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-primary save-event"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
          <Modal isOpen={showViewDocments}>
            <ModalHeader toggle={() => { setShowViewDocuments(false) }} tag="h4">
              {caseViewDocuments?.doc_name} Documents
            </ModalHeader>
            <ModalBody>
              <Row>
                {
                  caseViewDocuments?.doc_media && caseViewDocuments?.doc_media.map((document, index) =>
                  (
                    <Col className="col-12 mb-3" key={index}>
                      <Label className="form-label">{document?.doc_name} &nbsp;&nbsp;
                        {document?.attachment && (
                          <Link to={document?.attachment} target="_blank">
                            View
                          </Link>
                        )}</Label>

                    </Col>
                  ))}
              </Row>
            </ModalBody>
          </Modal>
          <div className="row" >
            <Col md="9"></Col>
            <Col md="3">
              <FormGroup className="mb-3" >
                <Input
                  name="invoice_action"
                  placeholder="More Actions"
                  type="select"
                  className="form-control"
                  id="invoice_action"
                  value={moreActions || ''}
                  onChange={(e) => {
                    handleActionSubmit(e);
                  }}
                >
                  <option value="">More Actions</option>
                  {moreactions.map((moreaction, index) => (
                    <option
                      key={moreaction.action + index}
                      value={moreaction.action}
                    >
                      {moreaction.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </div>
          <DatatableContainer
            columns={columns}
            data={caseDocuments}
            isGlobalFilter={true}
            isAddInvoiceList={false}
            isDocumentFilter={true}
            customPageSize={10}
            handleInvoiceClick={handleUserClicks}
            isDocumentFilterChange={reloadData}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Documents;
