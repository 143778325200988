import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_EMPLOYERTIMESHEETS, GET_EMPLOYERTIMESHEETDETAIL,
          UPDATE_EMPLOYERTIMESHEETSTATUS } from "./actionTypes"
import {

  getEmployerTimesheetsSuccess,
  getEmployerTimesheetsFail,
  getEmployerTimesheetDetailSuccess,
  getEmployerTimesheetDetailFail,
  updateEmployerTimesheetStatusSuccess,
  updateEmployerTimesheetStatusFail
  
} from "./actions"

//Include Both Helper File with needed methods
import { getEmployerTimesheetApiHelper, getEmployerTimesheetDetailApiHelper,
          updateEmployerViewTimesheet } from "../../../helpers/apibackend_helper"
import { setAlertMessage } from "../../common/actions"

function* fetchTimesheetDetail({ timesheetId }) { 
  try {
    const response = yield call(getEmployerTimesheetDetailApiHelper, timesheetId)
    yield put(getEmployerTimesheetDetailSuccess(response.data))
  } catch (error) {
    yield put(getEmployerTimesheetDetailFail(error))
  }
}

function* updateViewTimesheetStatus({ payload: timesheetStatusData }) {
  try {
    const response = yield call(updateEmployerViewTimesheet, timesheetStatusData)
    yield put(updateEmployerTimesheetStatusSuccess(response.data))
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(updateEmployerTimesheetStatusFail(error))
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* getEmployerTimesheets({ payload: timesheetData }) {
  try {
    const response = yield call(getEmployerTimesheetApiHelper, timesheetData)
    yield put(getEmployerTimesheetsSuccess(response.data))
  } catch (error) {
    yield put(getEmployerTimesheetsFail(error))
  }
}

function* employerTimesheetSaga() {
  // yield takeEvery(GET_INVOICES, fetchInvoices)
  yield takeEvery(GET_EMPLOYERTIMESHEETDETAIL, fetchTimesheetDetail)
  // yield takeEvery(ADD_INVOICE_DETAIL, addInvoiceDetail)
  // yield takeEvery(GET_INVOICE_SETTINGS, fetchInvoiceSettings)
  yield takeEvery(GET_EMPLOYERTIMESHEETS, getEmployerTimesheets)
  yield takeEvery(UPDATE_EMPLOYERTIMESHEETSTATUS, updateViewTimesheetStatus)
  // yield takeEvery(UPDATE_INVOICE_DETAIL, onUpdateinvoice)
}

export default employerTimesheetSaga
