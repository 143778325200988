import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUser, logoutUserSuccess } from "./actions";


import {
  postFakeLogin,
  postSocialLogin,
} from "../../../helpers/apibackend_helper";
import { setAlertMessage } from "../../common/actions"



function* loginUser({ payload: { user, history } }) {
  try {
    localStorage.removeItem("authUser");
    const response = yield call(postFakeLogin, {
      email: user.email,
      password: user.password,
    });

    localStorage.setItem("authUser", JSON.stringify(response));
    if (response.code == 200) {
      yield put(loginSuccess(response));
      let userRole = (response?.data?.user_type).toLowerCase();
      let userStatus = response.data.status;
      if (userRole == 'attorney') {
        history(userStatus == 'Pending' ? "/attorney/add-company-info" : "/attorney/dashboard")
      } else if (userRole == 'petitioner') {
        history(userStatus == 'Pending' ? "/employer/companyinfo" : "/employer/dashboard")
      } else if (userRole == 'superadmin' || userRole == 'admin') {
        history("/admin/dashboard")
      } else if (userRole == '') {
        history("/employee/dashboard")
      } else {
        history("/employee/dashboard")
      }

    } else {
      yield put(apiError(response ? { message: response.message } : { message: 'Invalid Login Details' }));
    }
  } catch (error) {
    yield put(apiError({ ...error, message: error.response.data.message }));
  }
}

function* FetchLogoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    localStorage.removeItem('user_type')
    localStorage.removeItem('i18nextLng')
    localStorage.removeItem('I18N_LANGUAGE')

    if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      // const response = yield call(logoutUser,history);
      const response = "You are logged out successfully";
      yield put(logoutUserSuccess(response));
      yield put(setAlertMessage({ message: { message: response }, type: "success" }));
    }
    if (history)
      history.push("/login")
  } catch (error) {
    yield put(apiError(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {

    const response = yield call(postSocialLogin, data);
    localStorage.setItem("authUser", JSON.stringify(response));
    yield put(loginSuccess(response));

    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, FetchLogoutUser);
}

export default authSaga;
