import React, { useEffect, useMemo, useState } from "react";
import {
  useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import DatatableContainer from "../../../components/Common/DatatableContainer";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { AttributetemplateDelete, getAttributetemplateDetail, getCasetypes, getQnnrtypes, getAttributetemplates as onGetAttributeTemplates } from "../../../store/actions";
import {
  CaseTypeName,
  CommonCell, Date,
  QnnrTypeName
} from "./invoicelistCol";
import { getAttributes as onGetAttributes, getAttributesets as onGetAttributesets, getAttributegroups as onGetAttributegroups } from "../../../store/actions";


//redux
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";
import { useAlert } from "../../../components/Common/Alert";


const InvoiceList = () => {

  //meta title
  document.title = "Questionnaire Attribute Templates | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [order, setOrder] = useState(null);

  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);

  const { attributetemplates } = useSelector((state) => ({
    attributetemplates: state.attributetemplates.attributetemplates,
  }));

  
  useEffect(() => {
    dispatch(getCasetypes());
    dispatch(getQnnrtypes());    
    dispatch(onGetAttributegroups());
    dispatch(onGetAttributeTemplates());
  }, []);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleUserClicks = () => {
    dispatch(getAttributetemplateDetail());
    navigate(`/admin/add-questionnaire-templates`);
  };

  const onUpdateData = (data) => {
    if (data.id) {
      dispatch(getAttributetemplateDetail(data.id));
    }
    navigate(`/admin/add-questionnaire-templates?id=${data.id}`);
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setOrder(data);
  };

  const handleDeleteInvoice = () => {
    if (order.id) {
      dispatch(AttributetemplateDelete(order.id));
      setDeleteModal(false);
    }
  };


  const columns = useMemo(
    () => [
      {
        Header: "Template Name",
        accessor: "name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Case Type",
        accessor: "case_type",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CaseTypeName {...cellProps} />;
        },
      },
      
      {
        Header: "Qnnr Type",
        accessor: "qnnrtype",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <QnnrTypeName {...cellProps}  />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (attributetemplate) => {
          return (
            <div className={""}>
              {attributetemplate.value}
            </div>
          )
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      
     
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => onUpdateData(cellProps.row.original)}>Edit</DropdownItem>
                {/* <DropdownItem onClick={() => onStatusData(cellProps.row.original)}>Status</DropdownItem> */}
                <DropdownItem onClick={() => onDeleteData(cellProps.row.original)}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );
  let title = 'Questionnaire Attribute Templates';
  const BreadcrumbData = [
    {name:'Dashboard', url:'/admin/dashboard'},
    { name: title, url: '/admin/questionnaire-templates' }
  ]
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteInvoice}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <DatatableContainer
                    columns={columns}
                    data={attributetemplates}
                    isGlobalFilter={true}
                    isFilterDisable={true}
                    isAddAttributetemplates={true}
                    customPageSize={10}
                    handleInvoiceClick={handleUserClicks}
                  />
                </CardBody>
                
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InvoiceList;
