import React, { useEffect, useRef, useState } from "react";
import { FormGroup, Input, Label, Row } from "reactstrap";

//Import Breadcrumb
import Select from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useDispatch } from "react-redux";
import {
  getEmployerEsign,
  uploadEmployerEsign,
} from "../../../store/employer/esign/actions";
import html2canvas from "html2canvas";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ShowEsign = () => {
  //meta title
  document.title = "Employer | Immidock ";
  const dispatch = useDispatch();
  let navigate = useNavigate();
//employerProfile
 const esignature_path = '';
  useEffect(() => {
    dispatch(getEmployerEsign());
  }, []);
  const { user_type } = useSelector((state) => ({
    user_type: state.login.user_type || localStorage.getItem('user_type'),
  }));
  const handleClickEsign = () => {
    navigate(`/${user_type}/create-esignature`);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="d-flex w-100" style={{ margin: "0px auto" }}>
            <Row className="d-flex justify-content-end w-100">
              <button
                onClick={handleClickEsign}
                type="button"
                className="btn btn-light waves-effect waves-light w-25"
              >
                <i className="bx bx-plus me-1"></i> Create Esign
              </button>
            </Row>
            <Row>
                <img src={esignature_path}/>
            </Row>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ShowEsign;
