import {
  GET_EMPLOYERTIMESHEETS_SUCCESS,
  GET_EMPLOYERTIMESHEETS_FAIL,
  GET_EMPLOYERTIMESHEETDETAIL_SUCCESS,
  GET_EMPLOYERTIMESHEETDETAIL_FAIL,
  UPDATE_EMPLOYERTIMESHEETSTATUS_SUCCESS,
  UPDATE_EMPLOYERTIMESHEETSTATUS_FAIL
} from "./actionTypes"

const INIT_STATE = {
  // invoices: [],
   employerTimesheetDetail: [],
  // invoicesettings: {},
   getEmployerTimesheetData: [],
   updateTimesheetViewStatus: []
  // error: {},
}

const EmployerTimesheets = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EMPLOYERTIMESHEETS_SUCCESS:
      return {
        ...state,
        // uploadedinvoiceattachments: [...state.Invoices, action.payload],
        getEmployerTimesheetData: action.payload,
      }

    case GET_EMPLOYERTIMESHEETS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_EMPLOYERTIMESHEETDETAIL_SUCCESS:
      return {
        ...state,
        // uploadedinvoiceattachments: [...state.Invoices, action.payload],
        employerTimesheetDetail: action.payload,
      }

    case GET_EMPLOYERTIMESHEETDETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    
    case UPDATE_EMPLOYERTIMESHEETSTATUS_SUCCESS:
      return {
        ...state,
        updateTimesheetViewStatus: action.payload,
      }

    case UPDATE_EMPLOYERTIMESHEETSTATUS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // case DELETE_INVOICE_DETAIL_SUCCESS:
    //   return {
    //     ...state,
    //     invoices: state.Invoices.filter((item) => item.id !== action.payload),
    //   };

    // case DELETE_INVOICE_DETAIL_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };

    // case UPDATE_INVOICE_DETAIL_SUCCESS:
    //   return {
    //     ...state,
    //     invoices: state.Invoices.map((order) =>
    //       order.id.toString() === action.payload.id.toString()
    //         ? { order, ...action.payload }
    //         : order
    //     ),
    //   };

    // case UPDATE_INVOICE_DETAIL_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };

    default:
      return state
  }
}

export default EmployerTimesheets
