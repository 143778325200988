import React from "react";

import EmployerInvoices from "../../Invoices/index";
import EmployerBasicSuite from "../../Basic";

const MainInvoicesComponent = () => {

  const getLoggedInPlanType = () => {
    var authUser = JSON.parse(localStorage.getItem("authUser")) ;
    return authUser?.data?.plan_type;
  }

  if(getLoggedInPlanType()==1) {
    return <EmployerInvoices/>
  } else {
    return <EmployerBasicSuite componentName="Invoices"/>
  }
};

export default MainInvoicesComponent;
