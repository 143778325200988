import React from "react";

import EmployerInvoiceEdit from "../../Invoices/CreateInvoice";
import EmployerBasicSuite from "../../Basic";

const MainInvoiceEditComponent = () => {

  const getLoggedInPlanType = () => {
    var authUser = JSON.parse(localStorage.getItem("authUser")) ;
    return authUser?.data?.plan_type;
  }

  if(getLoggedInPlanType()==1) {
    return <EmployerInvoiceEdit/>
  } else {
    return <EmployerBasicSuite componentName="Edit Invoice"/>
  }
};

export default MainInvoiceEditComponent;
