/* TIMESHEETS */
export const GET_EMPLOYERTIMESHEETS = "GET_EMPLOYERTIMESHEETS"
export const GET_EMPLOYERTIMESHEETS_SUCCESS = "GET_EMPLOYERTIMESHEETS_SUCCESS"
export const GET_EMPLOYERTIMESHEETS_FAIL= "GET_EMPLOYERTIMESHEETS_FAIL"
export const GET_EMPLOYERTIMESHEETDETAIL = "GET_EMPLOYERTIMESHEETDETAIL"
export const GET_EMPLOYERTIMESHEETDETAIL_SUCCESS = "GET_EMPLOYERTIMESHEETDETAIL_SUCCESS"
export const GET_EMPLOYERTIMESHEETDETAIL_FAIL= "GET_EMPLOYERTIMESHEETDETAIL_FAIL"

export const UPDATE_EMPLOYERTIMESHEETSTATUS = "UPDATE_EMPLOYERTIMESHEETSTATUS"
export const UPDATE_EMPLOYERTIMESHEETSTATUS_SUCCESS = "UPDATE_EMPLOYERTIMESHEETSTATUS_SUCCESS"
export const UPDATE_EMPLOYERTIMESHEETSTATUS_FAIL= "UPDATE_EMPLOYERTIMESHEETSTATUS_FAIL"

