import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Row, Form, Label, Input, FormGroup, Button } from "reactstrap"
import { DragDropContext, Droppable, Draggable, resetServerContext } from 'react-beautiful-dnd';
resetServerContext()
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//import { addcaseformAttributesets } from "../../../store/actions"; getCaseFormsList
import { addcaseformAttributesets, getCaseFormsList, getCaseFormAttributes, getcaseformAttributesets } from "../../../store/actions";
//import { getState } from "../../../store/actions";
//import { getState } from "../../../store/actions";

import Select, { components } from "react-select"
import {
  reorder,
  move,
  getItemStyle,
  getListStyle,
  getFirstListStyle
} from "../QnnrAttributeSets/helper";
import { useSelector, useDispatch } from "react-redux";
import { getCaseFormAttributeSetDetail, updateCaseformAttributesets } from "../../../store/admin/caseformattributesets/actions";
import { useAlert } from "../../../components/Common/Alert";


const FormLayoutsBasic = () => {
  //SAVE DATA
  let form_id = '';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  //const CaseFormsList = {};

  const { CaseFormsSelectList } = useSelector((state) => ({
    CaseFormsSelectList: state.CaseFormsList.CaseFormsSelectList,
  }));

  const { caseformattributes } = useSelector((state) => ({
    caseformattributes: state.CaseFormAttributes.caseformattributes,
  }));
  const { caseformattributesetDetail } = useSelector((state) => ({
    caseformattributesetDetail: state.caseformattributesets.caseformattributesetDetail,
  }));

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL
  const [selectedValuesx, setSelectedValuesx] = useState([]);
  const [formData, setFormData] = useState({
    "title": "",
    "name": "",
    "shortDescription": "",
    "description": "",
    "attributesListId": "",
    "status": "Active"
  });

  // useEffect(() => {
  //   setFormData(caseformattributes);
  // }, [dispatch]);

  useEffect(() => {
    dispatch(getCaseFormsList());
  }, [dispatch]);

  useEffect(() => {
    if (formData?.form_id) {
      dispatch(getCaseFormAttributes({ form_id: formData?.form_id }));
    }
  }, [formData]);

  useEffect(() => {
    if (editId) {
      dispatch(getCaseFormAttributeSetDetail(editId));
    }
  }, [editId]);

  useEffect(() => {
    if (Object.entries(caseformattributes).length > 0) {
      setLists(
        (prevState) => ({
          ...prevState,
          items: caseformattributes
        }))
    }
    else {
      setLists({
        items: [],
        selected: []
      });
    }
  }, [caseformattributes]);

  useEffect(() => {
    if (Object.entries(caseformattributesetDetail).length > 0) {
      let { attributesListId } = caseformattributesetDetail;
      let attributesArr = [];
      if (attributesArr != '')
        attributesArr = attributesListId.split(',');
      let filteredList = caseformattributes.filter((item) => {
        if (attributesArr.includes(item.id.toString())) {
          return item
        }
      })
      setFormData(caseformattributesetDetail);
      setSelectedValuesx(CaseFormsSelectList.filter((value, index) => {
        if (caseformattributesetDetail?.form_id) {
          let selected = caseformattributesetDetail.form_id.split(",")
          return selected.includes(value.value)
        }
      }))
      setLists({
        items: caseformattributes,
        // selected: filteredList 
        selected: caseformattributesetDetail.selectedAttributesList
      });
    }
  }, [caseformattributesetDetail])

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let attributesListId = lists.selected.map((item) => {
      return item.id;
    })

    attributesListId = attributesListId.join(",");
    let postBody = { ...formData, attributesListId };
    if (postBody.title) {
      let response = {};
      if (Number(formData.id) > 0) {
        dispatch(updateCaseformAttributesets(postBody));
      } else {
        dispatch(addcaseformAttributesets(postBody));
      }
      setFormData({
        "form_id": "",
        "id": "",
        "title": "",
        "name": "",
        "shortDescription": "",
        "description": "",
        "attributesListId": "",
        "status": "Active"
      });
      navigate("/admin/case-form-attribute-sets");


    }
  }
  const onClickCancel = () => {
    navigate("/admin/case-form-attribute-sets");
  }

  const [lists, setLists] = useState({ 'items': caseformattributes, 'selected': [] });
  const id2List = {
    droppable: 'items',
    droppable2: 'selected'
  };

  const getList = id => lists[id2List[id]];
  const onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === 'droppable2') {
        state = { selected: items };
      }
      let newstate = {
        ...lists,
        ...state
      }
      setLists(newstate);

    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      setLists({
        items: result.droppable,
        selected: result.droppable2
      });
    }
  };

  const BreadcrumbData = [
    { name: 'Case Form Attribute Sets', url: '/admin/case-form-attribute-sets' },
    { name: 'Create Case Form Attribute Set', url: '/admin/case-form-attribute-sets' }
  ]
  const [selectedOptions, setSelectedOptions] = useState([]);
  const selectedOptionsUpdate = (options) => {
    var form_ids = options.map((opt) => opt.value).toString();
    setFormData({ ...formData, ["form_id"]: form_ids });
    setSelectedOptions(options.map((opt) => opt.value));
  }

  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex "
    };

    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type="checkbox" checked={isSelected} /> &nbsp;
        {children}
      </components.Option>
    );
  };

  let title = editId ? 'Update Case Form Attribute Set' : 'Create Case Form Attribute Set';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

          <Row>
            <Col xs={12}>
              <form onSubmit={handleSubmit}>

                <div className="container">
                  <div className="row">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <div className="col-lg-6 col-md-12">
                        <div className="card dashboard-secondbox dashboard-border-radius">
                          <h5>Attributes List</h5>
                          <p>Please drag and drop and Make sets.</p>

                          <div className=''>
                            <div className="" style={{ 'height': '450px', 'overflow-y': 'auto', 'overflow-x': 'hidden' }}>
                              <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                  <ul className="list-group border"

                                    ref={provided.innerRef}
                                    style={getFirstListStyle(snapshot.isDraggingOver)}
                                  >
                                    {lists['items'].map((item, index) => (
                                      <Draggable
                                        key={item.id.toString()}
                                        draggableId={item.id.toString()}
                                        index={index}>
                                        {(provided, snapshot) => (
                                          <li className="list-group-item border"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            {item.name}
                                          </li>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}

                                  </ul>
                                )}
                              </Droppable>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">


                        <div className="card dashboard-secondbox dashboard-border-radius" style={{ "padding-left": "5px" }}>

                          <div className="">
                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInputTitle" className="form-label">
                                Title:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="formGroupExampleInputTitle"
                                placeholder="Enter Title"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Name:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="formGroupExampleInput"
                                placeholder="Enter Name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="mb-3">
                              <Label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-2 col-form-label"
                              >
                                Case Form:
                              </Label>
                              <div className="col-lg-6 col-md-12">
                                {/* <Input
                                  id="form_id"
                                  name="form_id"
                                  type="select"
                                  onChange={handleChange}
                                  disabled={caseformattributesetDetail?.form_id ? true : false}
                                > */}


                                <Select
                                  defaultValue={[]}
                                  value={CaseFormsSelectList.filter((value, index) => {
                                    if (formData?.form_id) {
                                      let selected = formData.form_id.split(",")
                                      return selected.includes(value.value)
                                    }
                                  })}
                                  isMulti
                                  // className={selectedOptions.length === 0 ? 'is-invalid':''}
                                  name="form_id"
                                  closeMenuOnSelect={false}
                                  hideSelectedOptions={true}
                                  selected={CaseFormsSelectList.filter(({ value }) =>
                                    caseformattributesetDetail?.form_id ?
                                      (caseformattributesetDetail.form_id.split(",")).includes(value) : ''
                                  )}
                                  // onChange={handleChange}
                                  onChange={(options) => {
                                    console.log(options)
                                    if (Array.isArray(options)) {
                                      selectedOptionsUpdate(options)
                                    }
                                  }}

                                  // value={caseformattributesetDetail?.form_id ? caseformattributesetDetail.form_id : ''}
                                  options={CaseFormsSelectList}
                                  components={{
                                    Option: InputOption
                                  }}
                                />
                                {/* <option value="">Select Case Form</option>
                                  {CaseFormsList.map(row => (
                                    <option key={row.form_id} value={row.form_id} selected={row.form_id == caseformattributesetDetail?.form_id}>
                                      {row.form_name || row.name}
                                    </option>
                                  ))} */}

                              </div><br></br>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Selected Attributes List:
                              </label>
                              <p>Please drag and drop from left to right.</p>

                            </div>
                            <div className="">
                              <Droppable droppableId="droppable2">
                                {(provided, snapshot) => (
                                  <ul className="list-group"
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}>
                                    {lists['selected'].map((item, index) => (
                                      <Draggable
                                        key={item.id.toString()}
                                        draggableId={item.id.toString()}
                                        index={index}>
                                        {(provided, snapshot) => (
                                          <li className="list-group-item"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}>
                                            {item.name}
                                          </li>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </ul>
                                )}
                              </Droppable>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Is dependent ? &nbsp;
                                <input
                                  type="checkbox"
                                  checked={formData.is_dependent_set == "1" ? true : false}
                                  id="formGroupExampleInput"
                                  name="is_dependent_set"
                                  value={formData.is_dependent_set}
                                  onChange={handleCheckboxChange}
                                />
                              </label>

                            </div>
                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Slug
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="formGroupExampleInput"
                                name="slug"
                                value={formData.slug}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Short Description
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="formGroupExampleInput"
                                name="shortDescription"
                                placeholder="Enter Short Description"
                                value={formData.shortDescription}
                                onChange={handleChange}
                              />
                            </div>

                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Description
                              </label>
                              <textarea
                                className="form-control"
                                id="formGroupExampleInput"
                                placeholder="Enter Description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                              />
                            </div>

                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput2" className="form-label">
                                Status:
                              </label>


                              <Input
                                id="validationCustom02"
                                name="status"
                                type="select"
                                onChange={handleChange}
                                value={formData.status || ""}

                              >
                                <option >Open this select status</option>
                                <option value="Active">Active</option>
                                <option value="InActive">InActive</option>
                              </Input>




                            </div>
                            <button type="button" className="btn btn-secondary" onClick={onClickCancel}>
                              Cancel
                            </button> {'   '}
                            <button type="submit" className="btn btn-primary">
                              save
                            </button>

                          </div>

                        </div>

                      </div>

                    </DragDropContext>
                  </div>
                </div>

              </form>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};
export default FormLayoutsBasic
