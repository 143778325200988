import React from 'react';
import {
    FormGroup, Input, Label
} from "reactstrap";
import { checkValidityClass } from "../Shared/utility";
const CheckboxGroupType = ({ name, label, type, placeholder, className, required, invalidMessage, value, content, onChanged }) => {
    return (
        <FormGroup className="mb-3" >
               
               {(content?.label && content?.label != '<br>') && (
                <Label htmlFor={content?.name} className="form-label mb-0" style={{ fontWeight: "bold"}}>
                    <span dangerouslySetInnerHTML={{__html: content?.label}}></span>
                    {content.required == true ? (<span className='text-danger'>*</span>) : null}
                </Label>
            )}
            {content.values.map((row) => (
                <div className="form-check mb-3" key={'checkbox' + row.id}>
                    <Input
                        name={content.name}
                        placeholder={content.placeholder}
                        type='checkbox'
                        aria-invalid="true"
                        aria-describedby={`invalid_${name}`}
                        value={row.value}
                        required={content.required}
                        defaultChecked={row.value == value ? true : false}
                        className={"form-control"}
                        onChange={onChanged}
                        checked={value && value.includes(row.value)}
                    />
                    <label className="form-check-label" >{row?.label} {content.required == true ? (<span className='text-danger'>*</span>) : null}</label>
                </div>
            ))}
            <div type="invalid" className="invalid-feedback">{invalidMessage}</div>
        </FormGroup>
    );

};
export default CheckboxGroupType