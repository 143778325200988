import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Row, Form, Label, Input, FormGroup, Button } from "reactstrap"
import { DragDropContext, Droppable, Draggable, resetServerContext } from 'react-beautiful-dnd';
resetServerContext()
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getAttributes, addAttributetemplateDetail, AttributetemplateUpdate, getAttributesetDetail, getCasetypes, getqnnrtypes, getQnnrtypes, getAttributetemplateDetail } from "../../../store/actions";
import {
  reorder,
  move,
  getItemStyle,
  getListStyle,
  getFirstListStyle
} from "./helper";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "../../../components/Common/Alert";
import { getAttributegroups as onGetAttributegroups } from "../../../store/actions";

const FormLayoutsBasic = () => {
  //SAVE DATA
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL


  const { attributegroups } = useSelector((state) => ({
    attributegroups: state.attributegroups.attributeGroupsFilter,
  }));
  const { attributetemplateDetail } = useSelector((state) => ({
    attributetemplateDetail: state.attributetemplates.attributetemplateDetail,
  }));

  const { attributetemplates } = useSelector((state) => ({
    attributetemplates: state.attributetemplates.attributetemplates,
  }));


  const { casetypes } = useSelector((state) => ({
    casetypes: state.casetypes.casetypes
  }));



  const { qnnrtypes } = useSelector((state) => ({
    qnnrtypes: state.qnnrtypes.qnnrtypes,
  }));



  const [formData, setFormData] = useState({
    "name": "",
    "shortDescription": "",
    "description": "",
    "attributesGroupIds": "",
    "qnnrtype": "",
    "case_type": "",
    "status": ""
  });
  useEffect(() => {
    dispatch(onGetAttributegroups());
    dispatch(getCasetypes());
  }, []);

  useEffect(() => {
    if (formData?.case_type) {
      const caseitem = casetypes.find((item) => item.case_type == formData?.case_type)
      if (caseitem?.id)
        dispatch(getQnnrtypes(caseitem?.id));
    }
  }, [formData?.case_type]);

  useEffect(() => {
    if (editId) {
      dispatch(getAttributetemplateDetail(editId));
    }
  }, [editId]);

  useEffect(() => {
    if (Object.entries(attributetemplateDetail).length > 0) {
      let { attributesGroupIds } = attributetemplateDetail;
      let attributesGroupArr = attributesGroupIds.split(',');
      let filteredList = attributegroups.filter((item) => {
        if (attributesGroupArr.includes(item.id)) {
          return item
        }
      })
      setFormData(attributetemplateDetail);
      setLists({
        items: attributegroups,
        selected: attributetemplateDetail.selectedAttributeGroupsList
      });
    }
  }, [attributetemplateDetail, attributegroups])



  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let attributesGroupIds = lists.selected.map((item) => {
      return item.id;
    })

    attributesGroupIds = attributesGroupIds.join(",");
    let postBody = { ...formData, attributesGroupIds };
    if (postBody.name) {
      let response = {};
      if (Number(formData.id) > 0) {
        dispatch(AttributetemplateUpdate(postBody));
      } else {
        dispatch(addAttributetemplateDetail(postBody));
      }
      setFormData({
        "id": "",
        "name": "",
        "shortDescription": "",
        "description": "",
        "attributesGroupIds": "",
        "status": "",
        "qnnrtype": "",
        "case_type": "",
      });
      navigate("/admin/questionnaire-templates");
    }
  }
  const handleClickCancle = () => {
    navigate("/admin/questionnaire-templates");
  }

  const [lists, setLists] = useState({ 'items': attributegroups, 'selected': [] });
  const id2List = {
    droppable: 'items',
    droppable2: 'selected'
  };

  const getList = id => lists[id2List[id]];

  const onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === 'droppable2') {
        state = { selected: items };
      }
      let newstate = {
        ...lists,
        ...state
      }
      setLists(newstate);

    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      setLists({
        items: result.droppable,
        selected: result.droppable2
      });
    }
  };

  const reloadQnnrTypes = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (Number(e.target.value))
      dispatch(getQnnrtypes(Number(e.target.value)));

  }

  let title = editId ? 'Update Questionnaire Attribute Templates' : 'Add Questionnaire Attribute Templates';
  const BreadcrumbData = [
    { name: 'Questionnaire Attribute Templates', url: '/admin/questionnaire-templates' },
    { name: title, url: '/admin/add-questionnaire-templates' }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

          <Row>
            <Col xs={12}>
              <form onSubmit={handleSubmit}>

                <div className="container">
                  <div className="row">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <div className="col-lg-6 col-md-12">
                        <div className="card dashboard-secondbox dashboard-border-radius">
                          <h5>Questionnaire attribute Groups</h5>
                          <p>Please drag and drop and Make sets.</p>

                          <div className=''>
                            <div className="">
                              <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                  <ul className="list-group border"

                                    ref={provided.innerRef}
                                    style={getFirstListStyle(snapshot.isDraggingOver)}
                                  >
                                    {lists['items'].map((item, index) => (
                                      <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}>
                                        {(provided, snapshot) => (
                                          <li className="list-group-item border"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            {item.title}
                                          </li>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}

                                  </ul>
                                )}
                              </Droppable>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">


                        <div className="card dashboard-secondbox dashboard-border-radius">
                          <h5>Create Attribute Templates</h5>


                          <div className="">
                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Label:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="formGroupExampleInput"
                                placeholder="Enter Label"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                              />
                            </div>

                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Case Types:
                              </label>
                              <Input
                                name="case_type"
                                type="select"
                                className="form-control"
                                id="validationCustom02"
                                onChange={reloadQnnrTypes}
                                value={formData.case_type || ""}
                                required
                              >
                                <option value="">Select visa category</option>
                                {casetypes.map(row => (
                                  <option key={row.id} value={row.id}>
                                    {row.case_type}
                                  </option>
                                ))}
                              </Input>
                            </div>

                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Qnnr Types:
                              </label>
                              <Input
                                name="qnnrtype"
                                placeholder=""
                                type="select"
                                className="form-control"
                                id="validationCustom02"
                                onChange={handleChange}
                                value={formData.qnnrtype || ""}
                                required

                              >

                                <option value="">Click to select</option>
                                {qnnrtypes.map(row => (
                                  <option key={row.case_type_id + row.id} value={row.id}>
                                    {row.qnnr_type}
                                  </option>
                                ))}
                              </Input>
                            </div>


                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Questionnaire Attribute Groups:
                              </label>
                              <p>Please drag and drop from left to right.</p>

                            </div>
                            <Droppable droppableId="droppable2">
                              {(provided, snapshot) => (
                                <ul className="list-group"
                                  ref={provided.innerRef}
                                  style={getListStyle(snapshot.isDraggingOver)}>
                                  {lists['selected'].map((item, index) => (
                                    <Draggable
                                      key={item.id}
                                      draggableId={item.id}
                                      index={index}>
                                      {(provided, snapshot) => (
                                        <li className="list-group-item"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}>
                                          {item.title}
                                        </li>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </ul>
                              )}
                            </Droppable>



                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Short Description
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="formGroupExampleInput"
                                name="shortDescription"
                                placeholder="Enter Short Description"
                                value={formData.shortDescription}
                                onChange={handleChange}
                              />
                            </div>

                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Description
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="formGroupExampleInput"
                                placeholder="Enter Description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                              />
                            </div>

                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput2" className="form-label">
                                Select Status:
                              </label>


                              <Input
                                id="validationCustom02"
                                name="status"
                                type="select"
                                onChange={handleChange}
                                value={formData.status || ""}

                              >
                                <option >Open this select status</option>
                                <option value="Active">Active</option>
                                <option value="InActive">InActive</option>
                              </Input>




                            </div>
                            <Button type="button" id="trash" color="secondary" onClick={handleClickCancle}>
                              Cancel
                            </Button>{' '}
                            <button type="submit" className="btn btn-primary">
                              Save
                            </button>

                          </div>

                        </div>

                      </div>

                    </DragDropContext>
                  </div>
                </div>

              </form>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};
export default FormLayoutsBasic
