import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_EMPLOYER_COMPANY, GET_EMPLOYER_COMPANY_DETAIL, ADD_EMPLOYER_COMPANY_DETAIL, DELETE_EMPLOYER_COMPANY_DETAIL, UPDATE_EMPLOYER_COMPANY_DETAIL } from "./actionTypes"
import {
  getEmployerCompanySuccess,
  getEmployerCompanyFail,
  getEmployerCompanyDetailSuccess,
  getEmployerCompanyDetailFail,
  addEmployerCompanydetailSuccess,
  addEmployerCompanydetailFail,
  EmployerCompanyDeleteSuccess,
  EmployerCompanyDeleteFail,
  EmployerCompanyUpdateSuccess,
  EmployerCompanyUpdateFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getEmployerCompany, getEmployerCompanyDetail, addEmployerCompany, deleteEmployerCompany, updateEmployerCompany } from "../../../helpers/apibackend_helper"
import { setAlertMessage } from "../../common/actions"

function* fetchEmployerCompany() {
  try {
    const response =yield call(getEmployerCompany)
    yield put(getEmployerCompanySuccess(response.data))
  } catch (error) {
    yield put(getEmployerCompanyFail(error))
  }
}

function* fetchEmployerCompanyDetail({ EmployerCompanyId }) {
  try {
    const response = yield call(getEmployerCompanyDetail, EmployerCompanyId)
    yield put(getEmployerCompanyDetailSuccess(response.data))
  } catch (error) {
    yield put(getEmployerCompanyDetailFail(error))
  }
}

function* addEmployerCompanyDetail({ payload: user }) {
  try {
    const response = yield call(addEmployerCompany, user)
    yield put(addEmployerCompanydetailSuccess(response.data))
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(addEmployerCompanydetailFail(error))
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* onDeleteEmployerCompany({ payload: data }) {
  try {
    const response = yield call(deleteEmployerCompany, data);
    yield put(EmployerCompanyDeleteSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(EmployerCompanyDeleteFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* onUpdateEmployerCompany({ payload: data }) {
  try {
    const response = yield call(updateEmployerCompany, data);
    yield put(EmployerCompanyUpdateSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(EmployerCompanyUpdateFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* EmployerCompanyaga() {
  yield takeEvery(GET_EMPLOYER_COMPANY, fetchEmployerCompany)
  yield takeEvery(GET_EMPLOYER_COMPANY_DETAIL, fetchEmployerCompanyDetail)
  yield takeEvery(ADD_EMPLOYER_COMPANY_DETAIL, addEmployerCompanyDetail)
  yield takeEvery(DELETE_EMPLOYER_COMPANY_DETAIL, onDeleteEmployerCompany)
  yield takeEvery(UPDATE_EMPLOYER_COMPANY_DETAIL, onUpdateEmployerCompany)
}

export default EmployerCompanyaga
