import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ATTORNEY_COMPANY, GET_ATTORNEY_COMPANY_DETAIL, ADD_ATTORNEY_COMPANY_DETAIL, DELETE_ATTORNEY_COMPANY_DETAIL, UPDATE_ATTORNEY_COMPANY_DETAIL, GET_ATTORNEY_ELIGIBILITY_STATUS } from "./actionTypes"
import {
  getAttorneyCompanySuccess,
  getAttorneyCompanyFail,
  getAttorneyCompanyDetailSuccess,
  getAttorneyCompanyDetailFail,
  addAttorneyCompanydetailSuccess,
  addAttorneyCompanydetailFail,
  AttorneyCompanyDeleteSuccess,
  AttorneyCompanyDeleteFail,
  AttorneyCompanyUpdateSuccess,
  AttorneyCompanyUpdateFail,
  getAttorneyEligibileStatusSuccess,
  getAttorneyEligibileStatusFail
} from "./actions"

//Include Both Helper File with needed methods
import { getAttorneyCompany, getAttorneyCompanyDetail, addAttorneyCompany, deleteAttorneyCompany, 
  updateAttorneyCompany,getAttorneyElgibileStatus } from "../../../helpers/apibackend_helper"
import { setAlertMessage } from "../../common/actions"

function* fetchAttorneyCompany() {
  try {
    const response =yield call(getAttorneyCompany)
    yield put(getAttorneyCompanySuccess(response.data))
  } catch (error) {
    yield put(getAttorneyCompanyFail(error))
  }
}

function* fetchAttorneyEligibileStatus() {
  try {
    const response =yield call(getAttorneyElgibileStatus)
    yield put(getAttorneyEligibileStatusSuccess(response.data))
  } catch (error) {
    yield put(getAttorneyEligibileStatusFail(error))
  }
}

function* fetchAttorneyCompanyDetail({ AttorneyCompanyId }) {
  try {
    const response = yield call(getAttorneyCompanyDetail, AttorneyCompanyId)
    yield put(getAttorneyCompanyDetailSuccess(response.data))
  } catch (error) {
    yield put(getAttorneyCompanyDetailFail(error))
  }
}

function* addAttorneyCompanyDetail({ payload: user }) {
  try {
    const response = yield call(addAttorneyCompany, user)
    yield put(addAttorneyCompanydetailSuccess(response.data))
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(addAttorneyCompanydetailFail(error))
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* onDeleteAttorneyCompany({ payload: data }) {
  try {
    const response = yield call(deleteAttorneyCompany, data);
    yield put(AttorneyCompanyDeleteSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(AttorneyCompanyDeleteFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* onUpdateAttorneyCompany({ payload: data }) {
  try {
    const response = yield call(updateAttorneyCompany, data);
    yield put(AttorneyCompanyUpdateSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(AttorneyCompanyUpdateFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* AttorneyCompanyaga() {
  yield takeEvery(GET_ATTORNEY_COMPANY, fetchAttorneyCompany)
  yield takeEvery(GET_ATTORNEY_ELIGIBILITY_STATUS, fetchAttorneyEligibileStatus)
  yield takeEvery(GET_ATTORNEY_COMPANY_DETAIL, fetchAttorneyCompanyDetail)
  yield takeEvery(ADD_ATTORNEY_COMPANY_DETAIL, addAttorneyCompanyDetail)
  yield takeEvery(DELETE_ATTORNEY_COMPANY_DETAIL, onDeleteAttorneyCompany)
  yield takeEvery(UPDATE_ATTORNEY_COMPANY_DETAIL, onUpdateAttorneyCompany)
}

export default AttorneyCompanyaga
