import React, { useEffect, useMemo, useState } from "react";
import DatatableContainer from "../../../components/Common/DatatableContainer";
import {
  Link,useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";

import { changePreloader, getEmployerTimesheets } from "../../../store/actions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
    CommonCell,Date,CaseTypeName
} from "./caseTypeListCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";

const EmployerTimesheetList = () => {

  //meta title
  document.title = "Timesheets | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [order, setOrder] = useState(null);

  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);

  const { employerTimesheets } = useSelector((state) => ({
    employerTimesheets: state.EmployerTimesheets.getEmployerTimesheetData || [],
  }));
  
  useEffect(() => { 
    dispatch(getEmployerTimesheets({"status":"All"}));  
    dispatch(changePreloader(true));
    setApiSuccess(true)
  }, [dispatch]);

  useEffect(() => {
    if (apiSuccess) {
      dispatch(changePreloader(false));
      setApiSuccess(false)
    }
  }, [employerTimesheets]);
  

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleUserClicks = () => {
    navigate(`/employer/timesheets`);
  };

  const onUpdateData = (data) => {
    navigate(`/employer/view-timesheet?timesheet-id=${data.id}`);
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setOrder(data);
  };

  const handleDeleteInvoice = () => {
    if (order.id) {
      dispatch(CasetypeDelete(order.id));
      setDeleteModal(false);
    }
  };

  const onReloadData = (event) => {
    var tabInfo = event.target.value;
    //setactiveTab(tabInfo);
    //dispatch(getAttorneyCases({ status: tabInfo }));
    dispatch(getEmployerTimesheets({ status: tabInfo }));
  }

  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   Cell: () => {
      //     return <input type="checkbox" />;
      //   },
      // },
      {
        Header: "Beneficiary",
        accessor: "emp_firstname",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CaseTypeName {...cellProps} />;
        },
      },
      {
        Header: "Client/Vendor",
        accessor: "customer",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Time Period",
        accessor: "period",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Total Hours",
        accessor: "total_hours",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },

      {
        Header: "Submitted Date",
        accessor: "submitted_date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      
      
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => onUpdateData(cellProps.row.original)}>View</DropdownItem>
                {/* <DropdownItem onClick={() => onDeleteData(cellProps.row.original)}>Delete</DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );
  let title = 'Timesheets';
  const BreadcrumbData = [
    {name:'Dashboard', url:'/employer/dashboard'},
    { name: title, url: '#' }
  ] 
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteInvoice}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <DatatableContainer
                    columns={columns}
                    data={employerTimesheets}
                    isEmployerTimesheetsFilter={true}
                    isGlobalFilter={true}
                    customPageSize={10}
                    handleInvoiceClick={handleUserClicks}
                    isEmployerTimesheetsFilterChange={onReloadData}
                  />
                </CardBody> 
                
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmployerTimesheetList;
