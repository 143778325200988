import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { CHANGE_PASSWORD, EMPLOYEE_RESEND_PASSWORD, EMPLOYER_RESEND_PASSWORD, FORGOT_PASSWORD, RESET_PASSWORD, UPDATE_LCA_EMPLOYEE_ACCESS, UPDATE_NOTIFICATION_EMAILS, UPDATE_QUESTIONAIRE_REVIEW } from "./actionTypes"
import {
  changePasswordFail,
  changePasswordSuccess,
  employeeResendPasswordFail,
  employeeResendPasswordSuccess,
  employerresendPasswordFail,
  employerresendPasswordSuccess,
  updateLcaEmployeeAccessFail,
  updateLcaEmployeeAccessSuccess,
  updateNotificationEmailsFail,
  updateNotificationEmailsSuccess,
  updateQuestionaireReviewFail,
  updateQuestionaireReviewSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { changePassword, employeeResendPassword, employerResendPassword, updateLcaEmployeeAccess, updateNotificationEmails, updateQuestionaireReview } from "../../helpers/apibackend_helper"
import { setAlertMessage } from "../common/actions"

function* fetchUpdateNotificationEmails({ payload: data }) {
  try {
    const response = yield call(updateNotificationEmails, data)
    yield put(updateNotificationEmailsSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(updateNotificationEmailsFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* fetchUpdateLcaEmployeeAccessSuccess({ userId }) {
  try {
    const response = yield call(updateLcaEmployeeAccess, userId)
    yield put(updateLcaEmployeeAccessSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(updateLcaEmployeeAccessFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* fetchUpdateQuestionaireReview({ payload: userdata }) {
  try {
    const response = yield call(updateQuestionaireReview, userdata)
    yield put(updateQuestionaireReviewSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(updateQuestionaireReviewFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* onChangePassword({ payload: data }) {
  try {
    const response = yield call(changePassword, data);
    yield put(changePasswordSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(changePasswordFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* onForgotPassword({ payload: data }) {
  try {
    const response = yield call(changePassword, data);
    yield put(changePasswordSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(changePasswordFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* onResetPassword({ payload: data }) {
  try {
    const response = yield call(changePassword, data);
    yield put(changePasswordSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(changePasswordFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* onEmployerResendPassword({ payload: data }) {
  try {
    const response = yield call(employerResendPassword, data);
    yield put(employerresendPasswordSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(employerresendPasswordFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}
function* onEmployeeResendPassword({ payload: data }) {
  try {
    const response = yield call(employeeResendPassword, data);
    yield put(employeeResendPasswordSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(employeeResendPasswordFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* userSaga() {
  yield takeEvery(UPDATE_NOTIFICATION_EMAILS, fetchUpdateNotificationEmails)
  yield takeEvery(UPDATE_LCA_EMPLOYEE_ACCESS, fetchUpdateLcaEmployeeAccessSuccess)
  yield takeEvery(UPDATE_QUESTIONAIRE_REVIEW, fetchUpdateQuestionaireReview)
  yield takeEvery(CHANGE_PASSWORD, onChangePassword)
  yield takeEvery(FORGOT_PASSWORD, onForgotPassword)
  yield takeEvery(RESET_PASSWORD, onResetPassword)
  yield takeEvery(EMPLOYER_RESEND_PASSWORD, onEmployerResendPassword)
  yield takeEvery(EMPLOYEE_RESEND_PASSWORD, onEmployeeResendPassword)
}

export default userSaga
