import React, { useEffect, useMemo, useState } from "react";
import {
  useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import DatatableContainer from "../../../components/Common/DatatableContainer";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { changePreloader, getTemplates, templateDelete } from "../../../store/actions";
import {
  CommonCell, Date
} from "./listCol";

//redux
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";



const TemplatesList = () => {

  //meta title
  document.title = "Templates List | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openTab, setopenTab] = useState("All");
  const [templatedata, setTemplatedata] = useState(null);

  // for delete template
  const [deleteModal, setDeleteModal] = useState(false);


  const { templateDetail, templates } = useSelector((state) => ({
    templates: state.Templates.templates,
    templateDetail: state.Templates.templateDetail,

  }));

  const [contactList, setContactList] = useState({ 'status': 'All' });
  const [apiSuccess, setApiSuccess] = useState(false);


  const [activeTab, setactiveTab] = useState("All");

  useEffect(() => {
    dispatch(getTemplates({ status: activeTab }));
    dispatch(changePreloader(true));
    setApiSuccess(true)
  }, [dispatch]);

  useEffect(() => {
    if (apiSuccess) {
      dispatch(changePreloader(false));
      setApiSuccess(false)
    }
  }, [templates]);

  const onReloadData = (event) => {
    var tabInfo = event.target.value;
    setactiveTab(tabInfo);
    dispatch(getTemplates({ status: tabInfo }));
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      setTemplatedata(null);
    } else {
      setModal(true);
    }
  };
  var userType = window.localStorage.getItem("user_type")

  const handleTemplateClicks = () => {
    navigate(`/${userType}/create-template`);
  };

  const onViewtemplate = (data) => {
    navigate(`/${userType}/view-template?templateId=${data.id}`);
  };

  const onEditData = (data) => {
    navigate(`/${userType}/edit-template?templateId=${data.id}`);
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setTemplatedata(data);
  };

  const handleDeleteTemplate = () => {
    if (templatedata.id) {
      dispatch(templateDelete({ template_id: templatedata.id }));
      setDeleteModal(false);
      dispatch(getTemplates({ 'status': 'All' }));
    }
  };


  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   Cell: () => {
      //     return <input type="checkbox" />;
      //   },
      // },
      {
        Header: "Name",
        accessor: "form_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      // {
      //   Header: "Modified By",
      //   accessor: "modified_by",
      //   filterable: true,
      //   disableFilters: true,
      //   Cell: (cellProps) => {
      //     return <CommonCell {...cellProps} />;
      //   },
      // },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Modified Date",
        accessor: "modified_date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => onEditData(cellProps.row.original)}>Edit</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  let title = 'PAF Templates';
  const BreadcrumbData = [
    { name: 'Dashboard', url: '/employer/dashboard' },
    { name: title, url: '#' }
  ]
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTemplate}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <DatatableContainer
                    columns={columns}
                    data={templates}
                    isGlobalFilter={true}
                    isTemplateFilter={true}
                    defaultAttorneyFilter={'Active'}
                    isTemplateFilterChange={onReloadData}
                    isAddTemplateList={true}
                    customPageSize={10}
                    handleTemplateClick={handleTemplateClicks}
                  />
                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TemplatesList;
