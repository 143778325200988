import classnames from 'classnames';
import React, { useEffect, useState } from "react";
import {
	Link, useNavigate
} from "react-router-dom";
import {
	Card, CardBody,
	Col,
	Container,
	NavItem, NavLink, Progress,
	Row,
	TabContent, TabPane,
	Toast, ToastBody, UncontrolledTooltip
} from "reactstrap";
import "../../../assets/scss/pages/_questionnaire.scss";
import { addEmployeeCaseQnnrTemplateGroupInfo, changePreloader, getEmployeeCaseQnnrTemplateGroupInfo, getEmployeeCaseQnnrTemplateInfo } from "../../../store/actions";
import Breadcrumbs from "../Breadcrumb";
import Form from "../DynamicForm/Form/Form";
import { checkValidation } from "../DynamicForm/Shared/utility";
//redux
import { useDispatch, useSelector } from "react-redux";

import { getDateFormatYMDMDY } from "../../../common/data/dateFormat";
import { setAlertMessage } from "../../../store/common/actions";


const EmployeeQuestionnaire = () => {

	//meta title
	document.title = "Cases | Immidock ";

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const caseId = queryParams.get('caseId'); // Read the 'id' query parameter from the URL
	const caseNumber = queryParams.get('caseNumber'); // Read the 'id' query parameter from the URL

	const { employeeCaseQnnrTemplateInfo } = useSelector((state) => ({
		employeeCaseQnnrTemplateInfo: state.EmployeeCases.employeeCaseQnnrTemplateInfo || [],
	}));
	const { employeeCaseQnnrTemplateGroupInfo, addQnnrTemplateGroupInfo, employeeCaseQnnrTemplateGroupError } = useSelector((state) => ({
		employeeCaseQnnrTemplateGroupInfo: state.EmployeeCases.employeeCaseQnnrTemplateGroupInfo || [],
		employeeCaseQnnrTemplateGroupError: state.EmployeeCases.employeeCaseQnnrTemplateGroupError || {},
		addQnnrTemplateGroupInfo: state.EmployeeCases.addQnnrTemplateGroupInfo || {},
	}));

	const [controls, setControls] = useState("");
	const [qnnrProgress, setQnnrProgress] = useState(0);

	const [ativeTabProgressState, setAtiveTabProgressState] = useState(1);
	const [toggleTabs, settoggleTabs] = useState({
		activeTabProgress: 1,
		progressValue: 0,
		validationError: '',
		toast: false
	});

	const [attributeSetDependency, setAttributeSetDependency] = useState([]);

	const { user_type } = useSelector((state) => ({
		user_type: state.login.user_type || localStorage.getItem('user_type'),
	}));

	const { qnnr_type_name } = useSelector((state) => ({
		qnnr_type_name: employeeCaseQnnrTemplateInfo.qnnr_type_name,
	}));

	const groupsTabLength = Object.keys(employeeCaseQnnrTemplateInfo).length && employeeCaseQnnrTemplateInfo.groups.length;
	// const groupProgress = Object.keys(employeeCaseQnnrTemplateGroupInfo).length && employeeCaseQnnrTemplateGroupInfo?.groupProgress;
	useEffect(() => {
		dispatch(getEmployeeCaseQnnrTemplateInfo({
			case_id: caseId,
			user_type: user_type
		}));
	}, []);

	useEffect(() => {
		if (!_.isEmpty(controls)) {
			setTimeout(() => {
				dispatch(changePreloader(false));
			}, 4000);
		}
	}, [controls]);

	useEffect(() => {
		if (employeeCaseQnnrTemplateGroupInfo.sets && employeeCaseQnnrTemplateGroupInfo.sets.length > 0) {
			employeeCaseQnnrTemplateGroupInfo.sets.map((sets, setsindex) => {
				sets.set.map((set, setindex) => {
					if (set?.attributes)
						set.attributes.map((attribute, attributeindex) => {
							{
								var content_field_name = `content[${sets.value.cloned_name}][${setindex}][${attribute.field_name}]`
								attribute.name = content_field_name;
								attribute.content.name = content_field_name;
								return attribute;
							}
						})
				});
			});
			setQnnrProgress(employeeCaseQnnrTemplateGroupInfo?.qnnrProgress);
		}
		setControls(employeeCaseQnnrTemplateGroupInfo);
	}, [employeeCaseQnnrTemplateGroupInfo]);

	useEffect(() => {
		if (employeeCaseQnnrTemplateGroupError.length > 0) {

		}
	}, [employeeCaseQnnrTemplateGroupError]);

	useEffect(() => {
		if (ativeTabProgressState) {
			settoggleTabs({
				...toggleTabs,
				activeTabProgress: ativeTabProgressState,
				progressValue: qnnrProgress
			})
		}
	}, [ativeTabProgressState]);

	useEffect(() => {
		if (!_.isEmpty(employeeCaseQnnrTemplateInfo)) {
			settoggleTabs({
				...toggleTabs,
				activeTabProgress: employeeCaseQnnrTemplateInfo?.activeTabProgress ?? 1,
				progressValue: employeeCaseQnnrTemplateInfo?.qnnrProgress,
			})
		}
	}, [employeeCaseQnnrTemplateInfo]);

	const allAttributeSets = (controls) => {
		setAttributeSetDependency([])
		if (controls.sets && controls.sets.length > 0) {
			controls.sets.map((sets, setsindex) => {
				sets.set.map((set, setindex) => {
					if (set?.attributes)
						set.attributes.map((attribute, attributeindex) => {
							{
								var content_field_name = `content[${sets.value.cloned_name}][${setindex}][${attribute.field_name}]`
								attribute.name = content_field_name;
								attribute.content.name = content_field_name;
								if (attribute?.content?.is_any_dependent_yes == true) {
									if (attribute?.content?.dependent_ids_yes && attribute?.value) {
										var attribute_dependent_set_value = attribute?.content?.dependent_ids_yes.replace(/'/g, '"')
										attribute_dependent_set_value = JSON.parse(attribute_dependent_set_value)
										if (attribute_dependent_set_value[attribute?.value]) {
											setAttributeSetDependency(attributeSetDependency => [...attributeSetDependency, attribute_dependent_set_value[attribute?.value]]);
										}
									}
								}
								return attribute;
							}
						})
				});
			});
		}
	}

	useEffect(() => {
		allAttributeSets(controls);
		// setControls(controls);
	}, [controls]);

	const updatedFormData = (updatedControls, formData) => {
		updatedControls.sets.map((sets, setsindex) => {
			var isDependent_block_check = checkAttributeSetDependency(sets)
			if (isDependent_block_check) {
				sets.set.map((set, setindex) => {
					if (set?.attributes)
						for (const attribute of set.attributes) {
							let value = (attribute?.value !== 'undefined') ? attribute?.value : null;
							let name = attribute?.content?.name;
							name = name ? name.split('#')[0] : name;
							let type = attribute?.type;
							if (attribute?.content?.select_field_type != 'label') {
								if (type == 'hidden') {
									let gen = attribute?.value != '' ? attribute?.value : window.crypto.randomUUID();
									formData.append(name, gen)
								} else if (type == "file" && typeof attribute?.file != "undefined") {
									if (updatedControls?.groupId == "Document") {
										formData.append("content['document']", "Success");
									} else {
										let case_doc_id = attribute?.content?.id;
										formData.append(name + '[file]', attribute?.file);
										formData.append(name + '[case_doc_id]', case_doc_id);
									}
								}
								else if (type != "file" && type != "date") {
									formData.append(name, value);
								}
								else if (type == "date") {
									formData.append(name, getDateFormatYMDMDY(attribute?.selected ?? value));
								}
							}
						}
				});
			}
		});
		return formData;
	};


	const checkAttributeSetDependency = (sets) => {
		let isDependent_block_check_status = sets?.value?.is_dependent_set == "1" ? false : true;
		attributeSetDependency && attributeSetDependency.every(function (dept, deptincx) {
			isDependent_block_check_status = sets?.value?.is_dependent_set == "1" &&
				dept && dept.includes(sets?.value?.slug)
				? true
				: sets?.value?.is_dependent_set == "1"
					? false
					: true;
			if (isDependent_block_check_status) {
				return false;
			} else return true;
		});
		return isDependent_block_check_status;
	}


	const toggleTabProgress = (tab, save_status = true) => {
		dispatch(changePreloader(true));

		const groupInfo = employeeCaseQnnrTemplateInfo.groups.find(group => group.id === tab);
		let isValidForm = true;
		const updatedControls = employeeCaseQnnrTemplateGroupInfo;
		if (updatedControls.sets && updatedControls.sets.length > 0) {
			updatedControls.sets.map((sets, setsindex) => {
				var isDependent_block_check = checkAttributeSetDependency(sets)
				if (isDependent_block_check) {
					sets.set.map((set, setindex) => {
						if (set?.attributes)
							set.attributes.map((attribute, attributeindex) => {
								if (updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].type != 'date' || updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].type == 'date') {
									const validationObj = checkValidation(updatedControls.sets[setsindex].set[setindex].attributes[attributeindex]?.value, updatedControls.sets[setsindex].set[setindex].attributes[attributeindex]?.content, updatedControls.sets[setsindex].set[setindex].attributes[attributeindex]?.content?.label);
									updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].isValid = validationObj.isValid;
									updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].invalidMessage = validationObj.invalidMessage;
									updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].selectedOption = false;
									if (!validationObj.isValid) {
										isValidForm = false;
									}
								}
							})
					});
				}
			});
			setControls(updatedControls);
		}

		if (isValidForm) {
			setAtiveTabProgressState(tab);
		}
		if (toggleTabs.activeTabProgress !== tab && (isValidForm == true || updatedControls?.groupId == 'Review')) { //

			settoggleTabs({ ...toggleTabs, activeTabProgress: tab, validationError: '', toast: false }) //getCurrentProgressValue(tab * 100 / groupsTabLength, updatedControls?.qnnrProgress)

			//Pre Form post data
			if (save_status) {
				if (updatedControls.sets && updatedControls.sets.length > 0) {

					var cal = (tab - 1) * 100 / groupsTabLength;
					var qnnr_progress = cal > qnnrProgress ? cal : qnnrProgress;
					var tabweight = Math.floor(100 / groupsTabLength);
					var group_progress_tab = Math.floor(qnnrProgress / tabweight);
					let formData = new FormData();
					formData.append('case_id', caseId);
					formData.append('group_id', updatedControls?.groupId);
					formData.append('groupProgress', updatedControls?.groupId);
					// formData.append('groupProgress', employeeCaseQnnrTemplateInfo?.groups[group_progress_tab]?.value);
					formData.append('group_name', updatedControls?.groupName);
					formData.append('template_id', employeeCaseQnnrTemplateInfo?.qnnr_template_id);
					formData.append('qnnr_progress', qnnr_progress);
					let contentObj = {};
					formData = updatedFormData(updatedControls, formData);
					let formObject = Object.fromEntries(formData.entries());
					dispatch(addEmployeeCaseQnnrTemplateGroupInfo(formObject));
					setQnnrProgress(qnnr_progress);
					setAtiveTabProgressState(tab);

				}
			}
			setTimeout(() => {
				dispatch(getEmployeeCaseQnnrTemplateGroupInfo({
					case_id: caseId,
					"group_id": groupInfo?.value ? groupInfo?.value : '',
					user_type: user_type
				}));
			}, 2000);
		}
		else if ((toggleTabs.activeTabProgress === tab && isValidForm == true) || updatedControls?.groupId == 'Review') {

			settoggleTabs({ ...toggleTabs, activeTabProgress: tab, validationError: '', toast: false }) // getCurrentProgressValue(tab * 100 / groupsTabLength, updatedControls?.qnnrProgress)
			if (save_status)
				if (updatedControls.sets && updatedControls.sets.length > 0) {
					var qnnr_progress = 100;
					let formData = new FormData();
					formData.append('case_id', caseId);
					formData.append('group_id', 'submit');
					formData.append('groupProgress', updatedControls?.groupId);
					// formData.append('groupProgress', updatedControls?.groupProgress);
					formData.append('group_name', 'submit');
					formData.append('template_id', employeeCaseQnnrTemplateInfo?.qnnr_template_id);
					formData.append('qnnr_progress', qnnr_progress);

					let contentObj = {};
					formData = updatedFormData(updatedControls, formData);
					let formObject = Object.fromEntries(formData.entries());
					dispatch(addEmployeeCaseQnnrTemplateGroupInfo(formObject));

					setQnnrProgress(qnnr_progress);
					setAtiveTabProgressState(tab);
					setTimeout(() => {
						navigate(`/${user_type}/view-case?caseId=${caseId}`);
					}, 3000);
				}
		}
		else {
			settoggleTabs({ ...toggleTabs, validationError: 'Please fill required fields', toast: true })
			const errorObj = { "response": { "status": "400", "data": { "message": "Error - Please fill required form fields" } } }
			dispatch(setAlertMessage({ message: errorObj, type: "danger" }));
		}
		if (!isValidForm) {
			dispatch(changePreloader(false));
		}

	};

	const setToast = () => {
		settoggleTabs({ ...toggleTabs, toast: false, validationError: false })
	}

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title="Cases" breadcrumbItem="Questionnaire" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<h4 className="card-title mb-4"> Case #{caseNumber} - {qnnr_type_name}</h4>
									<div className="notify-errors">
										<Toast isOpen={toggleTabs.toast} id="borderedToast1" className="toast-border-primary overflow-hidden mt-3">
											<ToastBody className='bg-danger text-white'>
												<div className="d-flex align-items-center">
													<div className="flex-shrink-0 me-2">
														<i className="ri-user-smile-line align-middle"></i>
													</div>
													<div className="flex-grow-1">
														<h6 className="mb-0 text-white">{toggleTabs.validationError}</h6>
													</div>
													<button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
														aria-label="Close" onClick={() => setToast()}></button>
												</div>
											</ToastBody>
										</Toast>
									</div>
									<div id="progrss-wizard" className="twitter-bs-wizard">
										<ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
											{groupsTabLength == 0 && (
												<>Case Questionnaire Loading . . .</>
											)}
											{groupsTabLength > 0 && employeeCaseQnnrTemplateInfo.groups.map((item) => (
												<NavItem key={item.key}>
													{/* {console.log(toggleTabs.activeTabProgress)} {toggleTabs.activeTabProgress} === {JSON.stringify(item)} */}
													<NavLink className={classnames({ active: toggleTabs.activeTabProgress == item.id })} onClick={() => { toggleTabProgress(item.id, false); }} >
														<div className="step-icon" data-bs-toggle="tooltip" id={'Qnnr' + item.id}>
															<i className="bx bx-list-ul"></i>
															<UncontrolledTooltip placement="top" target={'Qnnr' + item.id}>
																{item.key}
															</UncontrolledTooltip>
														</div>
														<div className="text-dark">{item.key}</div>
													</NavLink>
												</NavItem>
											))}



										</ul>
										<div id="bar" className="mt-4">
											<Progress color="success" striped animated value={toggleTabs.progressValue} />
										</div>
										<TabContent activeTab={toggleTabs.activeTabProgress} className="twitter-bs-wizard-tab-content">

											<TabPane tabId={toggleTabs.activeTabProgress} key={toggleTabs.activeTabProgress}>
												<form encType="multipart/form-data" className="case-questionnaire">
													<div className="text-left mb-4">
														<p className="card-title-desc" style={{ color: 'red' }}>{toggleTabs.validationError}</p>
													</div>
													{<Form
														controls={controls.length ? controls : Object.keys(employeeCaseQnnrTemplateGroupInfo).length ? employeeCaseQnnrTemplateGroupInfo : { sets: [{ attributes: [] }] }}
														setControls={setControls}
													/>}

												</form>
											</TabPane>

										</TabContent>

										<ul className="pager wizard twitter-bs-wizard-pager-link">
											<li className={((toggleTabs.activeTabProgress === 1) || (employeeCaseQnnrTemplateInfo?.isQnnrUnlock != 1)) ? "previous disabled" : "previous"}>
												<Link
													className={((toggleTabs.activeTabProgress === 1) || (employeeCaseQnnrTemplateInfo?.isQnnrUnlock != 1)) ? "btn btn-primary disabled" : "btn btn-primary"}
													to={window.location.href}
													onClick={() => {
														toggleTabProgress(toggleTabs.activeTabProgress - 1, false);
													}}
												>
													<i className="bx bx-chevron-left me-1"></i> Previous
												</Link>
											</li>

											<li className={((toggleTabs.activeTabProgress == groupsTabLength) || employeeCaseQnnrTemplateInfo?.isQnnrUnlock == 1) ? "next" : "next disabled"}>
												<Link
													className={(((toggleTabs.activeTabProgress == groupsTabLength) || (employeeCaseQnnrTemplateInfo?.isQnnrUnlock == 1)) ? "btn btn-primary" : "btn btn-primary disabled") + (employeeCaseQnnrTemplateInfo?.isQnnrUnlock != 1 ? " disabled" : "")}
													to={window.location.href}
													onClick={() => {
														let nexttab = toggleTabs.activeTabProgress;
														if (toggleTabs.activeTabProgress < groupsTabLength) {
															nexttab = toggleTabs.activeTabProgress + 1;
														}
														toggleTabProgress(nexttab, (employeeCaseQnnrTemplateInfo?.isQnnrUnlock == 1));
													}}
												>
													{toggleTabs.activeTabProgress == groupsTabLength || groupsTabLength == 0 ? (<>Submit</>) : (
														<>
															Next <i className='bx bx-chevron-right ms-1'></i>
														</>
													)}
												</Link>
											</li>
										</ul>
										{employeeCaseQnnrTemplateInfo?.isQnnrUnlock != 1 &&
											<div className="m-2 react-bootstrap-table-page-btns-ul text-danger">
												Note : This questionnaire has been locked by law office. Please contact for any modifications.
											</div>
										}
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default EmployeeQuestionnaire;
