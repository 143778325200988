import React from "react";

import EmployerInvoiceDownload from "../../Invoices/DownloadInvoice";
import EmployerBasicSuite from "../../Basic";

const MainInvoiceDownloadComponent = () => {

  const getLoggedInPlanType = () => {
    var authUser = JSON.parse(localStorage.getItem("authUser")) ;
    return authUser?.data?.plan_type;
  }

  if(getLoggedInPlanType()==1) {
    return <EmployerInvoiceDownload/>
  } else {
    return <EmployerBasicSuite componentName="Download Invoice"/>
  }
};

export default MainInvoiceDownloadComponent;
