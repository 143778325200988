import {
  GET_EMPLOYERTIMESHEETS,
  GET_EMPLOYERTIMESHEETS_SUCCESS,
  GET_EMPLOYERTIMESHEETS_FAIL,
  GET_EMPLOYERTIMESHEETDETAIL,
  GET_EMPLOYERTIMESHEETDETAIL_SUCCESS,
  GET_EMPLOYERTIMESHEETDETAIL_FAIL,
  UPDATE_EMPLOYERTIMESHEETSTATUS,
  UPDATE_EMPLOYERTIMESHEETSTATUS_SUCCESS,
  UPDATE_EMPLOYERTIMESHEETSTATUS_FAIL
} from "./actionTypes"



export const getEmployerTimesheets = (data) => (console.log('--- action calledd testttttttttttt ---'),{ 
  type: GET_EMPLOYERTIMESHEETS,
  payload: data
})

export const getEmployerTimesheetsSuccess = (data) => ({
  type: GET_EMPLOYERTIMESHEETS_SUCCESS,
  payload: data
})

export const getEmployerTimesheetsFail = (error) => ({
  type: GET_EMPLOYERTIMESHEETS_FAIL,
  payload: error
})

export const getEmployerTimesheetDetail = timesheetId => ({ 
  type: GET_EMPLOYERTIMESHEETDETAIL,
  timesheetId
})

export const getEmployerTimesheetDetailSuccess = (data) => ({
  type: GET_EMPLOYERTIMESHEETDETAIL_SUCCESS,
  payload: data
})

export const getEmployerTimesheetDetailFail = (error) => ({
  type: GET_EMPLOYERTIMESHEETDETAIL_FAIL,
  payload: error
})

export const updateEmployerTimesheetStatus = (data) => ({ 
  type: UPDATE_EMPLOYERTIMESHEETSTATUS,
  payload: data
})

export const updateEmployerTimesheetStatusSuccess = (data) => ({
  type: UPDATE_EMPLOYERTIMESHEETSTATUS_SUCCESS,
  payload: data
})

export const updateEmployerTimesheetStatusFail = (error) => ({
  type: UPDATE_EMPLOYERTIMESHEETSTATUS_FAIL,
  payload: error
})
