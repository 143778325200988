import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GENERATE_FORM,SEND_PAF_EMPLOYEE_NOTIFICATION,CREATE_PAF_BUNDLE, GET_EMPLOYERCASES, GET_EMPLOYER_PAF, GET_EMPLOYER_PAF_FORM, GET_EMPLOYER_PAF_FORMS, UPDATE_EMPLOYER_PAF_FORM, GET_EMPLOYER_PAFINFO, UPDATE_EMPLOYER_PAFINFO, GET_EMPLOYERCASE_DETAIL, ADD_EMPLOYERCASE_DETAIL, DELETE_EMPLOYERCASE_DETAIL, UPDATE_EMPLOYERCASE_DETAIL, GET_EMPLOYERCASETYPES, GET_EMPLOYERQNNRTYPES,
  UPLOAD_EMPLOYER_LCA,
  UPDATE_PAF_TEMPLATE,
  GET_PAF_TEMPLATE_VARIABLES
} from "./actionTypes"
import {
  getEmployerCasesSuccess,
  getEmployerCasesFail,
  getEmployerPafSuccess,
  getEmployerPafFail,
  sendPafEmployeeNotificationSuccess,
  sendPafEmployeeNotificationFail,
  createPafBundleSuccess,
  createPafBundleFail,
  getEmployerPafFormSuccess,
  getEmployerPafFormFail,
  getEmployerPafFormsSuccess,
  getEmployerPafFormsFail,
  updateEmployerPafFormSuccess,
  updateEmployerPafFormFail,
  getEmployerPafInfoSuccess,
  getEmployerPafInfoFail,
  updateEmployerPafInfoSuccess,
  updateEmployerPafInfoFail,
  getEmployerCaseDetailSuccess,
  getEmployerCaseDetailFail,
  addEmployerCasedetailSuccess,
  addEmployerCasedetailFail,
  EmployerCaseDeleteSuccess,
  EmployerCaseDeleteFail,
  EmployerCaseUpdateSuccess,
  EmployerCaseUpdateFail,
  getEmployerCaseTypesSuccess,
  getEmployerCaseTypesFail,
  getEmployerQnnrtypesSuccess,
  getEmployerQnnrtypesFail,
  uploadEmployerLcaFail,
  uploadEmployerLcaSuccess,
  updatePafTemplateSuccess,
  updatePafTemplateFail,
  getPafTemplateVariablesSuccess,
  getPafTemplateVariablesFail,

} from "./actions"

//Include Both Helper File with needed methods
import { setAlertMessage } from "../../common/actions"
import { getEmployerCases, getEmployerPaf, getEmployerPafForm, getEmployerPafForms, updateEmployerPafForm, getEmployerPafInfo,sendPafEmployeeNotification,createPafBundle, updateEmployerPafInfo, getEmployerCaseDetail, addEmployerCase, deleteEmployerCase, updateEmployerCase, getEmployerCaseTypes, getEmployerQnnrtypes, uploadEmployerLca, updatePafTemplate, getPafTemplateVariables } from "../../../helpers/apibackend_helper"

function* fetchEmployerCases({ payload: user }) {
  try {
    const response = yield call(getEmployerCases, user)
    yield put(getEmployerCasesSuccess(response.data))
  } catch (error) {
    yield put(getEmployerCasesFail(error))
  }
}

function* fetchEmployerPaf({ payload: paf }) {
  try {
    const response = yield call(getEmployerPaf, paf)
    yield put(getEmployerPafSuccess(response.data))
  } catch (error) {
    yield put(getEmployerPafFail(error))
  }
}

function* fetchEmployerPafForm({ payload: paf }) {
  try {
    const response = yield call(getEmployerPafForm, paf)
    yield put(getEmployerPafFormSuccess(response.data))
  } catch (error) {
    yield put(getEmployerPafFormFail(error))
  }
}

function* fetchEmployerPafForms({ payload: paf }) {
  try {
    const response = yield call(getEmployerPafForms, paf)
    yield put(getEmployerPafFormsSuccess(response.data))
  } catch (error) {
    yield put(getEmployerPafFormsFail(error))
  }
}

function* fetchEmployerPafInfo({ payload: paf }) {
  try {
    const response = yield call(getEmployerPafInfo, paf)
    yield put(getEmployerPafInfoSuccess(response.data))
  } catch (error) {
    yield put(getEmployerPafInfoFail(error))
  }
}

function* fetchUpdateEmployerPafForm({ payload: paf }) {
  try {
    const response = yield call(updateEmployerPafForm, paf)
    yield put(updateEmployerPafFormSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"})); 
  } catch (error) {
    yield put(updateEmployerPafFormFail(error))
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* fetchUpdateEmployerPafInfo({ payload: paf }) {
  try {
    const response = yield call(updateEmployerPafInfo, paf)
    yield put(updateEmployerPafInfoSuccess(response.data))
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(updateEmployerPafInfoFail(error))
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* fetchEmployerCaseDetail({ EmployerCaseId }) {
  try {
    const response = yield call(getEmployerCaseDetail, EmployerCaseId)
    yield put(getEmployerCaseDetailSuccess(response.data))
  } catch (error) {
    yield put(getEmployerCaseDetailFail(error))
  }
}

function* addEmployerCaseDetail({ payload: user }) {
  try {
    const response = yield call(addEmployerCase, user)
    yield put(addEmployerCasedetailSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"})); 
  } catch (error) {
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* onDeleteEmployerCase({ payload: data }) {
  try {
    const response = yield call(deleteEmployerCase, data);
    yield put(EmployerCaseDeleteSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"})); 
  } catch (error) {
    yield put(EmployerCaseDeleteFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* onUpdateEmployerCase({ payload: data }) {
  try {
    const response = yield call(updateEmployerCase, data);
    yield put(EmployerCaseUpdateSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"})); 
  } catch (error) {
    yield put(setAlertMessage({message:error}))
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* fetchEmployerCaseTypes({ payload: user }) {
  try {
    const response = yield call(getEmployerCaseTypes, user)
    yield put(getEmployerCaseTypesSuccess(response.data))
  } catch (error) {
    yield put(getEmployerCaseTypesFail(error))
  }
}

function* fetchEmployerQnnrTypes({ payload: EmployerCaseId }) {
  try {
    const response = yield call(getEmployerQnnrtypes, EmployerCaseId)
    yield put(getEmployerQnnrtypesSuccess(response.data))
  } catch (error) {
    yield put(getEmployerQnnrtypesFail(error))
  }
}

function* onSendPafEmployeeNotification({ payload: data }) {
  try {
    const response = yield call(sendPafEmployeeNotification, data);
    yield put(sendPafEmployeeNotificationSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"})); 
  } catch (error) {
    yield put(sendPafEmployeeNotificationFail(error))
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}
function* onCreatePafBundle({ payload: data }) {
  try {
    const response = yield call(createPafBundle, data);
    yield put(createPafBundleSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"})); 
  } catch (error) {
    yield put(createPafBundleFail(error))
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* fetchUpdatePafTemplate({ payload: paf }) {
  try {
    const response = yield call(updatePafTemplate, paf)
    yield put(updatePafTemplateSuccess(response.data))
    yield put(setAlertMessage({message:response,type:"success"})); 
  } catch (error) {
    yield put(updatePafTemplateFail(error))
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* fetchUploadEmployerLca({ payload: paf }) {
  try {
    const response = yield call(uploadEmployerLca, paf)
    yield put(uploadEmployerLcaSuccess(response.data))
    yield put(setAlertMessage({message:response,type:"success"})); 
  } catch (error) {
    yield put(uploadEmployerLcaFail(error))
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* fetchGetPafTemplateVariables({ payload: paf }) {
  try {
    const response = yield call(getPafTemplateVariables, paf)
    yield put(getPafTemplateVariablesSuccess(response.data))
  } catch (error) {
    yield put(getPafTemplateVariablesFail(error))
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* EmployerCasesaga() {
  yield takeEvery(GET_EMPLOYERCASES, fetchEmployerCases)
  yield takeEvery(GET_EMPLOYER_PAF, fetchEmployerPaf)
  yield takeEvery(GET_EMPLOYER_PAF_FORM, fetchEmployerPafForm)
  yield takeEvery(GET_EMPLOYER_PAF_FORMS, fetchEmployerPafForms)
  yield takeEvery(UPDATE_EMPLOYER_PAF_FORM, fetchUpdateEmployerPafForm)
  yield takeEvery(GET_EMPLOYER_PAFINFO, fetchEmployerPafInfo)
  yield takeEvery(UPDATE_EMPLOYER_PAFINFO, fetchUpdateEmployerPafInfo)
  yield takeEvery(GET_EMPLOYERCASE_DETAIL, fetchEmployerCaseDetail)
  yield takeEvery(ADD_EMPLOYERCASE_DETAIL, addEmployerCaseDetail)
  yield takeEvery(DELETE_EMPLOYERCASE_DETAIL, onDeleteEmployerCase)
  yield takeEvery(UPDATE_EMPLOYERCASE_DETAIL, onUpdateEmployerCase)
  yield takeEvery(SEND_PAF_EMPLOYEE_NOTIFICATION, onSendPafEmployeeNotification)
  yield takeEvery(CREATE_PAF_BUNDLE, onCreatePafBundle)
  yield takeEvery(GET_EMPLOYERCASETYPES, fetchEmployerCaseTypes)
  yield takeEvery(GET_EMPLOYERQNNRTYPES, fetchEmployerQnnrTypes)
  yield takeEvery(UPDATE_PAF_TEMPLATE, fetchUpdatePafTemplate)
  yield takeEvery(UPLOAD_EMPLOYER_LCA, fetchUploadEmployerLca)
  yield takeEvery(GET_PAF_TEMPLATE_VARIABLES, fetchGetPafTemplateVariables)
}

export default EmployerCasesaga
