import { GET_CASEFORMATTRIBUTEGROUPS,
      GET_CASEFORMATTRIBUTEGROUPS_SUCCESS,
      GET_CASEFORMATTRIBUTEGROUPS_FAIL,
      ADD_CASEFORMATTRIBUTEGROUP,
      ADD_CASEFORMATTRIBUTEGROUP_SUCCESS,
      ADD_CASEFORMATTRIBUTEGROUP_FAIL,
      GET_CASEFORMATTRGROUP_DETAIL_SUCCESS,
      GET_CASEFORMATTRGROUP_DETAIL_FAIL,
      GET_CASEFORMATTRGROUP_DETAIL,
      UPDATE_CASEFORMATTRIBUTEGROUP,
      UPDATE_CASEFORMATTRIBUTEGROUP_FAIL,
      UPDATE_CASEFORMATTRIBUTEGROUP_SUCCESS,
      DELETE_CASEFORM_ATTRIBUTEGROUP_DETAIL,
      DELETE_CASEFORM_ATTRIBUTEGROUP_DETAIL_SUCCESS,
      DELETE_CASEFORM_ATTRIBUTEGROUP_DETAIL_FAIL
 } from "./actionTypes"

export const getCaseFormAttributeGroups = (data) => ({
  type: GET_CASEFORMATTRIBUTEGROUPS,
  form_id:data?.form_id || ''
})

export const getCaseFormAttributeGroupsFail = error => ({
  type: GET_CASEFORMATTRIBUTEGROUPS_FAIL,
  payload: error
})

export const getCaseFormAttributeGroupsSuccess = CaseFormAttributeTemplates => ( console.log(CaseFormAttributeTemplates,"============ result ================"),{
  type: GET_CASEFORMATTRIBUTEGROUPS_SUCCESS,
  payload: CaseFormAttributeTemplates,
})

export const addcaseformAttributegroup = CaseFormAttributeGroup => ({
  type: ADD_CASEFORMATTRIBUTEGROUP,
  payload: CaseFormAttributeGroup,
})

export const addcaseformAttributegroupFail = error => ({
  type: ADD_CASEFORMATTRIBUTEGROUP_FAIL,
  payload: error
})

export const addcaseformAttributegroupSuccess = CaseFormAttributeGroup => ({
  type: ADD_CASEFORMATTRIBUTEGROUP_SUCCESS,
  payload: CaseFormAttributeGroup,
})

export const updateCaseformAttributegroup = CaseFormAttributeGroup => ({
  type: UPDATE_CASEFORMATTRIBUTEGROUP,
  payload: CaseFormAttributeGroup,
})

export const updateCaseformAttributegroupFail = error => ({
  type: UPDATE_CASEFORMATTRIBUTEGROUP_FAIL,
  payload: error
})

export const updateCaseformAttributegroupSuccess = CaseFormAttributeGroup => ({
  type: UPDATE_CASEFORMATTRIBUTEGROUP_SUCCESS,
  payload: CaseFormAttributeGroup,
})



export const caseFormAttributeGroupDelete = (data) => ({
  type: DELETE_CASEFORM_ATTRIBUTEGROUP_DETAIL,
  payload: data
})

export const caseFormAttributeGroupDeleteSuccess = (data) => ({
  type: DELETE_CASEFORM_ATTRIBUTEGROUP_DETAIL_SUCCESS,
  payload: data
})

export const caseFormAttributeGroupDeleteFail = (error) => ({
  type: DELETE_CASEFORM_ATTRIBUTEGROUP_DETAIL_FAIL,
  payload: error
})




export const getCaseFormAttributeGroupDetail = Caseformid => ({
  type: GET_CASEFORMATTRGROUP_DETAIL,
  Caseformid,
})

export const getCaseFormAttributeGroupDetailSuccess = Caseformattributegroups => ({
  type: GET_CASEFORMATTRGROUP_DETAIL_SUCCESS,
  payload: Caseformattributegroups,
})

export const getCaseFormAttributeGroupDetailFail = error => ({
  type: GET_CASEFORMATTRGROUP_DETAIL_FAIL,
  payload: error,
})

