import React, { useState, useEffect } from 'react';

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  Card, CardBody, CardHeader, Col, Container, Row, Form, Label, Input, FormGroup, Button, FormFeedback,
  Nav,
  NavItem,
  NavLink, TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import * as Yup from "yup";
import { useFormik, Field, FieldArray, FormikProvider, ErrorMessage } from "formik";

// import UpdateCase from "./UpdateCase";
// import { getAttorneyCaseDetail } from "../../../../store/actions";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { updateEmployeePayDetails, getCustomers } from "../../../../store/actions";
import { useAlert } from "../../../../components/Common/Alert";
import { getDateFormatYMD } from '../../../../common/data/dateFormat';
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";


export const Pay = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //meta title
  document.title = "Case Details | Immidock ";
  const moreactions = [{ 'label': 'Save', 'action': 'saveandsend' }]
  const [sendInvoice, setSendInvoice] = useState(true)
  const [timesheetAccessBillable, setTimesheetAccessBillable] = useState(false)
  const [timesheetAccessSelected, setTimesheetAccessSelected] = useState(false)
  const [showCreateVendor, setShowCreateVendor] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const employeeId = queryParams.get('id'); // Read the 'id' query parameter from the URL

  const handleActionSubmit = (e) => {
    validation.handleSubmit();
  }

  const { employercontactDetail } = useSelector((state) => ({
    employercontactDetail: state.EmployerContacts.employercontactDetail,
  }));

  const { customers } = useSelector((state) => ({
    customers: state.Customers.customers,
    //customerDetail: state.Customers.customerDetail,
  }));

  useEffect(() => {
    //dispatch(getCustomers({ status: activeTab }));
    dispatch(getCustomers({ status: 'All' }));
  }, [dispatch]);

  var empPayDetails = [], empDeposits = [];
  //var checkemptyArr = emptyemployercontactDetail.personPayDetails;
  if (_.isEmpty(employercontactDetail.personPayDetails)) {
    empPayDetails.push({
      pay_id: '', bill_rate: '', client_name: '', customer_id: '',
      pay_rate: '', payschedule: '', ot_bill_rate: '', ot_pay_rate: '',
      pay_start_date: '', pay_end_date: '', wages: ''
    })
  } else {
    employercontactDetail.personPayDetails.map((item, index) => {
      empPayDetails.push({
        pay_id: item.id, bill_rate: item.bill_rate, client_name: item.client_name, customer_id: item.customer_id,
        pay_rate: item.pay_rate, payschedule: item.payschedule, ot_bill_rate: item.ot_bill_rate, ot_pay_rate: item.ot_pay_rate,
        pay_start_date: item.pay_start_date, pay_end_date: item.pay_end_date, wages: item.wages
      })
    })
  }

  if (_.isEmpty(employercontactDetail.personDepositDetails)) {
    empDeposits.push({
      deposit_id: '', emp_id: '', account_type: '', bank_name: '',
      account_number: '', routing_number: '', deposit_type: '', amount: '',
    })
  } else {
    employercontactDetail.personDepositDetails.map((item, index) => {
      empDeposits.push({
        deposit_id: item.id, emp_id: item.emp_id, account_type: item.account_type, bank_name: item.bank_name,
        account_number: item.account_number, routing_number: item.routing_number, deposit_type: item.deposit_type, amount: item.amount,
      })
    })
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      employee_id: employercontactDetail.employee_id,
      time_sheet_access: employercontactDetail?.time_sheet_access == 1 ? true : false,
      status_report_access: employercontactDetail?.status_report_access == 1 ? true : false,
      billable: employercontactDetail?.billable ? employercontactDetail.billable : 'No',
      billable_start_date: employercontactDetail?.billable_start_date ? employercontactDetail.billable_start_date : '',
      billable_end_date: employercontactDetail?.billable_end_date ? employercontactDetail.billable_end_date : '',
      personPayDetails: empPayDetails,
      personDepositDetails: empDeposits
    },
    validationSchema: Yup.object({
      employee_id: Yup.string().required("Beneficiary ID is mandatory"),
      // first_name: Yup.string().required("Please Enter  First Name"),
      // last_name: Yup.string().required("Please Enter  Last Name"),
      // email: Yup.string().required("Please Enter Company Email"),
      // phone: Yup.string().required("Please Enter Phone Number"),
      // emp_type: Yup.string().required("Please select Beneficiary Type"),

      time_sheet_access: Yup.boolean(),
      status_report_access: Yup.boolean(),
    }),
    onSubmit: (values) => {
      //if (values.employerEmployee_action) {
      dispatch(updateEmployeePayDetails({ ...values, pay_start_date: getDateFormatYMD(values.pay_start_date), pay_end_date: getDateFormatYMD(values.pay_end_date), billable_start_date: getDateFormatYMD(values.billable_start_date), billable_end_date: getDateFormatYMD(values.billable_end_date) }));
    }
    // } else {
    //   dispatch(addEmployerContactDetail(values));
    // }
    //navigate("/employer/employeelist");
    //}
  });

  // Form validation 
  const addVendorvalidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      create_vendor_name: '',
    },
    validationSchema: Yup.object({
      create_vendor_name: Yup.string().required("Please Enter Vendor name"),
    }),
    onSubmit: (values) => {
      //dispatch(addDocumentUpload({...values,case_id:caseId}));
      setShowCreateVendor(false);
      setTimeout(() => {
        dispatch(getEmployerContactDetail({ employee_id: employeeId }));
      }, 2000);
      //  setMoreActions('');
      addVendorvalidation.resetForm();

    }
  });

  const handleChange = () => {
    setTimesheetAccessSelected(!timesheetAccessSelected)
  }

  const timesheetAccessBillableSelect = () => {
    setTimesheetAccessBillable(!timesheetAccessBillable)
  }

  const createNewContact = (e) => {
    console.log(e.target.value, '--- check tagert value-------')
    if (e.target.value === "createnewcontact") {
      //setShowCreateVendor(true);
      navigate("/employer/create-customer?createCustomer=fromPay&employeeId=" + employeeId);
    }
  }

  return (
    <React.Fragment>
      <div className="">
        <Modal isOpen={showCreateVendor}>
          <ModalHeader toggle={() => { addVendorvalidation.resetForm(); setShowCreateVendor(false) }} tag="h4">
            Add Vendor
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                addVendorvalidation.handleSubmit();
                return false;
              }}
            >
              <Row form>
                <Col className="col-12 mb-3">
                  <Label className="form-label">Vendor Name *</Label>
                  <Input
                    name="create_vendor_name"
                    type="text"
                    placeholder="Enter Input"
                    onChange={addVendorvalidation.handleChange}
                    onBlur={addVendorvalidation.handleBlur}
                    value={addVendorvalidation.values.create_vendor_name || ""}
                    invalid={
                      addVendorvalidation.touched.create_vendor_name &&
                        addVendorvalidation.errors.create_vendor_name
                        ? true
                        : false
                    }
                  />
                  {addVendorvalidation.touched.create_vendor_name &&
                    addVendorvalidation.errors.create_vendor_name ? (
                    <FormFeedback type="invalid">
                      {addVendorvalidation.errors.create_vendor_name}
                    </FormFeedback>
                  ) : null}
                </Col>

              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="ms-1 btn btn-primary save-event"
                    >
                      Create
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>

        <Row>

          <Col xs={12}>
            <Card>

              <CardBody className="p-4">
                {/* <div className="row">
            <Col md="9"></Col>
            <Col md="3">
              
            </Col>

          </div> */}
                <FormikProvider value={validation}>
                  <Form className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    {/* import TextualInputs */}
                    <h4>Pay Details</h4><br />
                    <FieldArray
                      name="personPayDetails"
                      render={arrayHelpers => (
                        <div>
                          {validation.values.personPayDetails.map((item, index) => (
                            <div key={index + "item"} className='more-item-box'>
                              <div className="remove-item">

                                <Col sm={1}>
                                  {validation.values.personPayDetails.length > 1 ? (
                                    <>
                                      <i className="bx bx-x bx-sm" onClick={() => arrayHelpers.remove(index)} ></i>
                                    </>
                                  ) : ''}
                                </Col>
                              </div>
                              <div className="row more-items">
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`personPayDetails.${index}.customer_id`}>Vendor</Label>

                                    <Input
                                      id={`personPayDetails.${index}.customer_id`}
                                      type="select"
                                      name={`personPayDetails.${index}.customer_id`}
                                      onChange={(e) => { createNewContact(e), validation.setFieldValue(`personPayDetails.${index}.customer_id`, e.target.value) }}
                                    >
                                      <option value="">Select Vendor</option>
                                      <option value="createnewcontact">Create a new Vendor</option>
                                      {customers && customers.map((data, vendIndex) => (
                                        <option key={vendIndex} selected={validation.values.personPayDetails[index].customer_id == data.id} value={data.id}>{data.cmp_name} </option>
                                      ))}
                                    </Input>
                                  </FormGroup>

                                </Col>
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`personPayDetails.${index}.client_name`}>Client</Label>

                                    <Field type="text"
                                      className="form-control"
                                      placeholder="Client"
                                      name={`personPayDetails.${index}.client_name`} />

                                    <ErrorMessage
                                      name={`personPayDetails.${index}.client_name`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`personPayDetails.${index}.bill_rate`}>Bill Rate/hour</Label>

                                    <Field type="text"
                                      className="form-control"
                                      placeholder="Bill Rate/hour"
                                      name={`personPayDetails.${index}.bill_rate`} />

                                    <ErrorMessage
                                      name={`personPayDetails.${index}.bill_rate`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`personPayDetails.${index}.pay_rate`}>Pay Rate/hour</Label>

                                    <Field type="text"
                                      className="form-control"
                                      placeholder="Pay Rate/hour"
                                      name={`personPayDetails.${index}.pay_rate`} />

                                    <ErrorMessage
                                      name={`personPayDetails.${index}.pay_rate`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`personPayDetails.${index}.payschedule`}>Pay Schedule</Label>

                                    <Input
                                      id={`personPayDetails.${index}.payschedule`}
                                      type="select"
                                      placeholder="Pay Schedule"
                                      name={`personPayDetails.${index}.payschedule`}
                                      onChange={(e) => {
                                        validation.handleChange(e);
                                        // validation.setFieldValue(`personPayDetails.${index}.payschedule`, e.target.value);
                                      }}
                                    >
                                      <option value="">Select</option>
                                      <option selected={validation.values.personPayDetails[index].payschedule == "1"} value="1">Weekly</option>
                                      <option selected={validation.values.personPayDetails[index].payschedule == "2"} value="2">Bi Weekly</option>
                                      <option selected={validation.values.personPayDetails[index].payschedule == "3"} value="3">Twice a Month</option>
                                      <option selected={validation.values.personPayDetails[index].payschedule == "4"} value="4">Monthly</option>
                                      <option selected={validation.values.personPayDetails[index].payschedule == "5"} value="5">Other</option>
                                    </Input>
                                  </FormGroup>

                                </Col>
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`personPayDetails.${index}.ot_bill_rate`}>OT Bill Rate/hour</Label>

                                    <Field type="text"
                                      className="form-control"
                                      placeholder="OT Bill Rate/hour"
                                      name={`personPayDetails.${index}.ot_bill_rate`} />

                                    <ErrorMessage
                                      name={`personPayDetails.${index}.ot_bill_rate`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`personPayDetails.${index}.ot_pay_rate`}>OT Rate/hour</Label>

                                    <Field type="text"
                                      className="form-control"
                                      placeholder="OT Rate/hour"
                                      name={`personPayDetails.${index}.ot_pay_rate`} />

                                    <ErrorMessage
                                      name={`personPayDetails.${index}.ot_pay_rate`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </FormGroup>
                                </Col>

                                <Col md="4">

                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`personPayDetails.${index}.pay_start_date`}>Start Date</Label>

                                    <ReactDatePicker
                                      showYearDropdown={true}
                                      showIcon
                                      placeholderText="MM/dd/yyyy"
                                      className="form-control"
                                      name={`personPayDetails.${index}.pay_start_date`}
                                      id={`personPayDetails.${index}.pay_start_date`}
                                      dateFormat="MM/dd/yyyy"
                                      onChange={(date) => {
                                        validation.setFieldValue(`personPayDetails[${index}].pay_start_date`, date)
                                        // validation.values.personPayDetails[index].pay_start_date= date;
                                      }
                                      }
                                      selected={validation.values.personPayDetails[index].pay_start_date ? new Date(validation.values.personPayDetails[index].pay_start_date) : ''}
                                    />

                                    <ErrorMessage
                                      name={`personPayDetails.${index}.pay_start_date`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`personPayDetails.${index}.pay_end_date`}>End Date</Label>

                                    <ReactDatePicker
                                      showYearDropdown={true}
                                      showIcon
                                      placeholderText="MM/dd/yyyy"
                                      className="form-control"
                                      name={`personPayDetails.${index}.pay_end_date`}
                                      id={`personPayDetails.${index}.pay_end_date`}
                                      dateFormat="MM/dd/yyyy"
                                      onChange={(date) => {
                                        validation.setFieldValue(`personPayDetails[${index}].pay_end_date`, date)
                                        // validation.values.personPayDetails[index].pay_end_date= date;
                                      }
                                      }
                                      selected={validation.values.personPayDetails[index].pay_end_date ? new Date(validation.values.personPayDetails[index].pay_end_date) : ''}
                                    />
                                    <ErrorMessage
                                      name={`personPayDetails.${index}.pay_end_date`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`personPayDetails.${index}.wages`}>Wages</Label>

                                    <Field type="text"
                                      className="form-control"
                                      placeholder="Wages"
                                      name={`personPayDetails.${index}.wages`} />

                                    <ErrorMessage
                                      name={`personPayDetails.${index}.wages`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </FormGroup>
                                </Col>
                                <Row className="justify-content-middle">
                                  <Col sm={10}></Col>
                                  <Col sm={2} style={{ padding: "0 0 16px 0" }}>
                                    {validation.values.personPayDetails.length === index + 1 ? (
                                      <Button
                                        color="primary add-more-button-right" className="ms-1"
                                        type="button"
                                        onClick={() => arrayHelpers.push({ ot_pay_rate: '', wages: '' })}
                                      >
                                        Add more
                                      </Button>
                                    ) : ''}
                                  </Col>

                                </Row>
                              </div>

                            </div>
                          ))}

                        </div>
                      )}
                    />
                    <br /><br />
                    <h4>Timesheet Information</h4><br />
                    <div className="mb-3">
                      <div
                        className="form-check form-switch form-switch-md mb-3"
                        dir="ltr"
                      >
                        <Input
                          name="time_sheet_access"
                          type="checkbox"
                          className="form-check-input"
                          id="validationCustom01"
                          onChange={(e) => (validation.handleChange(e))}
                          checked={validation.values.time_sheet_access}
                        //onBlur={handleBlur}
                        //value={validation.values.time_sheet_access}

                        />
                        {/* {validation.values.is_timesheet_access_enable && formData.errors.accept_terms ? (
                            <FormFeedback type="invalid">{formData.errors.accept_terms}</FormFeedback>
                          ) : null} */}

                        <span className="font-size-16">Timesheet Access</span>
                      </div>
                    </div>
                    <div className="row">
                      {(timesheetAccessSelected || validation.values.time_sheet_access) ? (
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom02">Billable</Label>
                            <Input
                              name="billable"
                              placeholder="Billable"
                              type="select"
                              className="form-control"
                              id="billable"
                              onChange={(e) => (timesheetAccessBillableSelect, validation.setFieldValue(`billable`, e.target.value))}
                            // invalid={
                            //   validation.touched.timesheetAccess_billable && validation.errors.timesheetAccess_billable ? true : false
                            // }
                            >  <option value="">Select</option>
                              <option selected={validation.values.billable == "Yes"} value="Yes">Yes</option>
                              <option selected={validation.values.billable == "No"} value="No">No</option>
                            </Input>
                            {/* {validation.touched.gender && validation.errors.gender ? (
                                <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
                              ) : null} */}
                          </FormGroup>
                        </Col>
                      ) : ''}
                      {(timesheetAccessBillable || validation.values.billable === "Yes") ? (
                        <React.Fragment>
                          <Col md="4">

                            <FormGroup className="mb-3">
                              <Label htmlFor='billable_start_date'>Start Date</Label>

                              <ReactDatePicker
                                showYearDropdown={true}
                                showIcon
                                placeholderText="MM/dd/yyyy"
                                className="form-control"
                                name={`billable_start_date`}
                                id={`billable_start_date`}
                                dateFormat="MM/dd/yyyy"
                                onChange={(date) => {
                                  validation.setFieldValue(`billable_start_date`, date)
                                }
                                }
                                selected={validation?.values?.billable_start_date ? new Date(validation.values.billable_start_date) : ''}
                              />

                              <ErrorMessage
                                name='billable_start_date'
                                component="div"
                                className="text-danger"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor='billable_end_date'>End Date</Label>

                              <ReactDatePicker
                                showYearDropdown={true}
                                showIcon
                                placeholderText="MM/dd/yyyy"
                                className="form-control"
                                name={`billable_end_date`}
                                id={`billable_end_date`}
                                dateFormat="MM/dd/yyyy"
                                onChange={(date) => {
                                  validation.setFieldValue(`billable_end_date`, date)
                                }
                                }
                                selected={validation?.values?.billable_end_date ? new Date(validation.values.billable_end_date) : ''}
                              />

                              <ErrorMessage
                                name='billable_end_date'
                                component="div"
                                className="text-danger"
                              />
                            </FormGroup>
                          </Col>
                        </React.Fragment>
                      ) : ''}
                    </div>
                    {/* {validation.values.is_timesheet_access_enable && (
                      <FormGroup className="mb-3">
                        <Label htmlFor="validationCustom01">Billable</Label>
                        <Input
                          name="discount_percentage"
                          placeholder="Billable"
                          type="text"
                          className="form-control"
                          id="validationCustom01"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.discount_percentage || 0}
                          invalid={
                            validation.touched.discount_percentage && validation.errors.discount_percentage ? true : false
                          }
                        />
                        {validation.touched.discount_percentage && validation.errors.discount_percentage ? (
                          <FormFeedback type="invalid">{validation.errors.discount_percentage}</FormFeedback>
                        ) : null}
                      </FormGroup>
                    )} 
                    <div className="mb-3">
                      <div
                        className="form-check form-switch form-switch-md mb-3"
                        dir="ltr"
                      >
                        <input
                          name="status_report_access"
                          //onChange={validation.handleChange}
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitchsizelg"
                          checked={validation.values.status_report_access}
                          onChange={(e) => (validation.handleChange(e))}
                        />
                        <span className="font-size-16">Status Report Access</span>
                      </div>
                    </div>*/}
                    <br /><h4>Direct Deposit</h4><br />
                    <FieldArray
                      name="personDepositDetails"
                      render={arrayHelpers2 => (
                        <div>
                          {validation.values.personDepositDetails.map((item, index) => (
                            <div key={index + "item"} className='more-item-box'>
                              <div className="remove-item">

                                <Col sm={1}>
                                  {validation.values.personDepositDetails.length > 1 ? (
                                    <>
                                      <i className="bx bx-x bx-sm" onClick={() => arrayHelpers2.remove(index)} ></i>
                                    </>
                                  ) : ''}
                                </Col>
                              </div>
                              <div className="row more-items">
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`personDepositDetails.${index}.account_type`}>Account Type</Label>

                                    <Field type="text"
                                      className="form-control"
                                      placeholder="Enter Account Type"
                                      name={`personDepositDetails.${index}.account_type`} />

                                    <ErrorMessage
                                      name={`personDepositDetails.${index}.account_type`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`personDepositDetails.${index}.routing_number`}>Routing Number</Label>

                                    <Field type="text"
                                      className="form-control"
                                      placeholder="Enter Routing Number"
                                      name={`personDepositDetails.${index}.routing_number`} />

                                    <ErrorMessage
                                      name={`personDepositDetails.${index}.routing_number`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`personDepositDetails.${index}.bank_name`}>Bank Name</Label>

                                    <Field type="text"
                                      className="form-control"
                                      placeholder="Enter Bank Name"
                                      name={`personDepositDetails.${index}.bank_name`} />

                                    <ErrorMessage
                                      name={`personDepositDetails.${index}.bank_name`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`personDepositDetails.${index}.account_number`}>Account Number</Label>

                                    <Field type="text"
                                      className="form-control"
                                      placeholder="Enter Account Number"
                                      name={`personDepositDetails.${index}.account_number`} />

                                    <ErrorMessage
                                      name={`personDepositDetails.${index}.account_number`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`personDepositDetails.${index}.deposit_type`}>Deposit Type</Label>

                                    <Field type="text"
                                      className="form-control"
                                      placeholder="Enter Deposit Type"
                                      name={`personDepositDetails.${index}.deposit_type`} />

                                    <ErrorMessage
                                      name={`personDepositDetails.${index}.deposit_type`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`personDepositDetails.${index}.amount`}>Amount$</Label>

                                    <Field type="text"
                                      className="form-control"
                                      placeholder="Enter Amount"
                                      name={`personDepositDetails.${index}.amount`} />

                                    <ErrorMessage
                                      name={`personDepositDetails.${index}.amount`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </FormGroup>
                                </Col>
                                <Row className="justify-content-middle">
                                  <Col sm={10}></Col>
                                  <Col sm={2} style={{ padding: "0 0 16px 0" }}>
                                    {validation.values.personDepositDetails.length === index + 1 ? (
                                      <Button
                                        color="primary add-more-button-right" className="ms-1"
                                        type="button"
                                        onClick={() => arrayHelpers2.push({ account_type: '', deposit_type: '' })}
                                      >
                                        Add more
                                      </Button>
                                    ) : ''}
                                  </Col>

                                </Row>
                              </div>

                            </div>
                          ))}

                        </div>
                      )}
                    />
                    <Row style={{ float: "right", marginTop: "18px" }}>
                      <Col >
                        <span className="mt-4">
                          <button type="reset" className="btn btn-secondary" color="secondary" onClick={() => navigate(-1)}>
                            Cancel
                          </button>
                        </span>
                        &nbsp;&nbsp;
                        <span className="mt-4">
                          <button type="submit" className="btn btn-primary">
                            Save
                          </button>
                        </span>
                      </Col>
                    </Row>
                  </Form>
                </FormikProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>



      </div>
    </React.Fragment>
  )
}

