import React from "react";

import EmployerPayroll from "../../../Attorney/Payroll";
import EmployerBasicSuite from "../../Basic";

const MainPayrollComponent = () => {

  const getLoggedInPlanType = () => {
    var authUser = JSON.parse(localStorage.getItem("authUser")) ;
    return authUser?.data?.plan_type;
  }

  if(getLoggedInPlanType()==1) {
    return <EmployerPayroll/>
  } else {
    return <EmployerBasicSuite componentName="Payroll"/>
  }
};

export default MainPayrollComponent;
