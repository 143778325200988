import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import { getEmployeeNotifications, readNotification } from "../../../store/actions";

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const { employeeNotifications } = useSelector((state) => ({
    employeeNotifications: state.EmployeeCases.employeeNotifications || '',
  }));

  const { user_type } = useSelector((state) => ({
    user_type: state.login.user_type || localStorage.getItem('user_type'),
  }));
  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getEmployeeNotifications());
  }, [dispatch]);

  const onViewNotification = (data) => {
    dispatch(readNotification({
      "id": data.id,
      "case_id": data.case_id,
      "type": data.notification_type,
    }));
    setTimeout(() => {
      dispatch(getEmployeeNotifications());
      // if (!data.link)
      if (user_type == 'employee') {
        navigate(`/${user_type}/questionnaire?caseId=${data.case_id}`);
      } else {
        navigate(`/${user_type}/view-case?caseId=${data.case_id}`);
      }

    }, 100)
  }
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <FeatherIcon icon="bell" className="icon-lg" />
          <span className="badge bg-danger rounded-pill">{employeeNotifications && employeeNotifications?.[0]?.unread_notifications_count}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {employeeNotifications && employeeNotifications?.map((item) => {
              if (!item.is_read)
                return (
                  <Link to="" className="text-reset notification-item" key={item?.case_id}>
                    <div className="d-flex">
                      <div className="avatar-sm me-3">
                        <span className="avatar-title bg-primary rounded-circle font-size-16" style={{ "padding": "10px" }}>
                          {item?.sender_name ? item.sender_name.substring(0, 2).toUpperCase() : ""}
                        </span>
                      </div>
                      <div className="flex-grow-1">
                        <h6 onClick={() => onViewNotification(item)} className="mt-0 mb-1" dangerouslySetInnerHTML={{ __html: item.notification }}>
                        </h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline" />{" "}
                            {item?.created_at}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
            })}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
