import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Form, Input, Label, Modal, ModalBody, Row, Table } from "reactstrap";

// Formik validation
import { Field, FieldArray, Formik, useFormik } from "formik";
import moment from "moment";


import { getEmployerTimesheetDetail, updateEmployerTimesheetStatus } from "../../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";



export const Timesheet = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showaddTimesheetModal, setshowaddTimesheetModal] = useState(false);
  const [showTimesheetRejectModal, setshowTimesheetRejectModal] = useState(false);
  const [selectedTimesheetDatefromState, setselectedTimesheetDatefromState] = useState({ timesheetInfo: [] })
  const [timesheetActionFormData, setTimesheetActionFormData] = useState({
    "timesheet_id": "",
    "status": "",
    "comment": ""
  })

  //const queryParams = new URLSearchParams(location.search);
  //const timesheet_id = queryParams.get('timesheet-id'); // Read the 'id' query parameter from the URL

  const { allTimesheetsData } = useSelector((state) => ({
    //selectedtimesheetDetails: state.EmployerTimesheets.employerTimesheetDetail.data?.timeSheetDetails || [],
    allTimesheetsData: state.EmployerContacts.employercontactDetail || [],
  }));

  const timesheet_id = _.isEmpty(allTimesheetsData.personTimesheets) ? '' : allTimesheetsData.personTimesheets[0].id;

  useEffect(() => {
    if (timesheet_id) {
      dispatch(getEmployerTimesheetDetail({ "timesheet_id": timesheet_id }));
    }
  }, [dispatch, allTimesheetsData.personTimesheets]);

  const { selectedtimesheetDetails } = useSelector((state) => ({
    //selectedtimesheetDetails: state.EmployerTimesheets.employerTimesheetDetail.data?.timeSheetDetails || [],
    selectedtimesheetDetails: state.EmployerTimesheets.employerTimesheetDetail || [],
  }));

  useEffect(() => {
    if (selectedtimesheetDetails?.timeSheetDetails)
      setRowAndColumnTotals();
  }, [selectedtimesheetDetails?.timeSheetDetails]);

  const getTimesheetData = (e) => {
    dispatch(getEmployerTimesheetDetail({ "timesheet_id": e.target.value }));
  }

  const [rowsTotalHours, setRowsTotalHours] = useState([]);
  const [colsTotalHours, setColsTotalHours] = useState({ columnsTotalHours: [] });
  var curreTotalTimesheetColumnItems = [], totalTimesheetHours = 0;
  const setRowAndColumnTotals = () => {
    console.log(selectedtimesheetDetails?.timesheet_items, '- vreify---')
    //var timesheetTempArray = selectedtimesheetDetails.timesheet_items;
    //timesheetTempArray.timesheet_items.push([rowIndex]);
    //timesheetTempArray.timesheet_items[rowIndex] = { total: 0, columns: [] }
    //var colTempTotalHours = 0;
    console.log(selectedtimesheetDetails, '--- why empty ----')
    selectedtimesheetDetails?.timeSheetDetails.timesheet_items.map((timesheetInfo, index1) => {
      console.log('-----verify innn========= ')
      var calSingleRowHours = 0, colTempTotalHours = 0;
      timesheetInfo.columns.map((hoursData, index2) => {
        //timesheetTempArray.timesheet_items[rowIndex].columns[rowFieldIndex] = { hours: 0, date: date }
        calSingleRowHours += parseInt(hoursData.hours)
        var oldColumnHours = curreTotalTimesheetColumnItems[index2] ?? 0;
        curreTotalTimesheetColumnItems[index2] = parseInt(hoursData.hours) + oldColumnHours;
        //setColsTotalHours[index2] = colTempTotalHours;
        console.log(parseInt(hoursData.hours), '--- last check ---', oldColumnHours)
        //setColsTotalHours[index2], curreTotalTimesheetColumnItems[index2];
      })
      setRowsTotalHours[index1] = calSingleRowHours;
      console.log(calSingleRowHours, '---check row total hours---------', colTempTotalHours);
    });
    //setColsTotalHours[curreTotalTimesheetColumnItems]; 

    setColsTotalHours({ ...colsTotalHours, columnsTotalHours: curreTotalTimesheetColumnItems });
    console.log(curreTotalTimesheetColumnItems, '=--=====final array=====', colsTotalHours);
    // settimesheetInitialState({ ...timesheetInitialState, timesheetItemsArr: timesheetTempArray });
    // console.log(timesheetInitialState, '--------- state after + click ----------')
  }


  var timesheetNumOfDays, timesheetDataSumitObj = {};
  // const [rowInputFields, setNumOfDaysTimesheet] = useState([1,2,3,4,5,6,7]);
  // const [allTimesheetDates, setAllTimesheetDates] = useState(rowInputFields);
  const [timesheetInitialState, settimesheetInitialState] = useState({
    dates: [],
    weekDays: [],
    timesheetItemsArr: { timesheet_items: [{ project: '', activity: '', total: '0.00', columns: [] }], totals: [{ columns: [], total: 0 }], timesheetAttachments: [] }
  });
  const [periodweekDay, setPeriodweekDay] = useState([]);
  const calweekDay = ["Sun}day", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  // console.log(allTimesheetDates,'=========== object values=====');
  const [timesheetData, settimesheetData] = useState({
    project: '',
    activity: '',
    weektotalHours: 0,
    weekWorkingHours: 0,
    totalWorkHours: 0,
    dayWorkingHours: [0],
    rowInputFields: [],
    footerRowTotals: [],
    allRowsFieldData: [[]],
    rowWeektotalHours0: 0,
    rowTimesheetFieldsArr: []
    //  daytotalenteredHours0:0,
    //  daytotalenteredHours1:0,
    //  daytotalenteredHours2:0,
    //  daytotalenteredHours3:0,
    //  daytotalenteredHours4:0,
    //  daytotalenteredHours5:0,
    //  daytotalenteredHours6:0
  })
  //const [weekWorkingHours, setweekWorkingHours] = useState(0);
  const [timeperiodInfo, setTimeperiodInfo] = useState({
    startDate: '',
    endDate: '',
    vendor: '',
    numOfPeriodDays: ''
  });
  const [calcDatesfromStartDate, setCalcDatesfromStartDate] = useState({
    displayDates: {
      date: '',
      weekDay: ''
    }
  });

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      timesheet_items: [{ project: '', activity: '', enteredWorkhours: 0 }],
      project_id: '',
      activity_id: '',
      eachTimeEntry: 0,
      weekTotal: 0,
      dayTotal: 0,
      overAllToatl: 0,
      numOfDaysFieldsArray: [],
      rowIndex: 0
    },
  });
  var rowFieldsArrayCalArr = [], tempFieldValArr = [[], []];


  const addNewTimesheetRow = (rowIndex) => {
    var timesheetTempArray = timesheetInitialState.timesheetItemsArr;
    timesheetTempArray.timesheet_items.push([rowIndex]);
    timesheetTempArray.timesheet_items[rowIndex] = { total: 0, columns: [] }
    timesheetInitialState.dates.map((date, rowFieldIndex) => {

      timesheetTempArray.timesheet_items[rowIndex].columns[rowFieldIndex] = { hours: 0, date: date }
    })

    settimesheetInitialState({ ...timesheetInitialState, timesheetItemsArr: timesheetTempArray });
  }
  const [file, setFile] = useState();
  const addTimesheetAttachments = (e) => {
    setFile(e.target.files[0]);
  }

  const removeTimesheetItem = (rowIndex) => {
    alert(rowIndex);
    var updatedTimesheetItemsArr = timesheetInitialState.timesheetItemsArr;
    timesheetData.rowInputFields.forEach((val, colIndex) => {
      console.log(updatedTimesheetItemsArr.timesheet_items[rowIndex].columns[colIndex].hours, '---- check each field hours ---');
      updatedTimesheetItemsArr.timesheet_items[rowIndex].columns[colIndex].hours = 0;
      console.log(updatedTimesheetItemsArr.timesheet_items[rowIndex].columns[colIndex].hours, '---- check each field hours ---');

    })

    settimesheetInitialState({ ...timesheetInitialState, timesheetItemsArr: updatedTimesheetItemsArr })
    calcTimesheetItems(rowIndex, '');
  }

  var totalsWorkedHoursVar = 0
  const setTimesheetItems = (e, rowIndex, colIndex) => {

    var updatedTimesheetItemsArr = timesheetInitialState.timesheetItemsArr;
    var timesheetFieldValue = e.target.value == '' ? 0 : parseInt(e.target.value);
    updatedTimesheetItemsArr.timesheet_items[rowIndex].columns[colIndex].hours = timesheetFieldValue;

    settimesheetInitialState({ ...timesheetInitialState, timesheetItemsArr: updatedTimesheetItemsArr })

    calcTimesheetItems(rowIndex, colIndex)
  }
  const calcTimesheetItems = (rowIndex, colIndex) => {
    var updatedTimesheetItemsArr = timesheetInitialState.timesheetItemsArr;

    var currTotalTimesheetRowItems = updatedTimesheetItemsArr.timesheet_items[rowIndex].columns.reduce((total, item) => parseInt(item.hours) + total, 0);
    updatedTimesheetItemsArr.timesheet_items[rowIndex].total = currTotalTimesheetRowItems;

    var curreTotalTimesheetColumnItems = [], totalTimesheetHours = 0;

    updatedTimesheetItemsArr.timesheet_items.map((item, index) => {
      item.columns?.map((data, index) => {
        var oldColumnHours = curreTotalTimesheetColumnItems[index] ?? 0;
        curreTotalTimesheetColumnItems[index] = data.hours + oldColumnHours;
        totalTimesheetHours += data.hours;
      })
    })
    updatedTimesheetItemsArr.totals.columns = curreTotalTimesheetColumnItems;
    updatedTimesheetItemsArr.totals.total = totalTimesheetHours;
    settimesheetInitialState({ ...timesheetInitialState, timesheetItemsArr: updatedTimesheetItemsArr })

  }

  const handleAddTimesheetClick = (data) => {
    if (data.endDate !== "") {
      setshowaddTimesheetModal(false);
      var d1 = new Date(data.startDate);
      var d2 = new Date(data.endDate);
      var dateDiff = d2.getTime() - d1.getTime();
      dateDiff = dateDiff / (1000 * 60 * 60 * 24)
      timesheetNumOfDays = new Array(parseInt(dateDiff));
      timesheetNumOfDays.fill(0);
      validation.initialValues.numOfDaysFieldsArray.concat([12])
      var timesheetTypeVal;
      if (data.time_period === '7')
        timesheetTypeVal = 'Weekly'
      else if (data.time_period === '14')
        timesheetTypeVal = 'BiWeekly'
      else if (data.time_period === '15')
        timesheetTypeVal = 'Semi Monthly'
      else if (data.time_period === '30')
        timesheetTypeVal = 'Monthly'
      settimesheetData({ ...timesheetData, rowInputFields: timesheetNumOfDays, footerRowTotals: timesheetNumOfDays });
      setTimeperiodInfo({
        ...timeperiodInfo, startDate: data.startDate, endDate: data.endDate,
        vendor: data.client_id, numOfPeriodDays: timesheetTypeVal
      });
      ß
      showTimesheetDates(data);
    }
  }
  const onCloseClick = () => {

  }
  const showTimesheetDates = (data) => {
    var temparr = [];
    var tempWeekDay = [];
    var j = 0, k = 0;
    var subseqstartDate = new Date(data.actualStartDate);
    //const updateddater = startDatesd.setDate(startDatesd.getDate() + 1);
    //var endDateYear = updateddater.getDate().toString();
    //const sdfbgbfc = startDatesd.setDate(startDatesd.getDate() + 1);
    var selectedMonthLastDate = new Date(subseqstartDate.getFullYear(), subseqstartDate.getMonth(), 0);
    selectedMonthLastDate = selectedMonthLastDate.getDate().toString();

    timesheetNumOfDays.forEach((num, i) => {
      var updateDate;
      //console.log(startDate.setDate(startDate.getDate() + (i+1)),'======');
      if (subseqstartDate.getDate() + i > selectedMonthLastDate) {
        var temp2 = 1 + k;
        temp2 = '0' + temp2;
        temparr.push(temp2);
      } else {
        temparr.push(subseqstartDate.getDate() + i);
      }
      if (subseqstartDate.getDay() + i > 7) {
        var tmpCount = 1 + j; tmpCount = tmpCount === 8 ? 1 : tmpCount;
        tempWeekDay.push(tmpCount);
        j + 1 > 7 ? j = 0 : j = j + 1;
      } else {
        tempWeekDay.push(subseqstartDate.getDay() + i);
      }

      // if(subseqstartDate.getDay()+i > 7) {
      //   j=j+1;
      // }
      if (subseqstartDate.getDate() + i > selectedMonthLastDate) {
        k = k + 1;
      }

    });
    var timesheetTempArray = timesheetInitialState.timesheetItemsArr;

    //timesheetTempArray.push([0]);

    //timesheetTempArray[0] = { total: 2, columns: [] }
    temparr.map((date, rowFieldIndex) => {


      timesheetTempArray.timesheet_items[0].columns[rowFieldIndex] = { hours: 0, date: date }

    })
    settimesheetInitialState({ ...timesheetInitialState, dates: temparr, weekDays: tempWeekDay, timesheetItemsArr: timesheetTempArray });

  }

  const onValChange = (e, rowIndex, selectType) => {
    var updatedTimesheetItemsArr = timesheetInitialState.timesheetItemsArr;
    if (e.target.id == 'project') {
      var timesheetRowProject = e.target.value == '' ? 0 : e.target.value;
      updatedTimesheetItemsArr.timesheet_items[rowIndex].project = timesheetRowProject;
    } else {
      var timesheetRowActivity = e.target.value == '' ? 0 : e.target.value;
      updatedTimesheetItemsArr.timesheet_items[rowIndex].activity = timesheetRowActivity;
    }
    settimesheetInitialState({ ...timesheetInitialState, timesheetItemsArr: updatedTimesheetItemsArr })
  }

  const onTimesheetSubmit = () => {
    var timesheet_items = timesheetInitialState.timesheetItemsArr.timesheet_items;
    timesheetDataSumitObj = {
      ...timesheetDataSumitObj,
      customer_id: timeperiodInfo.vendor,
      timesheet_type: timeperiodInfo.numOfPeriodDays,
      from_date: timeperiodInfo.startDate,
      to_date: timeperiodInfo.endDate,
      add_document_proof: '',
      total_hours: timesheetInitialState.timesheetItemsArr.totals.total,
      timesheet_items,
    }
    dispatch(addTimesheet(timesheetDataSumitObj));
    navigate("/employee/timesheets");
    //e.preventDefault();
  }

  const onReasonUpdate = (e) => {
    setTimesheetActionFormData({ ...timesheetActionFormData, comment: e.target.value })
  }

  const onTimesheeViewActionChange = (e) => {
    setTimesheetActionFormData({ ...timesheetActionFormData, status: e.target.value, timesheet_id: timesheet_id })
    if (e.target.value === "Approved" || e.target.value === "Reject")
      setshowTimesheetRejectModal(true);
  }

  const submitTimesheetViewAction = (e) => {
    console.log(timesheetActionFormData, '------timesheet action data--')
    dispatch(updateEmployerTimesheetStatus(timesheetActionFormData));
    navigate("/employer/timesheets");
  }

  const closeModal = () => {
    setTimesheetActionFormData({ ...timesheetActionFormData, status: "" })
    setshowTimesheetRejectModal(false)
  }

  let title = timesheet_id ? 'View Timesheet' : 'Timesheet';
  const tableTHPadding = {
    padding: "0.1rem 0.75rem",
    textAlign: "center"
  };

  const tableInputPadding = {
    textAlign: "center"
  }

  const tableFootPadding = {
    textAlign: "center"
  }

  const tableBodyTd = {
    border: "none",
    textAlign: "center"
  }
  return (
    <React.Fragment>
      <div >
        <Card>

          <CardBody className="p-4">
            <div className="row">

              <div className="col-sm-12">
                <h6 className="mb-0 font-size-18" style={{ paddingBottom: "7px" }}>Weeks</h6>
                <div className="col-sm-12">

                  <Input style={{ position: "absolute", width: "23%" }}
                    id="timesheetWeek"
                    type="select"
                    onChange={(e) => getTimesheetData(e)}
                    name="timesheetWeek"
                  >
                    {allTimesheetsData?.personTimesheets?.map((item, index) => (
                      <option name={allTimesheetsData.personTimesheets[index].id + index} key={allTimesheetsData.personTimesheets[index].id + index} value={allTimesheetsData.personTimesheets[index].id}>
                        {/* {(new Date(allTimesheetsData.personTimesheets[index].from_date)).getDay()} {(new Date(allTimesheetsData.personTimesheets[index].from_date)).toLocaleString('default', { month: 'short' })}, {(new Date(allTimesheetsData.personTimesheets[index].from_date)).getFullYear()} - {(new Date(allTimesheetsData.personTimesheets[index].to_date)).getDay()} {(new Date(allTimesheetsData.personTimesheets[index].to_date)).toLocaleString('default', { month: 'short' })}, {(new Date(allTimesheetsData.personTimesheets[index].to_date)).getFullYear()} */}
                        {allTimesheetsData.personTimesheets[index].from_date} - {allTimesheetsData.personTimesheets[index].to_date}
                      </option>
                    ))}
                  </Input>
                  {/* <div className="col-sm-6" style={{ width: "18%", position: "relative", float: "right", marginBottom: "15px" }}>
                    <Input
                      id="timesheetViewPageAction"
                      type="select"
                      onChange={(e) => onTimesheeViewActionChange(e)}
                      name="timesheetViewPageAction"
                    >
                      <option value="">More Actions</option>
                      <option value="Print">Print</option>
                    </Input>
                  </div>*/}
                </div>
                <Table className="timesheet-table">
                  {/* <th style={{ width: "16%" }}>Timesheet Period <br /></th>
            <th>Client/ Vendor<br /> </th> */}

                  <th style={{ width: "25%" }}>Timesheet Period <br />{moment(selectedtimesheetDetails?.timeSheetDetails?.from_date).format("MM/DD/YYYY")} - {moment(selectedtimesheetDetails?.timeSheetDetails?.to_date).format("MM/DD/YYYY")}</th>
                  <th>Client/Vendor<br /> {selectedtimesheetDetails?.timeSheetDetails?.client}</th>
                  <th>Approved Date<br /> {moment(selectedtimesheetDetails?.timeSheetDetails?.approved_date).format("MM/DD/YYYY")}</th>
                  <th>Submitted Date<br /> {moment(selectedtimesheetDetails?.timeSheetDetails?.submitted_date).format("MM/DD/YYYY")}</th>
                  {selectedtimesheetDetails?.timeSheetDetails?.status === "Approved" ? (
                    <th>Approved By<br /> {selectedtimesheetDetails?.timeSheetDetails.approver_first_name} {selectedtimesheetDetails?.timeSheetDetails.approver_last_name} </th>
                  ) : ''}
                  <th>Status<br /> {selectedtimesheetDetails?.timeSheetDetails?.status}</th>
                  <th>Total Hours<br /> {selectedtimesheetDetails?.timeSheetDetails?.total_hours}</th>
                  
                </Table><br />
                <Row>


                  <Formik
                    initialValues={{ numOfRows: ['1'] }}
                    // onSubmit={values =>
                    //   setTimeout(() => {
                    //     alert(JSON.stringify(values, null, 2));
                    //   }, 500)
                    // }
                    render={({ values }) => (
                      <Form
                      // onSubmit={(e) => {
                      //   e.preventDefault();
                      //   onTimesheetSubmit();
                      //   return false;
                      // }}
                      >
                        <FieldArray
                          name="numOfRows"
                          render={arrayHelpers => (
                            <div>
                              {values.numOfRows && values.numOfRows.length > 0 ? (

                                <Table responsive>
                                  {values.numOfRows.map((friend, idx) => (
                                    (idx === 0) ? (
                                      <thead key={idx}>

                                        {selectedtimesheetDetails?.timeSheetDetails?.timesheet_items.map((dateItems, dateIndex) => (
                                          dateIndex == 0 ? (
                                            <React.Fragment key={dateIndex}>
                                              <tr style={{ border: "hidden" }} key={dateIndex}>
                                                <th scope="col" style={tableTHPadding}>
                                                  Projects
                                                </th>
                                                <th scope="col" style={tableTHPadding}>
                                                  Activity
                                                </th>

                                                {dateItems.columns.map((dayItem, i) => (
                                                  <th key={i} style={tableTHPadding} scope="col">
                                                    <label>{new Date(dayItem.date).getDate()}</label>
                                                  </th>
                                                ))}


                                                <th scope="col" style={tableTHPadding}>
                                                  <label>Total </label>
                                                </th>
                                                <th scope="col" style={tableTHPadding}>
                                                </th>

                                              </tr>
                                              <tr style={{ lineHeight: "0.3" }} key={idx + 1}>
                                                <th style={tableTHPadding}></th>
                                                <th style={tableTHPadding}></th>

                                                {dateItems.columns.map((item, i) => (
                                                  <th key={i} style={tableTHPadding}>
                                                    <label>{(item.date)?.substring(0, 3)}</label>
                                                  </th>
                                                ))}
                                                <th scope="col" style={tableTHPadding}>
                                                </th>
                                                {/* <th style={tableTHPadding}></th>
                                      <th ></th> */}
                                              </tr>
                                            </React.Fragment>
                                          ) : ''
                                        ))}

                                      </thead>
                                    ) : (<span key={idx}></span>)
                                  ))}

                                  <tbody>
                                    {selectedtimesheetDetails?.timeSheetDetails?.timesheet_items.map((timesheetData, index) => (
                                      <tr key={index}>
                                        <td style={tableBodyTd}>
                                          <Input
                                            readOnly
                                            type="text"
                                            value={timesheetData.project_name}
                                            name={`items.${index}.project`}
                                          >
                                          </Input>
                                        </td>
                                        <td style={tableBodyTd}>
                                          <Input
                                            readOnly
                                            type="text"
                                            value={timesheetData.activity}
                                            name={`items.${index}.activity`}
                                          >
                                          </Input>
                                        </td>

                                        <React.Fragment>
                                          {timesheetData.columns.map((timeItem, colIndex) => (
                                            <td key={colIndex} style={tableBodyTd}>
                                              <Field
                                                type="text"
                                                className="form-control"
                                                readOnly
                                                style={tableInputPadding}
                                                value={timeItem.hours}
                                                name={`timesheet_items.${colIndex}.enteredWorkhours`}
                                              />
                                            </td>
                                          ))}
                                        </React.Fragment>

                                        <td style={tableBodyTd}>
                                          <label className="col-form-label form-label" style={{ textAlign: "center" }} name={`rowWeektotalHours${index}`} >
                                            {/* {timesheetInitialState.timesheetItemsArr.timesheet_items?.[`${index}`]?.total ?? 0} */}
                                            {setRowsTotalHours[index]}
                                          </label>
                                        </td>
                                        {/* <td style={tableBodyTd}>
                                    {values.numOfRows.length > 1 ? (
                                      <i className="bx bx-trash" style={{"cursor":"pointer","margin-top":"10px"}} onClick={() => (arrayHelpers.remove(index), removeTimesheetItem(index))} ></i>
                                    ) : <span></span>}
                                  </td>
                                  <td style={tableBodyTd}>
                                    {values.numOfRows.length === index + 1 ? (
                                      <i className="bx bx-plus-circle" style={{"cursor":"pointer","margin-top":"10px"}} onClick={(e) => (arrayHelpers.push({ name: '', age: '' }), addNewTimesheetRow(index + 1))}></i>
                                    ) : (<span></span>)}
                                  </td> */}
                                      </tr>

                                    ))}

                                  </tbody>


                                  <tfoot>
                                    {/* {selectedtimesheetDetails?.timesheet_items.map((timesheetData, index2) => (  */}
                                    <tr className="table-light" >
                                      <td colSpan={1}></td>
                                      <td colSpan={1}></td>

                                      {/* {selectedtimesheetDetails?.timeSheetDetails?.timesheet_items?.map((timesheetData, index) => (
                                      <td key={index}>
                                        <label className="col-form-label form-label" style={tableFootPadding} >
                                          {colsTotalHours[index] ?? 0}
                                        </label> 
                                      </td> 
                                    ))} */}

                                      {selectedtimesheetDetails?.timeSheetDetails?.timesheet_items?.map((timesheetData, index) => (
                                        index === 0 ?
                                          timesheetData.columns?.map((timesheetData, index2) => (
                                            <td key={index2}>
                                              <label className="col-form-label form-label" style={tableFootPadding} >
                                                {colsTotalHours.columnsTotalHours[index2]}
                                              </label>
                                            </td>
                                          ))
                                          : ''))}
                                      <td>
                                        <label className="col-form-label form-label" style={tableFootPadding}>
                                          {selectedtimesheetDetails?.timeSheetDetails?.total_hours ?? 0}
                                        </label>
                                      </td>
                                      <td>

                                      </td>
                                    </tr>

                                  </tfoot>


                                </Table>

                              ) : (
                                <button type="button" onClick={() => arrayHelpers.push('')}>
                                  {/* show this when user has removed all numOfRows from the list */}
                                  Add
                                </button>
                              )}

                            </div>
                          )}
                        />
                        <br />
                        <Row className="mb-4">
                          <Col sm={12}>
                            <Label
                              htmlFor="horizontal-firstname-input"
                              className="col-form-label"
                            >
                              Attachment:&nbsp;&nbsp;&nbsp;<a target="_blank" rel="noreferrer" href={selectedtimesheetDetails?.timeSheetDetails?.add_document_proof} className="align-middle font-size-15">{selectedtimesheetDetails?.timeSheetDetails?.attachment_name}</a>
                            </Label>
                          </Col>
                        </Row>

                        {/* <Row>
                    <Col>
                      <div className="text-center mt-3">
                      <Button type="submit" color="primary" className="ms-1">
                        Post Comment
                      </Button>
                      </div>
                    </Col>
                  </Row> */}
                      </Form>
                      // <Form
                      //   onSubmit={(e) => {
                      //     e.preventDefault();
                      //     onTimesheetSubmit();
                      //     return false;
                      //   }}
                      // >
                      //   <FieldArray
                      //     name="numOfRows"
                      //     render={arrayHelpers => (
                      //       <div>
                      //         {values.numOfRows && values.numOfRows.length > 0 ? (

                      //           <Table responsive>
                      //             {values.numOfRows.map((friend, idx) => (
                      //               (idx === 0) ? (
                      //                 <thead key={idx}>

                      //                   {selectedtimesheetDetails?.timeSheetDetails?.timesheet_items.map((dateItems, dateIndex) => ( 
                      //                     dateIndex == 0 ? (
                      //                     <React.Fragment key={dateIndex}>
                      //                     <tr style={{ border: "hidden" }} key={dateIndex}>
                      //                       <th scope="col" style={tableTHPadding}>
                      //                         Projects
                      //                       </th>
                      //                       <th scope="col" style={tableTHPadding}>
                      //                         Activity
                      //                       </th>

                      //                         {dateItems.columns.map((dayItem, i) => ( 
                      //                           <th key={i} style={tableTHPadding } scope="col">
                      //                             <label>{ new Date(dayItem.date).getDate() }</label>
                      //                           </th>
                      //                         ))}


                      //                       <th scope="col" style={tableTHPadding}>
                      //                         <label>Total</label>
                      //                       </th>
                      //                       <th scope="col" style={tableTHPadding}> 
                      //                       </th>

                      //                     </tr>
                      //                     <tr style={{ lineHeight: "0.3" }} key={idx+1}>
                      //                       <th style={tableTHPadding}></th>
                      //                       <th style={tableTHPadding}></th>

                      //                         {dateItems.columns.map((item, i) => ( 
                      //                         <th key={i} style={tableTHPadding }>
                      //                           <label>{(item.date)?.substring(0, 3)}</label>
                      //                         </th>
                      //                         ))}

                      //                       {/* <th style={tableTHPadding}></th>
                      //                       <th ></th> */}
                      //                     </tr>
                      //                     </React.Fragment>
                      //                     ) : ''
                      //                   ))}

                      //                 </thead>
                      //               ) : (<span key={idx}></span>)
                      //             ))}

                      //             <tbody>
                      //             {selectedtimesheetDetails?.timeSheetDetails?.timesheet_items.map((timesheetData, index) => ( 
                      //                 <tr key={index}>
                      //                   <td style={tableBodyTd}>
                      //                     <Input
                      //                       readOnly
                      //                       type="text"
                      //                       value={timesheetData.project}
                      //                       name={`items.${index}.project`}
                      //                     >
                      //                     </Input>
                      //                   </td>
                      //                   <td style={tableBodyTd}>
                      //                     <Input
                      //                       readOnly
                      //                       type="text"
                      //                       value={timesheetData.activity}
                      //                       name={`items.${index}.activity`}
                      //                     >
                      //                     </Input>
                      //                   </td>

                      //                   <React.Fragment>
                      //                   {timesheetData.columns.map((timeItem, colIndex) => (
                      //                       <td key={colIndex} style={tableBodyTd}>
                      //                         <Field
                      //                           type="text"
                      //                           className="form-control"
                      //                           readOnly
                      //                           style={tableInputPadding}
                      //                           value={timeItem.hours}
                      //                           name={`timesheet_items.${colIndex}.enteredWorkhours`}
                      //                         />
                      //                       </td>
                      //                     ))}
                      //                   </React.Fragment>

                      //                   <td  style={tableBodyTd}>
                      //                     <label className="col-form-label form-label" style={{textAlign:"center"}} name={`rowWeektotalHours${index}`} >
                      //                       {/* {timesheetInitialState.timesheetItemsArr.timesheet_items?.[`${index}`]?.total ?? 0} */}
                      //                       {setRowsTotalHours[index]}
                      //                     </label>
                      //                   </td>
                      //                   {/* <td style={tableBodyTd}>
                      //                     {values.numOfRows.length > 1 ? (
                      //                       <i className="bx bx-trash" style={{"cursor":"pointer","margin-top":"10px"}} onClick={() => (arrayHelpers.remove(index), removeTimesheetItem(index))} ></i>
                      //                     ) : <span></span>}
                      //                   </td>
                      //                   <td style={tableBodyTd}>
                      //                     {values.numOfRows.length === index + 1 ? (
                      //                       <i className="bx bx-plus-circle" style={{"cursor":"pointer","margin-top":"10px"}} onClick={(e) => (arrayHelpers.push({ name: '', age: '' }), addNewTimesheetRow(index + 1))}></i>
                      //                     ) : (<span></span>)}
                      //                   </td> */}
                      //                 </tr>

                      //               ))}

                      //             </tbody>


                      //             <tfoot>
                      //             {/* {selectedtimesheetDetails?.timesheet_items.map((timesheetData, index2) => (  */}
                      //                 <tr className="table-light" >
                      //                     <td colSpan={1}></td>
                      //                     <td colSpan={1}></td>

                      //                     {/* {selectedtimesheetDetails?.timeSheetDetails?.timesheet_items?.map((timesheetData, index) => (
                      //                       <td key={index}>
                      //                         <label className="col-form-label form-label" style={tableFootPadding} >
                      //                           {colsTotalHours[index] ?? 0}
                      //                         </label> 
                      //                       </td> 
                      //                     ))} */}

                      //                     {selectedtimesheetDetails?.timeSheetDetails?.timesheet_items?.map((timesheetData, index) => (
                      //                       index===0 ?
                      //                       timesheetData.columns?.map((timesheetData, index2) => (
                      //                       <td key={index2}>
                      //                         <label className="col-form-label form-label" style={tableFootPadding} >
                      //                           {colsTotalHours.columnsTotalHours[index2]}
                      //                         </label> 
                      //                       </td> 
                      //                       ))
                      //                     : ''))}
                      //                     <td>
                      //                       <label className="col-form-label form-label" style={tableFootPadding}>
                      //                         {selectedtimesheetDetails?.timeSheetDetails?.total_hours ?? 0}
                      //                       </label>
                      //                     </td>
                      //                     <td>

                      //                     </td>
                      //                   </tr>

                      //             </tfoot>


                      //           </Table>

                      //         ) : (
                      //           <button type="button" onClick={() => arrayHelpers.push('')}>
                      //             {/* show this when user has removed all numOfRows from the list */}
                      //             Add
                      //           </button>
                      //         )}

                      //       </div>
                      //     )}
                      //   />
                      //   <br /><br />
                      //   <Row className="mb-4">
                      //     <Label
                      //       htmlFor="horizontal-firstname-input"
                      //       className="col-sm-2 col-form-label"
                      //     >
                      //       Attachment:
                      //     </Label>
                      //     <Col sm={5}>
                      //     <a target="_blank" rel="noreferrer" href={selectedtimesheetDetails?.timeSheetDetails?.add_document_proof} className="align-middle font-size-15">{selectedtimesheetDetails?.timeSheetDetails?.attachment_name}</a>

                      //     </Col>
                      //   </Row>

                      //   <Row>
                      //     <Col>
                      //       <div className="text-center mt-3">
                      //         <button
                      //           type="button"
                      //           className="btn btn-primary btn-lg ms-2"
                      //         >
                      //           Post Comment
                      //         </button>
                      //       </div>
                      //     </Col>
                      //   </Row>
                      // </Form>

                    )}
                  />

                </Row>
              </div></div>
          </CardBody></Card>
      </div>
      <Modal isOpen={showTimesheetRejectModal} toggle={onCloseClick} centered={true}>
        <ModalBody className="py-3 px-5">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitTimesheetViewAction();
              return false;
            }}
          >
            {(timesheetActionFormData.status === "Reject") ? (
              <React.Fragment>
                <Row>
                  <Col lg={12}>
                    {/* <div className="text-center">
                    <h4>{"Reject Timesheet"}</h4>
                  </div> */}
                  </Col>
                </Row>

                <Row>
                  <h6>{"Reject Timesheet"}</h6>
                  <Input id="reasonOfRejection" name="reasonOfRejection" type="textarea"
                    onChange={onReasonUpdate}
                  ></Input>&nbsp;
                </Row>
                <Row>
                  <Col>
                    <div className="text-center mt-3">
                      <button
                        type="submit"
                        className="ms-1 btn btn-primary btn-lg ms-2"
                      >
                        Submit
                      </button>
                    </div>
                  </Col>
                  <Col>
                    <div className="text-center mt-3">
                      <button
                        type="button"
                        className="ms-1 btn btn-primary btn-lg ms-2"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            ) :
              <React.Fragment>
                <Row>
                  <Col lg={12}>
                    <div className="text-center">
                      <h4>{"Are you sure you want to approve the Timesheet ?"}</h4>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-center mt-3">
                      <button
                        type="submit"
                        className="ms-1 btn btn-primary btn-lg ms-2"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-center mt-3">
                      <button
                        type="submit"
                        className="ms-1 btn btn-primary btn-lg ms-2"
                      >
                        Approve
                      </button>
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            }
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

