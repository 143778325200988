import React, { useEffect, useMemo, useState } from "react";
import {
  useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown
} from "reactstrap";

//redux
import { useFormik } from "formik";
import * as Yup from "yup";

import DatatableContainer from "../../../components/Common/DatatableContainer";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { AttorneyCaseDelete, changePreloader, getAttorneyCases } from "../../../store/actions";
//import { WidgetsData } from "./cards";
import {
  CaseTypeName,
  CommonCell, Date
} from "./listCol";

//redux
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";

const CaseTypesList = () => {

  //meta title
  document.title = "Attorney Cases | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [activeTab, setactiveTab] = useState("All");

  // for delete 
  const [order, setOrder] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [moveToInactiveModal, setMoveToInactiveModal] = useState(false);
  const [moveToActiveModal, setMoveToActiveModal] = useState(false);

  const { attorneycases, casesdata } = useSelector((state) => ({
    attorneycases: state.AttorneyCases.attorneycase.cases || [],
    casesdata: state.AttorneyCases.attorneycase || [],
  }));

  const { casedetails } = useSelector((state) => ({
    casedetails: state.AttorneyCases.attorneycase || [],
  }));

  const WidgetsData = [
    {
      id: 1,
      title: "TOTAL CASES",
      price1: casedetails.cases_total_count,
      rank: "See Details",
      isDoller: true,
      secondaction: "",
      statusColor: "bg-primary border-primary",
      series: [2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2, 47, 42, 15],
    },
    {
      id: 2,
      title: "QUESTIONNAIRE",
      price1: casedetails.cases_questionnaire_pending_count,
      price2: casedetails.cases_questionnaire_complete_count,
      rank: "Pending",
      isDoller: true,
      secondAction: "Complete",
      statusColor: "bg-warning border-warning",
      series: [47, 15, 2, 67, 22, 20, 36, 60, 60, 30, 50, 11, 12, 3, 8,]
    },
    {
      id: 3,
      title: "FILING STATUS",
      price1: casedetails.cases_field_count,
      price2: casedetails.cases_receipt_count,
      rank: "Filed",
      isDoller: true,
      secondAction: "Receipt",
      statusColor: "bg-info border-info",
      series: [47, 15, 2, 67, 22, 20, 36, 60, 60, 30, 50, 11, 12, 3, 8,]
    },
    {
      id: 4,
      title: "RFE",
      price1: casedetails.cases_due_count,
      price2: casedetails.cases_responded_count,
      rank: "Due",
      isDoller: true,
      secondAction: "Responded",
      statusColor: "bg-danger border-danger",
      series: [47, 15, 2, 67, 22, 20, 36, 60, 60, 30, 50, 11, 12, 3, 8,]
    },
    {
      id: 5,
      title: "DECISSION",
      price1: casedetails.cases_approved_count,
      price2: casedetails.cases_rejected_count,
      rank: "Approved",
      isDoller: true,
      secondAction: "Rejected",
      statusColor: "bg-success border-success",
      series: [47, 15, 2, 67, 22, 20, 36, 60, 60, 30, 50, 11, 12, 3, 8,]
    },

  ];

  const [contactList, setContactList] = useState({ "status": "All" });

  const [apiSuccess, setApiSuccess] = useState(false);

  useEffect(() => {
    dispatch(getAttorneyCases(contactList));
    dispatch(changePreloader(true));
    setApiSuccess(true)
  }, []);

  useEffect(() => {
    //if (apiSuccess && !_.isEmpty(casesdata)) {
    if (apiSuccess) {
      dispatch(changePreloader(false));
      setApiSuccess(false)
    }
  }, [casesdata]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleUserClicks = () => {
    navigate(`/attorney/add-case`);
  };

  const onUpdateData = (data) => {
    navigate(`/attorney/view-case?caseId=${data.case_id}`);
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setOrder(data);
  };
  const onMoveToInactive = (data) => {
    setMoveToInactiveModal(true);
    setOrder(data);
  };
  const onMoveToActive = (data) => {
    setMoveToActiveModal(true);
    setOrder(data);
  };

  const handleChangeCaseStatus = (status, processing_status = '') => {
    if (order.case_id) {
      var data = {
        "case_id": order.case_id,
        "status": status
      };
      if (processing_status != '') {
        data.processing_status = processing_status;
      }
      dispatch(AttorneyCaseDelete(data));
      setDeleteModal(false);
      setMoveToInactiveModal(false);
      setMoveToActiveModal(false);
      setTimeout(() => {
        dispatch(getAttorneyCases(contactList));
      }, 200)
    }
  };

  const onReloadData = (event) => {
    var tabInfo = event.target.value;
    setactiveTab(tabInfo);
    dispatch(getAttorneyCases({ status: tabInfo }));
  }
  const options = {
    chart: {
      height: 50,
      type: "line",
      toolbar: { show: false },
      sparkline: {
        enabled: true
      }
    },
    colors: ["#5156be"],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
    },
  };
  const columns = useMemo(
    () => [
      {
        Header: "Case",
        accessor: "case_number",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CaseTypeName {...cellProps} />;
        },
      },
      {
        Header: "Beneficiary",
        accessor: "employee_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Petitioner",
        accessor: "petitioner_company",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Case Type",
        accessor: "case_type",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Questionnaire Type",
        accessor: "qnnr_type",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Processing Status",
        accessor: "processing_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },

      {
        Header: "Case Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {(cellProps.row.original.status != "Inactive") && (
                  <>
                  <DropdownItem onClick={() => onUpdateData(cellProps.row.original)}>View</DropdownItem>
                  <DropdownItem onClick={() => onMoveToInactive(cellProps.row.original)}>Move to Inactive</DropdownItem>
                  </>
                )}
                {(cellProps.row.original.status == "Inactive") && (
                  <DropdownItem onClick={() => onMoveToActive(cellProps.row.original)}>Move to Active</DropdownItem>
                )}
                <DropdownItem onClick={() => onDeleteData(cellProps.row.original)}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      processing_status: '',
    },
    validationSchema: Yup.object({
      processing_status: Yup.string().required("Please select processing status"),
    }),
    onSubmit: (values) => {
      if (values.processing_status != '') {
        handleChangeCaseStatus("Inactive", values.processing_status)
        validation.resetForm();
      }
    }
  });

  let title = 'Cases';
  const BreadcrumbData = [
    { name: 'Dashboard', url: '/attorney/dashboard' },
    { name: title, url: '/attorney/cases' }
  ]
  return (
    <React.Fragment>
      <Modal isOpen={moveToInactiveModal}>
        <ModalHeader toggle={() => {
          validation.resetForm();
          setMoveToInactiveModal(false)
        }} tag="h4">
          Move to Inactive
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row form>

              <Col className="col-12 mb-3">
                <Label className="form-label mb-1">Processing Status</Label>
                <Input
                  type="select"
                  name="processing_status"
                  title="Select from list"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.processing_status || ""}
                  invalid={
                    validation.touched.processing_status &&
                      validation.errors.processing_status
                      ? true
                      : false
                  }
                >        <option value="">Select status</option>
                  <option selected={validation.values.processing_status === "Approved"} value="Approved">Approved</option>
                  <option selected={validation.values.processing_status === "Rejected"} value="Rejected">Rejected</option>
                  <option selected={validation.values.processing_status === "Not Required"} value="Not Required">Not Required</option>
                  <option selected={validation.values.processing_status === "Duplicate"} value="Duplicate">Duplicate</option>
                  <option selected={validation.values.processing_status === "Withdrawn"} value="Withdrawn">Withdrawn</option>

                </Input>
                {validation.touched.processing_status &&
                  validation.errors.processing_status ? (
                  <FormFeedback type="invalid">
                    {validation.errors.processing_status}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-primary save-event"
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleChangeCaseStatus("Deleted")}
        onCloseClick={() => setDeleteModal(false)}
      />
      {/* <DeleteModal
        show={moveToInactiveModal}
        onDeleteClick={() => handleChangeCaseStatus("Inactive")}
        onCloseClick={() => setMoveToInactiveModal(false)}
        message={"Move case to Inactive status"}
        status={"Inactive"}
      /> */}
      <DeleteModal
        show={moveToActiveModal}
        onDeleteClick={() => handleChangeCaseStatus("Active")}
        onCloseClick={() => setMoveToActiveModal(false)}
        message={"Move case to Active status"}
        status={"Active"}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            {(WidgetsData || []).map((widget, key) => (
              <Col style={{ width: "20%" }} key={key}>
                <Card className={widget.statusColor} style={{ "background": "#7b8190", "backgroundBorder": "#7b8190", "height": "100px" }}>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col xs={12}>
                        <span className="mb-3 lh-1 d-block text-truncate" style={{ overflow: 'visible', fontWeight: '500', "color": "#fff" }}>
                          {widget.title}
                        </span>
                        <h4 className="mb-3" style={{ "fontSize": "13px" }}>
                          {/* {widget.isDoller === true ? "$" : ""} */}
                          <div className="counter-value" style={{ "float": "left", "paddingRight": "10px", "textAlign": "center", "color": "#fff" }}>
                            {widget.price1} <br></br>
                            {widget.rank}
                          </div>
                          <div className="counter-value" style={{ "textAlign": "center", "color": "#fff" }}>
                            {widget.price2}<br></br>
                            {widget.secondAction}
                          </div>
                        </h4>

                      </Col>

                    </Row>

                  </CardBody>
                </Card>
              </Col>
            ))}

          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <DatatableContainer
                    columns={columns}
                    data={attorneycases}
                    isGlobalFilter={true}
                    isAddAttorneyCase={true}
                    isAddEmployerCase={false}
                    customPageSize={10}
                    handleInvoiceClick={handleUserClicks}
                    isAttorneyFilter={true}
                    defaultAttorneyFilter={'Active'}
                    isAttorneyFilterChange={onReloadData}
                    isExport={true}
                  />
                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CaseTypesList;
