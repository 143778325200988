import React, { useEffect, useMemo, useState } from "react";
import DatatableContainer from "../../../../components/Common/DatatableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Progress
} from "reactstrap";

import { getCaseQnnrDetails, updateAttorneyUnlockQunnr } from "../../../../store/actions";

import {
  InvoiceId,
  Date,
  BillingName,
  Amount,
  DownloadPdf
} from "./invoicelistCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";

import * as Yup from "yup";
import DeleteModal from "../../../Calendar/DeleteModal";
import moment from "moment";
import { useNavigate } from "react-router";
import { useAlert } from "../../../../components/Common/Alert";


const BeneficiaryDetails = () => {

  //meta title
  document.title = "Beneficiary Details | Immidock ";

  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [order, setOrder] = useState(null);

  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const caseId = queryParams.get('caseId'); // Read the 'id' query parameter from the URL
  const { caseQnnrDetails } = useSelector((state) => ({
    caseQnnrDetails: [state.AttorneyCases.caseQnnrDetails] || [],
  }));

  const { user_type } = useSelector((state) => ({
    user_type: state.login.user_type || localStorage.getItem('user_type'),
  }));

  const navigate = useNavigate();

const handleQuestionaireClick = (data) => {
    //console.log('data',data);
    console.log(window.location)
    navigate(`/${user_type}/questionnaire?caseId=${data.case_id}&caseNumber=${data.case_number}`);
  };
  

  // useEffect(() => {
  //   dispatch(getCaseQnnrDetails({
  //     case_id: caseId
  //   }));
  // }, [dispatch]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setOrder(data);
  };

  const handleDeleteInvoice = () => {
    if (order.id) {
      dispatch(invoiceDelete(order.id));
      setDeleteModal(false);
    }
  };

  const onClickLock = (data) =>{
    let unlock = data.is_unlock_questionaire === 1 ? 0 : 1;
    // if(unlock === 1)
    // {
    //   handleAlertOpen("Attorney Unlock questionaire successfully!",'success');
    // }
    // else
    // {
    //   handleAlertOpen("Attorney lock questionaire successfully!",'success');
    // }
    dispatch(updateAttorneyUnlockQunnr({...data,is_unlock_questionaire:unlock}))
  }

  const onUpdateData = (data) => {
    setOrder({
      id: data.id,
      Amount: data.Amount,
      color: data.color,
      date: moment(data.date).format("YYYY-MM-DD"),
      founder: data.founder,
      invoiceId: data.invoiceId,
      status: data.status,
    });

    setIsEdit(true);

    toggle();
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   Cell: () => {
      //     return <input type="checkbox" />;
      //   },
      // },
      {
        Header: "Case Type",
        accessor: "qnnr_type",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <InvoiceId {...cellProps} />;
        },
      },
      {
        Header: "Submitted By",
        accessor: "submitted_by",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Submitted",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Updated By",
        accessor: "updated_by",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Amount {...cellProps} />;
        },
      },
      {
        Header: "Updated",
        accessor: "updated_at",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Qnnr Progress",
        accessor: "qnnrprocess",
        filterable: true,
        disableFilters: true,
        Cell: (qnnrprocess) => {
          return (
            <div className="animated-progess mb-4">
              <Progress
                value={`80`}
                color="success"
              >{qnnrprocess.row.original.status}</Progress>
            </div>
          )
        },
      },
      {
        Header: "Actions",
        Cell: (cellProps) => {
          return (
            <>
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => handleQuestionaireClick(cellProps.row.original)}>View Questionnarie</DropdownItem>
              </DropdownMenu>
              {(user_type === 'attorney') &&
                <button style={{border:'none',background:'none'}} onClick={() =>onClickLock(cellProps.row.original)}><i className={`bx font-size-24 ${cellProps.row.original.is_unlock_questionaire === 1 ? 'bx-lock-open text-success' : 'bx-lock text-muted'} `}></i></button>
              }
              </UncontrolledDropdown>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">

          <DatatableContainer
                columns={columns}
                data={caseQnnrDetails}
                isSelectRow={false}
                isGlobalFilter={false}
                isAddInvoiceList={false}
                customPageSize={10}
                handleInvoiceClick={handleUserClicks}
              />

        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BeneficiaryDetails;
