import {
  GET_INVOICES,
  GET_INVOICES_FAIL,
  GET_INVOICES_SUCCESS,
  GET_INVOICE_DETAIL,
  GET_INVOICE_DETAIL_FAIL,
  GET_INVOICE_DETAIL_SUCCESS,
  GET_QUICKBOOK_CALLBACK,
  GET_QUICKBOOK_CALLBACK_FAIL,
  GET_QUICKBOOK_CALLBACK_SUCCESS,
  GET_INVOICE_SETTINGS,
  GET_INVOICE_SETTINGS_FAIL,
  GET_INVOICE_SETTINGS_SUCCESS,
  GET_CREATE_INVOICE_SETTINGS,
  GET_CREATE_INVOICE_SETTINGS_FAIL,
  GET_CREATE_INVOICE_SETTINGS_SUCCESS,
  GET_QUICKBOOK_REFRESH,
  GET_QUICKBOOK_REFRESH_FAIL,
  GET_QUICKBOOK_REFRESH_SUCCESS,
  GET_QUICKBOOK_SYNC_INVOICE,
  GET_QUICKBOOK_SYNC_INVOICE_FAIL,
  GET_QUICKBOOK_SYNC_INVOICE_SUCCESS,
  UPDATE_INVOICE_SETTINGS,
  UPDATE_INVOICE_SETTINGS_FAIL,
  UPDATE_INVOICE_SETTINGS_SUCCESS,
  CONNECT_QUICKBOOKS,
  CONNECT_QUICKBOOKS_FAIL,
  CONNECT_QUICKBOOKS_SUCCESS,
  UPLOAD_INVOICE_ATTACHMENT,
  UPLOAD_INVOICE_ATTACHMENT_FAIL,
  UPLOAD_INVOICE_ATTACHMENT_SUCCESS,
  UPDATE_INVOICE_DETAIL_FAIL,
  UPDATE_INVOICE_DETAIL_SUCCESS,
  UPDATE_INVOICE_DETAIL,
  DELETE_INVOICE_DETAIL_FAIL,
  DELETE_INVOICE_DETAIL_SUCCESS,
  DELETE_INVOICE_DETAIL,
  ADD_INVOICE_DETAIL_SUCCESS,
  ADD_INVOICE_DETAIL,
  ADD_INVOICE_DETAIL_FAIL,
  SEND_INVOICE_SUCCESS,
  SEND_INVOICE,
  SEND_INVOICE_FAIL,
  UPDATE_INVOICE_PDF_SUCCESS,
  UPDATE_INVOICE_PDF,
  UPDATE_INVOICE_PDF_FAIL,
  RECORD_PAYMENT_SUCCESS,
  RECORD_PAYMENT,
  RECORD_PAYMENT_FAIL,
  GET_INVOICE_TIMESHEET_DETAIL,
  GET_INVOICE_TIMESHEET_DETAIL_FAIL,
  GET_INVOICE_TIMESHEET_DETAIL_SUCCESS,
  GET_QUICKBOOK_STATUS,
  GET_QUICKBOOK_STATUS_SUCCESS,
  GET_QUICKBOOK_STATUS_FAIL,

} from "./actionTypes"

export const getInvoices = (data) => ({
  type: GET_INVOICES,
  payload: data
})

export const getInvoicesSuccess = invoices => ({
  type: GET_INVOICES_SUCCESS,
  payload: invoices,
})

export const getInvoicesFail = error => ({
  type: GET_INVOICES_FAIL,
  payload: error,
})

export const getInvoiceDetail = invoiceId => ({
  type: GET_INVOICE_DETAIL,
  invoiceId,
})

export const getInvoiceDetailSuccess = invoices => ({
  type: GET_INVOICE_DETAIL_SUCCESS,
  payload: invoices,
})

export const getInvoiceDetailFail = error => ({
  type: GET_INVOICE_DETAIL_FAIL,
  payload: error,
})

export const sendInvoice = (data) => ({
  type: SEND_INVOICE,
  payload: data
})

export const sendInvoiceSuccess = (data) => ({
  type: SEND_INVOICE_SUCCESS,
  payload: data
})

export const sendInvoiceFail = (error) => ({
  type: SEND_INVOICE_FAIL,
  payload: error
})

export const updateInvoicePdf = (data) => ({
  type: UPDATE_INVOICE_PDF,
  payload: data
})

export const updateInvoicePdfSuccess = (data) => ({
  type: UPDATE_INVOICE_PDF_SUCCESS,
  payload: data
})

export const updateInvoicePdfFail = (error) => ({
  type: UPDATE_INVOICE_PDF_FAIL,
  payload: error
})

export const recordPayment = (data) => ({
  type: RECORD_PAYMENT,
  payload: data
})

export const recordPaymentSuccess = (data) => ({
  type: RECORD_PAYMENT_SUCCESS,
  payload: data
})

export const recordPaymentFail = (error) => ({
  type: RECORD_PAYMENT_FAIL,
  payload: error
})

export const addInvoiceDetail = (data) => ({
  type: ADD_INVOICE_DETAIL,
  payload: data
})

export const addInvoicedetailSuccess = (data) => ({
  type: ADD_INVOICE_DETAIL_SUCCESS,
  payload: data
})

export const addInvoicedetailFail = (error) => ({
  type: ADD_INVOICE_DETAIL_FAIL,
  payload: error
})

export const getInvoiceTimesheetDetails = (data) => ({
  type: GET_INVOICE_TIMESHEET_DETAIL,
  payload: data
})

export const getInvoiceTimesheetDetailsSuccess = (data) => ({
  type: GET_INVOICE_TIMESHEET_DETAIL_SUCCESS,
  payload: data
})

export const getInvoiceTimesheetDetailsFail = (error) => ({
  type: GET_INVOICE_TIMESHEET_DETAIL_FAIL,
  payload: error
})


// Get Quickbook quickbook-refresh
export const getQuickbookRefresh = (data) => ({
  type: GET_QUICKBOOK_REFRESH,
  payload: data
})

export const getQuickbookRefreshSuccess = (data) => ({
  type: GET_QUICKBOOK_REFRESH_SUCCESS,
  payload: data
})

export const getQuickbookRefreshFail = (error) => ({
  type: GET_QUICKBOOK_REFRESH_FAIL,
  payload: error
})
// Get Quickbook quickbook-status
export const getQuickbookStatus = (data) => ({
  type: GET_QUICKBOOK_STATUS,
  payload: data
})

export const getQuickbookStatusSuccess = (data) => ({
  type: GET_QUICKBOOK_STATUS_SUCCESS,
  payload: data
})

export const getQuickbookStatusFail = (error) => ({
  type: GET_QUICKBOOK_STATUS_FAIL,
  payload: error
})

// Get Quickbook quickbook-sync-invoice
export const getQuickbookSyncInvoice = (data) => ({
  type: GET_QUICKBOOK_SYNC_INVOICE,
  payload: data
})

export const getQuickbookSyncInvoiceSuccess = (data) => ({
  type: GET_QUICKBOOK_SYNC_INVOICE_SUCCESS,
  payload: data
})

export const getQuickbookSyncInvoiceFail = (error) => ({
  type: GET_QUICKBOOK_SYNC_INVOICE_FAIL,
  payload: error
})

// Get Quickbook call back

export const getQuickbookCallback = (data) => ({
  type: GET_QUICKBOOK_CALLBACK,
  payload: data
})

export const getQuickbookCallbackSuccess = (data) => ({
  type: GET_QUICKBOOK_CALLBACK_SUCCESS,
  payload: data
})

export const getQuickbookCallbackFail = (error) => ({
  type: GET_QUICKBOOK_CALLBACK_FAIL,
  payload: error
})

// Get Invoice settings for common invoice

export const getInvoiceSettings = (data) => ({
  type: GET_INVOICE_SETTINGS,
  payload: data
})

export const getInvoiceSettingsSuccess = (data) => ({
  type: GET_INVOICE_SETTINGS_SUCCESS,
  payload: data
})

export const getInvoiceSettingsFail = (error) => ({
  type: GET_INVOICE_SETTINGS_FAIL,
  payload: error
})

// Get Invoice settings for create invoice
export const getCreateInvoiceSettings = (data) => ({
  type: GET_CREATE_INVOICE_SETTINGS,
  payload: data
})

export const getCreateInvoiceSettingsSuccess = (data) => ({
  type: GET_CREATE_INVOICE_SETTINGS_SUCCESS,
  payload: data
})

export const getCreateInvoiceSettingsFail = (error) => ({
  type: GET_CREATE_INVOICE_SETTINGS_FAIL,
  payload: error
})

export const updateInvoiceSettings = (data) => ({
  type: UPDATE_INVOICE_SETTINGS,
  payload: data
})

export const updateInvoiceSettingsSuccess = (data) => ({
  type: UPDATE_INVOICE_SETTINGS_SUCCESS,
  payload: data
})

export const updateInvoiceSettingsFail = (error) => ({
  type: UPDATE_INVOICE_SETTINGS_FAIL,
  payload: error
})

export const connectQuickbooks = (data) => ({
  type: CONNECT_QUICKBOOKS,
  payload: data
})

export const connectQuickbooksSuccess = (data) => ({
  type: CONNECT_QUICKBOOKS_SUCCESS,
  payload: data
})

export const connectQuickbooksFail = (error) => ({
  type: CONNECT_QUICKBOOKS_FAIL,
  payload: error
})

export const uploadInvoiceAttachment = (data) => ({
  type: UPLOAD_INVOICE_ATTACHMENT,
  payload: data
})

export const uploadInvoiceAttachmentSuccess = (data) => ({
  type: UPLOAD_INVOICE_ATTACHMENT_SUCCESS,
  payload: data
})

export const uploadInvoiceAttachmentFail = (error) => ({
  type: UPLOAD_INVOICE_ATTACHMENT_FAIL,
  payload: error
})

export const invoiceDelete = (data) => ({
  type: DELETE_INVOICE_DETAIL,
  payload: data
})

export const invoiceDeleteSuccess = (data) => ({
  type: DELETE_INVOICE_DETAIL_SUCCESS,
  payload: data
})

export const invoiceDeleteFail = (error) => ({
  type: DELETE_INVOICE_DETAIL_FAIL,
  payload: error
})

export const invoiceUpdate = (data) => ({
  type: UPDATE_INVOICE_DETAIL,
  payload: data
})

export const invoiceUpdateSuccess = (data) => ({
  type: UPDATE_INVOICE_DETAIL_SUCCESS,
  payload: data
})

export const invoiceUpdateFail = (error) => ({
  type: UPDATE_INVOICE_DETAIL_FAIL,
  payload: error
})
