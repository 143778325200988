import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard/index";

// Calendar
import Calendar from "../pages/Calendar/index";

//Chat
import Chat from "../pages/Chat/Chat";

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";

//Invoice
import InvoicesList from "../pages/Invoices/invoices-list";
import InvoiceDetail from "../pages/Invoices/invoices-detail";

//Contact
/*import ContactsGrid from "../pages/Contacts/contacts-grid";
import ContactsList from "../pages/Contacts/ContactList/contacts-list";
import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile";*/

//blog
import BlogGrid from "../pages/Blog/blogGrid"
import BlogList from "../pages/Blog/blogList"
import BlogDetails from "../pages/Blog/blogDetails"

//Utility
import PagesStarter from "../pages/Utility/StarterPage";
import PageMaintenance from "../pages/Utility/PageMaintenance";
import PagesComingsoon from "../pages/Utility/PageComingsoon";
import PageTimeline from "../pages/Utility/PageTimeline";
import PageFaqs from "../pages/Utility/PageFaqs";
import PagePricing from "../pages/Utility/PagePricing/index";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

// Ui Components
import UiAlert from "../pages/UiElements/UiAlert";
import UiButton from "../pages/UiElements/UiButton";
import UiCard from "../pages/UiElements/UiCard";
import UiCarousel from "../pages/UiElements/UiCarousel";
import UiDropdowns from "../pages/UiElements/UiDropdowns";
import UiGrid from "../pages/UiElements/UiGrid";
import UiImages from "../pages/UiElements/UiImages";
import UiModal from "../pages/UiElements/UiModals";
import UiOffCanvas from "../pages/UiElements/UiOffCanvas";
import UiProgressbar from "../pages/UiElements/UiProgressbar";
import UiPlaceholders from "../pages/UiElements/UiPlaceholders";
import UiTabsAccordions from "../pages/UiElements/UiTabsAccordions";
import UiTypography from "../pages/UiElements/UiTypography";
import UiToasts from "../pages/UiElements/UiToast";
import UiVideo from "../pages/UiElements/UiVideo";
import UiGeneral from "../pages/UiElements/UiGeneral";
import UiColors from "../pages/UiElements/UiColors";
import UiUtilities from "../pages/UiElements/UiUtilities";

//Extended pages
import Lightbox from "../pages/Extended/Lightbox";
import Rangeslider from "../pages/Extended/Rangeslider";
import SessionTimeout from "../pages/Extended/SessionTimeout";
import UiRating from "../pages/Extended/UiRating";
import Notifications from "../pages/Extended/Notifications";

//Forms
import FormElements from "../pages/Forms/FormElements/index";
import FormValidation from "../pages/Forms/FormValidation/";
import AdvancedPlugins from "../pages/Forms/AdvancedPlugins";
import FormEditors from "../pages/Forms/FormEditors";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormMask from "../pages/Forms/FormMask";

//Tables
import BasicTable from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

//Charts
import Apexchart from "../pages/Charts/Apexcharts";
import EChart from "../pages/Charts/EChart";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import KnobCharts from "../pages/Charts/KnobCharts";
import SparklineChart from "../pages/Charts/SparklineChart";

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconFontawesomes from "../pages/Icons/Fontawesomes";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";
import MapsLeaflet from "../pages/Maps/MapsLeaflet";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//AuthenticationInner related pages
import PageLogin from "../pages/AuthenticationInner/PageLogin";
import PageRegister from "../pages/AuthenticationInner/PageRegister";
import RecoverPassword from "../pages/AuthenticationInner/RecoverPassword";
import LockScreen from "../pages/AuthenticationInner/LockScreen";
import ConfirmMail from "../pages/AuthenticationInner/ConfirmMail";
import EmailVerification from "../pages/AuthenticationInner/EmailVerification";
import TwoStepVerfication from "../pages/AuthenticationInner/TwoStepVerfication";
import AuthLogout from "../pages/AuthenticationInner/Logout";
import UserProfile from "../pages/Authentication/user-profile";


import CaseTypes from '../pages/Admin/CaseTypes';
import QnnrTypes from '../pages/Admin/QnnrTypes';
import QnnrAttributes from '../pages/Admin/QnnrAttributes';
import QnnrAttributeSets from '../pages/Admin/QnnrAttributeSets';
import QnnrAttributeGroups from '../pages/Admin/QnnrAttributeGroups';
import QnnrTemplates from '../pages/Admin/QnnrTemplates';
import Employers from '../pages/Admin/Employers';
import AddCaseTypeform from '../pages/Admin/CaseTypes/add';
import ViewCase from '../pages/Attorney/CaseTypes/viewCase';
import AddQnnrform from '../pages/Admin/QnnrTypes/add';
import AddQnnrAttributeform from '../pages/Admin/QnnrAttributes/add';
import AddQnnrAttributeGroupForm from '../pages/Admin/QnnrAttributeGroups/add';
import AddQnnrAttributeSetForm from '../pages/Admin/QnnrAttributeSets/add';
import AddQnnrTemplateForm from '../pages/Admin/QnnrTemplates/add';
import Attorneys from '../pages/Admin/Attorneys';
import AddAttorneyform from '../pages/Admin/Attorneys/add';
import AddAttorneyCase from '../pages/Attorney/CaseTypes/addCase';



import AttorneyCompanyInfo from '../pages/Attorney/CompanyInfo';
import AddAttorneyCompanyInfo from '../pages/Attorney/CompanyInfo/add';

import AttorneyDashboard from '../pages/Attorney/Dashboard';
import AttorneyCases from '../pages/Attorney/CaseTypes';
import AttorneyContacts from '../pages/Attorney/Contacts';
import AddAttorneyContacts from '../pages/Attorney/Contacts/add';
import UpdateAttorneyContacts from '../pages/Attorney/Contacts/updateContact';
import AttorneyReminders from '../pages/Attorney/Reminders';
import AttorneyTeams from '../pages/Attorney/Team';
import AttorneyEvents from '../pages/Attorney/Events';
import AttorneyInvoices from '../pages/Attorney/Invoices';
import Payroll from '../pages/Attorney/Payroll';
import CreatePayroll from '../pages/Attorney/Payroll/CreatePayroll';
import PayrollView from '../pages/Attorney/Payroll/PayrollView';
import CreateInvoice from '../pages/Attorney/Invoices/CreateInvoice';
import AttorneyLearningCenter from '../pages/Attorney/LearningCenter';
import AttorneySupport from '../pages/Attorney/Support';
import UpdateCaseDetails from '../pages/Attorney/CaseTypes/CaseDetails/UpdateCaseDetails';
import UpdateLCADetails from '../pages/Attorney/CaseTypes/CaseDetails/UpdateLCADetails';
import AddForm from '../pages/Attorney/CaseTypes/CaseDetails/AddForm';
import ViewForm from '../pages/Attorney/CaseTypes/CaseDetails/ViewForm';
import MasterDocs from '../pages/Attorney/MasterDocs';


import ContactsGrid from "../pages/Contacts/contacts-grid";
import ContactsList from "../pages/Contacts/ContactList/contacts-list";
import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile";

// Case forms
import CaseForms from "../pages/Admin/CaseForms";
import CaseFormAdd from "../pages/Admin/CaseForms/add";
import CaseFormAttributes from "../pages/Admin/CaseFormAttributes";
import AddCaseFormAttributes from "../pages/Admin/CaseFormAttributes/add";
import CaseFormAttributeSets from "../pages/Admin/CaseFormAttributeSets";
import AddCaseFormAttributeSets from "../pages/Admin/CaseFormAttributeSets/add";
import CaseFormAttributesGroups from "../pages/Admin/CaseFormAttributesGroups";
import CaseFormAttributeTemplates from "../pages/Admin/CaseFormAttributesTemplates";
import AddCaseformAttributeGroups from "../pages/Admin/CaseFormAttributesGroups/add";
import AddCaseformAttributeTemplates from "../pages/Admin/CaseFormAttributesTemplates/add";
import EditEmployer from "../pages/Admin/Employers/editEmployer";

/*EMPLOYER PAGES*/
import EmployerCompanyInfo from '../pages/Employer/CompanyInfo';
import AddEmployerCompanyInfo from '../pages/Employer/CompanyInfo/add';
import EmployerDashboard from '../pages/Employer/Dashboard';
import EmployerBasicDashboard from '../pages/Employer/Basic';
import Esignature from '../pages/Employer/Profile';
import ShowEsign from '../pages/Employer/Profile/showEsign';
import EmployerCases from '../pages/Employer/Cases';
import EmployerPaf from '../pages/Employer/Paf';
import EmployerPafForms from '../pages/Employer/Paf/ViewPafForms';
import EmployerPafForm from '../pages/Employer/Paf/ViewPafForm';
import AddEmployerCase from '../pages/Employer/Cases/add';
import EmployerContacts from '../pages/Employer/Contacts';
import AddEmployerContact from '../pages/Employer/Contacts/add';

import EmployerReminders from '../pages/Employer/Reminders';
import EmployerTeams from '../pages/Employer/Team';
import EmployerEvents from '../pages/Employer/Events';
import EmployerInvoices from '../pages/Employer/Invoices';
import EmployerCreateInvoice from '../pages/Employer/Invoices/CreateInvoice';
import EmployerInvoiceSettings from '../pages/Employer/Invoices/InvoiceSettings';
import EmployerQuickbookCallback from '../pages/Employer/Invoices/QuickbookCallback';
import EmployerViewInvoice from '../pages/Employer/Invoices/ViewInvoice';
import EmployerEditInvoice from '../pages/Employer/Invoices/EditInvoice';
import EmployerDownloadInvoice from '../pages/Employer/Invoices/DownloadInvoice';

//Users
import CommonUsers from '../pages/Common/Users';
import CreateCommonUser from '../pages/Common/Users/CreateUser';

//Profile
import ChangePassword from '../pages/Common/Profile/ChangePassword';
import EmployerSettings from '../pages/Common/Profile/Settings';

/* Below components for Employer Full suite/Basic suite */
import EmployerMainInvoices from "../pages/Employer/MainComponents/Invoices";
import EmployerMainPayroll from "../pages/Employer/MainComponents/Payroll";
import EmployerMainTimesheets from "../pages/Employer/MainComponents/Timesheets";
import EmployerMainInvoiceSettings from "../pages/Employer/MainComponents/InvoiceSettings";
import EmployerMainTimesheetView from "../pages/Employer/MainComponents/TimesheetView";
import EmployerMainPayrollCreate from "../pages/Employer/MainComponents/PayrollCreate";
import EmployerMainPayrollView from "../pages/Employer/MainComponents/PayrollView";
import EmployerMainInvoiceCreate from "../pages/Employer/MainComponents/InvoiceCreate";
import EmployerMainInvoiceView from "../pages/Employer/MainComponents/InvoiceView";
import EmployerMainInvoiceEdit from "../pages/Employer/MainComponents/InvoiceEdit";
import EmployerMainInvoiceDownload from "../pages/Employer/MainComponents/InvoiceDownload";

//Customers
import EmployerCustomers from '../pages/Employer/Customers';
import EmployerCreateCustomer from '../pages/Employer/Customers/CreateCustomer';
import EmployerCreatePaf from '../pages/Employer/Paf/CreatePaf';
import EmployerViewCustomer from '../pages/Employer/Customers/ViewCustomer';
import EmployerEditCustomer from '../pages/Employer/Customers/EditCustomer';

//Customers
import EmployerTemplates from '../pages/Employer/Templates';


import EmployerLearningCenter from '../pages/Employer/LearningCenter';
import EmployerSupport from '../pages/Employer/Support';

/*EMPLOYEE PAGES */
import EmployeeDashboard from '../pages/Employee/Dashboard';
import EmployeeCases from '../pages/Employee/Cases';
import EmployeeQuestionnaire from '../components/Common/EmployeeQuestionnaire';
import EmployeeLearningCenter from '../pages/Employee/LearningCenter';
import EmployeeSupport from '../pages/Employee/Support';
import EmployeeTimesheets from '../pages/Employee/Timesheets';
import AddTimesheets from '../pages/Employee/Timesheets/add';
import Documents from "../pages/Employee/Documents";
import Messages from "../pages/Employee/Messages";

/* Employer Timesheets */
import EmployerTimesheets from '../pages/Employer/Timesheets';
import EmployerTimesheetView from '../pages/Employer/Timesheets/TimesheetView';
import { User } from "feather-icons-react/build/IconComponents";
import PafEsignForm from "../pages/Employee/Dashboard/pafEsignForm";
import ViewTemplate from "../pages/Employer/Templates/ViewTemplate";


const userRoutes = [
  /* ADMIN ROUTES*/
  //dashboard
  { path: "/admin/dashboard", component: <Dashboard />, role: "admin" },
  // { path: "/dashboard", component: <Dashboard /> },
  { path: "/admin/attorneys", component: <Attorneys />, role: "admin" },
  { path: "/admin/add-attorney", component: <AddAttorneyform />, role: "admin" },

  //case-types
  { path: "/admin/case-types", component: <CaseTypes />, role: "admin" },
  { path: "/admin/add-case-types", component: <AddCaseTypeform />, role: "admin" },
  { path: "/admin/employers", component: <Employers />, role: "admin" },
  //{ path: "/admin/add-case-types", component: <AddEmployer />,role:"admin" },
  //QnnrTypes
  { path: "/admin/questionnaire-types", component: <QnnrTypes />, role: "admin" },
  { path: "/admin/add-questionnaire-types", component: <AddQnnrform />, role: "admin" },
  //QnnrAttributes
  { path: "/admin/questionnaire-attributes", component: <QnnrAttributes />, role: "admin" },
  { path: "/admin/add-questionnaire-attributes", component: <AddQnnrAttributeform />, role: "admin" },
  //QnnrAttributeSets
  { path: "/admin/questionnaire-attributes-sets", component: <QnnrAttributeSets />, role: "admin" },
  { path: "/admin/add-questionnaire-attributes-sets", component: <AddQnnrAttributeSetForm />, role: "admin" },
  //QnnrAttributeGroups
  { path: "/admin/questionnaire-attributes-groups", component: <QnnrAttributeGroups />, role: "admin" },
  { path: "/admin/add-questionnaire-attributes-groups", component: <AddQnnrAttributeGroupForm />, role: "admin" },
  //QnnrTemplates
  { path: "/admin/questionnaire-templates", component: <QnnrTemplates />, role: "admin" },
  { path: "/admin/add-questionnaire-templates", component: <AddQnnrTemplateForm />, role: "admin" },

  /* ATTORNEY ROUTES*/
  //dashboard
  { path: "/attorney/dashboard", component: <AttorneyDashboard />, role: "attorney" },
  { path: "/attorney/", component: <AttorneyDashboard />, role: "attorney" },
  { path: "/attorney/companyinfo", component: <AttorneyCompanyInfo />, role: "attorney" },
  { path: "/attorney/add-company-info", component: <AddAttorneyCompanyInfo />, role: "attorney" },
  { path: "/attorney/view-case", component: <ViewCase />, role: "attorney" },
  { path: "/attorney/update-case-details", component: <UpdateCaseDetails />, role: "attorney" },
  { path: "/attorney/update-lca-details", component: <UpdateLCADetails />, role: "attorney" },
  { path: "/attorney/add-form", component: <AddForm />, role: "attorney" },
  { path: "/attorney/view-form", component: <ViewForm />, role: "attorney" },
  { path: "/attorney/master-docs", component: <MasterDocs />, role: "attorney" },

  //Invoices
  { path: "/attorney/invoices", component: <AttorneyInvoices />, role: "attorney" },
  { path: "/attorney/create-invoice", component: <CreateInvoice />, role: "attorney" },
  { path: "/attorney/view-invoice", component: <CreateInvoice />, role: "attorney" },

  //Payroll
  { path: "/attorney/payroll", component: <Payroll />, role: "attorney" },
  { path: "/attorney/create-payroll", component: <CreatePayroll />, role: "attorney" },
  { path: "/attorney/payroll-employees-view", component: <PayrollView />, role: "attorney" },


  //case-types
  { path: "/attorney/contacts", component: <AttorneyContacts />, role: "attorney" },
  { path: "/attorney/add-contact", component: <AddAttorneyContacts />, role: "attorney" },
  { path: "/attorney/update-employer-contacts", component: <UpdateAttorneyContacts />, role: "attorney" },
 
  //QnnrTypes
  { path: "/attorney/cases", component: <AttorneyCases />, role: "attorney" },
  { path: "/attorney/add-employment-case", component: <AddQnnrform />, role: "attorney" },
  { path: "/attorney/add-case", component: <AddAttorneyCase />, role: "attorney" },
  
  //QnnrAttributes
  { path: "/attorney/reminders", component: <AttorneyReminders />, role: "attorney" },
  { path: "/attorney/add-reminders", component: <AddQnnrAttributeform />, role: "attorney" },
  //QnnrAttributeSets
  { path: "/attorney/team", component: <AttorneyTeams />, role: "attorney" },
  { path: "/attorney/add-team", component: <AddQnnrAttributeSetForm />, role: "attorney" },
  //QnnrAttributeGroups
  { path: "/attorney/events", component: <AttorneyEvents />, role: "attorney" },
  { path: "/attorney/add-event", component: <AddQnnrAttributeGroupForm />, role: "attorney" },
  //QnnrTemplates
  { path: "/admin/add-invoice", component: <AddQnnrTemplateForm />, role: "attorney" },
  { path: "/attorney/learningcenter", component: <AttorneyLearningCenter />, role: "attorney" },
  { path: "/attorney/support", component: <AttorneySupport />, role: "attorney" },
  { path: "/attorney/add-support", component: <AddQnnrTemplateForm />, role: "attorney" },
  { path: "/attorney/questionnaire", component: <EmployeeQuestionnaire />, role: "attorney" },

  /* EMPLOYER ROUTES*/
  //dashboard
  { path: "/employer/dashboard", component: <EmployerDashboard /> ,role:"employer"},
  { path: "/employer/", component: <EmployerDashboard />,role:"employer" },
  { path: "/employer/companyinfo", component: <AddEmployerCompanyInfo />,role:"employer" },
  { path: "/employer/view-case", component: <ViewCase />,role:"employer" },
  { path: "/employer/update-case-details", component: <UpdateCaseDetails />,role:"employer" },
  { path: "/employer/update-lca-details", component: <UpdateLCADetails />,role:"employer" },
  { path: "/employer/add-form", component: <AddForm />,role:"employer" },
  { path: "/employer/view-timesheet", component: <EmployerMainTimesheetView />,role:"employer" },
  { path: "/employer/timesheets", component: <EmployerMainTimesheets />,role:"employer" },


  { path: "/employer/create-esignature", component: <Esignature />, role: "employer" },
  { path: "/employer/esignature", component: <ShowEsign />, role: "employer" },



  { path: "/employer/cases", component: <EmployerCases />, role: "employer" },
  { path: "/employer/paf", component: <EmployerPaf />, role: "employer" },
  { path: "/employer/paf-forms", component: <EmployerPafForms />, role: "employer" },
  { path: "/employer/paf-content-view", component: <EmployerPafForm />, role: "employer" },
  { path: "/employer/add-employer-case", component: <AddEmployerCase />, role: "employer" },
  { path: "/employer/contacts", component: <EmployerContacts />, role: "employer" },
  { path: "/employer/add-employer-contact", component: <AddEmployerContact />, role: "employer" },
  //QnnrAttributes
  { path: "/employer/reminders", component: <AttorneyReminders />, role: "employer" },
  //QnnrAttributeSets
  { path: "/employer/employeelist", component: <EmployerContacts />, role: "employer" },
  //QnnrAttributeGroups
  { path: "/employer/learningcenter", component: <AttorneyEvents />, role: "employer" },
  //Employer Invoices
  { path: "/employer/invoices", component: <EmployerMainInvoices />, role: "employer" },
  { path: "/employer/payroll", component: <EmployerMainPayroll />, role: "employer" },
  { path: "/employer/payroll-employees-view", component: <EmployerMainPayrollView />, role: "employer" },
  { path: "/employer/create-payroll", component: <EmployerMainPayrollCreate />, role: "employer" },
  { path: "/employer/create-invoice", component: <EmployerMainInvoiceCreate />, role: "employer" },
  { path: "/employer/invoice-settings", component: <EmployerMainInvoiceSettings />, role: "employer" },
  { path: "/employer/quickbook-callback", component: <EmployerQuickbookCallback />, role: "employer" },
  { path: "/employer/view-invoice", component: <EmployerMainInvoiceView />, role: "employer" },
  { path: "/employer/edit-invoice", component: <EmployerMainInvoiceEdit />, role: "employer" },
  { path: "/employer/download-invoice", component: <EmployerMainInvoiceDownload />, role: "employer" },
  { path: "/employer/support", component: <AttorneySupport />, role: "employer" },

  // Employe Customers

  //{ path: "/employer/customers", component: <EmployerCustomers /> ,role:"employer"},
  // { path: "/employer/customers", component: <EmployerCustomers /> ,role:"employer"},
  // { path: "/employer/create-customer", component: <EmployerCreateCustomer />,role:"employer" },
  // { path: "/employer/edit-customer", component: <EmployerCreateCustomer /> ,role:"employer"},
  // { path: "/employer/view-customer", component: <EmployerViewCustomer /> ,role:"employer"},

  { path: "/employer/questionnaire", component: <EmployeeQuestionnaire />, role: "employer" },
  // Employer Templates
  { path: "/employer/templates", component: <EmployerTemplates />, role: "employer" },
  // Employer Customers
  { path: "/employer/customers", component: <EmployerCustomers />, role: "employer" },
  { path: "/employer/create-customer", component: <EmployerCreateCustomer />, role: "employer" },
  { path: "/employer/edit-customer", component: <EmployerCreateCustomer />, role: "employer" },
  { path: "/employer/view-customer", component: <EmployerViewCustomer />, role: "employer" },

  /** USERS ROUTES*/
  // Attorney Users
  { path: "/attorney/users", component: <CommonUsers />, role: "attorney" },
  { path: "/attorney/create-user", component: <CreateCommonUser />, role: "attorney" },
  { path: "/attorney/edit-user", component: <CreateCommonUser />, role: "attorney" },
  { path: "/attorney/view-user", component: <CreateCommonUser />, role: "attorney" },

  // Employer Users
  { path: "/employer/users", component: <CommonUsers />, role: "employer" },
  { path: "/employer/create-user", component: <CreateCommonUser />, role: "employer" },
  { path: "/employer/edit-user", component: <CreateCommonUser />, role: "employer" },
  { path: "/employer/view-user", component: <CreateCommonUser />, role: "employer" },
  { path: "/employer/edit-template", component: <ViewTemplate />, role: "employer" },

  { path: "/attorney/change-password", component: <ChangePassword />, role: "attorney" },
  { path: "/employee/change-password", component: <ChangePassword />, role: "employee" },
  { path: "/employer/change-password", component: <ChangePassword />, role: "employer" },
  { path: "/employer/settings", component: <EmployerSettings />, role: "employer" },

  /* EMPLOYEE ROUTES*/
  //dashboard
  { path: "/employee/dashboard", component: <EmployeeDashboard />, role: "employee" },
  { path: "/employee/", component: <EmployeeDashboard />, role: "employee" },
  { path: "/employee/cases", component: <EmployeeCases />, role: "employee" },
  { path: "/employee/questionnaire", component: <EmployeeQuestionnaire />, role: "employee" },
  { path: "/employee/learningcenter", component: <EmployeeLearningCenter />, role: "employee" },
  { path: "/employee/support", component: <EmployeeSupport />, role: "employee" },
  { path: "/employee/create-esignature", component: <Esignature />, role: "employee" },
  { path: "/employee/esignature", component: <ShowEsign />, role: "employee" },
  { path: "/employee/documents", component: <Documents />, role: "employee" },
  { path: "/employee/messages", component: <Messages />, role: "employee" },
  
  // Employee Timesheets
  { path: "/employee/timesheets", component: <EmployeeTimesheets /> ,role:"employee"},
  { path: "/employee/timesheets/add", component: <AddTimesheets />,role:"employee" },
 
  { path: "/admin/employers", component: <CaseTypes /> ,role:"admin"},
  { path: "/admin/add-employer", component: <AddCaseTypeform />,role:"admin" },
  { path: "/admin/editEmployer", component: <EditEmployer/>, role:"admin"},

  //profile
  { path: "/employee/profile", component: <UserProfile />, role: "employee" },
  { path: "/employee/paf-esign-form", component: <PafEsignForm />, role: "employee" },

  //Calendar
  // { path: "/apps-calendar", component: <Calendar /> },

  //Chat
  // { path: "/apps-chat", component: <Chat /> },

  //Email
  // { path: "/email-inbox", component: <EmailInbox /> },
  // { path: "/email-read", component: <EmailRead /> },

  //Invoice
  { path: "/employer/invoices-list", component: <InvoicesList />, role: "employer" },
  { path: "/employer/invoices-detail", component: <InvoiceDetail />, role: "employer" },

  //Contact
  // { path: "/contacts-grid", component: <ContactsGrid /> },
  // { path: "/contacts-list", component: <ContactsList /> },
  // { path: "/contacts-profile", component: <ContactsProfile /> },

  //blog
  // { path: "/blog-grid", component: <BlogGrid /> },
  // { path: "/blog-list", component: <BlogList /> },
  // { path: "/blog-details", component: <BlogDetails /> },

  //Utility
  // { path: "/pages-starter", component: <PagesStarter /> },
  // { path: "/pages-timeline", component: <PageTimeline /> },
  // { path: "/pages-faqs", component: <PageFaqs /> },
  // { path: "/pages-pricing", component: <PagePricing /> },

  //Components
  // { path: "/ui-alerts", component: <UiAlert /> },
  // { path: "/ui-buttons", component: <UiButton /> },
  // { path: "/ui-cards", component: <UiCard /> },
  // { path: "/ui-carousel", component: <UiCarousel /> },
  // { path: "/ui-dropdowns", component: <UiDropdowns /> },
  // { path: "/ui-grid", component: <UiGrid /> },
  // { path: "/ui-images", component: <UiImages /> },
  // { path: "/ui-modals", component: <UiModal /> },
  // { path: "/ui-offcanvas", component: <UiOffCanvas /> },
  // { path: "/ui-progressbars", component: <UiProgressbar /> },
  // { path: "/ui-placeholders", component: <UiPlaceholders /> },
  // { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
  // { path: "/ui-typography", component: <UiTypography /> },
  // { path: "/ui-toasts", component: <UiToasts /> },
  // { path: "/ui-video", component: <UiVideo /> },
  // { path: "/ui-general", component: <UiGeneral /> },
  // { path: "/ui-colors", component: <UiColors /> },
  // { path: "/ui-utilities", component: <UiUtilities /> },

  //Extended pages
  // { path: "/extended-lightbox", component: <Lightbox /> },
  // { path: "/extended-rangeslider", component: <Rangeslider /> },
  // { path: "/extended-session-timeout", component: <SessionTimeout /> },
  // { path: "/extended-rating", component: <UiRating /> },
  // { path: "/extended-notifications", component: <Notifications /> },

  //Forms
  // { path: "/form-elements", component: <FormElements /> },
  // { path: "/form-validation", component: <FormValidation /> },
  // { path: "/form-advanced", component: <AdvancedPlugins /> },
  // { path: "/form-editors", component: <FormEditors /> },
  // { path: "/form-uploads", component: <FormUpload /> },
  // { path: "/form-wizard", component: <FormWizard /> },
  // { path: "/form-mask", component: <FormMask /> },

  //tables
  // { path: "/tables-basic", component: <BasicTable /> },
  // { path: "/tables-datatable", component: <DatatableTables /> },
  // { path: "/tables-responsive", component: <ResponsiveTables /> },
  // { path: "/tables-editable", component: <EditableTables /> },

  //Charts
  // { path: "/charts-apex", component: <Apexchart /> },
  // { path: "/charts-echart", component: <EChart /> },
  // { path: "/charts-chartjs", component: <ChartjsChart /> },
  // { path: "/charts-knob", component: <KnobCharts /> },
  // { path: "/charts-sparkline", component: <SparklineChart /> },

  //Icons
  // { path: "/icons-boxicons", component: <IconBoxicons /> },
  // { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  // { path: "/icons-dripicons", component: <IconDripicons /> },
  // { path: "/icons-fontawesome", component: <IconFontawesomes /> },

  // Maps
  // { path: "/maps-google", component: <MapsGoogle /> },
  // { path: "/maps-vector", component: <MapsVector /> },
  // { path: "/maps-leaflet", component: <MapsLeaflet /> },

  // this route should be at the end of all other routes
  // { path: "/", exact: true, component: <Navigate to="/dashboard" /> },

  //case-forms
  { path: "/admin/case-forms", component: <CaseForms />, role: "admin" },
  { path: "/admin/add-case-forms", component: <CaseFormAdd />, role: "admin" },
  { path: "/admin/update-case-form", component: <CaseFormAdd />, role: "admin" },
  { path: "/admin/case-form-attributes", component: <CaseFormAttributes />, role: "admin" },
  { path: "/admin/add-case-form-attributes", component: <AddCaseFormAttributes />, role: "admin" },
  { path: "/admin/case-form-attribute-sets", component: <CaseFormAttributeSets />, role: "admin" },
  { path: "/admin/add-case-form-attribute-sets", component: <AddCaseFormAttributeSets />, role: "admin" },
  { path: "/admin/case-form-attribute-groups", component: <CaseFormAttributesGroups />, role: "admin" },
  { path: "/admin/case-form-attribute-templates", component: <CaseFormAttributeTemplates />, role: "admin" },
  { path: "/admin/add-case-form-attribute-groups", component: <AddCaseformAttributeGroups />, role: "admin" },
  { path: "/admin/add-case-form-attribute-templates", component: <AddCaseformAttributeTemplates />, role: "admin" },
];

const authRoutes = [
  //authencation page
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/reset-password", component: <ForgetPwd /> },
  { path: "/login", component: <Login /> },
  { path: "/", component: <Login /> },

  //AuthenticationInner pages
  // { path: "/page-login", component: <PageLogin /> },
  // { path: "/page-register", component: <PageRegister /> },
  // { path: "/page-recoverpw", component: <RecoverPassword /> },
  // { path: "/page-lock-screen", component: <LockScreen /> },
  // { path: "/page-confirm-mail", component: <ConfirmMail /> },
  // { path: "/page-email-verification", component: <EmailVerification /> },
  // { path: "/page-two-step-verification", component: <TwoStepVerfication /> },
  // { path: "/page-logout", component: <AuthLogout /> },

  //Utility page
  // { path: "/pages-maintenance", component: <PageMaintenance /> },
  // { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  // { path: "/pages-404", component: <Error404 /> },
  // { path: "/pages-500", component: <Error500 /> },
];

export { userRoutes, authRoutes };
