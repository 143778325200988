import React, { useEffect, useState } from "react";
import {
  useLocation, useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import './PayrollView.css';
import {
  CaseTypeName,
  CommonCell
} from "./listCol";

//redux
import { useDispatch, useSelector } from "react-redux";


import BootstrapTable, { TableHeaderColumn } from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { employerPayrollUpdate, getEmployerPayroll, refreshEmployeePayroll } from "../../../store/employer/payroll/actions";
// import { useAlert } from "../../../components/Common/Alert";

const PayrollView = () => {

  //meta title
  document.title = "Payroll List | Immidock ";

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openTab, setopenTab] = useState("Open");

  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('payrollId'); // Read the 'id' query parameter from the URL

  const { payroll, updateemployerPayrolls } = useSelector((state) => ({
    payroll: state.employerPayrolls.employerPayroll || [],
    updateemployerPayrolls: state.employerPayrolls.updateemployerPayrolls
  }));

  // useEffect(() => {
  //   if (Object.keys(updateemployerPayrolls).length > 0)
  //     dispatch(getEmployerPayroll(editId));
  // }, [updateemployerPayrolls]);

  useEffect(() => {
    if (editId) {
      dispatch(getEmployerPayroll(editId));
    }
  }, [dispatch]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const formatAmounts = (columnData) => {
    return "$" + columnData.reduce((acc, item) => acc + (+item), 0)
  }

  const handleRefreshEmployee = (cell, row) => {
    dispatch(refreshEmployeePayroll({
      "customer_id": row.customer_id,
      "employee_id": row.employee_id,
      "emp_id": row.employee_id,
      "payroll_id": row.payroll_id
    }))
    setTimeout(() => {
      dispatch(getEmployerPayroll(editId));
    }, 500)
  }

  const formatWithIcon = (cell, row) => {
    return (
      <span role='button' onClick={() => handleRefreshEmployee(cell, row)}><i className="bx bx-refresh h2 mb-0"></i></span>
    )
  }

  const columns = [

    {
      text: "Beneficiary",
      dataField: "employee_name",
      editable: false,
      filterable: true,
      sort: true,
      disableFilters: true,
      Cell: (cellProps) => {
        return <CaseTypeName {...cellProps} />;
      },
      footer: '',
    },
    {
      text: "Client/Vendor",
      dataField: "customer_name",
      editable: false,
      filterable: true,
      sort: true,
      disableFilters: true,
      Cell: (cellProps) => {
        return <CaseTypeName {...cellProps} />;
      },
      footer: '',
    },
    {
      text: "Regular",
      dataField: "hours",
      filterable: true,
      disableFilters: true,
      sort: true,
      headerFormatter: (column) => (<><Row className="combine-col-header">Hours</Row><Row>{column.text} </Row> </>),
      Cell: (cellProps) => {
        return <CaseTypeName {...cellProps} />;
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: 'Regula hours should be numeric'
          };
        }
        if (newValue < 1) {
          return {
            valid: false,
            message: 'Regula hours should bigger than 0'
          };
        }
        return true;
      },
      footer: '',
    },
    {
      text: "OT",
      dataField: "ot_hours",
      filterable: true,
      disableFilters: true,
      sort: true,
      headerFormatter: (column) => (<><Row className="combine-col-header">Hours</Row><Row>{column.text} </Row> </>),
      Cell: (cellProps) => {
        return <CaseTypeName {...cellProps} />;
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: 'OT hours should be numeric'
          };
        }
        if (newValue < 1) {
          return {
            valid: false,
            message: 'OT hours should bigger than 0'
          };
        }
        return true;
      },
      footer: '',
    },
    {
      text: "Regular",
      dataField: "bill_rate",
      filterable: true,
      disableFilters: true,
      sort: true,
      headerFormatter: (column) => (<><Row className="combine-col-header">Bill Rates</Row><Row>{column.text} </Row> </>),
      Cell: (cellProps) => {
        return <CaseTypeName {...cellProps} />;
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: 'Regular bill rate hours should be numeric'
          };
        }
        if (newValue < 1) {
          return {
            valid: false,
            message: 'Regular bill rate hours should bigger than 0'
          };
        }
        return true;
      },
      footer: '',
    },
    {
      text: "OT",
      dataField: "ot_bill_rate",
      filterable: true,
      disableFilters: true,
      sort: true,
      headerFormatter: (column) => (<><Row className="combine-col-header">Bill Rates</Row><Row>{column.text} </Row> </>),
      Cell: (cellProps) => {
        return <CaseTypeName {...cellProps} />;
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: 'OT bill rate hours should be numeric'
          };
        }
        if (newValue < 1) {
          return {
            valid: false,
            message: 'OT billrate hours should bigger than 0'
          };
        }
        return true;
      },
      footer: '',
    },
    {
      text: "Bill Amount",
      dataField: "bill_amount",
      editable: true,
      filterable: true,
      sort: true,
      disableFilters: true,
      Cell: (cellProps) => {
        return <CaseTypeName {...cellProps} />;
      },
      footer: columnData => formatAmounts(columnData),
    },

    {
      text: "Regular",
      dataField: "rate",
      editable: true,
      filterable: true,
      sort: true,
      headerFormatter: (column) => (<><Row className="combine-col-header">Pay Rates</Row><Row>{column.text} </Row> </>),
      disableFilters: true,
      Cell: (cellProps) => {
        return <CaseTypeName {...cellProps} />;
      },
      footer: '',
    },
    {
      text: "OT",
      dataField: "ot_pay_rate",
      filterable: true,
      sort: true,
      headerFormatter: (column) => (<><Row className="combine-col-header">Pay Rates</Row><Row>{column.text} </Row> </>),
      disableFilters: true,
      Cell: (cellProps) => {
        return <CaseTypeName {...cellProps} />;
      },
      footer: '',
    },
    {
      text: "Pay",
      dataField: "amount",
      editable: false,
      filterable: true,
      disableFilters: true,
      sort: true,
      Cell: (cellProps) => {
        return <CaseTypeName {...cellProps} />;
      },
      footer: columnData => formatAmounts(columnData),
    },
    {
      text: "Misc",
      dataField: "misc_amount",
      filterable: true,
      sort: true,
      disableFilters: true,
      Cell: (cellProps) => {
        return <CommonCell {...cellProps} />;
      },
      footer: '',
    },
    {
      text: "Misc Reimb",
      dataField: "misc_reimb",
      filterable: true,
      disableFilters: true,
      sort: true,
      Cell: (cellProps) => {
        return <CommonCell {...cellProps} />;
      },
      footer: '',
    },
    {
      text: "Deduction",
      dataField: "deductions",
      filterable: true,
      disableFilters: true,
      sort: true,
      Cell: (cellProps) => {
        return <CommonCell {...cellProps} />;
      },
      footer: '',
    },
    {
      text: "Insurance",
      dataField: "insurance",
      filterable: true,
      disableFilters: true,
      sort: true,
      Cell: (cellProps) => {
        return <CommonCell {...cellProps} />;
      },
      footer: '',
    },
    {
      text: "Total",
      dataField: "total_amount",
      editable: false,
      filterable: true,
      sort: true,
      disableFilters: true,
      Cell: (cellProps) => {
        return <CommonCell {...cellProps} />;
      },
      footer: columnData => formatAmounts(columnData),
    },
    {
      text: "Refresh",
      dataField: "total_amount",
      editable: false,
      filterable: false,
      sort: false,
      disableFilters: true,
      formatter: formatWithIcon,
    },
  ];



  function afterSaveCell(oldValue, newValue, row, column) {
    ///employer/update-payroll
    let reqData = {
      "emp_pay_id": row.emp_pay_id,
      "employee_id": row.employee_id,
      "payroll_id": row.payroll_id || editId,
      "field": column.dataField,
      "value": newValue
    }
    dispatch(employerPayrollUpdate(reqData));
    setTimeout(() => {
      dispatch(getEmployerPayroll(editId));
    }, 500)
  }

  function beforeSaveCell(oldValue, newValue, row, column, done) {
    if (confirm('Do you want to accept this change?')) {
      done(true);
    } else {
      done(false);
    }
    return { async: true };
  }
  const defaultSorted = [{
    dataField: 'emp_pay_id',
    order: 'desc'
  }];
  let title = 'Payroll View';
  const BreadcrumbData = [
    { name: 'Dashboard', url: '/attorney/dashboard' },
    { name: title, url: '#' }
  ]
  return (
    <React.Fragment>
      <div className="page-content payrollView">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    {(columns.length > 0 && payroll.length > 0) && <BootstrapTable
                      bootstrap4
                      keyField="emp_pay_id"
                      data={payroll || []}
                      columns={columns || []}
                      cellEdit={cellEditFactory({
                        mode: "click",
                        blurToSave: true,
                        beforeSaveCell,
                        afterSaveCell: (oldValue, newValue, row, column) => {
                          afterSaveCell(oldValue, newValue, row, column)
                        }
                      })}
                      defaultSorted={defaultSorted}
                    />

                    }

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PayrollView;
