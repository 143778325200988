import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ATTORNEY_CONTACTS, GET_ATTORNEY_CONTACT_DETAIL, ADD_ATTORNEY_CONTACT_DETAIL, DELETE_ATTORNEY_CONTACT_DETAIL, UPDATE_ATTORNEY_CONTACT_DETAIL, VALIDATE_COMPANY_EMAILID } from "./actionTypes"
import {
  getAttorneyContactsSuccess,
  getAttorneyContactsFail,
  getAttorneyContactDetailSuccess,
  getAttorneyContactDetailFail,
  addAttorneyContactDetailSuccess,
  addAttorneyContactDetailFail,
  AttorneyContactDeleteSuccess,
  AttorneyContactDeleteFail,
  AttorneyContactUpdateSuccess,
  AttorneyContactUpdateFail,
  validateDuplicateCompEmailIdSuccess,
  validateDuplicateCompEmailIdFail
} from "./actions"

//Include Both Helper File with needed methods
import { getAttorneyContacts, getAttorneyContactDetail, addAttorneyContact, deleteAttorneyContact, updateAttorneyContact, validateCompanyEmail } from "../../../helpers/apibackend_helper"
import { setAlertMessage } from "../../common/actions";

function* fetchAttorneyContacts({ payload: user }) {
  try {
    console.log(user);
    const response =yield call(getAttorneyContacts,user)
    yield put(getAttorneyContactsSuccess(response.data))
  } catch (error) {
    yield put(getAttorneyContactsFail(error))
  }
}

function* fetchAttorneyContactDetail({ ContactId }) {
  try {
    const response = yield call(getAttorneyContactDetail, {employer_id:ContactId})
    yield put(getAttorneyContactDetailSuccess(response.data))
  } catch (error) {
    yield put(getAttorneyContactDetailFail(error))
  }
}

function* addAttorneyContactDetail({ payload: user }) {
  try {
    const response = yield call(addAttorneyContact, user)
    yield put(addAttorneyContactDetailSuccess(response))
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(addAttorneyContactDetailFail(error))
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* onDeleteAttorneyContact({ payload: data }) {
  try {
    const response = yield call(deleteAttorneyContact, data);
    yield put(AttorneyContactDeleteSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(AttorneyContactDeleteFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* onUpdateAttorneyContact({ payload: data }) {
  try {
    const response = yield call(updateAttorneyContact, data);
    yield put(AttorneyContactUpdateSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(AttorneyContactUpdateFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* validateCompanyEmailID({ payload: user }) {
  try {
    const response = yield call(validateCompanyEmail, user)
    yield put(validateDuplicateCompEmailIdSuccess(response.data))
    // yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(validateDuplicateCompEmailIdFail(error))
    // yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* AttorneyContactsaga() {
  yield takeEvery(GET_ATTORNEY_CONTACTS, fetchAttorneyContacts)
  yield takeEvery(GET_ATTORNEY_CONTACT_DETAIL, fetchAttorneyContactDetail)
  yield takeEvery(ADD_ATTORNEY_CONTACT_DETAIL, addAttorneyContactDetail)
  yield takeEvery(DELETE_ATTORNEY_CONTACT_DETAIL, onDeleteAttorneyContact)
  yield takeEvery(UPDATE_ATTORNEY_CONTACT_DETAIL, onUpdateAttorneyContact)
  yield takeEvery(VALIDATE_COMPANY_EMAILID, validateCompanyEmailID)
}

export default AttorneyContactsaga
