import {
  GENERATE_FORM,
  GENERATE_FORM_FAIL,
  GENERATE_FORM_SUCCESS,
  GENERATE_FORM_DETAIL,
  GENERATE_FORM_DETAIL_FAIL,
  GENERATE_FORM_DETAIL_SUCCESS,
  GET_ATTORNEYCASES,
  GET_ATTORNEYCASES_FAIL,
  GET_ATTORNEYCASES_SUCCESS,
  GET_ATTORNEYCASE_DETAIL,
  GET_ATTORNEYCASE_DETAIL_FAIL,
  GET_ATTORNEYCASE_DETAIL_SUCCESS,
  UDATE_ATTORNEY_LCADETAILS,
  UDATE_ATTORNEY_LCADETAILS_SUCCESS,
  UDATE_ATTORNEY_LCADETAILS_FAIL,

  GET_ATTORNEYCASE_QNNR_DETAILS,
  GET_ATTORNEYCASE_QNNR_DETAILS_FAIL,
  GET_ATTORNEYCASE_QNNR_DETAILS_SUCCESS,

  GET_CASE_TIMELINES,
  GET_CASE_TIMELINES_FAIL,
  GET_CASE_TIMELINES_SUCCESS,

  GET_ATTORNEYCASE_FORMS,
  GET_ATTORNEYCASE_FORMS_FAIL,
  GET_ATTORNEYCASE_FORMS_SUCCESS,

  GET_ATTORNEYCASE_DOCUMENTS,
  GET_ATTORNEYCASE_DOCUMENTS_FAIL,
  GET_ATTORNEYCASE_DOCUMENTS_SUCCESS,


  UPDATE_ATTORNEYCASE_DETAIL_FAIL,
  UPDATE_ATTORNEYCASE_DETAIL_SUCCESS,
  UPDATE_ATTORNEYCASE_DETAIL,
  DELETE_ATTORNEYCASE_DETAIL_FAIL,
  DELETE_ATTORNEYCASE_DETAIL_SUCCESS,
  DELETE_ATTORNEYCASE_DETAIL,
  ADD_ATTORNEYCASE_DETAIL_SUCCESS,
  ADD_ATTORNEYCASE_DETAIL,
  ADD_ATTORNEYCASE_DETAIL_FAIL,
  GET_ATTORNEYQNNRTYPES_SUCCESS,
  GET_ATTORNEYCASETYPES,
  GET_ATTORNEYQNNRTYPES_FAIL,
  GET_ATTORNEYQNNRTYPES,
  GET_ATTORNEYCASETYPES_SUCCESS,
  GET_ATTORNEYCASETYPES_FAIL,
  SAVE_CASE_FORM_TEMPLATE_DATA,
  SAVE_CASE_FORM_TEMPLATE_DATA_SUCCESS,
  SAVE_CASE_FORM_TEMPLATE_DATA_FAIL,
  UDATE_ATTORNEY_FORM_SHARE,
  UDATE_ATTORNEY_FORM_SHARE_SUCCESS,
  UDATE_ATTORNEY_FORM_SHARE_FAIL,
  REVOKE_ATTORNEY_FORM_SHARE_REVIEW,
  REVOKE_ATTORNEY_FORM_SHARE_REVIEW_SUCCESS,
  REVOKE_ATTORNEY_FORM_SHARE_REVIEW_FAIL,
  ADD_ATTORNEY_DOCUMENT_UPLOAD,
  ADD_ATTORNEY_DOCUMENT_UPLOAD_SUCCESS,
  ADD_ATTORNEY_DOCUMENT_UPLOAD_FAIL,
  REQUEST_ATTORNEY_DOCUMENT,
  REQUEST_ATTORNEY_DOCUMENT_SUCCESS,
  REQUEST_ATTORNEY_DOCUMENT_FAIL,
  UDATE_ATTORNEY_UNLOCK_QUNNR,
  UDATE_ATTORNEY_UNLOCK_QUNNR_SUCCESS,
  UDATE_ATTORNEY_UNLOCK_QUNNR_FAIL,
  BUNDLE_CASEFORMS_SUCCESS,
  BUNDLE_CASEFORMS_FAIL,
  BUNDLE_CASEFORMS,

  SAVE_CASEFORMS,
  SAVE_CASEFORMS_SUCCESS,
  SAVE_CASEFORMS_FAIL,

  SORT_CASEFORMS,
  SORT_CASEFORMS_SUCCESS,
  SORT_CASEFORMS_FAIL,

  SAVE_NOTE,
  SAVE_NOTE_SUCCESS,
  SAVE_NOTE_FAIL,

  SAVE_REMINDER,
  SAVE_REMINDER_SUCCESS,
  SAVE_REMINDER_FAIL,

  SAVE_MESSAGE,
  SAVE_MESSAGE_SUCCESS,
  SAVE_MESSAGE_FAIL,

  GET_NOTE,
  GET_NOTE_SUCCESS,
  GET_NOTE_FAIL,

  GET_REMINDER,
  GET_REMINDER_SUCCESS,
  GET_REMINDER_FAIL,

  GET_MESSAGE,
  GET_MESSAGE_SUCCESS,
  GET_MESSAGE_FAIL,

  SHARE_UPLOADED_DOCUMENT_ATTORNEY,
  SHARE_UPLOADED_DOCUMENT_ATTORNEY_SUCCESS,
  SHARE_UPLOADED_DOCUMENT_ATTORNEY_FAIL,

  SEND_REMINDER_DOCUMENT_ATTORNEY,
  SEND_REMINDER_DOCUMENT_ATTORNEY_SUCCESS,
  SEND_REMINDER_DOCUMENT_ATTORNEY_FAIL,

  GET_CASE_DEPENDENTS_ATTORNEY,
  GET_CASE_DEPENDENTS_ATTORNEY_SUCCESS,
  GET_CASE_DEPENDENTS_ATTORNEY_FAIL,
  BUNDLE_CASE_DOCUMENTS,
  BUNDLE_CASE_DOCUMENTS_FAIL,
  BUNDLE_CASE_DOCUMENTS_SUCCESS,
  DELETE_CASE_FORM,
  DELETE_CASE_FORM_SUCCESS,
  DELETE_CASE_FORM_FAIL,
  GET_EMPLOYER_EMPLOYEES_FAIL,
  GET_EMPLOYER_EMPLOYEES_SUCCESS,
  GET_EMPLOYER_EMPLOYEES,

} from "./actionTypes"


export const saveCaseFormTemplateData = (data) => ({
  type: SAVE_CASE_FORM_TEMPLATE_DATA,
  payload: data
})
export const saveCaseFormTemplateDataSucess = EmployeeCases => ({
  type: SAVE_CASE_FORM_TEMPLATE_DATA_SUCCESS,
  payload: EmployeeCases,
})

export const saveCaseFormTemplateDataFail = error => ({
  type: SAVE_CASE_FORM_TEMPLATE_DATA_FAIL,
  payload: error,
})


export const generateForm = (data) => ({
  type: GENERATE_FORM,
  payload: data
})

export const generateFormSuccess = FormGen => ({
  type: GENERATE_FORM_SUCCESS,
  payload: FormGen,
})

export const generateFormFail = error => ({
  type: GENERATE_FORM_FAIL,
  payload: error,
})


export const generateFormDetail = (data) => ({
  type: GENERATE_FORM_DETAIL,
  payload: data
})

export const generateFormDetailSuccess = FormGenDetails => ({
  type: GENERATE_FORM_DETAIL_SUCCESS,
  payload: FormGenDetails,
})

export const generateFormDetailFail = error => ({
  type: GENERATE_FORM_DETAIL_FAIL,
  payload: error,
})

export const getAttorneyCases = (data) => ({
  type: GET_ATTORNEYCASES,
  payload: data
})

export const getAttorneyCasesSuccess = AttorneyCases => ({
  type: GET_ATTORNEYCASES_SUCCESS,
  payload: AttorneyCases,
})

export const getAttorneyCasesFail = error => ({
  type: GET_ATTORNEYCASES_FAIL,
  payload: error,
})

export const sortCaseForms = (data) => (console.log('-- api call', data), {
  type: SORT_CASEFORMS,
  payload: data
})

export const sortCaseFormsSuccess = CaseForms => ({
  type: SORT_CASEFORMS_SUCCESS,
  payload: CaseForms,
})

export const sortCaseFormsFail = error => ({
  type: SORT_CASEFORMS_FAIL,
  payload: error,
})


export const getAttorneyCaseDetail = (data) => ({
  type: GET_ATTORNEYCASE_DETAIL,
  payload: data
})

export const getAttorneyCaseDetailSuccess = AttorneyCases => ({
  type: GET_ATTORNEYCASE_DETAIL_SUCCESS,
  payload: AttorneyCases,
})

export const getAttorneyCaseDetailFail = error => ({
  type: GET_ATTORNEYCASE_DETAIL_FAIL,
  payload: error,
})

export const addAttorneyCaseDetail = (data) => ({
  type: ADD_ATTORNEYCASE_DETAIL,
  payload: data
})

export const addAttorneyCasedetailSuccess = (data) => ({
  type: ADD_ATTORNEYCASE_DETAIL_SUCCESS,
  payload: data
})

export const addAttorneyCasedetailFail = (error) => ({
  type: ADD_ATTORNEYCASE_DETAIL_FAIL,
  payload: error
})


export const getCaseQnnrDetails = (data) => ({
  type: GET_ATTORNEYCASE_QNNR_DETAILS,
  payload: data
})

export const getCaseQnnrDetailsSuccess = AttorneyQnnrDetails => ({
  type: GET_ATTORNEYCASE_QNNR_DETAILS_SUCCESS,
  payload: AttorneyQnnrDetails,
})

export const getCaseQnnrDetailsFail = error => ({
  type: GET_ATTORNEYCASE_QNNR_DETAILS_FAIL,
  payload: error,
})


export const getCaseForms = (data) => ({
  type: GET_ATTORNEYCASE_FORMS,
  payload: data
})

export const getCaseFormsSuccess = AttorneyCaseForms => ({
  type: GET_ATTORNEYCASE_FORMS_SUCCESS,
  payload: AttorneyCaseForms,
})

export const getCaseFormsFail = error => ({
  type: GET_ATTORNEYCASE_FORMS_FAIL,
  payload: error,
})


export const getCaseDocuments = (data) => ({
  type: GET_ATTORNEYCASE_DOCUMENTS,
  payload: data
})

export const getCaseDocumentsSuccess = AttorneyCaseDocuments => ({
  type: GET_ATTORNEYCASE_DOCUMENTS_SUCCESS,
  payload: AttorneyCaseDocuments,
})

export const getCaseDocumentsFail = error => ({
  type: GET_ATTORNEYCASE_DOCUMENTS_FAIL,
  payload: error,
})

/** CASE TIMELINES START */

export const getCaseTimelines = (data) => ({
  type: GET_CASE_TIMELINES,
  payload: data
})

export const getCaseTimelinesSuccess = CaseTimeLines => ({
  type: GET_CASE_TIMELINES_SUCCESS,
  payload: CaseTimeLines,
})

export const getCaseTimelinesFail = error => ({
  type: GET_CASE_TIMELINES_FAIL,
  payload: error,
})

/** CASE TIMELINES END */


export const deleteCaseForm = (data) => ({
  type: DELETE_CASE_FORM,
  payload: data
})

export const deleteCaseFormSuccess = (data) => ({
  type: DELETE_CASE_FORM_SUCCESS,
  payload: data
})

export const deleteCaseFormFail = (error) => ({
  type: DELETE_CASE_FORM_FAIL,
  payload: error
})


export const AttorneyCaseDelete = (data) => ({
  type: DELETE_ATTORNEYCASE_DETAIL,
  payload: data
})

export const AttorneyCaseDeleteSuccess = (data) => ({
  type: DELETE_ATTORNEYCASE_DETAIL_SUCCESS,
  payload: data
})

export const AttorneyCaseDeleteFail = (error) => ({
  type: DELETE_ATTORNEYCASE_DETAIL_FAIL,
  payload: error
})

export const AttorneyCaseUpdate = (data) => ({
  type: UPDATE_ATTORNEYCASE_DETAIL,
  payload: data
})

export const AttorneyCaseUpdateSuccess = (data) => ({
  type: UPDATE_ATTORNEYCASE_DETAIL_SUCCESS,
  payload: data
})

export const AttorneyCaseUpdateFail = (error) => ({
  type: UPDATE_ATTORNEYCASE_DETAIL_FAIL,
  payload: error
})


export const getAttorneyCaseTypes = () => ({
  type: GET_ATTORNEYCASETYPES,
})

export const getAttorneyCaseTypesSuccess = AttorneyCaseTypes => ({
  type: GET_ATTORNEYCASETYPES_SUCCESS,
  payload: AttorneyCaseTypes,
})

export const getAttorneyCaseTypesFail = error => ({
  type: GET_ATTORNEYCASETYPES_FAIL,
  payload: error,
})


export const getAttorneyQnnrtypes = (data) => ({
  type: GET_ATTORNEYQNNRTYPES,
  payload: data
})

export const getAttorneyQnnrtypesSuccess = AttorneyQnnrtypes => ({
  type: GET_ATTORNEYQNNRTYPES_SUCCESS,
  payload: AttorneyQnnrtypes,
})

export const getAttorneyQnnrtypesFail = error => ({
  type: UDATE_ATTORNEY_FORM_SHARE,
  payload: error,
})

export const updateAttorneyFormReview = (data) => ({
  type: UDATE_ATTORNEY_FORM_SHARE,
  payload: data
})

export const updateAttorneyFormReviewSuccess = attorneyFormReview => ({
  type: UDATE_ATTORNEY_FORM_SHARE_SUCCESS,
  payload: attorneyFormReview,
})

export const updateAttorneyFormReviewFail = error => ({
  type: UDATE_ATTORNEY_FORM_SHARE_FAIL,
  payload: error,
})


export const revokeAttorneyFormReview = (data) => ({
  type: REVOKE_ATTORNEY_FORM_SHARE_REVIEW,
  payload: data
})

export const revokeAttorneyFormReviewSuccess = attorneyFormReview => ({
  type: REVOKE_ATTORNEY_FORM_SHARE_REVIEW_SUCCESS,
  payload: attorneyFormReview,
})

export const revokeAttorneyFormReviewFail = error => ({
  type: REVOKE_ATTORNEY_FORM_SHARE_REVIEW_FAIL,
  payload: error,
})





export const addDocumentUpload = (data) => ({
  type: ADD_ATTORNEY_DOCUMENT_UPLOAD,
  payload: data
})

export const addDocumentUploadSuccess = data => ({
  type: ADD_ATTORNEY_DOCUMENT_UPLOAD_SUCCESS,
  payload: data,
})

export const addDocumentUploadFail = error => ({
  type: ADD_ATTORNEY_DOCUMENT_UPLOAD_FAIL,
  payload: error,
})

export const requestDocument = (data) => ({
  type: REQUEST_ATTORNEY_DOCUMENT,
  payload: data
})

export const requestDocumentSuccess = data => ({
  type: REQUEST_ATTORNEY_DOCUMENT_SUCCESS,
  payload: data,
})

export const requestDocumentFail = error => ({
  type: REQUEST_ATTORNEY_DOCUMENT_FAIL,
  payload: error,
})


export const updateAttorneyUnlockQunnr = (data) => ({
  type: UDATE_ATTORNEY_UNLOCK_QUNNR,
  payload: data
})

export const updateAttorneyUnlockQunnrSuccess = data => ({
  type: UDATE_ATTORNEY_UNLOCK_QUNNR_SUCCESS,
  payload: data,
})

export const updateAttorneyUnlockQunnrFail = error => ({
  type: UDATE_ATTORNEY_UNLOCK_QUNNR_FAIL,
  payload: error,
})

export const updateAttorneyLCADetails = (data) => ({
  type: UDATE_ATTORNEY_LCADETAILS,
  payload: data
})

export const updateAttorneyLCADetailsSuccess = AttorneyCases => ({
  type: UDATE_ATTORNEY_LCADETAILS_SUCCESS,
  payload: AttorneyCases,
})

export const updateAttorneyLCADetailsFail = error => ({
  type: UDATE_ATTORNEY_LCADETAILS_FAIL,
  payload: error,
})

export const saveCaseforms = (data) => ({
  type: SAVE_CASEFORMS,
  payload: data
})


export const saveCaseformFail = error => ({
  type: SAVE_CASEFORMS_FAIL,
  payload: error
})

export const saveCaseformSuccess = saveform => ({
  type: SAVE_CASEFORMS_SUCCESS,
  payload: saveform,
})

export const bundleCaseforms = (data) => ({
  type: BUNDLE_CASEFORMS,
  payload: data
})


export const bundleCaseformFail = error => ({
  type: BUNDLE_CASEFORMS_FAIL,
  payload: error
})

export const bundleCaseformSuccess = CaseFormsList => ({
  type: BUNDLE_CASEFORMS_SUCCESS,
  payload: CaseFormsList,
})


export const bundleCasedocuments = (data) => ({
  type: BUNDLE_CASE_DOCUMENTS,
  payload: data
})

export const bundleCasedocumentFail = error => ({
  type: BUNDLE_CASE_DOCUMENTS_FAIL,
  payload: error
})

export const bundleCasedocumentSuccess = CaseDocumentsList => ({
  type: BUNDLE_CASE_DOCUMENTS_SUCCESS,
  payload: CaseDocumentsList,
})

export const saveNote = (data) => ({
  type: SAVE_NOTE,
  payload: data
})

export const saveNoteFail = error => ({
  type: SAVE_NOTE_FAIL,
  payload: error
})

export const saveNoteSuccess = caseNote => ({
  type: SAVE_NOTE_SUCCESS,
  payload: caseNote,
})

export const saveReminder = (data) => ({
  type: SAVE_REMINDER,
  payload: data
})

export const saveReminderFail = error => ({
  type: SAVE_REMINDER_FAIL,
  payload: error
})

export const saveReminderSuccess = caseReminder => ({
  type: SAVE_REMINDER_SUCCESS,
  payload: caseReminder,
})

export const saveMessage = (data) => ({
  type: SAVE_MESSAGE,
  payload: data
})

export const saveMessageFail = error => ({
  type: SAVE_MESSAGE_FAIL,
  payload: error
})

export const saveMessageSuccess = caseMessage => ({
  type: SAVE_MESSAGE_SUCCESS,
  payload: caseMessage,
})

export const getNote = (data) => ({
  type: GET_NOTE,
  payload: data
})

export const getNoteFail = error => ({
  type: GET_NOTE_FAIL,
  payload: error
})

export const getNoteSuccess = caseNote => ({
  type: GET_NOTE_SUCCESS,
  payload: caseNote,
})

export const getReminder = (data) => ({
  type: GET_REMINDER,
  payload: data
})

export const getReminderFail = error => ({
  type: GET_REMINDER_FAIL,
  payload: error
})

export const getReminderSuccess = caseReminder => ({
  type: GET_REMINDER_SUCCESS,
  payload: caseReminder,
})

export const getMessage = (data) => ({
  type: GET_MESSAGE,
  payload: data
})

export const getMessageFail = error => ({
  type: GET_MESSAGE_FAIL,
  payload: error
})

export const getMessageSuccess = caseMessage => ({
  type: GET_MESSAGE_SUCCESS,
  payload: caseMessage,
})

export const shareUploadedDoc = (data) => ({
  type: SHARE_UPLOADED_DOCUMENT_ATTORNEY,
  payload: data
})

export const shareUploadedDocSuccess = AttorneyDoc => ({
  type: SHARE_UPLOADED_DOCUMENT_ATTORNEY_SUCCESS,
  payload: AttorneyDoc,
})

export const shareUploadedDocFail = error => ({
  type: SHARE_UPLOADED_DOCUMENT_ATTORNEY_FAIL,
  payload: error,
})


export const sendReminderDocument = (data) => ({
  type: SEND_REMINDER_DOCUMENT_ATTORNEY,
  payload: data
})

export const sendReminderDocumentSuccess = attorneyReminder => ({
  type: SEND_REMINDER_DOCUMENT_ATTORNEY_SUCCESS,
  payload: attorneyReminder,
})

export const sendReminderDocumentFail = error => ({
  type: SEND_REMINDER_DOCUMENT_ATTORNEY_FAIL,
  payload: error,
})

export const getCaseDependentsAttorney = (data) => ({
  type: GET_CASE_DEPENDENTS_ATTORNEY,
  payload: data
})

export const getCaseDependentsAttorneySuccess = dependents => ({
  type: GET_CASE_DEPENDENTS_ATTORNEY_SUCCESS,
  payload: dependents,
})

export const getCaseDependentsAttorneyFail = error => ({
  type: GET_CASE_DEPENDENTS_ATTORNEY_FAIL,
  payload: error,
})


export const getEmployerEmployees = (data) => ({
  type: GET_EMPLOYER_EMPLOYEES,
  payload: data
})

export const getEmployerEmployeesSuccess = (data) => ({
  type: GET_EMPLOYER_EMPLOYEES_SUCCESS,
  payload: data
})

export const getEmployerEmployeesFail = (error) => ({
  type: GET_EMPLOYER_EMPLOYEES_FAIL,
  payload: error
})