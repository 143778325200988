import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_EMPLOYER_PAYROLL,
  GET_EMPLOYER_PAYROLL_SUCCESS,
  GET_EMPLOYER_PAYROLL_FAIL,
  GET_EMPLOYER_PAYROLLS,
  GET_EMPLOYER_PAYROLLS_SUCCESS,
  GET_EMPLOYER_PAYROLLS_FAIL,
  UPDATE_EMPLOYER_PAYROLL,
  UPDATE_EMPLOYER_PAYROLL_SUCCESS,
  UPDATE_EMPLOYER_PAYROLL_FAIL,
  ADD_EMPLOYER_PAYROLL,
  ADD_EMPLOYER_PAYROLL_SUCCESS,
  ADD_EMPLOYER_PAYROLL_FAIL,
  DELETE_EMPLOYER_PAYROLL,
  REFRESH_EMPLOYER_PAYROLL,
  REFRESH_EMPLOYEE_PAYROLL,
} from "./actionTypes"
import {
  getEmployerPayrollSuccess,
  getEmployerPayrollFail,
  getEmployerPayrollsSuccess,
  getEmployerPayrollsFail,
  addEmployerPayrollSuccess,
  addEmployerPayrollFail,
  employerPayrollUpdateSuccess,
  employerPayrollUpdateFail,
  deleteEmployerPayrollSuccess,
  deleteEmployerPayrollFail,
  refreshEmployerPayrollSuccess,
  refreshEmployerPayrollFail
} from "./actions"

//Include Both Helper File with needed methods
import { getEmployerPayrollsApi, getEmployerPayrollApi, addEmployerPayrollApi, updateEmployerPayrollApi, deleteEmployerPayroll, refreshEmployerPayrollApi, refreshEmployeePayrollApi } from "../../../helpers/apibackend_helper"
import { setAlertMessage } from "../../common/actions"

function* fetchEmployerPayrolls({ payload: user }) {
  try {
    const response = yield call(getEmployerPayrollsApi, user)
    yield put(getEmployerPayrollsSuccess(response.data))
  } catch (error) {
    yield put(getEmployerPayrollsFail(error))
  }
}

function* fetchEmployerPayrollDetail({payload: payroll}) {
  try {
    const response = yield call(getEmployerPayrollApi, {
    "payroll_id":payroll
})
    yield put(getEmployerPayrollSuccess(response.data))
  } catch (error) {
    yield put(getEmployerPayrollFail(error))
  }
}

function* addEmployerPayroll({ payload: user }) {
  try {
    const response = yield call(addEmployerPayrollApi, user)
    yield put(addEmployerPayrollSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* onUpdateEmployerPayroll({ payload: data }) {
  try {
    const response = yield call(updateEmployerPayrollApi, data);
    yield put(employerPayrollUpdateSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(employerPayrollUpdateFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* onDeleteEmployerPayroll({ payload: data }) {
  try {
    const response = yield call(deleteEmployerPayroll, data);
    yield put(deleteEmployerPayrollSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(deleteEmployerPayrollFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* onRefreshEmployerPayroll({ payload: data }) {
  try {
    const response = yield call(refreshEmployerPayrollApi, data);
    yield put(refreshEmployerPayrollSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"}));   
  } catch (error) {
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* onRefreshEmployeePayroll({ payload: data }) {
  try {
    const response = yield call(refreshEmployeePayrollApi, data);
    yield put(refreshEmployerPayrollSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(refreshEmployerPayrollFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}


function* EmployerPayrollsaga() {
  yield takeEvery(GET_EMPLOYER_PAYROLLS, fetchEmployerPayrolls)
  yield takeEvery(GET_EMPLOYER_PAYROLL, fetchEmployerPayrollDetail)
  yield takeEvery(ADD_EMPLOYER_PAYROLL, addEmployerPayroll)
  yield takeEvery(UPDATE_EMPLOYER_PAYROLL, onUpdateEmployerPayroll)
  yield takeEvery(DELETE_EMPLOYER_PAYROLL, onDeleteEmployerPayroll)
  yield takeEvery(REFRESH_EMPLOYER_PAYROLL, onRefreshEmployerPayroll)
  yield takeEvery(REFRESH_EMPLOYEE_PAYROLL, onRefreshEmployeePayroll)
}

export default EmployerPayrollsaga
