import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_CUSTOMER_DETAIL, DELETE_CUSTOMER_DETAIL, GET_CUSTOMERS, GET_CUSTOMER_DETAIL, GET_CUSTOMER_SETTINGS, SEND_CUSTOMER, UPDATE_CUSTOMER_DETAIL, UPDATE_CUSTOMER_PDF, UPLOAD_CUSTOMER_ATTACHMENT } from "./actionTypes"
import {
  addCustomerdetailFail,
  addCustomerdetailSuccess,
  customerDeleteFail,
  customerDeleteSuccess,
  customerUpdateFail,
  customerUpdateSuccess,
  getCustomerDetailFail,
  getCustomerDetailSuccess,
  getCustomerSettingsFail,
  getCustomerSettingsSuccess,
  getCustomersFail,
  getCustomersSuccess,
  sendCustomerFail,
  sendCustomerSuccess,
  updateCustomerPdfFail,
  updateCustomerPdfSuccess,
  uploadCustomerAttachmentFail,
  uploadCustomerAttachmentSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { addCustomer, deleteCustomer, getCustomerDetail, getCustomerSettingsApiHelper, getCustomers, sendCustomer, updateCustomer, updateCustomerPdf, uploadCustomerAttachmentApiHelper } from "../../helpers/apibackend_helper"
import { setAlertMessage } from "../common/actions"
const customerList = [
  {
    id: 1,
    customerId: "#MN0215",
    founder: "Marion Burton",
    founderAt: "Skote",
    customerID: "14251",
    company: "Skote Dashboard UI",
    customerPrice: "1455",
    Amount: "$26.30",
    status: "Paid",
    date: "10 Oct, 2020",
    color: "success",
    orderId: "12345",
    billingAddress: "John Smith, 1234 Main, Apt. 4B, Springfield ST 54321",
    shippingAddress: "Kenny Rigdon, 1234 Main, Apt. 4B, Springfield ST 54321",
    card: "Visa ending **** 4242",
    email: "jsmith@email.com",
    orderDate: "October 16, 2019",
    orderSummary: {}
  },
]

function* fetchCustomers({ payload: data }) {
  try {
    const response = yield call(getCustomers, data)
    yield put(getCustomersSuccess(response.data))
  } catch (error) {
    yield put(getCustomersFail(error))
  }
}

function* fetchCustomerDetail({ customerId }) {
  try {
    const response = yield call(getCustomerDetail, customerId)
    yield put(getCustomerDetailSuccess(response.data))
  } catch (error) {
    yield put(getCustomerDetailFail(error))
  }
}

function* addCustomerDetail({ payload: customerdata }) {
  try {
    const response = yield call(addCustomer, customerdata)
    yield put(addCustomerdetailSuccess(response.data))
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(addCustomerdetailFail(error))
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* fetchSendCustomer({ payload: customerdata }) {
  try {
    const response = yield call(sendCustomer, customerdata)
    yield put(sendCustomerSuccess(response.data))
  } catch (error) {
    yield put(sendCustomerFail(error))
  }
}

function* fetchUpdateCustomerPdf({ payload: customerdata }) {
  try {
    const response = yield call(updateCustomerPdf, customerdata)
    yield put(updateCustomerPdfSuccess(response.data))
  } catch (error) {
    yield put(updateCustomerPdfFail(error))
  }
}


function* fetchCustomerSettings({ payload: customerdata }) {
  try {
    const response = yield call(getCustomerSettingsApiHelper, customerdata)
    yield put(getCustomerSettingsSuccess(response.data))
  } catch (error) {
    yield put(getCustomerSettingsFail(error))
  }
}

function* uploadCustomerAttachments({ payload: customerdata }) {
  try {
    const response = yield call(uploadCustomerAttachmentApiHelper, customerdata)
    yield put(uploadCustomerAttachmentSuccess(response.data))
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(uploadCustomerAttachmentFail(error))
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* onDeleteCustomer({ payload: data }) {
  try {
    const response = yield call(deleteCustomer, data);
    yield put(customerDeleteSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(customerDeleteFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* onUpdatecustomer({ payload: data }) {
  try {
    const response = yield call(updateCustomer, data);
    yield put(customerUpdateSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(customerUpdateFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* customerSaga() {
  yield takeEvery(GET_CUSTOMERS, fetchCustomers)
  yield takeEvery(GET_CUSTOMER_DETAIL, fetchCustomerDetail)
  yield takeEvery(ADD_CUSTOMER_DETAIL, addCustomerDetail)
  yield takeEvery(SEND_CUSTOMER, fetchSendCustomer)
  yield takeEvery(UPDATE_CUSTOMER_PDF, fetchUpdateCustomerPdf)
  yield takeEvery(GET_CUSTOMER_SETTINGS, fetchCustomerSettings)
  yield takeEvery(UPLOAD_CUSTOMER_ATTACHMENT, uploadCustomerAttachments)
  yield takeEvery(DELETE_CUSTOMER_DETAIL, onDeleteCustomer)
  yield takeEvery(UPDATE_CUSTOMER_DETAIL, onUpdatecustomer)
}

export default customerSaga
