import PropTypes from "prop-types";
import React, { Fragment } from "react";
import {
  useAsyncDebounce,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, Table } from "reactstrap";
import { DefaultColumnFilter, Filter } from "./filters";

//Import Icons
import FeatherIcon from "feather-icons-react";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <>
      <Col sm={1}>
        <div className="text-sm-end">
          <div className="search-box mt-2 d-inline-block">
            <div className="position-relative">
              <label htmlFor="search-bar-0" className="search-label">Search:</label>
            </div>
          </div>
        </div>
      </Col>
      <Col sm={3}>
        <div className="search-box mb-2 d-inline-block w-100">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label w-100">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
    </>
  );
}

const DatatableContainer = ({
  columns,
  data,
  isSelectRow,
  isGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  isAddCustList,
  customPageSize,
  className,
  isAddInvoiceList,
  isAddCaseTypes,
  isAddAttributes,
  isAddAttributesets,
  isAddAttributetemplates,
  isAddAttributegroups,
  isAddQnnrType,
  isAddAttorneys,
  isAddCompanyType,
  isAddEmployeeType,
  isAddEmployerCase,
  isAddAttorneyCase,
  isEmployerEmployeeFilters,
  isDocumentFilter,
  isDocumentFilterChange,
  isInvoiceFilter,
  isInvoiceFilterChange,
  isAttorneyFilter,
  isAttorneyFilterChange,
  isEmployerFilter,
  isEmployerFilterChange,
  isFilterDisable,
  isExport,
  defaultAttorneyFilter,
  handleInvoiceClick,
  isEmployerEmployeeFiltersChange,
  isAddPayrollList,
  isAddPayrollView,
  isAddCaseForm,
  isAddCaseFormAttribute,
  isAddCaseFormAttributeSet,
  isAddCaseFormAttributeGroup,
  isAddCaseFormAttributeTemplate,
  isAddTimesheets,
  handleAddTimesheetClick,
  isEmployerTimesheetsFilter,
  isEmployerTimesheetsFilterChange,
  isEmployerEmployeeDocumentsFilter,
  isEmployerEmployeeDocumentsFilterChange,
  handleCustomerClick,
  isAddCustomerList,
  isAddPafList,
  isGeneratePafBundle,
  viewGeneratedPafBundle,
  handlePafClick,
  handleGeneratePafBundle,
  isCustomerFilter,
  isCustomerFilterChange,
  isPafFilter,
  isPafFilterChange,
  defaultFilter,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
    // attoneySelector,
    // setAttoneySelector
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: customPageSize, attoneySelector: defaultAttorneyFilter, selector: defaultFilter },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  );
  const [attoneySelector, setAttoneySelector] = React.useState('');

  const [selector, setSelector] = React.useState(1);

  const [employeeType, setEmployeeType] = React.useState('');
  const [documentType, setDocumentType] = React.useState('');


  const [search_Menu, setsearch_Menu] = React.useState(false);

  const [copyData, setCopyData] = React.useState([]);

  // const [tableData, setTableData] = useState([])

  // useEffect(() => {
  //   setTableData(data)
  // }, [])

  const exportPDF = () => {
    // setTableData(data)
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "DataTables Immidock";
    let headers;
    if (isAddCompanyType) {
      headers = [["Name", "Phone", "Email", "Active Cases", "Total Cases", "Portal Access", "Created At"]];
    } else {
      headers = [["Case", "Beneficiary", "Petitioner", "Case Type", "Created At", "Pending Status"]];
    }
    // console.log(columns.map(x => x['Header']))

    // const Dataa = data.map(elt => [elt.firstName, elt.lastName, elt.age, elt.visits, elt.progress, elt.status]);
    const Dataa = data.map(elt => elt);

    let content = {
      startY: 50,
      head: headers,
      body: Dataa
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(title + ".pdf")
  }

  //Toggle Chat Box Menus
  const toggleSearch = () => {
    setsearch_Menu(!search_Menu);
  };
  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <Fragment>
      <Row className="mb-2">

        {isAddOptions && (
          <Col sm="12">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddCustomerList && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleCustomerClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Add Customer
              </button>
            </div>
          </Col>
        )}
        {isAddPafList && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handlePafClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Generate Bundle
              </button>
            </div>
          </Col>
        )}
        {isGeneratePafBundle && (
          <Col sm="12">
            <div className="text-sm-end">
              {viewGeneratedPafBundle && (
                <a href={viewGeneratedPafBundle}><i className="bx bx-file label-icon"></i> View Generated PAF&nbsp;&nbsp;</a>
              )}
              <button
                onClick={handleGeneratePafBundle}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bxs-file-pdf me-1"></i> Generate Bundle
              </button>
            </div>
          </Col>
        )}
        {isAddInvoiceList && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Create Invoice
              </button>
            </div>
          </Col>
        )}
        {isAddPayrollList && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Create Payroll
              </button>
            </div>
          </Col>
        )}
        {isAddQnnrType && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Add Questionnaire Type
              </button>
            </div>
          </Col>
        )}

        {isAddCompanyType && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Add Company
              </button>
            </div>
          </Col>
        )}

        {isAddEmployeeType && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Add Employee
              </button>
            </div>
          </Col>
        )}


        {isAddEmployerCase && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Add Case
              </button>
            </div>
          </Col>
        )}


        {isAddAttorneyCase && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Add Case
              </button>
            </div>
          </Col>
        )}


        {isAddAttorneys && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Add Attorney
              </button>
            </div>
          </Col>
        )}

        {isAddAttributes && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i>Add Questionnaire Attributes
              </button>
            </div>
          </Col>
        )}
        {isAddAttributesets && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Add Questionnaire Sets
              </button>
            </div>
          </Col>
        )}
        {isAddAttributegroups && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Add Questionnaire Attribute Group
              </button>
            </div>
          </Col>
        )}
        {isAddAttributetemplates && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Add Questionnaire Attribute Template
              </button>
            </div>
          </Col>
        )}
        {isAddCaseTypes && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Add Case Types
              </button>
            </div>
          </Col>
        )}

        {isAddUserList && (
          <Col sm="12">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="12">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}


        {isAddCaseForm && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Add Case Form
              </button>
            </div>
          </Col>
        )}

        {isAddCaseFormAttribute && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Add Case Form Attribute
              </button>
            </div>
          </Col>
        )}

        {isAddCaseFormAttributeGroup && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Add Case Form Attribute Group
              </button>
            </div>
          </Col>
        )}

        {isAddCaseFormAttributeSet && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Add Case Form Attribute Set
              </button>
            </div>
          </Col>
        )}

        {isAddCaseFormAttributeTemplate && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Add Case Form Attribute Template
              </button>
            </div>
          </Col>
        )}
        {isAddTimesheets && (
          <Col sm="12">
            <div className="text-sm-end">
              <button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Add Timesheets
              </button>
            </div>
          </Col>
        )}

      </Row>


      <Row>
        {isDocumentFilter && (
          <Col md={2}>
            <select
              className="form-select"
              value={documentType}
              onChange={e => {
                setDocumentType(e.target.value);
                isDocumentFilterChange(e);
              }}
            >
              {['All', 'Contracts', 'Job', 'Education', 'Immigration', 'Dependents', 'Other'].map(document => (
                <option key={document} value={document != "All" ? document : ''}>
                  {document}
                </option>
              ))}
            </select>
          </Col>
        )}

        {isEmployerEmployeeFilters && (
          <Col md={2}>
            <select
              className="form-select"
              value={employeeType}
              //onChange={isEmployerEmployeeFiltersChange}
              onChange={e => {
                setEmployeeType(e.target.value);
                isEmployerEmployeeFiltersChange(e);
              }}
            >
              {['All', 'Full Time', 'Part Time', 'Internship', '1099', 'C2C'].map((employeeType, index) => (
                <option key={index} value={index == 0 ? "All" : index}>
                  {employeeType}
                </option>
              ))}
            </select>
          </Col>
        )}

        {isCustomerFilter && (
          <Col md={2}>
            <select
              className="form-select"
              value={attoneySelector}
              defaultValue={defaultAttorneyFilter}
              onChange={e => {
                setAttoneySelector(e.target.value);
                isCustomerFilterChange(e);
              }}
            >
              {['Active', 'Inactive'].map(attoneySelector => (
                <option key={attoneySelector} value={attoneySelector}>
                  {attoneySelector}
                </option>
              ))}
            </select>
          </Col>
        )}
        {isPafFilter && (
          <Col md={2}>
            <select
              className="form-select"
              value={selector}
              defaultValue={defaultFilter}
              onChange={e => {
                setSelector(e.target.value);
                isPafFilterChange(e);
              }}
            >
              {Object.entries({ 1: 'Active', 0: 'Inactive' }).map(([value, option]) => (
                <option key={value} value={value}>
                  {option}{attoneySelector}
                </option>
              ))}
            </select>
          </Col>
        )}
        {isInvoiceFilter && (
          <Col md={2}>
            <select
              className="form-select"
              value={attoneySelector}
              defaultValue={defaultAttorneyFilter}
              onChange={e => {
                setAttoneySelector(e.target.value);
                isInvoiceFilterChange(e);
              }}
            >
              {['All', 'Open', 'Draft', 'Paid Partially', 'Paid'].map(attoneySelector => (
                <option key={attoneySelector} value={attoneySelector}>
                  {attoneySelector}
                </option>
              ))}
            </select>
          </Col>
        )}
        {isAttorneyFilter && (
          <Col md={2}>
            <select
              className="form-select"
              value={attoneySelector}
              defaultValue={defaultAttorneyFilter}
              onChange={e => {
                setAttoneySelector(e.target.value);
                isAttorneyFilterChange(e);
              }}
            >
              {['All', 'Active', 'Inactive', 'Pending'].map(attoneySelector => (
                <option key={attoneySelector} value={attoneySelector}>
                  {attoneySelector}
                </option>
              ))}
            </select>
          </Col>
        )}
        {isEmployerFilter && (
          <Col md={2}>
            <select
              className="form-select"
              value={attoneySelector}
              defaultValue={defaultAttorneyFilter}
              onChange={e => {
                setAttoneySelector(e.target.value);
                isEmployerFilterChange(e);
              }}
            >
              {['All', 'Active', 'Inactive', 'Pending'].map(attoneySelector => (
                <option key={attoneySelector} value={attoneySelector}>
                  {attoneySelector}
                </option>
              ))}
            </select>
          </Col>
        )}

        {isEmployerTimesheetsFilter && (
          <Col md={2}>
            <select
              className="form-select"
              value={attoneySelector}
              defaultValue={defaultAttorneyFilter}
              onChange={e => {
                setAttoneySelector(e.target.value);
                isEmployerTimesheetsFilterChange(e);
              }}
            >
              {['All', 'Submitted', 'Approved', 'Not Submitted', 'Reject'].map(attoneySelector => (
                <option key={attoneySelector} value={attoneySelector}>
                  {attoneySelector}
                </option>
              ))}
            </select>
          </Col>
        )}
        {isEmployerEmployeeDocumentsFilter && (
          <Col md={2}>
            <select
              className="form-select"
              value={attoneySelector}
              defaultValue={defaultAttorneyFilter}
              onChange={e => {
                setAttoneySelector(e.target.value);
                isEmployerEmployeeDocumentsFilterChange(e);
              }}
            >
              {['All', 'Contracts', 'Job', 'Education', 'Immigration', 'Dependents', 'Other'].map(attoneySelector => (
                <option key={attoneySelector} value={attoneySelector}>
                  {attoneySelector}
                </option>
              ))}
            </select>
          </Col>
        )}
        {(isSelectRow == undefined || isSelectRow) && (
          <>
            <Col md={2}>
              <label>Show&nbsp;
                <select
                  className="form-select"
                  style={{
                    width: "auto",
                    display: "inline-block"
                  }}
                  value={pageSize}
                  onChange={onChangeInSelect}
                >
                  {Array.from({ length: ((data.length % 10 > 0) ? 1 : 0) + (data.length / 10) }, (v, i) => i + 1 + "0").map(pageSize => (
                    <option key={+pageSize} value={+pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
                &nbsp;entries</label>
            </Col>
          </>
        )}
        {isExport && (
          <Col sm={3}></Col>
        )}
        {!isEmployerEmployeeFilters && !isExport && (
          <Col sm={isFilterDisable ? 6 : 4}></Col>
        )}
        {isEmployerEmployeeFilters && (
          <Col sm={2}></Col>
        )}
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        {isExport && (
          <Col sm={1}>
            <div className="dropdown">
              <Dropdown
                isOpen={search_Menu}
                toggle={toggleSearch}
              >
                <DropdownToggle
                  className="btn btn-ghost-secondary btn-icon"
                  tag="button"
                >
                  <FeatherIcon
                    icon="more-horizontal"
                    className="icon-sm"
                  />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className="dropdown-item"
                  ><CSVLink
                    data={data}
                    filename="DataTables Immidock.csv"
                  // className="dropdown-item"
                  >Export Excel
                    </CSVLink>
                  </DropdownItem>
                  {/* <DropdownItem
                    onClick={() => exportPDF()}
                    className="dropdown-item"
                  >
                    Export PDF
                  </DropdownItem> */}

                </DropdownMenu>
              </Dropdown>
              {/* </Link> */}
            </div>
          </Col>
        )}

      </Row>

      <div className="">
        {/* table-responsive */}
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {isAddPayrollView && (
              <tr role="row"><th rowSpan="1" colSpan="1"></th><th rowSpan="1" colSpan="1"></th><th colSpan="2" rowSpan="1">Hours</th><th colSpan="2" rowSpan="1">Bill Rates</th><th rowSpan="1" colSpan="1"></th><th colSpan="2" rowSpan="1">Pay Rates</th><th rowSpan="1" colSpan="1"></th><th rowSpan="1" colSpan="1"></th><th rowSpan="1" colSpan="1"></th><th rowSpan="1" colSpan="1"></th><th rowSpan="1" colSpan="1"></th><th rowSpan="1" colSpan="1"></th><th rowSpan="1" colSpan="1"></th><th rowSpan="1" colSpan="1"></th></tr>
            )}
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}

          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

DatatableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default DatatableContainer;