import React, { useState } from 'react';

const CarouselPage = (props) => {

    return (
        <React.Fragment>
            <div className="col-xxl-9 col-lg-8 col-md-7">
                <div className="auth-bg pt-md-5 p-4 d-flex">
                    <div className="bg-overlay bg-primary"></div>
                    <ul className="bg-bubbles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-xl-7">
                            <div className="p-0 p-sm-4 px-xl-0">
                                <div id="reviewcarouselIndicators" className="carousel slide" data-bs-ride="carousel">
                                    <h2 className=" text-white">Immigration Case Management and HR Compliance Platform </h2>
                                    <div className="carousel-item active">
                                        <div className="testi-contain text-white">
                                            <div className="mt-4 pt-3 pb-5">
                                                <div className="d-flex align-items-start">
                                                    <div className="flex-grow-1 ms-3 mb-4">
                                                        <p className="font-size-18 text-white">ImmiDock is an Immigration compliance software designed for Immigration Lawfirms and Consulting & Staffing companies.</p>
                                                        <p className="font-size-18 text-white">ImmiDock brings Law Firms and Consulting Firms closer for a better management of Immigration cases and reduce the communication gap for a better experience.
                                                        </p>
                                                        <p className="font-size-18 text-white">ImmiDock is cloud-based and scalable. In addition to immigration compliance, we do offer end to end HR Suite for consulting firms for day to day operations which makes ImmiDock a unique product.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CarouselPage;