import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row, Form, Label, Input, Table, Modal, ModalBody, FormFeedback } from "reactstrap"
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DatatableContainer from "../../../components/Common/DatatableContainer";

// Formik validation
import * as Yup from "yup";
import Dropzone from "react-dropzone";
import moment from "moment";
import { Formik, useFormik, Field, FieldArray, FormikProvider } from "formik"

import { getEmployerTimesheetDetail, getEmployerTimesheets, updateEmployerTimesheetStatus } from "../../../store/actions"
//redux
import { useSelector, useDispatch } from "react-redux";



const FormLayoutsBasic = () => {

  const BreadcrumbData = [
    { name: 'Timesheet', url: '/employer/timesheets' },
    { name: 'View Timesheet', url: '/view-timesheet' }
  ]

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showaddTimesheetModal, setshowaddTimesheetModal] = useState(false);
  const [showTimesheetRejectModal, setshowTimesheetRejectModal] = useState(false);
  const [selectedTimesheetDatefromState, setselectedTimesheetDatefromState] = useState({ timesheetInfo: [] })
  const [timesheetActionFormData, setTimesheetActionFormData] = useState({
    "timesheet_id": "",
    "status": "",
    "comment": ""
  })

  const queryParams = new URLSearchParams(location.search);
  const timesheet_id = queryParams.get('timesheet-id'); // Read the 'id' query parameter from the URL

  useEffect(() => {
    dispatch(getEmployerTimesheetDetail({ "timesheet_id": timesheet_id }));
  }, [dispatch]);

  const { selectedtimesheetDetails } = useSelector((state) => ({
    //selectedtimesheetDetails: state.EmployerTimesheets.employerTimesheetDetail.data?.timeSheetDetails || [],
    selectedtimesheetDetails: state.EmployerTimesheets.employerTimesheetDetail || [],
  }));

  useEffect(() => {
    if (selectedtimesheetDetails?.timeSheetDetails)
      setRowAndColumnTotals();
  }, [selectedtimesheetDetails?.timeSheetDetails]);

  const [rowsTotalHours, setRowsTotalHours] = useState([]);
  const [colsTotalHours, setColsTotalHours] = useState({ columnsTotalHours: [] });
  var curreTotalTimesheetColumnItems = [], totalTimesheetHours = 0;
  const setRowAndColumnTotals = () => {
    //var timesheetTempArray = selectedtimesheetDetails.timesheet_items;
    //timesheetTempArray.timesheet_items.push([rowIndex]);
    //timesheetTempArray.timesheet_items[rowIndex] = { total: 0, columns: [] }
    //var colTempTotalHours = 0;
    selectedtimesheetDetails?.timeSheetDetails.timesheet_items.map((timesheetInfo, index1) => {
      var calSingleRowHours = 0, colTempTotalHours = 0;
      timesheetInfo.columns.map((hoursData, index2) => {
        //timesheetTempArray.timesheet_items[rowIndex].columns[rowFieldIndex] = { hours: 0, date: date }
        calSingleRowHours += parseInt(hoursData.hours)
        var oldColumnHours = curreTotalTimesheetColumnItems[index2] ?? 0;
        curreTotalTimesheetColumnItems[index2] = parseInt(hoursData.hours) + oldColumnHours;
        //setColsTotalHours[index2] = colTempTotalHours;
        //setColsTotalHours[index2], curreTotalTimesheetColumnItems[index2];
      })
      setRowsTotalHours[index1] = calSingleRowHours;
    });
    //setColsTotalHours[curreTotalTimesheetColumnItems]; 

    setColsTotalHours({ ...colsTotalHours, columnsTotalHours: curreTotalTimesheetColumnItems });
    // settimesheetInitialState({ ...timesheetInitialState, timesheetItemsArr: timesheetTempArray });
    // console.log(timesheetInitialState, '--------- state after + click ----------')
  }


  var timesheetNumOfDays, timesheetDataSumitObj = {};
  // const [rowInputFields, setNumOfDaysTimesheet] = useState([1,2,3,4,5,6,7]);
  // const [allTimesheetDates, setAllTimesheetDates] = useState(rowInputFields);
  const [timesheetInitialState, settimesheetInitialState] = useState({
    dates: [],
    weekDays: [],
    timesheetItemsArr: { timesheet_items: [{ project: '', activity: '', total: '0.00', columns: [] }], totals: [{ columns: [], total: 0 }], timesheetAttachments: [] }
  });
  const [periodweekDay, setPeriodweekDay] = useState([]);
  const calweekDay = ["Sun}day", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  // console.log(allTimesheetDates,'=========== object values=====');
  const [timesheetData, settimesheetData] = useState({
    project: '',
    activity: '',
    weektotalHours: 0,
    weekWorkingHours: 0,
    totalWorkHours: 0,
    dayWorkingHours: [0],
    rowInputFields: [],
    footerRowTotals: [],
    allRowsFieldData: [[]],
    rowWeektotalHours0: 0,
    rowTimesheetFieldsArr: []
    //  daytotalenteredHours0:0,
    //  daytotalenteredHours1:0,
    //  daytotalenteredHours2:0,
    //  daytotalenteredHours3:0,
    //  daytotalenteredHours4:0,
    //  daytotalenteredHours5:0,
    //  daytotalenteredHours6:0
  })
  //const [weekWorkingHours, setweekWorkingHours] = useState(0);
  const [timeperiodInfo, setTimeperiodInfo] = useState({
    startDate: '',
    endDate: '',
    vendor: '',
    numOfPeriodDays: ''
  });
  const [calcDatesfromStartDate, setCalcDatesfromStartDate] = useState({
    displayDates: {
      date: '',
      weekDay: ''
    }
  });

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      timesheet_items: [{ project: '', activity: '', enteredWorkhours: 0 }],
      project_id: '',
      activity_id: '',
      eachTimeEntry: 0,
      weekTotal: 0,
      dayTotal: 0,
      overAllToatl: 0,
      numOfDaysFieldsArray: [],
      rowIndex: 0
    },
  });
  var rowFieldsArrayCalArr = [], tempFieldValArr = [[], []];
  const [moreActions, setMoreActions] = useState('');
  const moreactions = [
    { label: "Approve", action: "Approved" },
    { label: "Reject", action: "Reject" },
    // { label: "Print", action: "Print" }
  ];

  const addNewTimesheetRow = (rowIndex) => {
    var timesheetTempArray = timesheetInitialState.timesheetItemsArr;
    timesheetTempArray.timesheet_items.push([rowIndex]);
    timesheetTempArray.timesheet_items[rowIndex] = { total: 0, columns: [] }
    timesheetInitialState.dates.map((date, rowFieldIndex) => {

      timesheetTempArray.timesheet_items[rowIndex].columns[rowFieldIndex] = { hours: 0, date: date }
    })

    settimesheetInitialState({ ...timesheetInitialState, timesheetItemsArr: timesheetTempArray });
  }
  const [file, setFile] = useState();
  const addTimesheetAttachments = (e) => {
    setFile(e.target.files[0]);
  }

  const removeTimesheetItem = (rowIndex) => {
    alert(rowIndex);
    var updatedTimesheetItemsArr = timesheetInitialState.timesheetItemsArr;
    timesheetData.rowInputFields.forEach((val, colIndex) => {
      updatedTimesheetItemsArr.timesheet_items[rowIndex].columns[colIndex].hours = 0;
    })

    settimesheetInitialState({ ...timesheetInitialState, timesheetItemsArr: updatedTimesheetItemsArr })
    calcTimesheetItems(rowIndex, '');
  }

  var totalsWorkedHoursVar = 0
  const setTimesheetItems = (e, rowIndex, colIndex) => {
    var updatedTimesheetItemsArr = timesheetInitialState.timesheetItemsArr;
    var timesheetFieldValue = e.target.value == '' ? 0 : parseInt(e.target.value);
    updatedTimesheetItemsArr.timesheet_items[rowIndex].columns[colIndex].hours = timesheetFieldValue;

    settimesheetInitialState({ ...timesheetInitialState, timesheetItemsArr: updatedTimesheetItemsArr })

    calcTimesheetItems(rowIndex, colIndex)
  }
  const calcTimesheetItems = (rowIndex, colIndex) => {
    var updatedTimesheetItemsArr = timesheetInitialState.timesheetItemsArr;

    var currTotalTimesheetRowItems = updatedTimesheetItemsArr.timesheet_items[rowIndex].columns.reduce((total, item) => parseInt(item.hours) + total, 0);
    updatedTimesheetItemsArr.timesheet_items[rowIndex].total = currTotalTimesheetRowItems;

    var curreTotalTimesheetColumnItems = [], totalTimesheetHours = 0;

    updatedTimesheetItemsArr.timesheet_items.map((item, index) => {
      item.columns?.map((data, index) => {
        var oldColumnHours = curreTotalTimesheetColumnItems[index] ?? 0;
        curreTotalTimesheetColumnItems[index] = data.hours + oldColumnHours;
        totalTimesheetHours += data.hours;
      })
    })
    updatedTimesheetItemsArr.totals.columns = curreTotalTimesheetColumnItems;
    updatedTimesheetItemsArr.totals.total = totalTimesheetHours;
    settimesheetInitialState({ ...timesheetInitialState, timesheetItemsArr: updatedTimesheetItemsArr })

  }

  const handleAddTimesheetClick = (data) => {
    if (data.endDate !== "") {
      setshowaddTimesheetModal(false);
      var d1 = new Date(data.startDate);
      var d2 = new Date(data.endDate);
      var dateDiff = d2.getTime() - d1.getTime();
      dateDiff = dateDiff / (1000 * 60 * 60 * 24)
      timesheetNumOfDays = new Array(parseInt(dateDiff));
      timesheetNumOfDays.fill(0);
      validation.initialValues.numOfDaysFieldsArray.concat([12])
      var timesheetTypeVal;
      if (data.time_period === '7')
        timesheetTypeVal = 'Weekly'
      else if (data.time_period === '14')
        timesheetTypeVal = 'BiWeekly'
      else if (data.time_period === '15')
        timesheetTypeVal = 'Semi Monthly'
      else if (data.time_period === '30')
        timesheetTypeVal = 'Monthly'
      settimesheetData({ ...timesheetData, rowInputFields: timesheetNumOfDays, footerRowTotals: timesheetNumOfDays });
      setTimeperiodInfo({
        ...timeperiodInfo, startDate: data.startDate, endDate: data.endDate,
        vendor: data.client_id, numOfPeriodDays: timesheetTypeVal
      });
      showTimesheetDates(data);
    }
  }
  const onCloseClick = () => {

  }
  const showTimesheetDates = (data) => {
    var temparr = [];
    var tempWeekDay = [];
    var subseqFullDates = [];
    var j = 0, k = 0;
    var subseqstartDate = new Date(data.actualStartDate);
    var selectedMonthLastDate = new Date(subseqstartDate.getFullYear(), subseqstartDate.getMonth(), 0);
    selectedMonthLastDate = selectedMonthLastDate.getDate().toString();

    timesheetNumOfDays.forEach((num, i) => {
      var updateDate;
      //console.log(startDate.setDate(startDate.getDate() + (i+1)),'======');
      if (subseqstartDate.getDate() + i > selectedMonthLastDate) {
        var temp2 = 1 + k;
        temp2 = '0' + temp2;
        temparr.push(temp2);
        var setSeqDate = temp2 + '/' + subseqstartDate.getMonth() + '/' + subseqstartDate.getFullYear();
        subseqFullDates.push(setSeqDate)
      } else {
        temparr.push(subseqstartDate.getDate() + i);
      }
      if (subseqstartDate.getDay() + i > 7) {
        var tmpCount = 1 + j; tmpCount = tmpCount === 8 ? 1 : tmpCount;
        tempWeekDay.push(tmpCount);
        j + 1 > 7 ? j = 0 : j = j + 1;
      } else {
        tempWeekDay.push(subseqstartDate.getDay() + i);
      }

      // if(subseqstartDate.getDay()+i > 7) {
      //   j=j+1;
      // }
      if (subseqstartDate.getDate() + i > selectedMonthLastDate) {
        k = k + 1;
      }

    });
    var timesheetTempArray = timesheetInitialState.timesheetItemsArr;

    //timesheetTempArray.push([0]);

    //timesheetTempArray[0] = { total: 2, columns: [] }
    subseqFullDates.map((date, rowFieldIndex) => {


      timesheetTempArray.timesheet_items[0].columns[rowFieldIndex] = { hours: 0, date: date }

    })
    settimesheetInitialState({ ...timesheetInitialState, dates: temparr, weekDays: tempWeekDay, timesheetItemsArr: timesheetTempArray });
  }

  const onValChange = (e, rowIndex, selectType) => {
    var updatedTimesheetItemsArr = timesheetInitialState.timesheetItemsArr;
    if (e.target.id == 'project') {
      var timesheetRowProject = e.target.value == '' ? 0 : e.target.value;
      updatedTimesheetItemsArr.timesheet_items[rowIndex].project = timesheetRowProject;
    } else {
      var timesheetRowActivity = e.target.value == '' ? 0 : e.target.value;
      updatedTimesheetItemsArr.timesheet_items[rowIndex].activity = timesheetRowActivity;
    }
    settimesheetInitialState({ ...timesheetInitialState, timesheetItemsArr: updatedTimesheetItemsArr })
  }

  const onTimesheetSubmit = () => {
    var timesheet_items = timesheetInitialState.timesheetItemsArr.timesheet_items;
    timesheetDataSumitObj = {
      ...timesheetDataSumitObj,
      customer_id: timeperiodInfo.vendor,
      timesheet_type: timeperiodInfo.numOfPeriodDays,
      from_date: timeperiodInfo.startDate,
      to_date: timeperiodInfo.endDate,
      add_document_proof: '',
      total_hours: timesheetInitialState.timesheetItemsArr.totals.total,
      timesheet_items,
    }
    dispatch(addTimesheet(timesheetDataSumitObj));
    navigate("/employee/timesheets");
    //e.preventDefault();
  }

  const onReasonUpdate = (e) => {
    setTimesheetActionFormData({ ...timesheetActionFormData, comment: e.target.value })
  }

  const onTimesheeViewActionChange = (e) => {
    e.preventDefault();
    setMoreActions(e.target.value);
    setTimesheetActionFormData({ ...timesheetActionFormData, status: e.target.value, timesheet_id: timesheet_id })
    if (e.target.value === "Approved" || e.target.value === "Reject") {
      rejectvalidation.setFieldValue("status", e.target.value)
      setshowTimesheetRejectModal(true);
    }
  }


  const closeModal = () => {
    setMoreActions('');
    setTimesheetActionFormData({ ...timesheetActionFormData, status: "" })
    setshowTimesheetRejectModal(false)
  }

  let title = timesheet_id ? 'View Timesheet' : 'Timesheet';
  const tableTHPadding = {
    padding: "0.1rem 0.75rem",
    textAlign: "center"
  };

  const tableInputPadding = {
    textAlign: "center"
  }

  const tableFootPadding = {
    textAlign: "center"
  }

  const tableBodyTd = {
    border: "none"
  }


  // Form validation 
  const rejectvalidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      "timesheet_id": timesheet_id,
      "status": "",
      "comment": ""
    },
    validationSchema: Yup.object({
      comment: Yup.string().when('status', {
        is: (val) => val !== null && val.includes("Reject"),
        then: Yup.string()
          .required("Please Enter the reason for rejection").nullable(),
        otherwise: () => Yup.string().nullable(),
      })
    }),
    onSubmit: (values) => {
      if (values) {
        dispatch(updateEmployerTimesheetStatus(values));
        setTimeout(() => {
          dispatch(getEmployerTimesheets({ "status": "All" }));
          navigate("/employer/timesheets");
        }, 500)
      }
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>


          <Breadcrumbs BreadcrumbData={BreadcrumbData} />
          <div style={{ width: "18%", position: "relative", float: "right", marginBottom: "30px" }}>
            <Input
              id="timesheetViewPageAction"
              type="select"
              onChange={(e) => onTimesheeViewActionChange(e)}
              name="timesheetViewPageAction"
              value={moreActions || ''}
            >
              <option value="">More Actions</option>
              {moreactions.map((moreaction, index) => (
                <option
                  key={moreaction.action + index}
                  value={moreaction.action}
                >
                  {moreaction.label}
                </option>
              ))}
            </Input>
          </div>
          <Table>
            {/* <th style={{ width: "16%" }}>Timesheet Period <br /></th>
            <th>Client/ Vendor<br /> </th> */}

            <th style={{ maxWidth: "120px" }}>Timesheet Period <br />{selectedtimesheetDetails?.timeSheetDetails?.from_date} - {selectedtimesheetDetails?.timeSheetDetails?.to_date}</th>
            <th>Client/ Vendor<br /> {selectedtimesheetDetails?.timeSheetDetails?.client}</th>
            {selectedtimesheetDetails?.timeSheetDetails?.status === "Approved" ? (
              <th>Approved Date<br /> {selectedtimesheetDetails?.timeSheetDetails?.approved_date}</th>
            ) : ''}
            {selectedtimesheetDetails?.timeSheetDetails?.status === "Approved" ? (
              <th>Submitted Date<br /> {selectedtimesheetDetails?.timeSheetDetails?.submitted_date}</th>
            ) : ''}
            {selectedtimesheetDetails?.timeSheetDetails?.status === "Approved" ? (
              <th>Approved By<br /> {selectedtimesheetDetails?.timeSheetDetails.approver_first_name} {selectedtimesheetDetails?.timeSheetDetails.approver_last_name} </th>
            ) : ''}
            <th>Status<br /> {selectedtimesheetDetails?.timeSheetDetails?.status}</th>
            <th>Total Hours<br /> {selectedtimesheetDetails?.timeSheetDetails?.total_hours}</th>
          </Table><br />
          <Row>


            <Formik
              initialValues={{ numOfRows: ['1'] }}
              // onSubmit={values =>
              //   setTimeout(() => {
              //     alert(JSON.stringify(values, null, 2));
              //   }, 500)
              // }
              render={({ values }) => (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onTimesheetSubmit();
                    return false;
                  }}
                >
                  <FieldArray
                    name="numOfRows"
                    render={arrayHelpers => (
                      <div>
                        {values.numOfRows && values.numOfRows.length > 0 ? (

                          <Table responsive>
                            {values.numOfRows.map((friend, idx) => (
                              (idx === 0) ? (
                                <thead key={idx}>

                                  {selectedtimesheetDetails?.timeSheetDetails?.timesheet_items.map((dateItems, dateIndex) => (
                                    dateIndex == 0 ? (
                                      <React.Fragment key={dateIndex}>
                                        <tr style={{ border: "hidden" }} key={dateIndex}>
                                          <th scope="col" style={tableTHPadding}>
                                            Projects
                                          </th>
                                          <th scope="col" style={tableTHPadding}>
                                            Activity
                                          </th>

                                          {dateItems.columns.map((dayItem, i) => (
                                            <th key={i} style={tableTHPadding} scope="col">
                                              <label>{new Date(dayItem.date).getDate()}</label>
                                            </th>
                                          ))}


                                          <th scope="col" style={tableTHPadding}>
                                            <label>Total</label>
                                          </th>
                                          <th scope="col" style={tableTHPadding}>
                                          </th>

                                        </tr>
                                        <tr style={{ lineHeight: "0.3" }} key={idx + 1}>
                                          <th style={tableTHPadding}></th>
                                          <th style={tableTHPadding}></th>

                                          {dateItems.columns.map((item, i) => (
                                            <th key={i} style={tableTHPadding}>
                                              <label>{(item.date)?.substring(0, 3)}</label>
                                            </th>
                                          ))}

                                          <th scope="col" style={tableTHPadding}>
                                          </th>
                                        </tr>
                                      </React.Fragment>
                                    ) : ''
                                  ))}

                                </thead>
                              ) : (<span key={idx}></span>)
                            ))}

                            <tbody>
                              {selectedtimesheetDetails?.timeSheetDetails?.timesheet_items.map((timesheetData, index) => (
                                <tr key={index}>
                                  <td style={tableBodyTd}>
                                    <Input
                                      readOnly
                                      type="text"
                                      value={timesheetData.project_name}
                                      name={`items.${index}.project_name`}
                                    >
                                    </Input>
                                  </td>
                                  <td style={tableBodyTd}>
                                    <Input
                                      readOnly
                                      type="text"
                                      value={timesheetData.activity}
                                      name={`items.${index}.activity`}
                                    >
                                    </Input>
                                  </td>

                                  <React.Fragment>
                                    {timesheetData.columns.map((timeItem, colIndex) => (
                                      <td key={colIndex} style={tableBodyTd}>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          readOnly
                                          style={tableInputPadding}
                                          value={timeItem.hours}
                                          name={`timesheet_items.${colIndex}.enteredWorkhours`}
                                        />
                                      </td>
                                    ))}
                                  </React.Fragment>

                                  <td style={tableBodyTd}>
                                    <label className="col-form-label form-label" style={{ textAlign: "center" }} name={`rowWeektotalHours${index}`} >
                                      {/* {timesheetInitialState.timesheetItemsArr.timesheet_items?.[`${index}`]?.total ?? 0} */}
                                      {setRowsTotalHours[index]}
                                    </label>
                                  </td>
                                  {/* <td style={tableBodyTd}>
                                    {values.numOfRows.length > 1 ? (
                                      <i className="bx bx-trash" style={{"cursor":"pointer","margin-top":"10px"}} onClick={() => (arrayHelpers.remove(index), removeTimesheetItem(index))} ></i>
                                    ) : <span></span>}
                                  </td>
                                  <td style={tableBodyTd}>
                                    {values.numOfRows.length === index + 1 ? (
                                      <i className="bx bx-plus-circle" style={{"cursor":"pointer","margin-top":"10px"}} onClick={(e) => (arrayHelpers.push({ name: '', age: '' }), addNewTimesheetRow(index + 1))}></i>
                                    ) : (<span></span>)}
                                  </td> */}
                                </tr>

                              ))}

                            </tbody>


                            <tfoot>
                              {/* {selectedtimesheetDetails?.timesheet_items.map((timesheetData, index2) => (  */}
                              <tr className="table-light" >
                                <td colSpan={1}></td>
                                <td colSpan={1}></td>

                                {/* {selectedtimesheetDetails?.timeSheetDetails?.timesheet_items?.map((timesheetData, index) => (
                                      <td key={index}>
                                        <label className="col-form-label form-label" style={tableFootPadding} >
                                          {colsTotalHours[index] ?? 0}
                                        </label> 
                                      </td> 
                                    ))} */}

                                {selectedtimesheetDetails?.timeSheetDetails?.timesheet_items?.map((timesheetData, index) => (
                                  index === 0 ?
                                    timesheetData.columns?.map((timesheetData, index2) => (
                                      <td key={index2}>
                                        <label className="col-form-label form-label" style={tableFootPadding} >
                                          {colsTotalHours.columnsTotalHours[index2]}
                                        </label>
                                      </td>
                                    ))
                                    : ''))}
                                <td>
                                  <label className="col-form-label form-label" style={tableFootPadding}>
                                    {selectedtimesheetDetails?.timeSheetDetails?.total_hours ?? 0}
                                  </label>
                                </td>
                                <td>

                                </td>
                              </tr>

                            </tfoot>


                          </Table>

                        ) : (
                          <button type="button" onClick={() => arrayHelpers.push('')}>
                            {/* show this when user has removed all numOfRows from the list */}
                            Add
                          </button>
                        )}

                      </div>
                    )}
                  />
                  <br />
                  <Row className="mb-4">

                    <Col sm={12}>
                      <Label
                        htmlFor="horizontal-firstname-input"
                        className="col-form-label"
                      >
                        Attachment:&nbsp;&nbsp;&nbsp;<a target="_blank" rel="noreferrer" href={selectedtimesheetDetails?.timeSheetDetails?.add_document_proof} className="align-middle font-size-15">{selectedtimesheetDetails?.timeSheetDetails?.attachment_name}</a>
                      </Label>
                    </Col>
                  </Row>

                  {/* <Row>
                    <Col>
                      <div className="text-center mt-3">
                        <button
                          type="submit"
                          className="ms-1 btn btn-primary btn-lg ms-2"
                        >
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row> */}
                </Form>

              )}
            />

          </Row>


        </Container>
      </div>
      <Modal isOpen={showTimesheetRejectModal} toggle={onCloseClick} centered={true}>
        <ModalBody className="py-3 px-5">

          <FormikProvider value={validation}>
            <Form className="needs-validation"
              onSubmit={(values) => {
                values.preventDefault();
                if (_.size(validation.errors) > 0) {
                  const errorObj = { "response": { "status": "400", "data": { "message": "Error - Please fill required form fields" } } }
                  dispatch(setAlertMessage({ message: errorObj, type: "danger" }));
                }
                rejectvalidation.handleSubmit();
                return false;
              }}
            >
              {(timesheetActionFormData.status === "Reject") ? (
                <React.Fragment>
                  <Row>
                    <Col lg={12}>
                      {/* <div className="text-center">
                    <h4>{"Reject Timesheet"}</h4>
                  </div> */}
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col sm={12}>
                      <h6>{"Reject Timesheet"}</h6>
                      <Input
                        rows={5}
                        type="textarea"
                        id="comment"
                        name="comment"
                        className={`form-control ${rejectvalidation.touched.comment && rejectvalidation.errors.comment ? 'is-invalid' : ''}`}
                        value={rejectvalidation.values.comment || ""}
                        onChange={(e) => {
                          rejectvalidation.handleChange(e);
                          // onReasonUpdate(e)
                        }}
                      ></Input>
                      {rejectvalidation.touched.comment && rejectvalidation.errors.comment ? (
                        <FormFeedback type="invalid">{rejectvalidation.errors.comment}</FormFeedback>
                      ) : null}
                    </Col>
                  </Row>

                  <Row style={{ float: "right", marginRight: "73px" }}>
                    <Col >
                      <span className="mt-4">
                        <button type="reset" onClick={closeModal} className="btn btn-secondary">
                          Cancel
                        </button>
                      </span>
                    </Col>

                    <Col >
                      <span className="mt-4">
                        <button type="submit" className="btn btn-primary">
                          Save
                        </button>
                      </span>
                    </Col>
                  </Row>
                </React.Fragment>
              ) :
                <React.Fragment>
                  <Row>
                    <Col lg={12}>
                      <div className="text-center">
                        <h4>{"Are you sure you want to approve the Timesheet ?"}</h4>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ float: "right", marginTop: '25px', marginRight: '73px' }}>
                    <Col >
                      <span className="mt-4">
                        <button type="reset" onClick={closeModal} className="btn btn-secondary">
                          Close
                        </button>
                      </span>
                    </Col>

                    <Col >
                      <span className="mt-4">
                        <button type="submit" className="btn btn-primary">
                          Approve
                        </button>
                      </span>
                    </Col>
                  </Row>

                </React.Fragment>
              }
            </Form>
          </FormikProvider>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default FormLayoutsBasic;