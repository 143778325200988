import {
  ADD_ATTORNEYCASE_DETAIL_FAIL,
  ADD_ATTORNEYCASE_DETAIL_SUCCESS,
  ADD_ATTORNEY_DOCUMENT_UPLOAD_FAIL,
  ADD_ATTORNEY_DOCUMENT_UPLOAD_SUCCESS,
  BUNDLE_CASEFORMS_FAIL,
  BUNDLE_CASEFORMS_SUCCESS,
  SAVE_CASEFORMS_FAIL,
  SAVE_CASEFORMS_SUCCESS,
  DELETE_ATTORNEYCASE_DETAIL_FAIL,
  DELETE_ATTORNEYCASE_DETAIL_SUCCESS,
  GENERATE_FORM_DETAIL_FAIL,
  GENERATE_FORM_DETAIL_SUCCESS,
  GENERATE_FORM_FAIL,
  GENERATE_FORM_SUCCESS,
  GET_ATTORNEYCASES_FAIL,
  GET_ATTORNEYCASES_SUCCESS,
  GET_ATTORNEYCASETYPES_FAIL,
  GET_ATTORNEYCASETYPES_SUCCESS,
  GET_ATTORNEYCASE_DETAIL_FAIL,
  GET_ATTORNEYCASE_DETAIL_SUCCESS,
  GET_ATTORNEYCASE_DOCUMENTS_FAIL,
  GET_ATTORNEYCASE_DOCUMENTS_SUCCESS,
  GET_ATTORNEYCASE_FORMS_FAIL,
  GET_ATTORNEYCASE_FORMS_SUCCESS,
  GET_ATTORNEYCASE_QNNR_DETAILS_FAIL,
  GET_ATTORNEYCASE_QNNR_DETAILS_SUCCESS,
  GET_ATTORNEYQNNRTYPES_FAIL,
  GET_ATTORNEYQNNRTYPES_SUCCESS,
  GET_CASE_TIMELINES_FAIL,
  GET_CASE_TIMELINES_SUCCESS,
  REQUEST_ATTORNEY_DOCUMENT_FAIL,
  REQUEST_ATTORNEY_DOCUMENT_SUCCESS,
  REVOKE_ATTORNEY_FORM_SHARE_REVIEW_FAIL,
  REVOKE_ATTORNEY_FORM_SHARE_REVIEW_SUCCESS,
  SAVE_CASE_FORM_TEMPLATE_DATA_FAIL,
  SAVE_CASE_FORM_TEMPLATE_DATA_SUCCESS,
  SORT_CASEFORMS_FAIL,
  SORT_CASEFORMS_SUCCESS,
  UDATE_ATTORNEY_FORM_SHARE_FAIL,
  UDATE_ATTORNEY_FORM_SHARE_SUCCESS,
  UDATE_ATTORNEY_LCADETAILS_FAIL,
  UDATE_ATTORNEY_LCADETAILS_SUCCESS,
  UDATE_ATTORNEY_UNLOCK_QUNNR_FAIL,
  UDATE_ATTORNEY_UNLOCK_QUNNR_SUCCESS,
  UPDATE_ATTORNEYCASE_DETAIL_FAIL,
  UPDATE_ATTORNEYCASE_DETAIL_SUCCESS,


  GET_NOTE_SUCCESS,
  GET_NOTE_FAIL,
  GET_REMINDER_SUCCESS,
  GET_REMINDER_FAIL,
  GET_MESSAGE_SUCCESS,
  GET_MESSAGE_FAIL,

  SAVE_NOTE_SUCCESS,
  SAVE_NOTE_FAIL,
  SAVE_REMINDER_SUCCESS,
  SAVE_REMINDER_FAIL,
  SAVE_MESSAGE_SUCCESS,
  SAVE_MESSAGE_FAIL,

  SHARE_UPLOADED_DOCUMENT_ATTORNEY_SUCCESS,
  SHARE_UPLOADED_DOCUMENT_ATTORNEY_FAIL,

  SEND_REMINDER_DOCUMENT_ATTORNEY_SUCCESS,
  SEND_REMINDER_DOCUMENT_ATTORNEY_FAIL,

  GET_CASE_DEPENDENTS_ATTORNEY_SUCCESS,
  GET_CASE_DEPENDENTS_ATTORNEY_FAIL,
  BUNDLE_CASE_DOCUMENTS_FAIL,
  BUNDLE_CASE_DOCUMENTS_SUCCESS,

  GET_EMPLOYER_EMPLOYEES_SUCCESS,
  GET_EMPLOYER_EMPLOYEES_FAIL

} from "./actionTypes"

const INIT_STATE = {
  changecasestatus: {},
  attorneycase: [],
  generateform: [],
  generateformDetail: [],
  updateattorneycase: {},
  caseDetail: {},
  caseQnnrDetails: [],
  caseTimelines: [],
  caseForms: [],
  caseDocuments: [],
  attorneyCaseTypes: [],
  attorneyQnnrTypes: [],
  bundlecaseforms: "",
  bundlecasedocs: "",
  savecaseforms: {},
  caseformtemplatedata: {},
  error: {},
  savenote: {},
  savereminder: {},
  savemessage: {},
  casenote: {},
  casereminder: {},
  casemessage: {},
  shared_uploaded: {},
  sendReminderDocument: {},
  casedependents: [],
  casedependentsFormList: [],
  employeremployees: [],
}

const AttorneyCases = (state = INIT_STATE, action) => {
  switch (action.type) {

    case SAVE_CASE_FORM_TEMPLATE_DATA_SUCCESS:
      return {
        ...state,
        caseformtemplatedata: action.payload
      }

    case SAVE_CASE_FORM_TEMPLATE_DATA_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case GENERATE_FORM_SUCCESS:
      return {
        ...state,
        generateform: action.payload?.templateInfo,
        generateformDetail: action.payload?.groupInfo,
      }

    case GENERATE_FORM_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GENERATE_FORM_DETAIL_SUCCESS:
      return {
        ...state,
        generateformDetail: action.payload,
      }

    case GENERATE_FORM_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case GET_ATTORNEYCASES_SUCCESS:
      return {
        ...state,
        attorneycase: action.payload,
      }

    case GET_ATTORNEYCASES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SORT_CASEFORMS_SUCCESS:
      return {
        ...state,
        caseForms: action.payload,
      }

    case SORT_CASEFORMS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ATTORNEYCASE_DETAIL_SUCCESS:
      return {
        ...state,
        caseDetail: action.payload,
      }

    case GET_ATTORNEYCASE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case GET_ATTORNEYCASE_QNNR_DETAILS_SUCCESS:
      return {
        ...state,
        caseQnnrDetails: action.payload,
      }

    case GET_ATTORNEYCASE_QNNR_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CASE_TIMELINES_SUCCESS:
      return {
        ...state,
        caseTimelines: action.payload,
      }

    case GET_CASE_TIMELINES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ATTORNEYCASE_FORMS_SUCCESS:
      return {
        ...state,
        caseForms: action.payload,
      }

    case GET_ATTORNEYCASE_FORMS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UDATE_ATTORNEY_FORM_SHARE_SUCCESS:
      return {
        ...state,
        caseForms: state.caseForms.map((row) =>
          (row.case_form_id.toString() === action.payload.case_form_id.toString()) && (row.case_id.toString() === action.payload.case_id.toString())
            ? { ...row, ...action.payload, shared_form_count: action.payload.share_user === 'Both' ? 2 : action.payload.share_user === 'Employee' ? 1 : action.payload.share_user === 'Employer' ? 1 : '0', share_to_benificiery: (action.payload.share_user === 'Employee' || action.payload.share_user === 'Both') ? 1 : '0', share_to_employer: (action.payload.share_user === 'Employer' || action.payload.share_user === 'Both') ? 1 : '0', status: action.payload.share_user === 'Both' ? 'In-review With Both' : action.payload.share_user === 'Employee' ? 'Benificiery In-review' : action.payload.share_user === 'Employer' ? 'Petitioner In-review' : 'To Do' }
            : row
        ),
      }
    case UDATE_ATTORNEY_FORM_SHARE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case REVOKE_ATTORNEY_FORM_SHARE_REVIEW_SUCCESS:
      return {
        ...state,
        caseForms: action.payload.formType === 'forms' ? state.caseForms.map((row) =>
          (row.case_form_id.toString() === action.payload.case_form_id.toString()) && (row.case_id.toString() === action.payload.case_id.toString())
            ? {
              ...row, ...action.payload,
              shared_form_count: action.payload.user_type === 'Both' ? 2 : action.payload.user_type === 'Employee' ? row.shared_form_count - 1 : action.payload.user_type === 'Petitioner' ? row.shared_form_count - 1 : '0',
              share_to_benificiery: (action.payload.user_type === 'Employee') ? 0 : row.share_to_benificiery,
              share_to_employer: (action.payload.user_type === 'Petitioner') ? 0 : row.share_to_employer,
              status: action.payload.user_type === 'Both' ? 'In-review With Both' : (action.payload.user_type === 'Employee' && row.share_to_employer == '1') ? 'Petitioner In-review' : (action.payload.user_type === 'Petitioner' && row.share_to_benificiery == '1') ? 'Benificiery In-review' : 'To Do',
              share_user: action.payload.user_type === 'Petitioner' ? 'Employer' : action.payload.user_type === 'Employee' ? 'Employee' : action.payload.user_type === 'Both' ? 'Both' : ''
            }
            : row
        ) : state.caseForms,
        caseDocuments: action.payload.formType === 'documents' ? state.caseDocuments.map((row) =>
          (row.case_docs_id.toString() === action.payload.case_docs_id.toString()) && (row.case_id.toString() === action.payload.case_id.toString())
            ? {
              ...row, ...action.payload,
              shared_doc_count: action.payload.user_type === 'Both' ? 2 : action.payload.user_type === 'Employee' ? row.shared_doc_count - 1 : action.payload.user_type === 'Petitioner' ? row.shared_doc_count - 1 : '0',
              is_shared_to_employee: (action.payload.user_type === 'Employee') ? 0 : row.is_shared_to_employee,
              is_shared_to_petitioner: (action.payload.user_type === 'Petitioner') ? 0 : row.is_shared_to_petitioner,
              status: action.payload.user_type === 'Both' ? 'In-review With Both' : (action.payload.user_type === 'Employee' && row.is_shared_to_petitioner == '1') ? 'Petitioner In-review' : (action.payload.user_type === 'Petitioner' && row.is_shared_to_employee == '1') ? 'Benificiery In-review' : 'To Do',
              share_user: action.payload.user_type === 'Petitioner' ? 'Employer' : action.payload.user_type === 'Employee' ? 'Employee' : action.payload.user_type === 'Both' ? 'Both' : ''
            }
            : row
        ) : state.caseDocuments,

      }
    case REVOKE_ATTORNEY_FORM_SHARE_REVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case REQUEST_ATTORNEY_DOCUMENT_SUCCESS:
      return {
        ...state,
        caseDocuments: [...state.caseDocuments, action.payload]
      }
    case REQUEST_ATTORNEY_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_ATTORNEY_DOCUMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        caseDocuments: [...state.caseDocuments, action.payload]
      }
    case ADD_ATTORNEY_DOCUMENT_UPLOAD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_ATTORNEYCASE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        caseDocuments: action.payload,
      }

    case GET_ATTORNEYCASE_DOCUMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_ATTORNEYCASE_DETAIL_SUCCESS:
      return {
        ...state,
      }

    case ADD_ATTORNEYCASE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ATTORNEYCASE_DETAIL_SUCCESS:
      return {
        ...state,
        changecasestatus: action.payload,
      };

    case DELETE_ATTORNEYCASE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_ATTORNEYCASE_DETAIL_SUCCESS:
      return {
        ...state,
        updateattorneycase: action.payload,
      };

    case UPDATE_ATTORNEYCASE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_ATTORNEYCASETYPES_SUCCESS:
      return {
        ...state,
        attorneyCaseTypes: action.payload,
      }

    case GET_ATTORNEYCASETYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case GET_ATTORNEYQNNRTYPES_SUCCESS:
      return {
        ...state,
        attorneyQnnrTypes: action.payload,
      }

    case GET_ATTORNEYQNNRTYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UDATE_ATTORNEY_UNLOCK_QUNNR_SUCCESS:
      return {
        ...state,
        caseQnnrDetails: state.caseQnnrDetails.length > 0 ? state.caseQnnrDetails.map((row) =>
          (row.case_id.toString() === action.payload.case_id.toString())
            ? { ...row, ...action.payload }
            : row
        ) : { ...state.caseQnnrDetails, ...action.payload, is_unlock_questionaire: (action.payload.is_unlock_questionaire == "1" || action.payload.is_unlock_questionaire == 1) ? 1 : 0 },
      }
    case UDATE_ATTORNEY_UNLOCK_QUNNR_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UDATE_ATTORNEY_LCADETAILS_SUCCESS:
      return {
        ...state,
        attorneycase: action.payload,
      };

    case UDATE_ATTORNEY_LCADETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case BUNDLE_CASEFORMS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case BUNDLE_CASEFORMS_SUCCESS:
      return {
        ...state,
        bundlecaseforms: action.payload,
      }

    case BUNDLE_CASE_DOCUMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case BUNDLE_CASE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        bundlecasedocs: action.payload,
      }

    case SAVE_CASEFORMS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SAVE_CASEFORMS_SUCCESS:
      return {
        ...state,
        savecaseforms: action.payload,
      }


    case SAVE_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SAVE_NOTE_SUCCESS:
      return {
        ...state,
        savenote: action.payload,
      }

    case SAVE_REMINDER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SAVE_REMINDER_SUCCESS:
      return {
        ...state,
        savereminder: action.payload,
      }

    case SAVE_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SAVE_MESSAGE_SUCCESS:
      return {
        ...state,
        savemessage: action.payload,
      }

    case GET_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_NOTE_SUCCESS:
      return {
        ...state,
        casenote: action.payload,
      }

    case GET_REMINDER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_REMINDER_SUCCESS:
      return {
        ...state,
        casereminder: action.payload,
      }

    case GET_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MESSAGE_SUCCESS:
      return {
        ...state,
        casemessage: action.payload,
      }

    case SHARE_UPLOADED_DOCUMENT_ATTORNEY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SHARE_UPLOADED_DOCUMENT_ATTORNEY_SUCCESS:
      return {
        ...state,
        shared_uploaded: action.payload,
      }

    case SEND_REMINDER_DOCUMENT_ATTORNEY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SEND_REMINDER_DOCUMENT_ATTORNEY_SUCCESS:
      return {
        ...state,
        sendReminderDocument: action.payload,
      }

    case GET_CASE_DEPENDENTS_ATTORNEY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CASE_DEPENDENTS_ATTORNEY_SUCCESS:
      return {
        ...state,
        casedependents: action.payload,
        casedependentsFormList: action.payload ? action.payload.map(dependent => ({
          value: `${dependent?.dependent_id}`,
          label: `${dependent?.first_name} ${dependent?.last_name}`
        })) : []
      }

    case GET_EMPLOYER_EMPLOYEES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_EMPLOYER_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employeremployees: action.payload,
      }

    default:
      return state
  }
}

export default AttorneyCases
