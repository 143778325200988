import React from "react";

import EmployerTimesheets from "../../Timesheets";
import EmployerBasicSuite from "../../Basic";

const MainTimesheetComponent = () => {

  const getLoggedInPlanType = () => {
    var authUser = JSON.parse(localStorage.getItem("authUser")) ;
    return authUser?.data?.plan_type;
  }

  if(getLoggedInPlanType()==1) {
    return <EmployerTimesheets/>
  } else {
    return <EmployerBasicSuite componentName="Timesheets"/>
  }
};

export default MainTimesheetComponent;
