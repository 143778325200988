import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CASEFORMATTRIBUTEGROUPS, ADD_CASEFORMATTRIBUTEGROUP, GET_CASEFORMATTRGROUP_DETAIL, UPDATE_CASEFORMATTRIBUTEGROUP, DELETE_CASEFORM_ATTRIBUTEGROUP_DETAIL } from "./actionTypes"
import { getCaseFormAttributeGroupsSuccess, getCaseFormAttributeGroupsFail,
        addcaseformAttributegroupSuccess, addcaseformAttributegroupFail, getCaseFormAttributeGroupDetailSuccess, getCaseFormAttributeGroupDetailFail, updateCaseformAttributegroupSuccess, caseFormAttributeGroupDeleteSuccess, caseFormAttributeGroupDeleteFail } from "./actions"

//Include Both Helper File with needed methods
import { getCaseformattributegroups, addCaseformattributegroup, getCaseFormAttributeGroupDetailInfo, updateCaseformAttributeGroups, deleteCaseformAttributeGroups  } from "../../../helpers/apibackend_helper"
import { setAlertMessage } from "../../common/actions"

function* fetchCaseformattributetemp(data) {
  try {
    const response =yield call(getCaseformattributegroups,data)
    yield put(getCaseFormAttributeGroupsSuccess(response.data))
  } catch (error) {
    yield put(getCaseFormAttributeGroupsFail(error))
  }
  //document.getElementById("preloader").style.display = "none"; 
}

function* addCaseformattributegroups({ payload: caseformattributegroup }) {
  try {
    const response =yield call(addCaseformattributegroup, caseformattributegroup)
    yield put(addcaseformAttributegroupSuccess({...response.data,id:caseformattributegroup.id}))
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(addcaseformAttributegroupFail(error))
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}


function* fetchCaseFormAttributeGroupDetail({ Caseformid }) {
  try {
    if(Caseformid)
    {
      const response = yield call(getCaseFormAttributeGroupDetailInfo, Caseformid)
      yield put(getCaseFormAttributeGroupDetailSuccess(response.data))
    }
    else
    {
      yield put(getCaseFormAttributeGroupDetailSuccess({}))
    }
    
  } catch (error) {
    yield put(getCaseFormAttributeGroupDetailFail(error))
  }
}

function* onUpdateCaseFormAttributeGroupDetail({ payload: data }) {
  try {
    const response = yield call(updateCaseformAttributeGroups, data);
    yield put(updateCaseformAttributegroupSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(updateCaseformAttributesetsFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* onDeleteCaseFormAttributeGroupDetail({ payload: data }) {
  try {
    const response = yield call(deleteCaseformAttributeGroups, data);
    yield put(caseFormAttributeGroupDeleteSuccess(data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(caseFormAttributeGroupDeleteFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  }
}

function* CaseformattributesGroupssaga() {
  yield takeEvery(GET_CASEFORMATTRIBUTEGROUPS, fetchCaseformattributetemp)
   yield takeEvery(ADD_CASEFORMATTRIBUTEGROUP, addCaseformattributegroups)
  yield takeEvery(GET_CASEFORMATTRGROUP_DETAIL, fetchCaseFormAttributeGroupDetail)
  yield takeEvery(DELETE_CASEFORM_ATTRIBUTEGROUP_DETAIL, onDeleteCaseFormAttributeGroupDetail)
  yield takeEvery(UPDATE_CASEFORMATTRIBUTEGROUP, onUpdateCaseFormAttributeGroupDetail)
}

export default CaseformattributesGroupssaga
