import {
  GET_EMPLOYERS,
  GET_EMPLOYERS_FAIL,
  GET_EMPLOYERS_SUCCESS,
  GET_EMPLOYER_DETAILS,
  GET_EMPLOYER_DETAILS_SUCCESS,
  GET_EMPLOYER_DETAILS_FAIL,
  UPDATE_EMPLOYER_DETAILS,
  UPDATE_EMPLOYER_DETAILS_SUCCESS,
  UPDATE_EMPLOYER_DETAILS_FAIL

} from "./actionTypes"

export const getEmployers= Employers => ({
  type: GET_EMPLOYERS,
  payload: Employers,
})

export const getEmployersSuccess = Employers => ({
  type: GET_EMPLOYERS_SUCCESS,
  payload: Employers,
})

export const getEmployersFail = error => ({
  type: GET_EMPLOYERS_FAIL,
  payload: error,
})

export const getEmployerDetails = EmployerId => (console.log(EmployerId,'---- emp id---'),{
  type: GET_EMPLOYER_DETAILS,
  EmployerId,
})

export const getEmployerDetailsSuccess = EmployerDetails => ({
  type: GET_EMPLOYER_DETAILS_SUCCESS,
  payload: EmployerDetails,
})

export const getEmployerDetailsFail = error => ({
  type: GET_EMPLOYER_DETAILS_FAIL,
  payload: error,
})

export const updateEmployer = EmployerDetails => ({
  type: UPDATE_EMPLOYER_DETAILS,
  EmployerDetails,
})

export const updateEmployerSuccess = EmployerDetails => ({
  type: UPDATE_EMPLOYER_DETAILS_SUCCESS,
  payload: EmployerDetails,
})

export const updateEmployerFail = error => ({
  type: UPDATE_EMPLOYER_DETAILS_FAIL,
  payload: error,
})



