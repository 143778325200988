import {
  GET_CASEFORMATTRIBUTESETS_FAIL,
  GET_CASEFORMATTRIBUTESETS_SUCCESS,
  ADD_CASEFORMATTRSET_SUCCESS,
  ADD_CASEFORMATTRSET_FAIL,
  GET_CASEFORMATTRSETS_DETAIL_SUCCESS,
  GET_CASEFORMATTRSETS_DETAIL_FAIL,
  UPDATE_CASEFORM_ATTRIBUTESET_DETAIL_FAIL,
  UPDATE_CASEFORM_ATTRIBUTESET_DETAIL_SUCCESS,
  DELETE_CASEFORM_ATTRIBUTESET_DETAIL_SUCCESS,
  DELETE_CASEFORM_ATTRIBUTESET_DETAIL_FAIL,
  // GET_ATTRIBUTESET_DETAIL_SUCCESS,
  // GET_ATTRIBUTESET_DETAIL_FAIL,
  // UPDATE_ATTRIBUTESET_DETAIL_FAIL,
  // UPDATE_ATTRIBUTESET_DETAIL_SUCCESS,
  // UPDATE_ATTRIBUTESET_DETAIL,
  // DELETE_ATTRIBUTESET_DETAIL_FAIL,
  // DELETE_ATTRIBUTESET_DETAIL_SUCCESS,
  // DELETE_ATTRIBUTESET_DETAIL,
  // ADD_ATTRIBUTESET_DETAIL_SUCCESS,
  // ADD_ATTRIBUTESET_DETAIL,
  // ADD_ATTRIBUTESET_DETAIL_FAIL
} from "./actionTypes"

const INIT_STATE = {
  caseformattributesets: [],
  caseformattributeSetsFilter: [],
  caseformattributesetDetail: {},
  error: {},
}

const CaseFormAttributesets = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CASEFORMATTRIBUTESETS_SUCCESS:
      return {
        ...state,
        caseformattributesets: action.payload,
      }

    case GET_CASEFORMATTRIBUTESETS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CASEFORMATTRSETS_DETAIL_SUCCESS:
      return {
        ...state,
        caseformattributesetDetail: action.payload,
      }

    case GET_CASEFORMATTRSETS_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CASEFORMATTRSET_SUCCESS:
      return {
        ...state,
        caseformattributesets: action.payload,
      }

    case ADD_CASEFORMATTRSET_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // case DELETE_ATTRIBUTESET_DETAIL_SUCCESS:
    //   return {
    //     ...state,
    //     attributesets: state.attributesets.filter((item) => item.id !== action.payload),
    //   };

    // case DELETE_ATTRIBUTESET_DETAIL_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };

    case UPDATE_CASEFORM_ATTRIBUTESET_DETAIL_SUCCESS:
      console.log('state', state)
      return {
        ...state,
        caseformattributesets: action.payload
      };

    case UPDATE_CASEFORM_ATTRIBUTESET_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_CASEFORM_ATTRIBUTESET_DETAIL_SUCCESS:
      return {
        ...state,
        caseformattributesets: state.caseformattributesets.filter((item) => item.id !== action.payload)
      };

    case DELETE_CASEFORM_ATTRIBUTESET_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state
  }
}

export default CaseFormAttributesets
