import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "reactstrap";
import { changePreloader, getCaseDependentsAttorney, getEmployeeCaseQnnrTemplateGroupInfo, getEmployeeCountrys, getEmployeeStates, uploadQnnrDocument } from "../../../../store/actions";
import CheckboxGroupType from "../Elements/CheckboxGroupType";
import FileType from "../Elements/File";
import InputType from "../Elements/Input";
import InputTextarea from "../Elements/InputTextarea";
import MaskPhoneInputType from "../Elements/MaskPhone";
import RadioGroupType from "../Elements/RadioGroupType";
import ReadOnly from "../Elements/ReadOnly";
import Select from "../Elements/Select";
import SSNInputType from "../Elements/SSNInput";
import { checkValidation } from "../Shared/utility";


const Form = ({ controls, setControls, btnText }) => {
  const [attributeSetDependency, setAttributeSetDependency] = useState([]);
  const [isDependentBlock, setIsDependentBlock] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryField, setSelectedCountryField] = useState("");
  const { countrys, selectedCountries, casedependentsFormList } = useSelector((state) => ({
    countrys: state.EmployeeCases.countrys || [],
    selectedCountries: state.EmployeeCases.selectedCountries || [],
    casedependentsFormList: state.AttorneyCases.casedependentsFormList || [],
  }));

  const { employeeCaseQnnrTemplateInfo } = useSelector((state) => ({
    employeeCaseQnnrTemplateInfo: state.EmployeeCases.employeeCaseQnnrTemplateInfo || [],
  }));
  const dispatch = useDispatch();
  const { states, selectedStates, addQnnrTemplateGroupInfo, uploadqnnrdocuments } = useSelector((state) => ({
    states: state.EmployeeCases.states || [],
    selectedStates: state.EmployeeCases.selectedStates || [],
    addQnnrTemplateGroupInfo: state.EmployeeCases.addQnnrTemplateGroupInfo || [],
    uploadqnnrdocuments: state.EmployeeCases.uploadqnnrdocuments || [],
  }));

  const { user_type } = useSelector((state) => ({
    user_type: state.login.user_type || localStorage.getItem('user_type'),
  }));

  const checkAttributeSetDependency = (sets) => {
    let isDependent_block_check_status = sets?.value?.is_dependent_set == "1" ? false : true;
    attributeSetDependency && attributeSetDependency.every(function (dept, deptincx) {
      isDependent_block_check_status = sets?.value?.is_dependent_set == "1" &&
        dept && dept.includes(sets?.value?.slug)
        ? true
        : sets?.value?.is_dependent_set == "1"
          ? false
          : true;
      // console.log(isDependent_block_check_status, "isDependent_block_check_status", sets?.value?.slug, "=Selected=", dept, "==dept==", dept.includes(sets?.value?.slug))

      if (isDependent_block_check_status) {
        return false;
      } else return true;
    });
    return isDependent_block_check_status;
  }
  const allAttributeSets = (controls) => {
    setAttributeSetDependency([])
    if (controls.sets && controls.sets.length > 0) {
      controls.sets.map((sets, setsindex) => {
        if (sets.set)
          sets.set.map((set, setindex) => {
            var isDependent_block_check = checkAttributeSetDependency(sets)
            if (isDependent_block_check)
              if (set?.attributes)
                set.attributes.map((attribute, attributeindex) => {

                  var content_field_name = `content[${sets.value.cloned_name}][${setindex}][${attribute.field_name}]`
                  attribute.name = content_field_name;
                  attribute.content.name = content_field_name;

                  if (attribute?.content?.is_any_dependent_yes == true) {
                    if (attribute?.content?.dependent_ids_yes && attribute?.value) {
                      var attribute_dependent_set_value = attribute?.content?.dependent_ids_yes.replace(/'/g, '"')
                      attribute_dependent_set_value = JSON.parse(attribute_dependent_set_value)
                      if (attribute_dependent_set_value[attribute?.value]) {
                        setAttributeSetDependency(attributeSetDependency => [...attributeSetDependency, attribute_dependent_set_value[attribute?.value]]);
                      }
                    }
                  }

                  if (attribute.select_field === "country") { // && attribute.value != ""
                    setSelectedCountry(attribute.value);
                  }
                  else if (attribute.select_field === "countryus") {
                    setSelectedCountryField("countryus");
                    setSelectedCountry("USA");
                  } else if (attribute.select_field === "dependent") {
                  }
                })
          });
      });
    }
  }

  useEffect(() => {
    if (selectedCountry) {
      const cuntryCode = countrys.find(
        (item) => item.label === selectedCountry
      );
      dispatch(getEmployeeStates(cuntryCode?.code || 233));
    }
  }, [selectedCountry]);

  const [apiSuccess, setApiSuccess] = useState(false);

  useEffect(() => {

    if (_.isEmpty(uploadqnnrdocuments))
      dispatch(changePreloader(true));
    else
      dispatch(changePreloader(false));

    if (Object.keys(uploadqnnrdocuments).length > 0 && apiSuccess) {
      dispatch(getEmployeeCaseQnnrTemplateGroupInfo({
        case_id: caseId,
        "group_id": "Documents",
        user_type: user_type
      }));
      setApiSuccess(false);
    }
  }, [uploadqnnrdocuments]);


  useEffect(() => {
    dispatch(getEmployeeCountrys());
    dispatch(getCaseDependentsAttorney({ case_id: caseId }))
    dispatch(getEmployeeStates(233));
  }, []);

  const queryParams = new URLSearchParams(location.search);
  const caseId = queryParams.get('caseId'); // Read the 'id' query parameter from the URL


  useEffect(() => {
    allAttributeSets(controls);
    // setControls(controls);
  }, [controls]);

  const onFileUploadHandler = (controlName) => {
    const updatedControls = { ...controls };

    updatedControls.sets.map((sets, setsindex) => {
      sets.set.map((set, setindex) => {
        if (set?.attributes)
          set.attributes.map((attribute, attributeindex) => {
            if (attribute.name === controlName) {
              let case_doc_id = attribute?.content?.id;
              var uploadedFiles = [];
              if (attribute?.file)
                Array.from(attribute?.file).map(val => {
                  uploadedFiles.push(val);
                });
              if (uploadedFiles.length > 0) {
                {
                  employeeCaseQnnrTemplateInfo?.isQnnrUnlock == 1 &&
                    dispatch(uploadQnnrDocument({
                      "case_id": caseId,
                      "case_doc_id": case_doc_id,
                      "file": uploadedFiles
                    }))
                  dispatch(changePreloader(true));
                  setApiSuccess(true);
                }
              } else {
                alert("Please select the " + attribute?.content?.label + " file")
              }
            }
          });
      });

    });

    setControls(updatedControls);
  };
  const onInputChangedHandler = (event, controlName, item) => {
    const updatedControls = { ...controls };

    updatedControls.sets.map((sets, setsindex) => {
      sets.set.map((set, setindex) => {

        var isDependent_block_check = checkAttributeSetDependency(sets)

        if (isDependent_block_check)
          if (set?.attributes)
            set.attributes.map((attribute, attributeindex) => {
              if (updatedControls.sets[setsindex].set[setindex].attributes[attributeindex]?.select_field === "country") {
                setSelectedCountry(event.target?.value);
              }
              else if (updatedControls.sets[setsindex].set[setindex].attributes[attributeindex]?.select_field === "countryus") {
                setSelectedCountryField("countryus");
                setSelectedCountry("USA");
              }
              if (attribute.name === controlName) {
                var attribute_value = event.target?.value;
                if (updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].type === "date") {
                  updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].selected = event?.target?.value ? "" : event;
                  updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].value = attribute_value ? "" : event;
                  updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].isValid = attribute_value || attribute?.value ? true : false;
                  if (attribute_value || attribute?.value) {
                    updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].invalidMessage = "";
                  }
                } else
                  if (updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].type != "file" &&
                    updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].type != "date"
                  ) {
                    if (updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].type === "checkbox-group") {
                      attribute_value = event.target?.checked ? (attribute_value.length > 0 ? attribute_value : "true") : '';
                    }
                    if (attribute?.content?.multiple) {
                      var previous_value = (attribute?.value).split(",");
                      attribute_value = updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].value != '' ? (updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].value) : [];
                      if (event.target.options) {
                        var selected_options = Array.from(event.target.selectedOptions, option => option.value);
                        for (const option of event.target.options) {
                          if (option.selected && option.value != '') {
                            const index = previous_value.findIndex(v => v === option.value);
                            if (index > -1) {
                              previous_value.splice(index, 1);
                            } else {
                              previous_value.push(option.value);
                            }
                          }
                        }
                        attribute_value = previous_value.filter(value => value != '');
                      }
                      if (Array.isArray(attribute_value)) {
                        attribute_value = attribute_value.join(",");
                      }
                    }

                    updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].value = attribute_value;

                    const validationObj = checkValidation(
                      attribute_value,
                      attribute?.content,
                      attribute?.content?.label
                    );
                    updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].isValid = validationObj.isValid;
                    updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].invalidMessage = validationObj.invalidMessage;
                    return; // Attribute found and updated, exit the loop
                  }
              }
              setControls(updatedControls);
            })
      });
    });

    updatedControls.sets.map((sets, setsindex) => {
      sets.set.map((set, setindex) => {
        if (set?.attributes)
          set.attributes.map((attribute, attributeindex) => {
            if (
              updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].type == "file" &&
              updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].name === controlName
            ) {
              updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].file = event.target.files;
              // if (event.target.files[0]?.name) {
              //   updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].isValid = true;
              //   updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].invalidMessage = '';
              //   updatedControls.sets[setsindex].set[setindex].attributes[attributeindex].value = event.target.files;
              // }
            }
          });
      });

      // if (item?.content?.is_any_dependent_yes == true) {
      //   if (item?.content?.dependent_ids_yes && item?.value) {
      //     var attribute_dependent_set_value = item?.content?.dependent_ids_yes.replace(/'/g, '"')
      //     attribute_dependent_set_value = JSON.parse(attribute_dependent_set_value)
      //     if (attribute_dependent_set_value[item?.value]) {
      //       setAttributeSetDependency(attributeSetDependency => [...attributeSetDependency, attribute_dependent_set_value[item?.value]]);
      //     }
      //     // else
      //     // setAttributeSetDependency([['Application_H4_EAD_Additional_Information_Select_Yes'], ['List_of_public_benefits_received_or_is_currently_certified']])

      //   }
      // }
      allAttributeSets(updatedControls); // setting the dependents state

    });

    setControls(updatedControls);
  };

  return (
    <>
      {controls.sets.length > 0 &&
        controls.sets.map((item, setsindex) => {
          {
            var isDependent_block_check = checkAttributeSetDependency(item);
          }
          return (
            <>
              {
                isDependent_block_check
                  ? (
                    <div
                      key={"form" + item.id}
                      className="mt-4"
                    >

                      {item?.value?.name ? (
                        <div className="text-left mb-2">
                          <h5>{item?.value?.name}</h5>
                          {item?.value?.name && controls?.groupId != "Review" && (
                            <p className="card-title-desc">Fill all information below</p>
                          )}
                        </div>) : ""}

                      {item?.set && item?.set.length > 0 && item.set.map((set, setindex) => (
                        <div className="row more-item-box" key={"set" + setindex}>
                          {item?.value?.is_add_more == "1" && item.set.length > 1 ? (
                            <>
                              <div className="col-lg-11">
                              </div>
                              <div className="col-lg-1">
                                <div className="remove-item">
                                  <>
                                    <i className="bx bx-x bx-sm" onClick={() => {
                                      controls.sets[setsindex].set.splice(setindex, 1);
                                      setControls(controls);
                                    }
                                    } ></i>
                                  </>

                                </div>
                              </div>
                            </>
                          ) : ""}
                          {set?.attributes && set.attributes.map((row, rowix) => {
                            return (
                              <>
                                <div className={row?.content?.class_name ? row?.content?.class_name : 'col-md-4'} key={setindex + row.id}>
                                  {

                                    (row.type === "file") ? (
                                      <>
                                        <Control
                                          {...row}
                                          type="file"
                                          content={{ ...row.content, name: row.name }}
                                          name={row.name}
                                          onChanged={(event) =>
                                            onInputChangedHandler(event, row.name, row)
                                          }
                                          onChange={(event) =>
                                            onInputChangedHandler(event, row.name, row)
                                          }
                                          isDocumentTab={item?.value?.name && controls?.groupId != "Document"}
                                          isQnnrUnlock={employeeCaseQnnrTemplateInfo?.isQnnrUnlock}
                                          onFileUploadHandler={onFileUploadHandler}
                                        />
                                      </>
                                    ) :
                                      row?.select_field === "country" &&
                                        (row.type === "text" || row.type === "select") ? (
                                        <>
                                          <Control
                                            {...row}
                                            name={row.name}
                                            type="select"
                                            content={{ ...row.content, name: row.name, values: selectedCountries }}
                                            onChanged={(event) =>
                                              onInputChangedHandler(event, row.name, row)
                                            }
                                          />
                                        </>
                                      ) : row?.select_field === "countryus" &&
                                        (row.type === "text" || row.type === "select") ? (
                                        <>
                                          <Control
                                            {...row}
                                            name={row.name}
                                            type="select"
                                            content={{
                                              ...row.content,
                                              name: row.name,
                                              values: [
                                                {
                                                  code: 233,
                                                  label: "USA",
                                                  value: "USA",
                                                  iso: "US",
                                                  phonecode: "1",
                                                  selected: false,
                                                },
                                              ],
                                            }}
                                            onChanged={(event) =>
                                              onInputChangedHandler(event, row.name, row)
                                            }
                                          />
                                        </>
                                      ) : row?.select_field === "state" &&
                                        (row.type === "select") ? ( // || row.type === "text"
                                        <>
                                          {selectedStates ? (
                                            <Control
                                              {...row}
                                              name={row.name}
                                              type="select"
                                              content={{ ...row.content, name: row.name, values: selectedStates }}
                                              onChanged={(event) =>
                                                onInputChangedHandler(event, row.name, row)
                                              }
                                            />
                                          ) : (
                                            <Control
                                              {...row}
                                              name={row.name}
                                              content={{ ...row.content, name: row.name, values: [] }}
                                              onChanged={(event) =>
                                                onInputChangedHandler(event, row.name, row)
                                              }
                                              onChange={(event) =>
                                                onInputChangedHandler(event, row.name, row)
                                              }
                                            />
                                          )}
                                        </>
                                      ) : row?.select_field === "dependent" &&
                                        (row.type === "text" || row.type === "select") ? (
                                        <>
                                          <Control
                                            {...row}
                                            name={row.name}
                                            type="select"
                                            content={{ ...row.content, name: row.name, values: casedependentsFormList }}
                                            onChanged={(event) =>
                                              onInputChangedHandler(event, row.name, row)
                                            }
                                          />
                                        </>
                                      ) : row?.select_field_type === "MaskPhone" &&
                                        row.type === "text" ? (
                                        <ControlFieldType
                                          {...row}
                                          name={row.name}
                                          onChanged={(event) =>
                                            onInputChangedHandler(event, row.name, row)
                                          }
                                        />
                                      ) : row?.select_field_type === "MaskSSN" &&
                                        row.type === "text" ? (
                                        <ControlFieldType
                                          {...row}
                                          content={{ ...row.content, name: row.name }}
                                          name={row.name}
                                          onChanged={(event) =>
                                            onInputChangedHandler(event, row.name, row)
                                          }
                                        />
                                      ) : (
                                        <>
                                          <Control
                                            {...row}
                                            content={{ ...row.content, name: row.name }}
                                            name={row.name}
                                            onChanged={(event) =>
                                              onInputChangedHandler(event, row.name, row)
                                            }
                                            onChange={(event) =>
                                              onInputChangedHandler(event, row.name, row)
                                            }
                                          />
                                        </>
                                      )}
                                </div>

                                {(row.type === "file") && item?.value?.name && controls?.groupId != "Document" && (
                                  <hr></hr>
                                )} </>
                            )
                          })}
                          {item?.value?.is_add_more == "1" && item?.set.length == setindex + 1 ? (
                            <>
                              <div className="col-lg-10">
                              </div>
                              <div className="col-lg-2">
                                <button
                                  className="mb-3 ms-1 btn btn-primary add-more-button-right"
                                  type="button"
                                  onClick={() => {
                                    const updatedSet = structuredClone(set);
                                    updatedSet?.attributes && updatedSet.attributes.map((row, rowix) => {
                                      {
                                        var content_field_name = `content[${item.value.cloned_name}][${setindex + 1}][${row.field_name}]`
                                        row.name = content_field_name;
                                        row.content.name = content_field_name;
                                        row.value = '';
                                        return row;
                                      }
                                    })
                                    controls.sets[setsindex].set.push(updatedSet);
                                    setControls(controls);
                                  }}
                                >
                                  + Add More
                                </button>
                              </div>
                            </>

                          ) : ""}
                        </div>
                      ))}

                    </div>
                  )
                  : ""
              }

            </>
          )
        })}
    </>
  );
};

export default Form;

const Control = (props) => {
  switch (props.type) {
    case "textarea":
      return <InputTextarea {...props} />;

    case "select":
      return <Select {...props} />;
    case "checkbox-group":
      return <CheckboxGroupType {...props} />;
    case "radio-group":
      return <RadioGroupType {...props} />;
    case "file":
      return <FileType {...props} />;
    case "label":
      return <ReadOnly {...props} />;
    case "date":
      return (
        <div className="">
          <Label className="form-label mb-0" style={{ fontWeight: "bold" }}> {props?.content?.label}{props?.required == true ? (<span className='text-danger'>*</span>) : null}</Label>
          <ReactDatePicker
            showYearDropdown={true}
            placeholderText="MM/dd/yyyy"
            {...props}
            dateFormat="MM/dd/yyyy"
            showIcon
            placeholder="Date picker"
            className={props?.invalidMessage ? "is-invalid form-control" : "form-control"}
          />
          <div type="invalid" className="invalid-feedback">{props?.invalidMessage}</div>
        </div>
      );
    case "text":
      if (props.select_field_type == "label")
        return <ReadOnly {...props} />;
      else
        return <InputType {...props} />;
    default:
      return <InputType {...props} />;
  }
};
const ControlFieldType = (props) => {
  switch (props.select_field_type) {
    case "MaskPhone":
      return <MaskPhoneInputType {...props} />;
    case "MaskSSN":
      return <SSNInputType {...props} />;
    default:
      return <InputType {...props} />;
  }
};
