import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Row, Form, Label, Input, FormGroup, Button } from "reactstrap"
import { DragDropContext, Droppable, Draggable, resetServerContext } from 'react-beautiful-dnd';
resetServerContext()
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getAttributes as onGetAttributes, addAttributesetDetail, getAttributesetDetail, AttributesetUpdate } from "../../../store/actions";
import {
  reorder,
  move,
  getItemStyle,
  getListStyle,
  getFirstListStyle
} from "./helper";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "../../../components/Common/Alert";

const FormLayoutsBasic = () => {
  //SAVE DATA
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL

  const { attributes } = useSelector((state) => ({
    attributes: state.attributes.attributesFilter,
  }));

  const { attributesetDetail } = useSelector((state) => ({
    attributesetDetail: state.attributesets.attributesetDetail,
  }));
  const [formData, setFormData] = useState({
    "title": "",
    "name": "",
    "shortDescription": "",
    "description": "",
    "attributesListId": "",
    "status": "Active",
    "slug": "",
    "is_dependent_set": false,
    "is_add_more": false
  });

  useEffect(() => {
    if (editId) {
      dispatch(onGetAttributes());
      dispatch(getAttributesetDetail(editId));
    }
  }, [editId]);

  useEffect(() => {
    if (Object.entries(attributesetDetail).length > 0) {
      let { attributesListId } = attributesetDetail;
      let attributesArr = attributesListId.split(',');
      let filteredList = attributes.filter((item) => {
        if (attributesArr.includes(item.id)) {
          return item
        }
      })

      let attributesList = attributes.filter((item) => {
        if (!attributesArr.includes(item.id)) {
          return item
        }
      })

      setFormData(attributesetDetail);
      setLists({
        items: attributesList,
        // selected: filteredList,
        selected: attributesetDetail.selectedAttributesList
      });
    }
  }, [attributesetDetail, attributes])


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let attributesListId = lists.selected.map((item) => {
      return item.id;
    })

    attributesListId = attributesListId.join(",");
    let postBody = { ...formData, attributesListId };
    if (postBody.title) {
      let response = {};
      if (Number(formData.id) > 0) {
        dispatch(AttributesetUpdate(postBody));
      } else {
        dispatch(addAttributesetDetail(postBody));
      }
      setFormData({
        "id": "",
        "title": "",
        "name": "",
        "shortDescription": "",
        "description": "",
        "attributesListId": "",
        "status": "Active",
        "slug": "",
        "is_dependent_set": false,
        "is_add_more": false
      });
      navigate("/admin/questionnaire-attributes-sets");


    }
  }

  const onClickCancel = () => {
    navigate("/admin/questionnaire-attributes-sets");
  }

  const [lists, setLists] = useState({ 'items': attributes, 'selected': [] });
  const id2List = {
    droppable: 'items',
    droppable2: 'selected'
  };

  const getList = id => lists[id2List[id]];

  const onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === 'droppable2') {
        state = { selected: items };
      }
      let newstate = {
        ...lists,
        ...state
      }
      console.log('newstate', newstate)
      setLists(newstate);

    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );
      console.log('result.droppable2', result.droppable2)

      setLists({
        items: result.droppable,
        selected: result.droppable2
      });
    }
  };

  let title = editId ? 'Update Questionnaire Attribute Set' : 'Add Questionnaire Attribute Set';
  const BreadcrumbData = [
    { name: 'Questionnaire Attribute Sets', url: '/admin/questionnaire-attributes-sets' },
    { name: title, url: '/admin/add-questionnaire-attributes-sets' }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

          <Row>
            <Col xs={12}>
              <form onSubmit={handleSubmit}>

                <div className="container">
                  <div className="row">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <div className="col-lg-6 col-md-12">
                        <div className="card dashboard-secondbox dashboard-border-radius">
                          <h5>Questionnaire attributes List</h5>
                          <p>Please drag and drop and Make sets.</p>

                          <div className=''>
                            <div className="" style={{ 'height': '500px', 'overflow-y': 'auto', 'overflow-x': 'hidden' }}>
                              <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                  <ul className="list-group border"

                                    ref={provided.innerRef}
                                    style={getFirstListStyle(snapshot.isDraggingOver)}
                                  >
                                    {lists['items'].map((item, index) => (
                                      // {item.name != "state"}
                                      <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}>
                                        {(provided, snapshot) => (
                                          <li className="list-group-item border"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            {item.name}
                                          </li>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}

                                  </ul>
                                )}
                              </Droppable>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">


                        <div className="card dashboard-secondbox dashboard-border-radius">
                          <h5>Creat Attribute Sets</h5>


                          <div className="">

                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Title:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="formGroupExampleInput"
                                placeholder="Enter Title"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Name:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="formGroupExampleInput"
                                placeholder="Enter Name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Questionnaire Attributes List:
                              </label>
                              <p>Please drag and drop from left to right.</p>

                            </div>
                            <div className="">
                              <Droppable droppableId="droppable2">
                                {(provided, snapshot) => (
                                  <ul className="list-group"
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}>
                                    {lists['selected'].map((item, index) => (
                                      <Draggable
                                        key={item.id}
                                        draggableId={"'" + item.id + "'"}
                                        index={index}>
                                        {(provided, snapshot) => (
                                          <li className="list-group-item"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}>
                                            {item.name}
                                          </li>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </ul>
                                )}
                              </Droppable>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="is_dependent_set" className="form-label">
                                Is dependent ? &nbsp;
                                <input
                                  type="checkbox"
                                  checked={formData.is_dependent_set == "1" ? true : false}
                                  id="is_dependent_set"
                                  name="is_dependent_set"
                                  value={formData.is_dependent_set}
                                  onChange={handleCheckboxChange}
                                />
                              </label>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="is_add_more" className="form-label">
                                Is add more ? &nbsp;
                                <input
                                  type="checkbox"
                                  checked={formData.is_add_more == "1" ? true : false}
                                  id="is_add_more"
                                  name="is_add_more"
                                  value={formData.is_add_more}
                                  onChange={handleCheckboxChange}
                                />
                              </label>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Slug
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="formGroupExampleInput"
                                name="slug"
                                value={formData.slug}
                                onChange={handleChange}
                              />
                            </div>

                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Short Description
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="formGroupExampleInput"
                                name="shortDescription"
                                placeholder="Enter Short Description"
                                value={formData.shortDescription}
                                onChange={handleChange}
                              />
                            </div>

                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Description
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="formGroupExampleInput"
                                placeholder="Enter Description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                              />
                            </div>

                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput2" className="form-label">
                                Select Status:
                              </label>


                              <Input
                                id="validationCustom02"
                                name="status"
                                type="select"
                                onChange={handleChange}
                                value={formData.status || ""}

                              >
                                <option >Open this select status</option>
                                <option value="Active">Active</option>
                                <option value="InActive">InActive</option>
                              </Input>




                            </div>
                            <button type="button" className="btn btn-secondary" onClick={onClickCancel}>
                              Cancel
                            </button> {' '}
                            <button type="submit" className="btn btn-primary">
                              save
                            </button>

                          </div>

                        </div>

                      </div>

                    </DragDropContext>
                  </div>
                </div>

              </form>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};
export default FormLayoutsBasic
