import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"
import {
    Card,
    CardBody,
    CardHeader,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledDropdown,
    Table,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import classnames from "classnames";
import Notifications from "../../Common/Notifications";

const BasicTable = () => {
    //meta title
    document.title = "Employer | Immidock ";
    const [activeTab, setactiveTab] = useState("1");
    const [activeTab1, setactiveTab1] = useState("5");
    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [username, setusername] = useState("Employer");
    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            setusername(obj.data.name);
        }
    }, []);
    const toggle1 = (tab) => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab);
        }
    };
    let title = 'Dashboard';
    const BreadcrumbData = [
        { name: 'Dashboard', url: '/employer/dashboard' },
        { name: title, url: '#' }
    ]
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

                    <Row>
                        <Col xl={6}>


                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Welcome, {username}</h4>
                                </CardHeader>
                                <CardBody>

                                    <div className="hstack gap-2">
                                        {/* <Link to="/#" className="btn btn-soft-primary waves-effect waves-light gap">
                                                            <span>Directory</span>
                                                        </Link> */}
                                        {/* <div className="vr"></div> */}

                                        <Link to="/employer/cases" className="btn btn-soft-primary waves-effect waves-light">
                                            <span>Cases</span>
                                        </Link>
                                        <div className="vr"></div>
                                        <Link to="/employer/employeelist" className="btn btn-soft-success waves-effect waves-light">
                                            <span>Beneficiaries</span>
                                        </Link>
                                        <div className="vr"></div>
                                        <Link to="/employer/timesheets" className="btn btn-soft-secondary waves-effect waves-light">
                                            <span>Timesheets</span>
                                        </Link>
                                        {/* <Link to="/#" className="btn btn-soft-success waves-effect waves-light">
                                                            <span>Performance</span>
                                                        </Link>
                                                        <div className="vr"></div> */}
                                        <Link to="/employer/invoices" className="btn btn-soft-info waves-effect waves-light">
                                            <span>Invoices</span>
                                        </Link>
                                        <div className="vr"></div>
                                        <Link to="/employer/customers" className="btn btn-soft-primary waves-effect waves-light">
                                            <span>Customers</span>
                                        </Link>

                                    </div>

                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">CREATE CASE</h4>
                                    <p className="card-title-desc">
                                        Create a case by filling out contact details.
                                        Create Case.
                                    </p>

                                    <p className="card-title-desc">
                                        <Link to="/employer/add-employer-case" className="btn btn-primary">
                                            <span>Create Case</span>
                                        </Link>
                                    </p>
                                </CardHeader>

                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Add Beneficiary</h4>
                                    <p className="card-title-desc">
                                        <Link to="/employer/add-employer-contact" className="btn btn-primary">
                                            <span>Create</span>
                                        </Link>
                                    </p>
                                </CardHeader>



                            </Card>
{/* 
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">RFE'S DUE</h4>


                                </CardHeader>

                                <CardBody>
                                    <div className="table-responsive">
                                        <Table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Visa</th>
                                                    <th>RFE DUE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Mark</td>
                                                    <td>Otto</td>
                                                    <td>@mdo</td>
                                                </tr>

                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card> */}
                        </Col>

                        <Notifications></Notifications>



                    </Row>




                </div>
            </div>
        </React.Fragment>
    );
};

export default BasicTable;
