import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  ADD_TIMESHEET, CREATE_PROJECT, GET_EMPLOYEETIMESHEETS,
  GET_PROJECTS,
  VALIDATE_TIMESHEET_BY_PERIOD
} from "./actionTypes"
import {
  addTimesheetSuccess,
  addTimesheetFail,
  fetchEmployeeTimesheetsSuccess,
  fetchEmployeeTimesheetsFail,
  getProjectsSuccess,
  getProjectsFail,
  createProjectSuccess,
  createProjectFail,
  validateTimesheetByPeriodSuccess,
  validateTimesheetByPeriodFail
} from "./actions"

//Include Both Helper File with needed methods
import { setAlertMessage } from "../../common/actions";
import {
  AddTimesheetApiHelper, FetchEmployeeTimesheetsApiHelper,
  FetchProjectApiHelper, AddProjectApiHelper,
  validateTimesheetByPeriodApiHelper
} from "../../../helpers/apibackend_helper"

function* fetchEmployeeTimesheetsData({ payload: data }) {
  // console.log('---in emp timesheets saga--------------');
  try {
    const response = yield call(FetchEmployeeTimesheetsApiHelper, data)
    yield put(fetchEmployeeTimesheetsSuccess(response.data))
  } catch (error) {
    yield put(fetchEmployeeTimesheetsFail(error))
  }
}

function* addTimesheetData({ payload: timesheetData }) {
  try {
    const response = yield call(AddTimesheetApiHelper, timesheetData)
    yield put(addTimesheetSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(addTimesheetFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}
function* fetchValidateTimesheetByPeriod({ payload: timesheetData }) {
  try {
    const response = yield call(validateTimesheetByPeriodApiHelper, timesheetData)
    yield put(validateTimesheetByPeriodSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(validateTimesheetByPeriodFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* fetchProjectsData({ payload: data }) {
  try {
    const response = yield call(FetchProjectApiHelper, data)
    yield put(getProjectsSuccess(response.data))
  } catch (error) {
    yield put(getProjectsFail(error))
  }
}

function* createProject({ payload: projectdata }) {
  try {
    const response = yield call(AddProjectApiHelper, projectdata)
    yield put(createProjectSuccess(response.data))
  } catch (error) {
    yield put(createProjectFail(error))
  }
}

function* timesheetSaga() {
  yield takeEvery(GET_EMPLOYEETIMESHEETS, fetchEmployeeTimesheetsData)
  yield takeEvery(GET_PROJECTS, fetchProjectsData)
  yield takeEvery(ADD_TIMESHEET, addTimesheetData)
  yield takeEvery(VALIDATE_TIMESHEET_BY_PERIOD, fetchValidateTimesheetByPeriod)
  yield takeEvery(CREATE_PROJECT, createProject)
}

export default timesheetSaga
