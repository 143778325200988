import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown
} from "reactstrap";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DatatableContainer from "../../../components/Common/DatatableContainer";
import { getCaseDocuments } from "../../../store/actions";

import {
  Amount,
  Assigned,
  BillingName,
  InvoiceId,
  SharedInfo
} from "./formCol";

import {
  Link
} from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";


const Documents = () => {
  //meta title
  document.title = "Documents | Immidock ";

  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const caseId = queryParams.get("caseId"); // Read the 'id' query parameter from the URL
  const { caseDocuments } = useSelector((state) => ({
    caseDocuments: state.AttorneyCases.caseDocuments || [],
  }));

  const { caseDetail } = useSelector((state) => ({
    caseDetail: state.AttorneyCases.caseDetail || [],
  }));
  useEffect(() => {
    dispatch(getCaseDocuments({
      case_id: caseId
    }));
  }, [caseId]);

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [order, setOrder] = useState(null);
  const reloadData = (event) => {
    var tabInfo = event.target.value;
    dispatch(getCaseDocuments({
      case_id: caseId,
      category: tabInfo
    }));
  }
  const [showSharedModel, setShowSharedModel] = useState(false);
  const [sharedContacts, setSharedContacts] = useState([]);
  const [docInfo, setDocumentInfo] = useState('');
  const clickSharedInfo = (cellInfo) => {
    setShowSharedModel(!showSharedModel)
    let { is_shared_to_petitioner, is_shared_to_employee, is_shared_to_attroney, petitioner_company, attorney_company } = cellInfo.cell.row.original;
    let sharedDataShow = [];
    if (is_shared_to_petitioner === 1) {
      sharedDataShow = [...sharedDataShow, { contact: petitioner_company, type: 'Petitioner' }]
    }
    if (is_shared_to_attroney === 1) {
      sharedDataShow = [...sharedDataShow, { contact: attorney_company, type: 'Attorney' }]
    }
    // if (is_shared_to_employee == 1) {
    //   sharedDataShow = [...sharedDataShow, { contact: employee_name, type: 'Employee' }]
    // }



    setSharedContacts(sharedDataShow);
    setDocumentInfo(cellInfo);

  };

  const [caseViewDocuments, setCaseViewDocuments] = useState([]);

  const viewDocuments = (data) => {
    setShowViewDocuments(!showViewDocments);
    setCaseViewDocuments(data);
  }

  const document_details = [
    {
      id: 1,
      invoiceId: "G-28 (I129)",
      date: "10/15/2023",
      founder: "--",
      founderAt: "--",
      invoiceID: "14251",
      company: "--",
      invoicePrice: "1455",
      Amount: "--",
      status: "Pending",
      qnnrprocess: "50",
      isprinted: true,
    },
  ];

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const columns = [
    {
      Header: "Name",
      accessor: "doc_name",
      filterable: true,
      disableFilters: true,
      Cell: (cellProps) => {
        return <InvoiceId {...cellProps} />;
      },
    },
    {
      Header: "Assigned To",
      accessor: "assigned_to",
      filterable: true,
      disableFilters: true,
      Cell: (cellProps) => {
        return <Assigned {...cellProps} />;
      },
    },
    {
      Header: "Uploaded By",
      accessor: "uploaded_by",
      filterable: true,
      disableFilters: true,
      Cell: (cellProps) => {
        return <BillingName {...cellProps} />;
      },
    },
    {
      Header: "Uploaded",
      accessor: "upload_date",
      filterable: true,
      disableFilters: true,
      Cell: (cellProps) => {
        return <Amount {...cellProps} />;
      },
    },

    {
      Header: "Shared Info",
      accessor: "shared_doc_count",
      filterable: true,
      disableFilters: true,
      Cell: (cellProps) => {
        return (
          <SharedInfo
            {...cellProps}
            clickSharedInfo={() => clickSharedInfo(cellProps)}
          />
        );
      },
    },

    // {
    //   Header: "Is Printed",
    //   accessor: "is_printed",
    //   filterable: true,
    //   disableFilters: true,
    //   Cell: (cellProps) => {
    //     return <IsPrinted {...cellProps} />;
    //   },
    // },
    {
      Header: "Status",
      accessor: "doc_status",
      filterable: true,
      disableFilters: true,
      Cell: (invoice) => {
        return (
          <div className={(invoice.row.original.doc_status == "Active" ? "badge-soft-success" : "badge-soft-warning") + " badge font-size-12"}>
            {invoice.row.original.doc_status == "Active" ? "Uploaded" : invoice.row.original.doc_status}
          </div>
        );
      },
    },

    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn btn-link text-muted py-1 font-size-16 shadow-none"
              tag="a"
            >
              <i className="bx bx-dots-horizontal-rounded"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <>
                {cellProps.row.original?.doc_media && cellProps.row.original.doc_media.length > 0 && (
                  <DropdownItem onClick={() => viewDocuments(cellProps.row.original)}>View Documents
                  </DropdownItem>
                )}
              </>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  const [showViewDocments, setShowViewDocuments] = useState(false);

  var userType = window.localStorage.getItem("user_type")
  let title = 'Documents';
  const BreadcrumbData = [
    { name: 'Dashboard', url: '/employee/dashboard' },
    { name: title, url: '#' }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Modal isOpen={showViewDocments}>
                    <ModalHeader toggle={() => { setShowViewDocuments(false) }} tag="h4">
                      {caseViewDocuments?.doc_name} Documents
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        {
                          caseViewDocuments?.doc_media && caseViewDocuments?.doc_media.map((document, index) =>
                          (
                            <Col className="col-12 mb-3" key={index}>
                              <Label className="form-label">{document?.doc_name} &nbsp;&nbsp;
                                {document?.attachment && (
                                  <Link to={document?.attachment} target="_blank">
                                    View
                                  </Link>
                                )}</Label>

                            </Col>
                          ))}
                      </Row>
                    </ModalBody>
                  </Modal>

                  <Modal isOpen={showSharedModel}>
                    <ModalHeader toggle={() => setShowSharedModel(false)} tag="h4">
                      Shared Information
                    </ModalHeader>
                    <ModalBody>
                      <Table bordered hover>
                        <thead className="table-light table-nowrap">
                          <tr role="row">
                            <th>Contact</th>
                            <th>User Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sharedContacts.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item?.contact}</td>
                                <td>{item?.type}</td>
                              </tr>
                            )
                          })}

                        </tbody>
                      </Table>
                    </ModalBody>
                  </Modal>
                  {/* <div className="row" >
                <Col md="9"></Col>
                <Col md="3">
                  <FormGroup className="mb-3" >
                    <Input
                      name="invoice_action"
                      placeholder="More Actions"
                      type="select"
                      className="form-control"
                      id="invoice_action"
                      value={moreActions || ''}
                      onChange={(e) => {
                        handleActionSubmit(e);
                      }}
                    >
                      <option value="">More Actions</option>
                      {moreactions.map((moreaction, index) => (
                        <option
                          key={moreaction.action + index}
                          value={moreaction.action}
                        >
                          {moreaction.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </div> */}
                  <DatatableContainer
                    columns={columns}
                    data={caseDocuments}
                    isGlobalFilter={true}
                    isAddInvoiceList={false}
                    isDocumentFilter={true}
                    customPageSize={10}
                    handleInvoiceClick={handleUserClicks}
                    isDocumentFilterChange={reloadData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Documents;