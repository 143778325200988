import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_CASEFORMS, GET_CASEFORMS, GET_CASE_FORM, UPDATE_CASE_FORM } from "./actionTypes"
import {
  addCaseformFail,
  addCaseformSuccess,
  getCaseFormFail,
  getCaseFormSuccess,
  getCaseFormsFail,
  getCaseFormsSuccess,
  updateCaseFormFail,
  updteCaseFormSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { addCaseforms, getCaseForm, getCaseFormsList, updateCaseForm } from "../../../helpers/apibackend_helper"
import { setAlertMessage } from "../../common/actions"

function* fetchCaseformattributetemp() {
  try {
    const response = yield call(getCaseFormsList)
    yield put(getCaseFormsSuccess(response.data))
  } catch (error) {
    yield put(getCaseFormsFail(error))
  }
}

function* fetchCaseform({ id }) {
  try {
    const response = yield call(getCaseForm, id)
    yield put(getCaseFormSuccess(response.data))
  } catch (error) {
    yield put(getCaseFormFail(error))
  }
}

function* addCaseformsdata({ payload: data }) {
  try {
    const response = yield call(addCaseforms, data)
    yield put(addCaseformSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));

  } catch (error) {
    yield put(addCaseformFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }));

  }
}

function* updateCaseFormData({ payload: data }) {
  try {
    const response = yield call(updateCaseForm, data)
    yield put(updteCaseFormSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));

  } catch (error) {
    yield put(updateCaseFormFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }));

  }
}

function* Caseformattributetemplatessaga() {
  yield takeEvery(GET_CASEFORMS, fetchCaseformattributetemp)
  yield takeEvery(ADD_CASEFORMS, addCaseformsdata)
  yield takeEvery(UPDATE_CASE_FORM, updateCaseFormData)
  yield takeEvery(GET_CASE_FORM, fetchCaseform)
  // yield takeEvery(ADD_CASETYPE_DETAIL, addCasetypeDetail)
  // yield takeEvery(DELETE_CASETYPE_DETAIL, onDeleteCasetype)
  // yield takeEvery(UPDATE_CASETYPE_DETAIL, onUpdateCasetype)
}

export default Caseformattributetemplatessaga
