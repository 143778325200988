import React, { useEffect, useMemo, useState } from "react";
import DatatableContainer from "../../../components/Common/DatatableContainer";
import {
  Link, useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";

import { useAlert } from "../../../components/Common/Alert";
import { getInvoices, getInvoiceDetail, getQuickbookSyncInvoice, changePreloader, quickbookPreloader, addInvoice, setAlertFlagInfo } from "../../../store/actions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  CommonCell, Date, CaseTypeName
} from "./listCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";


const InvoicesList = () => {

  //meta title
  document.title = "Invoices List | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openTab, setopenTab] = useState("Open");

  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);

  const invoicesx = [
    {
      "case_id": 42,
      "invoice": "P7UXDCX1HLQ7",
      "customer": "Customer",
      "employee": "Joel Benett",
      "date": "01\/05\/2023",
      "due_date": "02\/07\/2023",
      "total": "$26.30",
      "status": "Active"
    }];

  const { quickbooksyncstatus, quickbooksyncstatuserror, invoiceDetail, invoices } = useSelector((state) => ({
    quickbooksyncstatus: state.Invoices.quickbooksyncstatus,
    quickbooksyncstatuserror: state.Invoices.quickbooksyncstatuserror,
    invoices: state.Invoices.invoices,
    invoiceDetail: state.Invoices.invoiceDetail,

  }));

  const [contactList, setContactList] = useState({ "invoice_type": "Open" });


  const [activeTab, setactiveTab] = useState("All");

  useEffect(() => {
    dispatch(getInvoices({ invoice_type: activeTab }));
    dispatch(changePreloader(true));
    setApiSuccess(true)
  }, [dispatch]);

  useEffect(() => {
    if (apiSuccess) {
      dispatch(changePreloader(false));
      setApiSuccess(false)
    }
  }, [invoices]);

  useEffect(() => {
    // if (!quickbooksyncstatus && _.isEmpty(quickbooksyncstatuserror))
    //   dispatch(quickbookPreloader(true));
    // else
    //   dispatch(quickbookPreloader(false));

    if (!_.isEmpty(quickbooksyncstatuserror) || quickbooksyncstatus) {
      dispatch(quickbookPreloader(false));
      if (!_.isEmpty(quickbooksyncstatuserror)) {
        // handleAlertOpen(quickbooksyncstatuserror.response.data.message, 'warning');
      } else if (quickbooksyncstatus) {
        // handleAlertOpen("Quickbooks Invoice Sync successfully!", 'success');
      }
    }
  }, [quickbooksyncstatus, quickbooksyncstatuserror]);

  const onReloadData = (event) => {
    var tabInfo = event.target.value;
    setactiveTab(tabInfo);
    dispatch(getInvoices({ invoice_type: tabInfo }));
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };
  var userType = window.localStorage.getItem("user_type")


  const handleUserClicks = () => {
    navigate(`/${userType}/create-invoice`);
  };

  const onViewinvoice = (data) => {
    navigate(`/${userType}/view-invoice?invoiceId=${data.invoice_id}`);
  };

  const onEditData = (data) => {
    dispatch(getInvoiceDetail({ invoice_id: data.invoice_id }));
    navigate(`/${userType}/edit-invoice?invoiceId=${data.invoice_id}`);
  };

  const onQuickbookSyncInvoice = (data) => {
    dispatch(getQuickbookSyncInvoice({ invoice_id: data.invoice_id }));
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setOrder(data);
  };

  const handleDeleteInvoice = () => {
    if (order.id) {
      dispatch(qnnrtypeDelete(order.id));
      setDeleteModal(false);
    }
  };


  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   Cell: () => {
      //     return <input type="checkbox" />;
      //   },
      // },
      {
        Header: "Invoice",
        accessor: "invoice_number",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CaseTypeName {...cellProps} />;
        },
      },
      {
        Header: "Customer",
        accessor: "customer",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Beneficiary",
        accessor: "employee",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Date",
        accessor: "invoice_date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },

      {
        Header: "Due Date",
        accessor: "invoice_due_date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Total",
        accessor: "total_amount",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },

      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },


      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => onViewinvoice(cellProps.row.original)}>View</DropdownItem>
                <DropdownItem onClick={() => onEditData(cellProps.row.original)}>Edit</DropdownItem>
                <DropdownItem onClick={() => onQuickbookSyncInvoice(cellProps.row.original)}>Quickbooks Sync Invoice</DropdownItem>
                <DropdownItem onClick={() => onDeleteData(cellProps.row.original)}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  let title = 'Invoices';
  const BreadcrumbData = [
    {name:'Dashboard', url:'/employer/dashboard'},
    { name: title, url: '#' }
  ]

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteInvoice}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <DatatableContainer
                    columns={columns}
                    data={invoices}
                    isGlobalFilter={true}
                    isInvoiceFilter={true}
                    defaultAttorneyFilter={'All'}
                    isInvoiceFilterChange={onReloadData}
                    isAddInvoiceList={true}
                    customPageSize={10}
                    handleInvoiceClick={handleUserClicks}
                  />
                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InvoicesList;
