import React, { useEffect, useState } from "react";
import {
    Card, CardBody, CardHeader, Col, Container, Row, Form, Label, Input,
    FormGroup, FormFeedback,
} from "reactstrap"

import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { getProfile, changePassword } from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";

const ChangePassword = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);

    const [initialValues, setInitialValues] = useState(false);

    const [currentPasswordShow, setCurrentPasswordShow] = useState(false);
    const [newPasswordShow, setNewPasswordShow] = useState(false);
    const [confirmNewPasswordShow, setConfirmNewPasswordShow] = useState(false);

    const handleActionSubmit = (e) => {
        validation.handleSubmit();
    };

    const { profile, changepassword } = useSelector((state) => ({
        profile: state.Profile.profile || {},
        changepassword: state.Profile.changepassword || {},
    }));

    useEffect(() => {
        dispatch(getProfile());
    }, [dispatch]);

    const iniValues = {
        current_password: "",
        new_password: "",
        confirm_new_password: "",
    };
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: iniValues,
        validationSchema: Yup.object({
            current_password: Yup.string().required("Please Enter Current Password").nullable(),
            new_password: Yup.string().required("Please Enter New Password").nullable(),
            confirm_new_password: Yup.string().required("Please Enter Email address").nullable(),
        }),
        onSubmit: (values) => {
            dispatch(changePassword(values));
        }
    });


    let title = 'Change Password';
    //meta title
    document.title = title + " | Immidock ";
    var userType = window.localStorage.getItem("user_type")
    const BreadcrumbData = [
        { name: 'Dashboard', url: '/' + userType + '/dashboard' },
        { name: title, url: '#' }
    ]
    return (
        <React.Fragment>
            <>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

                        <Col xs={12}>
                            <Card>

                                <CardHeader>
                                    <h4 className="card-title">{title}</h4>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <FormikProvider value={validation}>
                                        <Form className="needs-validation"
                                            onSubmit={(values) => {
                                                values.preventDefault();
                                                // console.log(validate(values));
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >

                                            <Row>
                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="current_password">Current Password *</Label>
                                                        <div className="input-group auth-pass-inputgroup">
                                                            <Input
                                                                name="current_password"
                                                                placeholder="Current Password"
                                                                type={currentPasswordShow ? "text" : "password"}
                                                                className="form-control"
                                                                id="current_password"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.current_password || ""}
                                                                invalid={
                                                                    validation.touched.current_password && validation.errors.current_password ? true : false
                                                                }
                                                            />
                                                            <button onClick={() => setCurrentPasswordShow(!currentPasswordShow)} className="btn btn-light shadow-none ms-0" type="button" id="current-password-addon"><i className="mdi mdi-eye-outline"></i></button>

                                                            {validation.touched.current_password && validation.errors.current_password ? (
                                                                <FormFeedback type="invalid">{validation.errors.current_password}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="new_password">New Password *</Label>
                                                        <div className="input-group auth-pass-inputgroup">
                                                            <Input
                                                                name="new_password"
                                                                placeholder="New Password"
                                                                type={newPasswordShow ? "text" : "password"}
                                                                className="form-control"
                                                                id="new_password"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.new_password || ""}
                                                                invalid={
                                                                    validation.touched.new_password && validation.errors.new_password ? true : false
                                                                }
                                                            />
                                                            <button onClick={() => setNewPasswordShow(!newPasswordShow)} className="btn btn-light shadow-none ms-0" type="button" id="new-password-addon"><i className="mdi mdi-eye-outline"></i></button>

                                                            {validation.touched.new_password && validation.errors.new_password ? (
                                                                <FormFeedback type="invalid">{validation.errors.new_password}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="confirm_new_password">Confirm New Password *</Label>

                                                        <div className="input-group auth-pass-inputgroup">
                                                            <Input
                                                                name="confirm_new_password"
                                                                placeholder="Confirm New Password"
                                                                type={confirmNewPasswordShow ? "text" : "password"}
                                                                className="form-control"
                                                                id="confirm_new_password"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.confirm_new_password || ""}
                                                                invalid={
                                                                    validation.touched.confirm_new_password && validation.errors.confirm_new_password ? true : false
                                                                }
                                                            />
                                                            <button onClick={() => setConfirmNewPasswordShow(!confirmNewPasswordShow)} className="btn btn-light shadow-none ms-0" type="button" id="confirm-password-addon"><i className="mdi mdi-eye-outline"></i></button>

                                                            {validation.touched.confirm_new_password && validation.errors.confirm_new_password ? (
                                                                <FormFeedback type="invalid">{validation.errors.confirm_new_password}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </FormGroup>
                                                </Col>


                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <Row style={{ float: "right" }}>
                                                        <Col >
                                                            <span className="mt-4">
                                                                <button type="reset" className="btn btn-secondary" color="secondary" onClick={() => navigate(-1)}>
                                                                    Cancel
                                                                </button>
                                                            </span>
                                                            &nbsp;&nbsp;
                                                            <span className="mt-4">
                                                                <button type="submit" className="btn btn-primary">
                                                                    Save
                                                                </button>
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>

                                        </Form>
                                    </FormikProvider>
                                </CardBody>

                            </Card>
                        </Col>
                    </Container>
                </div>
            </>
        </React.Fragment >
    );
};

export default ChangePassword;