/* EMPLOYERQNNRTYPES */
export const GET_EMPLOYEECASES = "GET_EMPLOYEECASES"
export const GET_EMPLOYEECASES_SUCCESS = "GET_EMPLOYEECASES_SUCCESS"
export const GET_EMPLOYEECASES_FAIL = "GET_EMPLOYEECASES_FAIL"

export const GET_EMPLOYEECASE_DETAIL = "GET_EMPLOYEECASE_DETAIL"
export const GET_EMPLOYEECASE_DETAIL_SUCCESS = "GET_EMPLOYEECASE_DETAIL_SUCCESS"
export const GET_EMPLOYEECASE_DETAIL_FAIL = "GET_EMPLOYEECASE_DETAIL_FAIL"

export const ADD_EMPLOYEECASE_DETAIL = "ADD_EMPLOYEECASE_DETAIL"
export const ADD_EMPLOYEECASE_DETAIL_SUCCESS = "ADD_EMPLOYEECASE_DETAIL_SUCCESS"
export const ADD_EMPLOYEECASE_DETAIL_FAIL = "ADD_EMPLOYEECASE_DETAIL_FAIL"

export const DELETE_EMPLOYEECASE_DETAIL = "DELETE_EMPLOYEECASE_DETAIL"
export const DELETE_EMPLOYEECASE_DETAIL_SUCCESS = "DELETE_EMPLOYEECASE_DETAIL_SUCCESS"
export const DELETE_EMPLOYEECASE_DETAIL_FAIL = "DELETE_EMPLOYEECASE_DETAIL_FAIL"

export const UPDATE_EMPLOYEECASE_DETAIL = "UPDATE_EMPLOYEECASE_DETAIL"
export const UPDATE_EMPLOYEECASE_DETAIL_SUCCESS = "UPDATE_EMPLOYEECASE_DETAIL_SUCCESS"
export const UPDATE_EMPLOYEECASE_DETAIL_FAIL = "UPDATE_EMPLOYEECASE_DETAIL_FAIL"


export const GET_EMPLOYEECASETYPES = "GET_EMPLOYEECASETYPES"
export const GET_EMPLOYEECASETYPES_SUCCESS = "GET_EMPLOYEECASETYPES_SUCCESS"
export const GET_EMPLOYEECASETYPES_FAIL = "GET_EMPLOYEECASETYPES_FAIL"

export const GET_EMPLOYERQNNRTYPES = "GET_EMPLOYERQNNRTYPES"
export const GET_EMPLOYERQNNRTYPES_SUCCESS = "GET_EMPLOYERQNNRTYPES_SUCCESS"
export const GET_EMPLOYERQNNRTYPES_FAIL = "GET_EMPLOYERQNNRTYPES_FAIL"

//
export const GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO="GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO"
export const GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO_SUCCESS = "GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO_SUCCESS"
export const GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO_FAIL = "GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO_FAIL"

export const GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO="GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO"
export const GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_SUCCESS = "GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_SUCCESS"
export const GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_FAIL = "GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_FAIL"

export const ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO="ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO"
export const ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_SUCCESS = "ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_SUCCESS"
export const ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_FAIL = "ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_FAIL"

export const UPLOAD_QNNR_DOCUMENT="UPLOAD_QNNR_DOCUMENT"
export const UPLOAD_QNNR_DOCUMENT_SUCCESS = "UPLOAD_QNNR_DOCUMENT_SUCCESS"
export const UPLOAD_QNNR_DOCUMENT_FAIL = "UPLOAD_QNNR_DOCUMENT_FAIL"

export const GET_EMPLOYEE_COUNTRYS="GET_EMPLOYEE_COUNTRYS"
export const GET_EMPLOYEE_COUNTRYS_SUCCESS = "GET_EMPLOYEE_COUNTRYS_SUCCESS"
export const GET_EMPLOYEE_COUNTRYS_FAIL = "GET_EMPLOYEE_COUNTRYS_FAIL"

export const GET_EMPLOYEE_STATES="GET_EMPLOYEE_STATES"
export const GET_EMPLOYEE_STATES_SUCCESS = "GET_EMPLOYEE_STATES_SUCCESS"
export const GET_EMPLOYEE_STATES_FAIL = "GET_EMPLOYEE_STATES_FAIL"


export const GET_EMPLOYEE_NOTIFICATIONS="GET_EMPLOYEE_NOTIFICATIONS"
export const GET_EMPLOYEE_NOTIFICATIONS_SUCCESS = "GET_EMPLOYEE_NOTIFICATIONS_SUCCESS"
export const GET_EMPLOYEE_NOTIFICATIONS_FAIL = "GET_EMPLOYEE_NOTIFICATIONS_FAIL"

export const READ_NOTIFICATION="READ_NOTIFICATION"
export const READ_NOTIFICATION_SUCCESS = "READ_NOTIFICATION_SUCCESS"
export const READ_NOTIFICATION_FAIL = "READ_NOTIFICATION_FAIL"

export const GET_EMPLOYEE_PAF_ESIGN_FORM="GET_EMPLOYEE_PAF_ESIGN_FORM"
export const GET_EMPLOYEE_PAF_ESIGN_FORM_SUCCESS = "GET_EMPLOYEE_PAF_ESIGN_FORM_SUCCESS"
export const GET_EMPLOYEE_PAF_ESIGN_FORM_FAIL = "GET_EMPLOYEE_PAF_ESIGN_FORM_FAIL"

export const UPDATE_EMPLOYEE_PAF_ESIGN_FORM="UPDATE_EMPLOYEE_PAF_ESIGN_FORM"
export const UPDATE_EMPLOYEE_PAF_ESIGN_FORM_SUCCESS = "UPDATE_EMPLOYEE_PAF_ESIGN_FORM_SUCCESS"
export const UPDATE_EMPLOYEE_PAF_ESIGN_FORM_FAIL = "UPDATE_EMPLOYEE_PAF_ESIGN_FORM_FAIL"

