import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";

// Form Mask
import InputMask from "react-input-mask";

import { EmployerCompanyUpdate, getEmployerCompanyDetail, getProfile } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";


const FormLayoutsBasic = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL

  const [username, setusername] = useState("Employer");

  const { profile } = useSelector((state) => ({
    profile: state.Profile.profile,
  }));

  const getSessionUserType = JSON.parse(localStorage.getItem("authUser"));
  const userStatus = profile ? profile?.status : getSessionUserType?.data?.status;

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setusername(obj.data.name);
    }
  }, []);

  useEffect(() => {
    dispatch(getEmployerCompanyDetail());
    dispatch(getProfile('employer'));
  }, [dispatch, editId]);

  const { employercompanyDetail } = useSelector((state) => ({
    employercompanyDetail: state.EmployerCompanyInfo.employercompanyDetail,
  }));
  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: employercompanyDetail,
    validationSchema: Yup.object({
      // company_logo: Yup.mixed().required('Please select company logo'),
      company_name: Yup.string().required("Please Enter representative Company Name").nullable(),
      phone: Yup.string().required("Please Enter Phone Number").nullable(),
      //  fax: Yup.string().required("Please Enter fax").nullable(),
      // email: Yup.string().required("Please Enter Email address").nullable(),
      website: Yup.string().required("Please Enter website").nullable(),
      first_name: Yup.string().matches(/^[A-Za-z ]*$/, 'Please Enter Valid Representative First Name').required("Please Enter Representative First Name").nullable(),
      last_name: Yup.string().matches(/^[A-Za-z ]*$/, 'Please Enter Valid Representative Last Name').required("Please Enter Representative Last Name").nullable(),
      title: Yup.string().required("Please Enter representative Title").nullable(),
      auth_email: Yup.string().required("Please Enter Email address").nullable(),
      cell: Yup.string().required("Please Enter Cell phone").nullable(),
      daytime_phone: Yup.string().required("Please Enter Daytime Phone").nullable(),
      current_address: Yup.string().required("Please Enter Street Number And Name").nullable(),
      current_apt_ste_flr: Yup.string().required("Please select Apt/Ste/Flr").nullable(),
      current_number: Yup.string().required("Please Enter Number").nullable(),
      current_city: Yup.string().required("Please Enter City").nullable(),
      current_state: Yup.string().required("Please Select State").nullable(),
      current_zipcode: Yup.string().required("Please Enter Zipcode").nullable(),
      current_province: Yup.string().nullable(),
      current_pcode: Yup.string().required("Please Enter Postal Code").nullable(),
      current_country: Yup.string().required("Please Enter Country").nullable(),


      mailing_address: Yup.string().required("Please Enter Street Number And Name").nullable(),
      mailing_number: Yup.string().required("Please Enter Number").nullable(),
      mailing_city: Yup.string().required("Please Enter City").nullable(),
      mailing_state: Yup.string().required("Please Enter State").nullable(),
      mailing_zipcode: Yup.string().required("Please Enter Zipcode").nullable(),
      mailing_apt_ste_flr: Yup.string().required("Please select Apt/Ste/Flr").nullable(),
      mailing_province: Yup.string().nullable(),
      mailing_pcode: Yup.string().required("Please Enter Postal Code").nullable(),
      mailing_country: Yup.string().required("Please Enter Country").nullable(),

      business_type: Yup.string().required("Please Enter Type Of Business").nullable(),
      irs_tax_num: Yup.string().required("Please Enter Individual Irs Tax Number (IRS)").nullable(),
      gross_income: Yup.string().required("Please Enter Gross Annual Income (USD)").nullable(),
      naisc_code: Yup.string().required("Please Enter NAISC Code").nullable(),
      fein: Yup.string().required("Please Enter Federal Employer Identification Number (FEIN)").nullable(),
      place_incorporated: Yup.string().required("Please Enter Place Incorporated In US").nullable(),
      net_income: Yup.string().required("Please Enter Net Annual Income (USD)").nullable(),
      yiiu: Yup.string().required("Please Enter Year Incorporated In US").nullable(),
      cnoeitus: Yup.string().required("Please Enter Current Number Of Employees In The United States").nullable(),
      employees_nonimmigrant_status: Yup.string().required("Please select Are  more than 50 percent of these employees in H-1B,L-1A or L-1B nonimmigrant Status").nullable(),
    }),
    onSubmit: (values) => {
      if (Number(editId) > 0) {
        dispatch(EmployerCompanyUpdate(values));
      } else {
        dispatch(EmployerCompanyUpdate(values));
      }
      setTimeout(() => {
        dispatch(getProfile('employer'));
        navigate("/employer/dashboard");
      }, 1000);
    }
  });
  const setMailingAddressSameAsCurrentAddress = () => {
    validation.setFieldValue('mailing_address', validation?.values?.current_address)
    validation.setFieldValue('mailing_apt_ste_flr', validation?.values?.current_apt_ste_flr)
    validation.setFieldValue('mailing_number', validation?.values?.current_number)
    validation.setFieldValue('mailing_city', validation?.values?.current_city)
    validation.setFieldValue('mailing_state', validation?.values?.current_state)
    validation.setFieldValue('mailing_zipcode', validation?.values?.current_zipcode)
    validation.setFieldValue('mailing_province', validation?.values?.current_province)
    validation.setFieldValue('mailing_pcode', validation?.values?.current_pcode)
    validation.setFieldValue('mailing_country', validation?.values?.current_country)
  }
  let title = userStatus == 'Pending' ? 'Registration' : 'Update Company Info';
  const BreadcrumbData = [
    { name: 'Dashboard', url: '/employer/dashboard' },
    { name: title, url: '#' }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

          <CardHeader>
            <h4 className="card-title">Welcome, {username}</h4>
            <p>Welcome to Immidock, your complete Immigration Case Management software. Please complete below one time registartion process. This information will be used to populate G-28 forms for your future immigration cases. If you require any changes later, you can always update the information from your dashboard.</p>

          </CardHeader>

          <Row>
            <Col xs={12}>
              <Form className="needs-validation"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Card>

                  <CardHeader>
                    <h4 className="card-title">Basic information</h4>
                  </CardHeader>
                  <CardBody className="p-4">


                    {/* import TextualInputs */}
                    <Row>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Company Name or Organization Name *</Label>
                          <Input
                            name="company_name"
                            placeholder=""
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.company_name || ""}
                            invalid={
                              validation.touched.company_name && validation.errors.company_name ? true : false
                            }
                          />
                          {validation.touched.company_name && validation.errors.company_name ? (
                            <FormFeedback type="invalid">{validation.errors.company_name}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Phone Number *</Label>

                          <InputMask
                            name="phone"
                            mask="(999)-999-9999"
                            className={`${validation.touched.phone && validation.errors.phone ? 'form-control is-invalid' : 'form-control'}`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone || ""}
                            invalid={
                              validation.touched.phone && validation.errors.phone ? true : false
                            }
                          >
                            {(inputProps) => (
                              // <Input  type="tel" className="" />
                              <InputMask
                                {...inputProps}
                                mask="(999)-999-9999"
                                placeholder="Enter Phone"
                                type="text"
                                id="validationCustom02"
                              />
                            )}
                          </InputMask>
                          {validation.touched.phone && validation.errors.phone ? (
                            <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Fax Number</Label>
                          <Input
                            name="fax"
                            placeholder="Enter Fax"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.fax || ""}
                            invalid={
                              validation.touched.fax && validation.errors.fax ? true : false
                            }
                          />
                          {validation.touched.fax && validation.errors.fax ? (
                            <FormFeedback type="invalid">{validation.errors.fax}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Email Address *</Label>
                          <Input
                            name="email"
                            placeholder="Enter Email Address"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.auth_email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Website*</Label>
                          <Input
                            name="website"
                            placeholder="Enter Website"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.website || ""}
                            invalid={
                              validation.touched.website && validation.errors.website ? true : false
                            }
                          />
                          {validation.touched.website && validation.errors.website ? (
                            <FormFeedback type="invalid">{validation.errors.website}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Company Logo</Label>
                          <Input
                            type="file"
                            className="form-control"
                            name="company_logo"
                            onChange={(e) => validation.setFieldValue('company_logo', e.target.files[0])}
                            accept=".png, .jpg, .jpeg"
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.company_logo &&
                                validation.errors.company_logo
                                ? true
                                : false}
                          />
                          {validation.touched.company_logo &&
                            validation.errors.company_logo ? (
                            <FormFeedback type="invalid">
                              {validation.errors.company_logo}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                        {validation?.values?.company_logo_url && (
                          <span className="logo-sm">
                            <img src={validation?.values?.company_logo_url} alt="" width="24" height="24" />
                          </span>
                        )}
                      </Col>

                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h4 className="card-title">Authorized representative</h4>
                  </CardHeader>
                  <CardBody className="p-4">
                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">First name *</Label>
                          <Input
                            name="first_name"
                            placeholder="First name"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.first_name || ""}
                            invalid={
                              validation.touched.first_name && validation.errors.first_name ? true : false
                            }
                          />
                          {validation.touched.first_name && validation.errors.first_name ? (
                            <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Last name *</Label>
                          <Input
                            name="last_name"
                            placeholder="Last name"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.last_name || ""}
                            invalid={
                              validation.touched.last_name && validation.errors.last_name ? true : false
                            }
                          />
                          {validation.touched.last_name && validation.errors.last_name ? (
                            <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Title*</Label>
                          <Input
                            name="title"
                            placeholder="Title"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.title || ""}
                            invalid={
                              validation.touched.title && validation.errors.title ? true : false
                            }
                          />
                          {validation.touched.title && validation.errors.title ? (
                            <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Daytime Phone *</Label>
                          <InputMask
                            name="daytime_phone"
                            mask="(999)-999-9999"
                            className="form-control input-color"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.daytime_phone || ""}
                            invalid={
                              validation.touched.daytime_phone && validation.errors.daytime_phone ? true : false
                            }

                          >
                            {(inputProps) => (
                              // <Input  type="tel" className="" />
                              <InputMask
                                {...inputProps}
                                placeholder="Daytime Phone"
                                mask="(999)-999-9999"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                              />
                            )}
                          </InputMask>

                          {validation.touched.daytime_phone && validation.errors.daytime_phone ? (
                            <FormFeedback type="invalid">{validation.errors.daytime_phone}</FormFeedback>
                          ) : null}

                          <span className="font-13 text-muted">
                            (999) 999-9999
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Cell Phone *</Label>
                          <InputMask
                            name="cell"
                            mask="(999)-999-9999"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.cell || ""}
                            invalid={
                              validation.touched.cell && validation.errors.cell ? true : false
                            }
                          >
                            {(inputProps) => (
                              // <Input  type="tel" className="" />
                              <InputMask
                                {...inputProps}
                                placeholder="Cell Phone"
                                mask="(999)-999-9999"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                              />
                            )}
                          </InputMask>
                          {validation.touched.cell && validation.errors.cell ? (
                            <FormFeedback type="invalid">{validation.errors.cell}</FormFeedback>
                          ) : null}
                          <span className="font-13 text-muted">
                            (999) 999-9999
                          </span>
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Email Address *</Label>
                          <Input
                            name="auth_email"
                            placeholder="Email Address"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.auth_email || ""}
                            invalid={
                              validation.touched.auth_email && validation.errors.auth_email ? true : false
                            }
                          />
                          {validation.touched.auth_email && validation.errors.auth_email ? (
                            <FormFeedback type="invalid">{validation.errors.auth_email}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>


                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h4 className="card-title">Current Address</h4>
                  </CardHeader>
                  <CardBody className="p-4">

                    <Row>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Street Number And Name *</Label>
                          <Input
                            name="current_address"
                            placeholder="Street Number And Name"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.current_address || ""}
                            invalid={
                              validation.touched.current_address && validation.errors.current_address ? true : false
                            }
                          />
                          {validation.touched.current_address && validation.errors.current_address ? (
                            <FormFeedback type="invalid">{validation.errors.current_address}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Apt/Ste/Flr *</Label>
                          <div className="hstack gap-3">
                            <Input
                              name="current_apt_ste_flr"
                              type="radio"
                              className="form-control"
                              id="validationCustom02"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="Apt"
                              checked={validation.values.current_apt_ste_flr == "Apt" ? true : false}
                            />
                            Apt
                            <Input
                              name="current_apt_ste_flr"
                              type="radio"
                              className="form-control"
                              id="validationCustom02"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="Ste"
                              checked={validation.values.current_apt_ste_flr == "Ste" ? true : false}

                            />
                            Ste

                            <Input
                              name="current_apt_ste_flr"
                              type="radio"
                              className="form-control"
                              id="validationCustom02"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="Flr"
                              checked={validation.values.current_apt_ste_flr == "Flr" ? true : false}
                            />Flr

                          </div>

                        </FormGroup>
                        {validation.touched.current_apt_ste_flr && validation.errors.current_apt_ste_flr ? (
                          <FormFeedback type="invalid">{validation.errors.current_apt_ste_flr}</FormFeedback>
                        ) : null}
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Number *</Label>
                          <Input
                            name="current_number"
                            placeholder="Current Number"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.current_number || ""}
                            invalid={
                              validation.touched.current_number && validation.errors.current_number ? true : false
                            }
                          />
                          {validation.touched.current_number && validation.errors.current_number ? (
                            <FormFeedback type="invalid">{validation.errors.current_number}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">City or Town*</Label>
                          <Input
                            name="current_city"
                            placeholder="Current City"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.current_city || ""}
                            invalid={
                              validation.touched.current_city && validation.errors.current_city ? true : false
                            }
                          />
                          {validation.touched.current_city && validation.errors.current_city ? (
                            <FormFeedback type="invalid">{validation.errors.current_city}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">State*</Label>
                          <Input
                            name="current_state"
                            placeholder="Current State"
                            type="select"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.current_state || ""}
                            invalid={
                              validation.touched.current_state && validation.errors.current_state ? true : false
                            }
                          >

                            <option value="">Choose State</option>

                            <option value="AL">Alabama</option>

                            <option value="AK">Alaska</option>

                            <option value="AS">American Samoa</option>

                            <option value="AZ">Arizona</option>

                            <option value="AR">Arkansas</option>

                            <option value="UM-81">Baker Island</option>

                            <option value="CA">California</option>

                            <option value="CO">Colorado</option>

                            <option value="CT">Connecticut</option>

                            <option value="DE" selected="">Delaware</option>

                            <option value="DC">District of Columbia</option>

                            <option value="FL">Florida</option>

                            <option value="GA">Georgia</option>

                            <option value="GU">Guam</option>

                            <option value="HI">Hawaii</option>

                            <option value="UM-84">Howland Island</option>

                            <option value="ID">Idaho</option>

                            <option value="IL">Illinois</option>

                            <option value="IN">Indiana</option>

                            <option value="IA">Iowa</option>

                            <option value="UM-86">Jarvis Island</option>

                            <option value="UM-67">Johnston Atoll</option>

                            <option value="KS">Kansas</option>

                            <option value="KY">Kentucky</option>

                            <option value="UM-89">Kingman Reef</option>

                            <option value="LA">Louisiana</option>

                            <option value="ME">Maine</option>

                            <option value="MD">Maryland</option>

                            <option value="MA">Massachusetts</option>

                            <option value="MI">Michigan</option>

                            <option value="UM-71">Midway Atoll</option>

                            <option value="MN">Minnesota</option>

                            <option value="MS">Mississippi</option>

                            <option value="MO">Missouri</option>

                            <option value="MT">Montana</option>

                            <option value="UM-76">Navassa Island</option>

                            <option value="NE">Nebraska</option>

                            <option value="NV">Nevada</option>

                            <option value="NH">New Hampshire</option>

                            <option value="NJ">New Jersey</option>

                            <option value="NM">New Mexico</option>

                            <option value="NY">New York</option>

                            <option value="NC">North Carolina</option>

                            <option value="ND">North Dakota</option>

                            <option value="MP">Northern Mariana Islands</option>

                            <option value="OH">Ohio</option>

                            <option value="OK">Oklahoma</option>

                            <option value="OR">Oregon</option>

                            <option value="UM-95">Palmyra Atoll</option>

                            <option value="PA">Pennsylvania</option>

                            <option value="PR">Puerto Rico</option>

                            <option value="RI">Rhode Island</option>

                            <option value="SC">South Carolina</option>

                            <option value="SD">South Dakota</option>

                            <option value="TN">Tennessee</option>

                            <option value="TX">Texas</option>

                            <option value="UM">United States Minor Outlying Islands</option>

                            <option value="VI">United States Virgin Islands</option>

                            <option value="UT">Utah</option>

                            <option value="VT">Vermont</option>

                            <option value="VA">Virginia</option>

                            <option value="UM-79">Wake Island</option>

                            <option value="WA">Washington</option>

                            <option value="WV">West Virginia</option>

                            <option value="WI">Wisconsin</option>

                            <option value="WY">Wyoming</option>

                          </Input>
                          {validation.touched.current_state && validation.errors.current_state ? (
                            <FormFeedback type="invalid">{validation.errors.current_state}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>


                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Zipcode *</Label>
                          <Input
                            name="current_zipcode"
                            placeholder="Current Zipcode"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.current_zipcode || ""}
                            invalid={
                              validation.touched.current_zipcode && validation.errors.current_zipcode ? true : false
                            }
                          />
                          {validation.touched.current_zipcode && validation.errors.current_zipcode ? (
                            <FormFeedback type="invalid">{validation.errors.current_zipcode}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>


                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Province</Label>
                          <Input
                            name="current_province"
                            placeholder="Current Province"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.current_province || ""}
                            invalid={
                              validation.touched.current_province && validation.errors.current_province ? true : false
                            }
                          />
                          {validation.touched.current_province && validation.errors.current_province ? (
                            <FormFeedback type="invalid">{validation.errors.current_province}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>


                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Postal Code *</Label>
                          <Input
                            name="current_pcode"
                            placeholder="Current Pcode"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.current_pcode || ""}
                            invalid={
                              validation.touched.current_pcode && validation.errors.current_pcode ? true : false
                            }
                          />
                          {validation.touched.current_pcode && validation.errors.current_pcode ? (
                            <FormFeedback type="invalid">{validation.errors.current_pcode}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Country *</Label>
                          <Input
                            name="current_country"
                            placeholder="Current Country"
                            type="select"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.current_country || ""}
                            invalid={
                              validation.touched.current_country && validation.errors.current_country ? true : false
                            }
                          >
                            <option value="">Choose Country</option>
                            <option value="USA" selected="">USA</option>

                          </Input>
                          {validation.touched.current_country && validation.errors.current_country ? (
                            <FormFeedback type="invalid">{validation.errors.current_country}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h4 className="card-title">Mailing Address</h4>
                  </CardHeader>
                  <CardBody className="p-4">
                    <Row>
                      <Col md="12">
                        <FormGroup className="form-check mb-3">
                          <Input
                            name="is_mailing_addr_same_as_current_addr"
                            type="checkbox"
                            className="form-check-input"
                            id="is_mailing_addr_same_as_current_addr"
                            onChange={(e) => {
                              validation.handleChange(e)
                              setMailingAddressSameAsCurrentAddress()
                            }}
                            checked={validation.values.is_mailing_addr_same_as_current_addr}
                          />
                          <Label htmlFor="mailing_addr_same_as_current_addr" className="form-check-label">Mailing address same as current address</Label>

                        </FormGroup></Col></Row>
                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Street Number And Name *</Label>
                          <Input
                            disabled={validation.values.is_mailing_addr_same_as_current_addr}
                            name="mailing_address"
                            placeholder="Street Number And Name"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mailing_address || ""}
                            invalid={
                              validation.touched.mailing_address && validation.errors.mailing_address ? true : false
                            }
                          />
                          {validation.touched.mailing_address && validation.errors.mailing_address ? (
                            <FormFeedback type="invalid">{validation.errors.mailing_address}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <div className="hstack gap-3">
                            <Input
                              disabled={validation.values.is_mailing_addr_same_as_current_addr}
                              name="mailing_apt_ste_flr"
                              type="radio"
                              className="form-control"
                              id="validationCustom02"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="Apt"
                              checked={validation.values.mailing_apt_ste_flr == "Apt" ? true : false}
                            />
                            Apt
                            <Input
                              disabled={validation.values.is_mailing_addr_same_as_current_addr}
                              name="mailing_apt_ste_flr"
                              type="radio"
                              className="form-control"
                              id="validationCustom02"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="Ste"
                              checked={validation.values.mailing_apt_ste_flr == "Ste" ? true : false}
                            />
                            Ste

                            <Input
                              disabled={validation.values.is_mailing_addr_same_as_current_addr}
                              name="mailing_apt_ste_flr"
                              type="radio"
                              className="form-control"
                              id="validationCustom02"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="Flr"
                              checked={validation.values.mailing_apt_ste_flr == "Flr" ? true : false}
                            />Flr

                          </div>

                          {validation.touched.apt_ste_flr && validation.errors.apt_ste_flr ? (
                            <FormFeedback type="invalid">{validation.errors.apt_ste_flr}</FormFeedback>
                          ) : null}
                        </FormGroup>

                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Number</Label>
                          <Input
                            disabled={validation.values.is_mailing_addr_same_as_current_addr}
                            name="mailing_number"
                            placeholder="Mailing Number"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mailing_number || ""}
                            invalid={
                              validation.touched.mailing_number && validation.errors.mailing_number ? true : false
                            }
                          />
                          {validation.touched.mailing_number && validation.errors.mailing_number ? (
                            <FormFeedback type="invalid">{validation.errors.mailing_number}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">City or Town*</Label>
                          <Input
                            disabled={validation.values.is_mailing_addr_same_as_current_addr}
                            name="mailing_city"
                            placeholder="Mailing City"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mailing_city || ""}
                            invalid={
                              validation.touched.mailing_city && validation.errors.mailing_city ? true : false
                            }
                          />
                          {validation.touched.mailing_city && validation.errors.mailing_city ? (
                            <FormFeedback type="invalid">{validation.errors.mailing_city}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">State*</Label>
                          <Input
                            disabled={validation.values.is_mailing_addr_same_as_current_addr}
                            name="mailing_state"
                            placeholder="mailing_state"
                            type="select"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mailing_state || ""}
                            invalid={
                              validation.touched.mailing_state && validation.errors.mailing_state ? true : false
                            }
                          >

                            <option value="">Choose State</option>

                            <option value="AL">Alabama</option>

                            <option value="AK">Alaska</option>

                            <option value="AS">American Samoa</option>

                            <option value="AZ">Arizona</option>

                            <option value="AR">Arkansas</option>

                            <option value="UM-81">Baker Island</option>

                            <option value="CA">California</option>

                            <option value="CO">Colorado</option>

                            <option value="CT">Connecticut</option>

                            <option value="DE" selected="">Delaware</option>

                            <option value="DC">District of Columbia</option>

                            <option value="FL">Florida</option>

                            <option value="GA">Georgia</option>

                            <option value="GU">Guam</option>

                            <option value="HI">Hawaii</option>

                            <option value="UM-84">Howland Island</option>

                            <option value="ID">Idaho</option>

                            <option value="IL">Illinois</option>

                            <option value="IN">Indiana</option>

                            <option value="IA">Iowa</option>

                            <option value="UM-86">Jarvis Island</option>

                            <option value="UM-67">Johnston Atoll</option>

                            <option value="KS">Kansas</option>

                            <option value="KY">Kentucky</option>

                            <option value="UM-89">Kingman Reef</option>

                            <option value="LA">Louisiana</option>

                            <option value="ME">Maine</option>

                            <option value="MD">Maryland</option>

                            <option value="MA">Massachusetts</option>

                            <option value="MI">Michigan</option>

                            <option value="UM-71">Midway Atoll</option>

                            <option value="MN">Minnesota</option>

                            <option value="MS">Mississippi</option>

                            <option value="MO">Missouri</option>

                            <option value="MT">Montana</option>

                            <option value="UM-76">Navassa Island</option>

                            <option value="NE">Nebraska</option>

                            <option value="NV">Nevada</option>

                            <option value="NH">New Hampshire</option>

                            <option value="NJ">New Jersey</option>

                            <option value="NM">New Mexico</option>

                            <option value="NY">New York</option>

                            <option value="NC">North Carolina</option>

                            <option value="ND">North Dakota</option>

                            <option value="MP">Northern Mariana Islands</option>

                            <option value="OH">Ohio</option>

                            <option value="OK">Oklahoma</option>

                            <option value="OR">Oregon</option>

                            <option value="UM-95">Palmyra Atoll</option>

                            <option value="PA">Pennsylvania</option>

                            <option value="PR">Puerto Rico</option>

                            <option value="RI">Rhode Island</option>

                            <option value="SC">South Carolina</option>

                            <option value="SD">South Dakota</option>

                            <option value="TN">Tennessee</option>

                            <option value="TX">Texas</option>

                            <option value="UM">United States Minor Outlying Islands</option>

                            <option value="VI">United States Virgin Islands</option>

                            <option value="UT">Utah</option>

                            <option value="VT">Vermont</option>

                            <option value="VA">Virginia</option>

                            <option value="UM-79">Wake Island</option>

                            <option value="WA">Washington</option>

                            <option value="WV">West Virginia</option>

                            <option value="WI">Wisconsin</option>

                            <option value="WY">Wyoming</option>

                          </Input>
                          {validation.touched.mailing_state && validation.errors.mailing_state ? (
                            <FormFeedback type="invalid">{validation.errors.mailing_state}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>




                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Zipcode *</Label>
                          <Input
                            disabled={validation.values.is_mailing_addr_same_as_current_addr}
                            name="mailing_zipcode"
                            placeholder="Mailing Zipcode"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mailing_zipcode || ""}
                            invalid={
                              validation.touched.mailing_zipcode && validation.errors.mailing_zipcode ? true : false
                            }
                          />
                          {validation.touched.mailing_zipcode && validation.errors.mailing_zipcode ? (
                            <FormFeedback type="invalid">{validation.errors.mailing_zipcode}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>


                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Province</Label>
                          <Input
                            disabled={validation.values.is_mailing_addr_same_as_current_addr}
                            name="mailing_province"
                            placeholder="Mailing Province"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mailing_province || ""}
                            invalid={
                              validation.touched.mailing_province && validation.errors.mailing_province ? true : false
                            }
                          />
                          {validation.touched.mailing_province && validation.errors.mailing_province ? (
                            <FormFeedback type="invalid">{validation.errors.mailing_province}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>


                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Postal Code</Label>
                          <Input
                            disabled={validation.values.is_mailing_addr_same_as_current_addr}
                            name="mailing_pcode"
                            placeholder="Mailing Pcode"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mailing_pcode || ""}
                            invalid={
                              validation.touched.mailing_pcode && validation.errors.mailing_pcode ? true : false
                            }
                          />
                          {validation.touched.mailing_pcode && validation.errors.mailing_pcode ? (
                            <FormFeedback type="invalid">{validation.errors.mailing_pcode}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Country *</Label>
                          <Input
                            disabled={validation.values.is_mailing_addr_same_as_current_addr}
                            name="mailing_country"
                            placeholder="mailing_country"
                            type="select"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mailing_country || ""}
                            invalid={
                              validation.touched.mailing_country && validation.errors.mailing_country ? true : false
                            }
                          >

                            <option value="">Choose Country</option>

                            <option value="USA" selected="">USA</option>
                          </Input>
                          {validation.touched.mailing_country && validation.errors.mailing_country ? (
                            <FormFeedback type="invalid">{validation.errors.mailing_country}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h4 className="card-title">Other information</h4>
                  </CardHeader>
                  <CardBody className="p-4">

                    <Row>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Type Of Business *</Label>
                          <Input
                            name="business_type"
                            placeholder="Type Of Business"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.business_type || ""}
                            invalid={
                              validation.touched.business_type && validation.errors.business_type ? true : false
                            }
                          />
                          {validation.touched.business_type && validation.errors.business_type ? (
                            <FormFeedback type="invalid">{validation.errors.business_type}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>


                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Individual IRS Tax Number (IRS) *</Label>
                          <Input
                            name="irs_tax_num"
                            placeholder="Individual IRS Tax Number (IRS)"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.irs_tax_num || ""}
                            invalid={
                              validation.touched.irs_tax_num && validation.errors.irs_tax_num ? true : false
                            }
                          />
                          {validation.touched.irs_tax_num && validation.errors.irs_tax_num ? (
                            <FormFeedback type="invalid">{validation.errors.irs_tax_num}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Gross Annual Income (USD)*</Label>
                          <Input
                            name="gross_income"
                            placeholder="Type Of Business"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.gross_income || ""}
                            invalid={
                              validation.touched.gross_income && validation.errors.gross_income ? true : false
                            }
                          />
                          {validation.touched.gross_income && validation.errors.gross_income ? (
                            <FormFeedback type="invalid">{validation.errors.gross_income}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">NAISC Code *</Label>
                          <Input
                            name="naisc_code"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.naisc_code || ""}
                            invalid={
                              validation.touched.naisc_code && validation.errors.naisc_code ? true : false
                            }
                          />
                          {validation.touched.naisc_code && validation.errors.naisc_code ? (
                            <FormFeedback type="invalid">{validation.errors.naisc_code}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>



                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Year Incorporated In US *</Label>
                          <Input
                            name="yiiu"
                            placeholder="Mobile Phone"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.yiiu || ""}
                            invalid={
                              validation.touched.yiiu && validation.errors.yiiu ? true : false
                            }
                          />
                          {validation.touched.yiiu && validation.errors.yiiu ? (
                            <FormFeedback type="invalid">{validation.errors.yiiu}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Net Annual Income (USD) *</Label>
                          <Input
                            name="net_income"
                            placeholder="Net Income Address"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.net_income || ""}
                            invalid={
                              validation.touched.net_income && validation.errors.net_income ? true : false
                            }
                          />
                          {validation.touched.net_income && validation.errors.net_income ? (
                            <FormFeedback type="invalid">{validation.errors.net_income}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Place Incorporated In US *</Label>
                          <Input
                            name="place_incorporated"
                            placeholder="Place Incorporated Address"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.place_incorporated || ""}
                            invalid={
                              validation.touched.place_incorporated && validation.errors.place_incorporated ? true : false
                            }
                          />
                          {validation.touched.place_incorporated && validation.errors.place_incorporated ? (
                            <FormFeedback type="invalid">{validation.errors.place_incorporated}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Federal Employer Identification Number (FEIN) *</Label>
                          <Input
                            name="fein"
                            placeholder="Fein Address"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.fein || ""}
                            invalid={
                              validation.touched.fein && validation.errors.fein ? true : false
                            }
                          />
                          {validation.touched.fein && validation.errors.fein ? (
                            <FormFeedback type="invalid">{validation.errors.fein}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>


                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Current Number Of Employees In The United States *</Label>
                          <Input
                            name="cnoeitus"
                            placeholder="Cnoeitus Address"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.cnoeitus || ""}
                            invalid={
                              validation.touched.cnoeitus && validation.errors.cnoeitus ? true : false
                            }
                          />
                          {validation.touched.cnoeitus && validation.errors.cnoeitus ? (
                            <FormFeedback type="invalid">{validation.errors.cnoeitus}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>



                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Are  more than 50 percent of these employees in H-1B,L-1A or L-1B nonimmigrant Status ? *</Label>
                          <Input
                            name="employees_nonimmigrant_status"
                            placeholder="Last name"
                            type="select"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.employees_nonimmigrant_status || ""}
                            invalid={
                              validation.touched.employees_nonimmigrant_status && validation.errors.employees_nonimmigrant_status ? true : false
                            }
                          > <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Input>


                          {validation.touched.employees_nonimmigrant_status && validation.errors.employees_nonimmigrant_status ? (
                            <FormFeedback type="invalid">{validation.errors.employees_nonimmigrant_status}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                    </Row>



                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-input"
                        className="col-sm-2 col-form-label"
                      >
                      </Label>
                      <Col sm={5}>
                        <div style={{ float: "right" }}>
                          <Button type="reset" color="secondary" onClick={() => navigate(-1)}>
                            Cancel
                          </Button>
                          {" "}
                          <Button type="submit" color="primary" className="ms-1">
                            Save
                          </Button>
                        </div>

                      </Col>
                    </Row>


                  </CardBody>

                </Card>
              </Form>
            </Col>


          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};
export default FormLayoutsBasic
