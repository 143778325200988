import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_EMPLOYERCONTACTS, GET_EMPLOYERCONTACT_DETAIL, ADD_EMPLOYERCONTACT_DETAIL, DELETE_EMPLOYERCONTACT_DETAIL, UPDATE_EMPLOYERCONTACT_DETAIL, UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL,
  UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL, UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL,
  GET_EMPLOYEREMPLOYEE_COUNTRIES, GET_EMPLOYEREMPLOYEE_STATES,
  ADD_EMPLOYEREMPLOYEE_DOC, SHARE_EMPLOYEREMPLOYEE_DOC,
  ASSIGN_DOC_TO_CASE,
  GET_DOCUMENTS_BYCASE,
  VALIDATE_EMPLOYEE_EMAILID
} from "./actionTypes"
import {
  getEmployerContactsSuccess,
  getEmployerContactsFail,
  getEmployerContactDetailSuccess,
  getEmployerContactDetailFail,
  addEmployerContactdetailSuccess,
  addEmployerContactdetailFail,
  EmployerContactDeleteSuccess,
  EmployerContactDeleteFail,
  EmployerContactUpdateSuccess,
  EmployerContactUpdateFail,
  updateEmployeeJobDetailsSuccess,
  updateEmployeeJobDetailsFail,
  updateEmployeePayDetailsSuccess,
  updateEmployeePayDetailsFail,
  updateEmployeePersonalDetailsSuccess,
  updateEmployeePersonalDetailsFail,
  getEmployerEmployeeCountriessSuccess,
  getEmployerEmployeeCountriesFail,
  getEmployerEmployeeStatesSuccess,
  getEmployerEmployeeStatesFail,
  addEmployerEmployeeDocumentSuccess,
  addEmployerEmployeeDocumentFail,
  shareEmployeeEmployerDocSuccess,
  shareEmployeeEmployerDocFail,
  assignDocToCaseSuccess,
  assignDocToCaseFail,
  getDcoumentsByCaseSuccess,
  getDcoumentsByCaseFail,
  validateDuplicateEmailIdSuccess,
  validateDuplicateEmailIdFail

} from "./actions"

//Include Both Helper File with needed methods
import {
  getEmployerContacts, updateEmployeeJobDetails, getEmployerContactDetail, updateEmployeePayDetails,
  addEmployerContact, deleteEmployerContact, updateEmployerContact,
  updateEmployeePersonalDetails, getEmployerEmployeeCountriesList,
  getEmployerEmployeeStatesList, addEmployerEmployeeDocHelper,
  shareEmployerEmployeeDocHelper, assignDocumentToCaseHelper,
  documentsByCaseHelper, validateEmployeeEmail
} from "../../../helpers/apibackend_helper"
import { setAlertMessage } from "../../common/actions"

function* fetchEmployerContacts({ payload: user }) {
  try {
    const response = yield call(getEmployerContacts, user)
    yield put(getEmployerContactsSuccess(response.data))
  } catch (error) {
    yield put(getEmployerContactsFail(error))
  }
}

function* fetchEmployerContactDetail({ payload: EmployerContactId }) {
  try {
    const response = yield call(getEmployerContactDetail, EmployerContactId)
    yield put(getEmployerContactDetailSuccess(response.data))
  } catch (error) {
    yield put(getEmployerContactDetailFail(error))
  }
}

function* addEmployerContactDetail({ payload: user }) {
  try {
    const response = yield call(addEmployerContact, user)
    yield put(addEmployerContactdetailSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(addEmployerContactdetailFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* onDeleteEmployerContact({ payload: data }) {
  try {
    const response = yield call(deleteEmployerContact, data);
    yield put(EmployerContactDeleteSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(EmployerContactDeleteFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* onUpdateEmployerContact({ payload: data }) {
  try {
    const response = yield call(updateEmployerContact, data);
    yield put(EmployerContactUpdateSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(EmployerContactUpdateFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* onUpdateEmployerEmployeeJobDetails({ payload: data }) {
  try {
    const response = yield call(updateEmployeeJobDetails, data);
    yield put(updateEmployeeJobDetailsSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(updateEmployeeJobDetailsFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* onUpdateEmployerEmployeePayDetails({ payload: data }) {
  try {
    const response = yield call(updateEmployeePayDetails, data);
    yield put(updateEmployeePayDetailsSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(updateEmployeePayDetailsFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* onUpdateEmployerEmployeePersonalDetails({ payload: data }) {
  try {
    const response = yield call(updateEmployeePersonalDetails, data);
    yield put(updateEmployeePersonalDetailsSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(updateEmployeePersonalDetailsFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* fetchEmployerEmployeeCountrys() {
  try {
    const response = yield call(getEmployerEmployeeCountriesList)
    yield put(getEmployerEmployeeCountriessSuccess(response.data))
  } catch (error) {
    yield put(getEmployerEmployeeCountriesFail(error))
  }
}

function* fetchEmployerEmployeeStates({ payload: data }) {
  try {
    const response = yield call(getEmployerEmployeeStatesList, data)
    yield put(getEmployerEmployeeStatesSuccess(response.data))
  } catch (error) {
    yield put(getEmployerEmployeeStatesFail(error))
  }
}

function* addEmployerEmployeeDocument({ payload: data }) {
  try {
    const response = yield call(addEmployerEmployeeDocHelper, data)
    yield put(addEmployerEmployeeDocumentSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(addEmployerEmployeeDocumentFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* shareEmployerEmployeeDocument({ payload: data }) {
  try {
    const response = yield call(shareEmployerEmployeeDocHelper, data)
    yield put(shareEmployeeEmployerDocSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(shareEmployeeEmployerDocFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* assignDocumentToCase({ payload: data }) {
  try {
    const response = yield call(assignDocumentToCaseHelper, data)
    yield put(assignDocToCaseSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(assignDocToCaseFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* getAllDcoumentsByCase({ payload: data }) {
  try {
    const response = yield call(documentsByCaseHelper, data)
    yield put(getDcoumentsByCaseSuccess(response.data))
  } catch (error) {
    yield put(getDcoumentsByCaseFail(error))
  }
}

function* onValidateEmployeeEmail({ payload: data }) {
  try {
    const response = yield call(validateEmployeeEmail, data);
    yield put(validateDuplicateEmailIdSuccess(response.data));
  } catch (error) {
    yield put(validateDuplicateEmailIdFail(error));
  }
}

function* EmployerContactsaga() {
  yield takeEvery(GET_EMPLOYERCONTACTS, fetchEmployerContacts)
  yield takeEvery(GET_EMPLOYERCONTACT_DETAIL, fetchEmployerContactDetail)
  yield takeEvery(ADD_EMPLOYERCONTACT_DETAIL, addEmployerContactDetail)
  yield takeEvery(DELETE_EMPLOYERCONTACT_DETAIL, onDeleteEmployerContact)
  yield takeEvery(UPDATE_EMPLOYERCONTACT_DETAIL, onUpdateEmployerContact)
  yield takeEvery(UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL, onUpdateEmployerEmployeeJobDetails)
  yield takeEvery(UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL, onUpdateEmployerEmployeePayDetails)
  yield takeEvery(UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL, onUpdateEmployerEmployeePersonalDetails)
  yield takeEvery(GET_EMPLOYEREMPLOYEE_COUNTRIES, fetchEmployerEmployeeCountrys),
    yield takeEvery(GET_EMPLOYEREMPLOYEE_STATES, fetchEmployerEmployeeStates),
    yield takeEvery(ADD_EMPLOYEREMPLOYEE_DOC, addEmployerEmployeeDocument)
  yield takeEvery(SHARE_EMPLOYEREMPLOYEE_DOC, shareEmployerEmployeeDocument)
  yield takeEvery(ASSIGN_DOC_TO_CASE, assignDocumentToCase)
  yield takeEvery(GET_DOCUMENTS_BYCASE, getAllDcoumentsByCase)
  yield takeEvery(VALIDATE_EMPLOYEE_EMAILID, onValidateEmployeeEmail)

}

export default EmployerContactsaga
