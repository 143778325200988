import React, { useEffect, useMemo, useState } from "react";
import {
  useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import DatatableContainer from "../../../components/Common/DatatableContainer";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { changePreloader, employeeResendPassword, getEmployerContacts, EmployerContactDelete } from "../../../store/actions";
import {
  CommonCell, Date
} from "./listCol";

//redux
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";


const InvoiceList = () => {

  //meta title
  document.title = "Contacts | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [activeTab, setactiveTab] = useState("All");
  const [empType, setEmpType] = useState("All");

  // for delete 
  const [order, setOrder] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [moveToInactiveModal, setMoveToInactiveModal] = useState(false);

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setOrder(data);
  };
  const onMoveToInactive = (data) => {
    setMoveToInactiveModal(true);
    setOrder(data);
  };


  const handleChangeEmployeeStatus = (status) => {
    if (order.employee_id) {
      dispatch(EmployerContactDelete({
        "employee_id": order.employee_id,
        "status": status
      }));
      dispatch(changePreloader(true));
      setApiSuccess(true)
      setDeleteModal(false);
      setMoveToInactiveModal(false);
      setTimeout(() => {
        dispatch(getEmployerContacts(contactList));
      }, 800)
    }
  };

  const { employercontacts } = useSelector((state) => ({
    employercontacts: state.EmployerContacts.employercontacts || [],
  }));
  const [contactList, setContactList] = useState({ "status": "All", "emp_type": "All" });
  const [apiSuccess, setApiSuccess] = useState(false);

  useEffect(() => {
    dispatch(getEmployerContacts(contactList));
    dispatch(changePreloader(true));
    setApiSuccess(true)
  }, [dispatch]);

  useEffect(() => {
    if (apiSuccess) {
      dispatch(changePreloader(false));
      setApiSuccess(false)
    }
  }, [employercontacts]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleUserClicks = () => {
    navigate(`/employer/add-employer-contact`);
  };



  const onUpdateData = (data) => {
    navigate(`/employer/add-employer-contact?id=${data.employee_id}`);
  };
  const onResendLogin = (data) => {
    dispatch(employeeResendPassword(data.employee_id));
    dispatch(changePreloader(true));
    setApiSuccess(true)
    setTimeout(() => {
      dispatch(getEmployerContacts(contactList));
    }, 200)
  };

  const onReloadData = (event) => {
    var tabInfo = event.target.value;
    setactiveTab(tabInfo);
    dispatch(getEmployerContacts({ status: tabInfo, emp_type: empType }));
  }

  const handleEmployeeFiltersChange = (event) => {
    var empType = event.target.value;
    setEmpType(empType);
    dispatch(getEmployerContacts({ status: activeTab, emp_type: empType }));
  }


  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",

        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Job Title",
        accessor: "job_title",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },


      {
        Header: "Visa",
        accessor: "visa_type",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Expire Date",
        accessor: "expiration_date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Beneficiary Type",
        accessor: "emp_type",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },

      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },


      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => onUpdateData(cellProps.row.original)}>View</DropdownItem>
                {(cellProps.row.original.status != "Inactive") && (
                  <>
                    <DropdownItem onClick={() => onResendLogin(cellProps.row.original)}>Resend Password</DropdownItem>
                    <DropdownItem onClick={() => onMoveToInactive(cellProps.row.original)}>Move to Inactive</DropdownItem>
                  </>
                )}
                <DropdownItem onClick={() => onDeleteData(cellProps.row.original)}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  let title = 'Beneficiaries';
  const BreadcrumbData = [
    { name: 'Dashboard', url: '/employer/dashboard' },
    { name: title, url: '#' }
  ]
  return (
    <React.Fragment>

      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleChangeEmployeeStatus("Deleted")}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={moveToInactiveModal}
        onDeleteClick={() => handleChangeEmployeeStatus("Inactive")}
        onCloseClick={() => setMoveToInactiveModal(false)}
        message={"You are about to move the beneficiary to inactive status."}
        status={"Inactive"}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <DatatableContainer
                    columns={columns}
                    data={employercontacts}
                    isGlobalFilter={true}
                    isAddEmployeeType={true}
                    isEmployerEmployeeFilters={true}
                    isEmployerEmployeeFiltersChange={handleEmployeeFiltersChange}
                    customPageSize={10}
                    handleInvoiceClick={handleUserClicks}
                    isAttorneyFilter={true}
                    defaultAttorneyFilter={'Active'}
                    isAttorneyFilterChange={onReloadData}
                  />
                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InvoiceList;
