import React, { useEffect, useState } from "react";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledAccordion,
  UncontrolledDropdown,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import {
  deleteAttorneyMasterDocs,
  getAttorneyMasterDocs,
  updateAttorneyMasterDocs,
} from "../../../store/attorney/masterdocs/actions";
import { useSelector } from "react-redux";
import { useAlert } from "../../../components/Common/Alert";
import { changePreloader } from "../../../store/actions";

const MasterDocs = () => {
  //meta title
  document.title = "Attorney | Immidock ";
  const title = "Attorney Case Documents";
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [docTypesValues, setDocTypesValues] = useState();
  const [apiSuccess, setApiSuccess] = useState(false);

  const { getMasterDocs } = useSelector((state) => ({
    getMasterDocs: state?.AttorneyMasterDocs?.getMasterDocs || [],
  }));
  const [updateList, setUpdateList] = useState();
  useEffect(() => {
    if (Object.entries(getMasterDocs).length > 0) {
      setDocTypesValues(Object.entries(getMasterDocs));
      setUpdateList(Object.entries(getMasterDocs)?.length > 0 ?  Object.entries(getMasterDocs)?.map((itemName)=> Object.entries(itemName[1])?.map((objItem, index) => {
        return [...objItem[1]["principle_applicant_checklist"].map((objItem2, index) => { return objItem2.is_checked_list == 1 && objItem2.id}),
        ...objItem[1]["applicant_dependant_checklist"].map((objItem2, index) => { return objItem2.is_checked_list == 1 && objItem2.id})]
        }).flat()).flat().filter((list)=> list != false) : [])
    }
  }, [getMasterDocs]);

  useEffect(() => {
    dispatch(getAttorneyMasterDocs());
    dispatch(changePreloader(true));
    setApiSuccess(true)
  }, []);

  useEffect(() => {
    if (apiSuccess) {
      dispatch(changePreloader(false));
      setApiSuccess(false)
    }
  }, [getMasterDocs]);
    
  

  const uniqueArray = (arr) =>{
    return Array.from(new Set(arr));
  } 

  const updateValue = (dataObj,value) =>{
    let data = dataObj;
    data['is_checked_list'] = value
    return data;
  }
  const mapList = (itemName,id,value) =>{

  let arr = [];
   const data =  Object.entries(itemName)?.forEach((objItem, index) => {
    arr[objItem[0]] = {principle_applicant_checklist: [...objItem[1]["principle_applicant_checklist"].map((objItem2, index) => { return objItem2.id == id ? updateValue(objItem2,value) : objItem2; })],
      applicant_dependant_checklist:[...objItem[1]["applicant_dependant_checklist"].map((objItem2, index) => { return objItem2.id == id ? updateValue(objItem2,value) : objItem2;})]
  }})

  return arr;;
  }


  const handleChangeList = (id, checked) => {
    if (checked) {
      setDocTypesValues(Object.entries(getMasterDocs)?.length > 0 ?  Object.entries(getMasterDocs)?.map((itemName)=>{
        return [itemName[0], mapList(itemName[1],id,1)]}) : [])
      setUpdateList((prevState) => uniqueArray([...prevState,...updateList, id]));
    } else {
      console.log(Object.entries(getMasterDocs)?.length > 0 ?  Object.entries(getMasterDocs)?.map((itemName)=>{
       return [itemName[0], mapList(itemName[1],id,0)]}) : [])
  
      setDocTypesValues(Object.entries(getMasterDocs)?.length > 0 ?  Object.entries(getMasterDocs)?.map((itemName)=>{
        return [itemName[0], mapList(itemName[1],id,0)]}) : [])
      setUpdateList((prevState) => uniqueArray([...prevState,...updateList]).filter((item) => id != item));
    }
  };
  const handleUpdateList = () => {
    dispatch(updateAttorneyMasterDocs({ doc_ids: updateList.toString() }));
  };
  const hadleDeleteList = (id) => {
    dispatch(deleteAttorneyMasterDocs({ doc_ids: id }));
  };
  const BreadcrumbData = [
    {name:'Dashboard', url:'/attorney/dashboard'},
    { name: title, url: '#' }
  ] 
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col xs={9}>
            </Col>
            <Col xs={3}>
              <Input
                name="customer_action"
                placeholder="More Actions"
                type="select"
                className="form-control"
                id="customer_action"
                onChange={(e) => {
                  if (e.target.value == "Submit documents") {
                    handleUpdateList();
                  }
                }}
              >
                <option value="">More Actions</option>
                <option value="Submit documents">Submit documents</option>
              </Input>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <UncontrolledAccordion defaultOpen="1">
                <AccordionItem>
                  {docTypesValues &&
                    docTypesValues.map((item) => {
                      return (
                        <>
                          <h3>{item[0]}</h3>
                          {Object.entries(item[1]).length > 0 &&
                            Object.entries(item[1]).map((objItem, index) => {
                              return (
                                <>
                                  <AccordionHeader targetId={index}>
                                    <>{objItem[0]}</>
                                  </AccordionHeader>
                                  <AccordionBody accordionId={index}>
                                    {console.log(objItem[1])}
                                    <Row>
                                      <Col xs="6">
                                        <Row xs="12" m="3">
                                          <h4>Principal Applicant Checklist</h4>
                                        </Row>
                                        {objItem[1][
                                          "principle_applicant_checklist"
                                        ].map((objItem2, index) => {
                                          return (
                                            <>
                                              <Row xs="12" m="3">
                                                <div className="mb-2">
                                                  <div className="form-check custom-checkbox">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      id={objItem2.id}
                                                      checked={
                                                        (objItem2.is_checked_list ==
                                                        1
                                                          ? true
                                                          : updateList?.some(
                                                            (id) =>
                                                              id == objItem2.id
                                                          ) ? true : false)
                                                        
                                                      }
                                                      onChange={(e) =>
                                                        handleChangeList(
                                                          objItem2.id,
                                                          e.target.checked
                                                        )
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor={objItem2.id}
                                                    >
                                                      {objItem2.doc_name}
                                                    </label>
                                                    <label
                                                      onClick={() =>
                                                        hadleDeleteList(
                                                          objItem2.id
                                                        )
                                                      }
                                                    >
                                                      {" "}
                                                      &nbsp;
                                                      <i
                                                        className="fa fa-trash"
                                                        style={{
                                                          color: "red",
                                                          cursor: "pointer",
                                                        }}
                                                        aria-hidden="true"
                                                      ></i>
                                                    </label>
                                                  </div>
                                                </div>
                                              </Row>
                                            </>
                                          );
                                        })}
                                      </Col>
                                      <Col xs="6">
                                        <Row xs="12">
                                          <h4>
                                            Applicant Dependant's Checklist
                                          </h4>
                                        </Row>
                                        {objItem[1][
                                          "applicant_dependant_checklist"
                                        ].map((objItem2, index) => {
                                          return (
                                            <>
                                              <Row xs="12">
                                                <div className="mb-2">
                                                  <div className="form-check custom-checkbox">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      id={objItem2.id}
                                                      checked={
                                                        (objItem2.is_checked_list ==
                                                        1
                                                          ? true
                                                          : updateList?.some(
                                                            (id) =>
                                                              id == objItem2.id
                                                          ) ? true : false)                                                        
                                                      }
                                                      onChange={(e) =>
                                                        handleChangeList(
                                                          objItem2.id,
                                                          e.target.checked
                                                        )
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor={objItem2.id}
                                                    >
                                                      {objItem2.doc_name}
                                                    </label>
                                                    <label
                                                      onClick={() =>
                                                        hadleDeleteList(
                                                          objItem2.id
                                                        )
                                                      }
                                                    >
                                                      &nbsp;
                                                      <i
                                                        style={{
                                                          color: "red",
                                                          cursor: "pointer",
                                                        }}
                                                        className="fa fa-trash"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </label>
                                                  </div>
                                                </div>
                                              </Row>
                                            </>
                                          );
                                        })}
                                      </Col>
                                    </Row>
                                  </AccordionBody>
                                </>
                              );
                            })}
                        </>
                      );
                    })}
                </AccordionItem>
              </UncontrolledAccordion>
            </Col>
          </Row>
          {/* <Row>
            <div className="col text-center m-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleUpdateList}
              >
                Submit
              </button>
            </div>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MasterDocs;
