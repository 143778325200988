import React, { useEffect, useRef, useState } from "react";
import {
    Col, Container, Row, Form, Label, Input,
    FormGroup,
    Modal,
    FormFeedback,
} from "reactstrap"
import { useAlert } from "../../../components/Common/Alert";

// import fs from 'fs';
import { usePDF, PDFViewer, pdf, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

import moment from "moment";

import Dropzone from "react-dropzone";

import { Link } from "react-router-dom";

import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import * as Yup from "yup";
import { useFormik, Field, FieldArray, FormikProvider, ErrorMessage } from "formik";
import classnames from "classnames";
// Form Editor
import { convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


import { changePreloader, getInvoices, getInvoiceDetail, addInvoiceDetail, updateInvoicePdf, recordPayment as recordPaymentApi, sendInvoice as sendInvoiceApi, getInvoiceSettings, invoiceUpdate, getEmployerContacts as getEmployerContacts, getEmployerContactDetail, addEmployerContactDetail, EmployerContactUpdate, uploadInvoiceAttachment } from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";


const ViewInvoice = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const invoiceId = queryParams.get('invoiceId'); // Read the 'invoiceId' query parameter from the URL
    const sendInvoiceParam = queryParams.get('sendinvoice') ? true : false; // Read the 'sendinvoice' query parameter from the URL


    const [sendInvoice, setSendInvoice] = useState(sendInvoiceParam)
    const [recordPaymentModal, setRecordPaymentModal] = useState(false)

    const [editorState, seteditorState] = useState({});

    const [activeTab1, setactiveTab1] = useState("invoice");
    const toggle1 = (tab) => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab);
        }
    };

    const moreactions = [{ 'label': 'Record Payment', 'action': 'recordpayment' }, { 'label': 'Export as PDF', 'action': 'pdf' }, { 'label': 'Print PDF', 'action': 'printpdf' }, { 'label': 'Resend Invoice', 'action': 'saveandsend' }]
    const handleActionSubmit = (e) => {
        validation.handleSubmit();
    };
    const handleRecordPaymentActionSubmit = (e) => {
        record_payment_validation.handleSubmit();
    };
    const onEditorStateChange = (editorState) => {
        seteditorState(editorState)
        validation.values.message = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    };
    const onEditorFooterStateChange = (editorFooterDescription) => {
        seteditorFooterDescription(editorFooterDescription)
        validation.values.footer_description = draftToHtml(convertToRaw(editorFooterDescription.getCurrentContent()));
    };
    const {
        recordPaymentDetail, invoiceDetail, employercontacts, invoicesettings, uploadedinvoiceattachments } = useSelector((state) => ({
            invoiceDetail: state.Invoices.invoiceDetail,
            recordPaymentDetail: state.Invoices.recordPaymentDetail,
            employercontacts: state.EmployerContacts.employercontacts || [],
            invoicesettings: state.Invoices.invoicesettings || {},
            uploadedinvoiceattachments: state.Invoices.uploadedinvoiceattachments || "",
        }));

    const pdfRef = useRef(null);

    const printDocument = (print) => {
        if (pdfRef) {
            if (Object.keys(invoiceDetail).length > 0) {
                const unit = "pt";
                const size = "A4"; // Use A1, A2, A3 or A4
                const orientation = "portrait"; // portrait or landscape
                const content = pdfRef.current;
                const doc = new jsPDF(orientation, unit, size);
                doc.margin = { horiz: 20, vert: 10 };
                doc.setFontSize(1);
                doc.setPage(1);
                doc.html(content, {
                    callback: function (doc) {
                        dispatch(changePreloader(true));
                        let file_name = invoiceDetail.invoice_number + '.pdf';
                        if (print == "saveandsend") {
                            if (invoiceDetail.invoice_pdf_file == null || invoiceDetail.invoice_pdf_file.length === 0) {
                                var binaryStr = doc.output('blob');
                                dispatch(updateInvoicePdf({
                                    invoice_id: invoiceId,
                                    invoice_pdf_file: binaryStr,
                                    name: file_name
                                }));
                            }
                            setSendInvoice(true);
                        }
                        if (print == "pdf") {
                            doc.save(file_name);
                        }
                        if (print == "printpdf") {
                            window.open(doc.output('bloburl'));
                        }
                        dispatch(changePreloader(false));
                    },
                    html2canvas: { scale: 0.5 }
                });
            }
        }
    };

    useEffect(() => {
        dispatch(getInvoiceDetail({ invoice_id: invoiceId }));
        dispatch(getInvoices({ invoice_type: 'Open' }));
        dispatch(getInvoiceSettings({}));
        // dispatch(getInvoiceSettings(values));
        // setInitialValues(invoicesettings?.invoice_settings)
        // dispatch(getEmployerContactDetail(invoiceId));
    }, [dispatch]);

    useEffect(() => {
        if (sendInvoice) {
            if (invoiceDetail.invoice_pdf_file == null || invoiceDetail.invoice_pdf_file.length === 0) {
                printDocument("saveandsend")
            }
        }
    }, [sendInvoice, invoiceDetail]);

    // Form Send Invoice validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            invoice_id: invoiceId,
            to_email: '',
            from_email: '',
            cc_email: '',
            message: '',
            subject: '',
            // invoice_attachments: [],
            // invoice_action: '',
        },
        validationSchema: Yup.object({
            invoice_action: Yup.string().nullable(),
            invoice_attachments: Yup.array().nullable(),
            subject: Yup.string().required("Subject required").nullable(),
            from_email: Yup.string().required("From email address required").nullable(),
            to_email: Yup.string().required("To email address required").nullable(),
            cc_email: Yup.string().nullable(),
            message: Yup.string().nullable(),
        }),
        onSubmit: (values) => {
            dispatch(changePreloader(true));
            dispatch(sendInvoiceApi(values));
            dispatch(getInvoices({ invoice_type: 'Open' }));
            setTimeout(() => {
                dispatch(changePreloader(false));
                navigate("/employer/invoices", { replace: true });
            }, 1000);
            // setSendInvoice(false);
        }
    });

    // Form RecordPayment validation 
    const record_payment_validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            invoice_id: invoiceId,
            payment_items: [{
                payment_record_id: '', payment_amount: 0, receive_date: '',
                payment_type: '', reference: '', is_discount_amount_enable: false,
                discount_amount: '', qb_deposit_to_account_ref: ''
            }],
        },
        validationSchema: Yup.object({
            payment_items: Yup.array()
                .of(
                    Yup.object().shape({
                        // payment_record_id: Yup.string().required("Please Enter Payment Record Id").nullable(),
                        // payment_amount: Yup.number().min(0).default(1).positive("Must be a positive value").transform((value) => Number.isNaN(value) ? null : value).required("Please Enter Payment").typeError('Payment must be a number'),
                        // receive_date: Yup.string().required("Please Enter Receive Date").nullable(),
                        // payment_type: Yup.string().required("Please Enter Payment Type").nullable(),
                        // reference: Yup.string().required("Please Enter Reference").nullable(),
                        // is_discount_amount_enable: Yup.boolean(),
                        // discount_amount: Yup.number().when('is_discount_amount_enable', {
                        //     is: (val) => val === true, //&& val.includes("1"),
                        //     then: Yup.number().min(0).default(1).positive("Must be a positive value").transform((value) => Number.isNaN(value) ? null : value).typeError('Amount must be a number')
                        //         .required("Please Enter Discount amount").nullable(),
                        //     otherwise: () => Yup.number().default(0).nullable(),
                        // }),
                        // qb_deposit_to_account_ref: Yup.string().nullable(),

                    })
                ) // .required('Must filled the payment details'),
            ,
        }),
        onSubmit: (values) => {
            dispatch(changePreloader(true));
            dispatch(recordPaymentApi(values));
            dispatch(getInvoices({ invoice_type: 'Open' }));
            setRecordPaymentModal(false);
            setTimeout(() => {
                dispatch(changePreloader(false));
                navigate("/employer/invoices", { replace: true });
            }, 1000);
        }
    });

    let title = invoiceId ? 'View Invoice' : 'Invoice';
    //meta title
    document.title = title + " | Immidock ";
    return (
        <React.Fragment>
            {(Object.keys(invoiceDetail).length > 0) && (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0 font-size-18">{title} Invoice</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a href="javascript: void(0);">Invoices</a></li>
                                                <li className="breadcrumb-item active">Invoice Detail</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div className="col-12">
                                <div className="row">
                                    <Col md="2">
                                        <p className="">Status: {invoiceDetail.status}</p>
                                    </Col>
                                    <Col md="4">
                                        <p className="">Invoice Due Date: {(invoiceDetail.invoice_due_date != '') && (
                                            <>
                                                {(new Date(invoiceDetail.invoice_due_date)).getDate()} {(new Date(invoiceDetail.invoice_due_date)).toLocaleString('default', { month: 'short' })}, {(new Date(invoiceDetail.invoice_due_date)).getFullYear()}
                                            </>)}</p>
                                    </Col>
                                    <Col md="3">
                                        <p className="">Balance Due: ${invoiceDetail?.balance_amount}</p>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Input
                                                name="invoice_action"
                                                placeholder="More Actions"
                                                type="select"
                                                className="form-control"
                                                id="invoice_action"
                                                onChange={(e) => {
                                                    if (e.target.value == "recordpayment") {
                                                        setRecordPaymentModal(true);
                                                    } else {
                                                        printDocument(e.target.value);
                                                    }
                                                }}
                                            >
                                                <option value="">More Actions</option>
                                                {moreactions.map((moreaction, index) => (
                                                    <option key={moreaction.action + index} value={moreaction.action}>
                                                        {moreaction.label}
                                                    </option>
                                                ))}
                                            </Input>
                                            {/* {validation.touched.customer_id && validation.errors.customer_id ? (
                                                <FormFeedback type="invalid">{validation.errors.customer_id}</FormFeedback>
                                            ) : null} */}
                                        </FormGroup>
                                    </Col>

                                </div>
                                <div className="row" ref={pdfRef}>
                                    <div className="col-lg-12">

                                        <div className="card">
                                            <div className="card-body">
                                                <div className="invoice-title">
                                                    <div className="d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <div className="mb-4">
                                                                {/* <img src="assets/images/logo-sm.svg" alt="" height="24"><span className="logo-txt">Minia</span></img> */}
                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="mb-4">
                                                                <h4 className="font-size-16">Invoice # {invoiceDetail.invoice_number}</h4>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <p className="mb-1">1874 County Line Road City, FL 33566</p>
                                                    <p className="mb-1"><i className="mdi mdi-email align-middle me-1"></i> abc@123.com</p>
                                                    <p><i className="mdi mdi-phone align-middle me-1"></i> 012-345-6789</p>
                                                </div>
                                                <hr className="my-4"></hr>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div>
                                                            <h5 className="font-size-15 mb-3">Billed To:</h5>
                                                            {/* {(Object.keys(billedTo).length > 0) && (
                                                                <>
                                                                    <h5 className="font-size-14 mb-2">{billedTo.cmp_name || ""}</h5>
                                                                    <p className="mb-1">{billedTo.inv_email || ""}</p>
                                                                    <p className="mb-1">{billedTo.cmp_street || ""} {billedTo.state || ""} {billedTo.cmp_city || ""} {billedTo.cmp_country || ""} {billedTo.zipcode || ""}</p>
                                                                    <p>{billedTo.cmp_phone || ""}</p>
                                                                </>
                                                            )} */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div>
                                                            <div>
                                                                <h5 className="font-size-15">Invoice Date  : </h5>
                                                                {(invoiceDetail.invoice_date != '') && (
                                                                    <p>{(new Date(invoiceDetail.invoice_date)).getDate()} {(new Date(invoiceDetail.invoice_date)).toLocaleString('default', { month: 'short' })}, {(new Date(invoiceDetail.invoice_date)).getFullYear()}</p>
                                                                )}
                                                            </div>

                                                            {invoiceDetail.is_payment_due_enable && (
                                                                <div className="mt-4">
                                                                    <h5 className="font-size-15">Payment Due:</h5>
                                                                    {(invoiceDetail.invoice_due_date != '') && (
                                                                        <p>{(new Date(invoiceDetail.invoice_due_date)).getDate()} {(new Date(invoiceDetail.invoice_due_date)).toLocaleString('default', { month: 'short' })}, {(new Date(invoiceDetail.invoice_due_date)).getFullYear()}</p>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {invoiceDetail.is_invoice_period_enable && (
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div>
                                                                <div>
                                                                    <h5 className="font-size-15">Invoice Period : </h5>
                                                                    {(invoiceDetail.invoice_from_date != '' && invoiceDetail.invoice_to_date != '') && (
                                                                        <p>{(new Date(invoiceDetail.invoice_from_date)).getDate()} {(new Date(invoiceDetail.invoice_from_date)).toLocaleString('default', { month: 'short' })}, {(new Date(invoiceDetail.invoice_from_date)).getFullYear()} - {(new Date(invoiceDetail.invoice_to_date)).getDate()} {(new Date(invoiceDetail.invoice_to_date)).toLocaleString('default', { month: 'short' })}, {(new Date(invoiceDetail.invoice_to_date)).getFullYear()}</p>
                                                                    )}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {invoiceDetail.is_candidate_name_enable && (
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div>
                                                                <div>
                                                                    <h5 className="font-size-15">Candidate Name: </h5>
                                                                    <p>{invoiceDetail.emp_name}</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                )}
                                                {invoiceDetail.is_job_title_enable && (
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div>
                                                                <div>
                                                                    <h5 className="font-size-15">Job Title: </h5>
                                                                    <p>{invoiceDetail.service_name}</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                )}
                                                {invoiceDetail.is_service_enable && (
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div>
                                                                <div>
                                                                    <h5 className="font-size-15">Service : </h5>
                                                                    <p>{invoiceDetail.service_name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}



                                                <div className="py-2 mt-3">
                                                    <h5 className="font-size-15">Order summary</h5>
                                                </div>
                                                <div className="p-4 border rounded">
                                                    <div className="table-responsive">
                                                        <table className="table table-nowrap align-middle mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "70px" }}>S.No.</th>
                                                                    <th className="text-end" style={{ width: "120px" }}>Description</th>
                                                                    <th className="text-end" style={{ width: "120px" }}>Hours</th>
                                                                    <th className="text-end" style={{ width: "120px" }}>Rate</th>
                                                                    <th className="text-end" style={{ width: "120px" }}>Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(Object.keys(invoiceDetail.invoice_items).length > 0) && invoiceDetail.invoice_items.map((item, index) => (
                                                                    <tr key={index + "itemdisplay"}>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>
                                                                            <h5 className="font-size-15 mb-1">{item.description}</h5>
                                                                            {/* <p className="font-size-13 text-muted mb-0">item desc </p> */}
                                                                        </td>
                                                                        <td className="text-end">{item.hours}</td>
                                                                        <td className="text-end">${item.rate}</td>
                                                                        <td className="text-end">{(item.hours && item.rate) ? "$" + parseFloat(item.hours * item.rate).toFixed(2) : ""}</td>
                                                                    </tr>
                                                                ))}


                                                                <tr>
                                                                    <th scope="row" colSpan="4" className="text-end">Sub Total</th>
                                                                    <td className="text-end">
                                                                        ${invoiceDetail.invoice_amount}
                                                                    </td>
                                                                </tr>
                                                                {(invoiceDetail.is_discount_percentage_enable || invoiceDetail.is_discount_amount_enable) && (
                                                                    <tr>
                                                                        <td scope="row" colSpan="4" className="text-end">Discount {invoiceDetail.is_discount_percentage_enable ? "(" + invoiceDetail.discount_percentage + "%)" : ""}</td>
                                                                        < td className="text-end">
                                                                            ${invoiceDetail.total_discount_amount}
                                                                        </td>
                                                                    </tr>
                                                                )}

                                                                <tr>
                                                                    <th scope="row" colSpan="4" className="border-0 text-end">Total</th>
                                                                    <td className="border-0 text-end"><h4 className="m-0">${invoiceDetail.total_amount}</h4></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                {invoiceDetail.is_footer_enable && (
                                                    <div className="py-2 mt-3">
                                                        <h5 className="font-size-15">Notes:</h5>
                                                        <p dangerouslySetInnerHTML={{ __html: invoiceDetail.footer_description }}></p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FormikProvider value={validation}>
                        <Form className="needs-validation"
                            onSubmit={(values, { validate }) => {
                                values.preventDefault();
                                // console.log(validate(values));
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <Modal
                                isOpen={sendInvoice}
                                toggle={() => {
                                    // tog_varyingmodal('@mdo')
                                }}
                                scrollable={true}
                                id="staticBackdrop"
                            >
                                <div className="modal-header">
                                    <h5 className="modal-title">Send Invoice</h5>
                                    <button type="button" className="btn-close"
                                        onClick={() => {
                                            setSendInvoice(false)
                                        }} aria-label="Send Invoice"></button>
                                </div>
                                <div className="modal-body">
                                    <FormGroup className="mb-3">

                                        <Label htmlFor="to_email">To Email *</Label>
                                        <Field
                                            name="to_email"
                                            placeholder="To Email"
                                            type="text"
                                            className="form-control"
                                            id="to_email"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.to_email || ""}
                                            invalid={
                                                validation.touched.to_email && validation.errors.to_email ? true : false
                                            }
                                        />
                                         {validation.touched.to_email && validation.errors.to_email ? (
                                            <FormFeedback type="invalid">{validation.errors.to_email}</FormFeedback>
                                        ) : null}

                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="from_email">From Email *</Label>
                                        <Field
                                            name="from_email"
                                            placeholder="From Email"
                                            type="text"
                                            className="form-control"
                                            id="from_email"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.from_email || ""}
                                            invalid={
                                                validation.touched.from_email && validation.errors.from_email ? true : false
                                            }
                                        />
                                        {validation.touched.from_email && validation.errors.from_email ? (
                                            <FormFeedback type="invalid">{validation.errors.from_email}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="cc_email">Cc Email</Label>
                                        <Field
                                            name="cc_email"
                                            placeholder="Cc Email"
                                            type="text"
                                            className="form-control"
                                            id="cc_email"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.cc_email || ""}

                                        />
                                        <ErrorMessage
                                            name="cc_email"
                                            component="div"
                                            className="text-danger"
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="subject">Subject *</Label>
                                        <Field
                                            name="subject"
                                            placeholder="Subject"
                                            type="text"
                                            className="form-control"
                                            id="subject"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.subject || ""}
                                            invalid={
                                                validation.touched.subject && validation.errors.subject ? true : false
                                            }
                                        />
                                        {validation.touched.subject && validation.errors.subject ? (
                                            <FormFeedback type="invalid">{validation.errors.subject}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="message">Message</Label>
                                        <Editor
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChange}

                                        />
                                    </FormGroup>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={(e) => {
                                        handleActionSubmit(e);
                                    }}>Send</button>
                                    {/* 
                                                <Row className="justify-content-middle">
                                                    <Col sm={12}>
                                                        <FormGroup className="mb-0">
                                                            <div>
                                                                <Button type="submit" color="primary" className="ms-1">
                                                                    Save
                                                                </Button>{" "}
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row> */}
                                </div>
                            </Modal>
                        </Form>
                    </FormikProvider>



                    <FormikProvider value={record_payment_validation}>
                        <Form className="needs-validation"
                            onSubmit={(values, { validate }) => {
                                values.preventDefault();
                                // console.log(validate(values));
                                record_payment_validation.handleSubmit();
                                return false;
                            }}
                        >
                            <Modal
                                isOpen={recordPaymentModal}
                                toggle={() => {
                                    // tog_varyingmodal('@mdo')
                                }}
                                scrollable={true}
                                id="staticBackdrop"
                            >
                                <div className="modal-header">
                                    <h5 className="modal-title">Record Payment</h5>
                                    <button type="button" className="btn-close"
                                        onClick={() => {
                                            setRecordPaymentModal(false)

                                        }} aria-label="Send Invoice"></button>
                                </div>
                                <div className="modal-body">


                                    <FieldArray
                                        name="payment_items"
                                        render={arrayHelpers => (
                                            <div>
                                                {record_payment_validation.values.payment_items.map((item, index) => (
                                                    <div key={index + "item"}>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor={`payment_items.${index}.payment_amount`}>Payment Amount</Label>

                                                                <Field type="text"
                                                                    className="form-control"
                                                                    placeholder="Payment Amount"
                                                                    name={`payment_items.${index}.payment_amount`} />

                                                                <ErrorMessage
                                                                    name={`payment_items.${index}.payment_amount`}
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor={`payment_items.${index}.receive_date`}>Receive Date</Label>

                                                                <Field type="date"
                                                                    className="form-control"
                                                                    placeholder="Receive Date"
                                                                    name={`payment_items.${index}.receive_date`} />

                                                                <ErrorMessage
                                                                    name={`payment_items.${index}.receive_date`}
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor={`payment_items.${index}.reference`}>Reference #</Label>
                                                                <Field type="text"
                                                                    className="form-control"
                                                                    placeholder="Reference #"
                                                                    name={`payment_items.${index}.reference`} />

                                                                <ErrorMessage
                                                                    name={`payment_items.${index}.reference`}
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor={`payment_items.${index}.payment_type`}>Payment Type</Label>
                                                                <Field as="select"
                                                                    className="form-control"
                                                                    placeholder="Payment Type"
                                                                    name={`payment_items.${index}.payment_type`} >
                                                                    <option value="">Select Payment Type</option>
                                                                    <option value="Check">Check</option>
                                                                    <option value="ACH">ACH</option>
                                                                    <option value="DirectDeposit">DirectDeposit</option>
                                                                    <option value="Cash">Cash</option>
                                                                </Field>

                                                                <ErrorMessage
                                                                    name={`payment_items.${index}.payment_type`}
                                                                    component="div"
                                                                    className="text-danger"
                                                                />



                                                                {/* <Input
                                                                    name="invoice_action"
                                                                    placeholder="More Actions"
                                                                    type="select"
                                                                    className="form-control"
                                                                    id="invoice_action"
                                                                    onChange={(e) => {
                                                                        if (e.target.value == "recordpayment") {
                                                                            setRecordPaymentModal(true);
                                                                        } else {
                                                                            printDocument(e.target.value);
                                                                        }
                                                                    }}
                                                                >

                                                                </Input> */}
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md="12">
                                                            <div className="mb-3">
                                                                <div
                                                                    className="form-check form-switch form-switch-md mb-3"
                                                                    dir="ltr"
                                                                >
                                                                    <Label htmlFor={`payment_items.${index}.is_discount_amount_enable`}>Discount</Label>
                                                                    <Field type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customSwitchsizelg"
                                                                        name={`payment_items.${index}.is_discount_amount_enable`} />

                                                                    <ErrorMessage
                                                                        name={`payment_items.${index}.is_discount_amount_enable`}
                                                                        component="div"
                                                                        className="text-danger"
                                                                    />

                                                                </div>
                                                            </div>

                                                            {record_payment_validation.values.payment_items[index].is_discount_amount_enable && (
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor={`payment_items.${index}.discount_amount`}>Discount Amount</Label>
                                                                    <Field type="text"
                                                                        className="form-control"
                                                                        placeholder="Discount Amount"
                                                                        name={`payment_items.${index}.discount_amount`} />

                                                                    <ErrorMessage
                                                                        name={`payment_items.${index}.discount_amount`}
                                                                        component="div"
                                                                        className="text-danger"
                                                                    />
                                                                </FormGroup>
                                                            )}
                                                        </Col>

                                                        {record_payment_validation.values.payment_items.length > 1 ? (
                                                            <>
                                                                <Col md="12 text-end">
                                                                    <button className="mb-3" type="button" onClick={() => arrayHelpers.remove(index)}>
                                                                        - Remove Item
                                                                    </button>
                                                                </Col>
                                                            </>
                                                        ) : ''}
                                                    </div>
                                                ))}

                                                <Row className="justify-content-middle">
                                                    <Col md="8"></Col>
                                                    <Col md="4">
                                                        <button
                                                            className="mb-3"
                                                            type="button"
                                                            onClick={() => arrayHelpers.push({ name: '', age: '' })}
                                                        >
                                                            + Add Payment
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    />


                                    {validation.values.is_discount_amount_enable && (
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="discount_amount">Discount Amount</Label>
                                            <Input
                                                name="discount_amount"
                                                placeholder="Discount Amount"
                                                type="text"
                                                className="form-control"
                                                id="discount_amount"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.discount_amount || 0}
                                                invalid={
                                                    validation.touched.discount_amount && validation.errors.discount_amount ? true : false
                                                }
                                            />
                                            {validation.touched.discount_amount && validation.errors.discount_amount ? (
                                                <FormFeedback type="invalid">{validation.errors.discount_amount}</FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    )}

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={(e) => {
                                        handleRecordPaymentActionSubmit(e);
                                    }}>Record Payment</button>

                                </div>
                            </Modal>
                        </Form>
                    </FormikProvider>

                </>
            )}
        </React.Fragment >
    );
};

export default ViewInvoice;