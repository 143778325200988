import React, { useEffect, useMemo, useState } from "react";
import DatatableContainer from "../../../components/Common/DatatableContainer";
import {
  Link, useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";

//import { getCaseFormAttributesets } from "../../../store/actions";
import { getCaseFormAttributes, getcaseformAttributesets } from "../../../store/actions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import {
//     CommonCell,Date,CaseTypeName
// } from "./caseTypeListCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";
import { caseFormAttributeSetDelete, getCaseFormAttributeSetDetail } from "../../../store/admin/caseformattributesets/actions";
import { useAlert } from "../../../components/Common/Alert";


const CaseTypesList = () => {

  //meta title
  document.title = "Case Form Attribute Sets | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [order, setOrder] = useState(null);

  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);

  const { caseformattributesets } = useSelector((state) => ({
    caseformattributesets: state.caseformattributesets.caseformattributesets,
  }));


  useEffect(() => {
    dispatch(getCaseFormAttributes());
    dispatch(getcaseformAttributesets());
  }, [dispatch]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleUserClicks = () => {
    dispatch(getCaseFormAttributeSetDetail());
    navigate(`/admin/add-case-form-attribute-sets`);
  };

  const onUpdateData = (data) => {
    if (data.id) {
      dispatch(getCaseFormAttributeSetDetail(data.id));
    }
    navigate(`/admin/add-case-form-attribute-sets?id=${data.id}`);
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setOrder(data);
  };

  const handleDeleteInvoice = () => {
    if (order.id) {
      dispatch(caseFormAttributeSetDelete(order.id));
      setDeleteModal(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        filterable: true,
        disableFilters: true,
      }, ,
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
        disableFilters: true,
        // Cell: (cellProps) => {
        //   return <CaseTypeName {...cellProps} />;
        // },
      },
      {
        Header: "Case Form",
        accessor: "form_name",
        filterable: true,
        disableFilters: true,
        // Cell: (cellProps) => {
        //   return <CaseTypeName {...cellProps} />;
        // },
      },

      {
        Header: "Description",
        accessor: "description",
        filterable: true,
        disableFilters: true,
        // Cell: (casetype) => {
        //   console.log(casetype.value,'=======status');
        //   return (
        //     <div className={""}>
        //       {casetype.value}
        //     </div>
        //   )
        // },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        // Cell: (casetype) => {
        //   console.log(casetype.value,'=======status');
        //   return (
        //     <div className={""}>
        //       {casetype.value}
        //     </div>
        //   )
        // },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        filterable: true,
        disableFilters: true,
        // Cell: (cellProps) => {
        //   return <Date {...cellProps} />;
        // },
      },


      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => onUpdateData(cellProps.row.original)}>Edit</DropdownItem>
                <DropdownItem onClick={() => onDeleteData(cellProps.row.original)}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );
  const BreadcrumbData = [
    { name: 'Dashboard', url: '/admin/dashboard' },
    { name: 'Case Form Attribute Sets', url: '/admin/case-form-attribute-sets' }
  ]
  let title = 'Case Form Attribute Sets';

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteInvoice}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <DatatableContainer
                    columns={columns}
                    data={caseformattributesets}
                    isGlobalFilter={true}
                    isAddCaseFormAttributeSet={true}
                    isFilterDisable={true}
                    customPageSize={10}
                    handleInvoiceClick={handleUserClicks}
                  />
                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CaseTypesList;
